import { PelikanStatusEnum } from "../../types/domain";
import { IsPelikanStatusOpen, PelikanStatusName } from "../../utilities/domain-utils";
import { FormValidationUtility } from "./FormValidationUtility";

type PelikanStatusInputProps = {
    initialStatus?: PelikanStatusEnum,
    fvu?: FormValidationUtility,
    status?: PelikanStatusEnum,
    onChange: (status?: PelikanStatusEnum) => void,
    canResolve?: boolean,
    canInstall?: boolean,
    canManage?: boolean
};

const decoyFvu = new FormValidationUtility();

export function PelikanStatusInput(props: PelikanStatusInputProps) {
    const renderStatusOption = function(status: PelikanStatusEnum) {
        return <option key={status} value={status}>{PelikanStatusName(status)}</option>;
    }

    const onPelikanStatusChange = function (event: React.ChangeEvent<HTMLSelectElement>) {
        const status: PelikanStatusEnum = parseInt(event.currentTarget.value);

        if (props.onChange) {
            props.onChange(status);
        }
    }

    const pelikanStatusesToSelect = function (): PelikanStatusEnum[] {

        const allPelikanStatuses = [
            PelikanStatusEnum.Open,
            PelikanStatusEnum.InProgress,
            PelikanStatusEnum.Installed,
            PelikanStatusEnum.Solved,
            PelikanStatusEnum.Archived,
            PelikanStatusEnum.Removed
        ];

        const isOpen = !props.initialStatus
            || IsPelikanStatusOpen(props.initialStatus);

        return allPelikanStatuses.filter(ps => {
            // Can set to current pelikan's status value
            if (ps == props.initialStatus) {
                return true;
            }

            // If it is closed nobody can reopen
            if (!isOpen && IsPelikanStatusOpen(ps)) {
                return false;
            }

            if (props.canManage) {
                return true;
            }

            if (ps == PelikanStatusEnum.Installed && props.canInstall) {
                return true;
            }

            if (ps == PelikanStatusEnum.Solved && props.canResolve) {
                return true;
            }

            return false;
        });
    }

    const fvu = props.fvu || decoyFvu;

    return <>
        <select id="status" className={`form-select form-select-sm ${fvu.invalidClass('status')}`} value={props.status || '0'} onChange={onPelikanStatusChange}>
            {pelikanStatusesToSelect().map(renderStatusOption)}
        </select>
        {fvu.fieldFeedback('status')}
    </>

}