import React, { Component } from 'react';
import { WorktimeEntryDTO, OperationResultWithDataDTO, TesterNoteDTO, CabinetModelSubtypeBriefDTO } from '../types/dto';
import { WorkTypeEnum } from '../types/domain';
import { allWorkTypes, renderWorktimeDescription } from '../utilities/domain-utils';
import { Worktime } from './Worktime';
import { WorktimeForm } from './WorktimeForm';
import { Dropdown } from 'bootstrap';
import { apiFetchData } from '../utilities/auth-api';

type WorktimeTableRowProps = {
    worktimeEntry: WorktimeEntryDTO,
    canEdit: boolean,
    onWorktimeChanged?: (we: WorktimeEntryDTO) => void,
    testerNotes: TesterNoteDTO[],
    cabinetModelSubTypes?: CabinetModelSubtypeBriefDTO[],
    allowSetDateAndEmployee?: boolean
};

type WorktimeTableRowState = {
    isEdited: boolean,
    editedDropdown: Dropdown | null,
    availableWorktypes: WorkTypeEnum[]
};

export class WorktimeTableRow extends Component<WorktimeTableRowProps, WorktimeTableRowState> {
    static displayName = WorktimeTableRow.name;

    constructor(props: WorktimeTableRowProps) {
        super(props);

        this.state = {
            isEdited: false,
            editedDropdown: null,
            availableWorktypes: []
        };
        this.startEdit = this.startEdit.bind(this);
        this.onWorktimeSaved = this.onWorktimeSaved.bind(this);
        this.fetchAvailableWorktypes = this.fetchAvailableWorktypes.bind(this);
    }

    workTypeAndEmployee(we: WorktimeEntryDTO): React.ReactNode {
        return <>
            {renderWorktimeDescription(we)}<br /><small>{we.Employee.Surname} {we.Employee.FirstName}</small>
        </>;
    }

    async startEdit(event: React.MouseEvent, we: WorktimeEntryDTO) {
        const element = event.currentTarget as Element;
        const dropdown = Dropdown.getInstance(element)!;

        await this.fetchAvailableWorktypes();

        this.setState({
            isEdited: true,
            editedDropdown: dropdown
        });

        dropdown.show();
    }

    async fetchAvailableWorktypes() {

        if (this.props.allowSetDateAndEmployee) {
            // This means we can set any worktype
            this.setState({
                availableWorktypes: allWorkTypes
            });
            return;
        }

        const result = await apiFetchData(`project/worktime/${this.props.worktimeEntry.Id}/type`) as OperationResultWithDataDTO<WorkTypeEnum[]>;

        if (result && result.Success) {
            this.setState({
                availableWorktypes: result.Data || []
            });
        }
    }


    onWorktimeSaved(we: WorktimeEntryDTO): void {
        if (this.state.editedDropdown) {
            this.state.editedDropdown.hide();
        }

        if (this.props.onWorktimeChanged) {
            this.props.onWorktimeChanged(we);
        }
    }

    render() {
        const we = this.props.worktimeEntry;

        return (
            <tr>
                <td>{this.workTypeAndEmployee(we)}</td>
                <td><Worktime start={we.Start!} end={we.End} showStartEnd={true} /></td>
                <td>{this.props.canEdit && <div className="dropdown">
                    <button className="btn btn-sm btn-outline-primary dropdown-toggle" title="Edytuj wpis" type="button" data-bs-auto-close="outside" data-bs-toggle="dropdown" aria-expanded="false" onClick={(event) => this.startEdit(event, we)}><i className="fa-solid fa-edit"></i></button>
                    <div className="dropdown-menu dropdown-menu-end p-2">
                        {this.state.isEdited &&
                            <WorktimeForm
                            worktime={this.props.worktimeEntry}
                            onSave={this.onWorktimeSaved}
                            workTypes={this.state.availableWorktypes}
                            testerNotes={this.props.testerNotes}
                            cabinetModelSubTypes={this.props.cabinetModelSubTypes}
                            allowSetDate={this.props.allowSetDateAndEmployee}
                            allowSetEmployee={this.props.allowSetDateAndEmployee} />
                        }
                    </div>
                </div>}
                </td>
            </tr>
        );
    }
}
