import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { WorkStatusPane } from '../WorkStatusPane';

type LoginMenuProps = {
};

type LoginMenuState = {
    isAuthenticated: boolean,
    userName: string | null
};

export class LoginMenu extends Component<LoginMenuProps, LoginMenuState> {
    constructor(props: LoginMenuProps) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    _subscription: number = 0;

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: (user && user.userName) || null
        });
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = `${ApplicationPaths.LogOut}`;
            const logoutState = { local: true };

            return this.authenticatedView(
                userName,
                profilePath,
                logoutPath,
                logoutState);
        }
    }

    authenticatedView(
        userName: string | null,
        profilePath: string,
        logoutPath: string,
        logoutState: { local: boolean }
    ) {
        return (<Fragment>
            <NavItem>
                <WorkStatusPane />
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={profilePath}><i className="fa-solid fa-user" /> {userName}</NavLink>
            </NavItem>
            <NavItem>
                <NavLink replace tag={Link} className="text-dark" to={logoutPath} state={logoutState}>Wyloguj</NavLink>
            </NavItem>
        </Fragment>);
    }

    anonymousView(loginPath: string) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Zaloguj</NavLink>
            </NavItem>
        </Fragment>);
    }
}
