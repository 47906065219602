import React, { Component, Fragment } from 'react';
import { PelikanRemarkDTO } from '../types/dto';
import { PelikanRemarkForm } from './PelikanRemarkForm';
import authService from './api-authorization/AuthorizeService';
import { FormValidationUtility } from './common/FormValidationUtility';
import { doerName } from '../utilities/domain-utils';

type PelikanRemarksProps = {
    pelikanId: number,
    pelikanRemarks: PelikanRemarkDTO[],
    onSaved?: (pelikanRemark: PelikanRemarkDTO) => void
};

type PelikanRemarksState = {
    showList: boolean,
    editedRemarkId?: number,
    editedRemarkText: string,
    employeeId: number,
    fvu: FormValidationUtility
};

export class PelikanRemarks extends Component<PelikanRemarksProps, PelikanRemarksState> {
    static displayName = PelikanRemarks.name;

    constructor(props: PelikanRemarksProps) {
        super(props);

        this.state = {
            showList: true,
            editedRemarkText: '',
            employeeId: authService.getEmployeeIdSync(),
            fvu: new FormValidationUtility()
        };

        this.toggleList = this.toggleList.bind(this);
        this.onCancelEdit = this.onCancelEdit.bind(this);
        this.onStartEdit = this.onStartEdit.bind(this);
        this.onPelikanRemarkSaved = this.onPelikanRemarkSaved.bind(this);
        this.newRemark = this.newRemark.bind(this);
        this.hideForm = this.hideForm.bind(this);
    }

    toggleList() {
        this.setState({
            showList: !this.state.showList
        });
    }

    onPelikanRemarkSaved(pelikanRemark: PelikanRemarkDTO) {
        this.setState({
            editedRemarkId: undefined,
            editedRemarkText: ''
        });

        if (this.props.onSaved) {
            this.props.onSaved(pelikanRemark);
        }
    }

    onCancelEdit() {
        this.setState({
            editedRemarkId: undefined,
            editedRemarkText: ''
        });
    }

    onStartEdit(remarkId: number) {
        const editedRemark = this.props.pelikanRemarks.find(r => r.Id == remarkId);

        if (editedRemark) {
            this.setState({
                editedRemarkId: remarkId,
                editedRemarkText: editedRemark.Text
            });
        }
    }

    newRemark() {
        this.setState({
            editedRemarkId: 0,
            showList: true
        })
    }

    hideForm() {
        this.setState({
            editedRemarkId: undefined
        });
    }

    render() {
        const remarks = this.props.pelikanRemarks;
        const haveRemarks = this.props.pelikanRemarks.length > 0;
        const fvu = this.state.fvu;

        return (
            <Fragment>
                <div>
                    <strong>Dyskusja</strong>&nbsp;
                    {haveRemarks && <button className="btn btn-sm btn-outline-primary" type="button" title="Widok historii" onClick={this.toggleList}><i className={this.state.showList ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"} /></button>}
                    {this.state.editedRemarkId !== 0 && <button type="button" className="btn btn-sm btn-outline-primary ms-2" onClick={this.newRemark}><i className="fa-solid fa-plus" /> Dodaj komentarz</button>}
                </div>
                {(haveRemarks || this.state.editedRemarkId == 0) && this.state.showList && <table className="table table-sm table-borderless">
                    <tbody>
                        {remarks.map(r => {
                            if (r.Id == this.state.editedRemarkId) {
                                return <PelikanRemarkForm key={r.Id} pelikanId={this.props.pelikanId} remarkId={r.Id} initialRemarkText={r.Text} onSaved={this.onPelikanRemarkSaved} onCancel={this.onCancelEdit} renderAsTableRow={true} />;
                            } else {
                                return <tr key={r.Id}>
                                    <td className={`${this.getRemarkTextClass(r)}`}>
                                        <div>
                                            <p className="mb-0 text-secondary"><small>{doerName(r.Employee)}</small></p>
                                            <p className="mb-0">{r.Text}</p>
                                        </div>
                                    </td>
                                    <td>
                                        {r.Employee && r.Employee.Id == this.state.employeeId && <button type="button" className="btn btn-sm" onClick={() => this.onStartEdit(r.Id)}><i className="fa-regular fa-edit" /></button>}
                                    </td>
                                </tr>;
                            }
                        })}
                        {this.state.editedRemarkId == 0 && <PelikanRemarkForm pelikanId={this.props.pelikanId} onSaved={this.onPelikanRemarkSaved} onCancel={this.hideForm} renderAsTableRow={true} />}
                    </tbody>
                </table>}
            </Fragment>
        );
    }

    getRemarkTextClass(r: PelikanRemarkDTO) {
        return r.Employee && r.Employee.Id === this.state.employeeId
            ? "ps-4 table-primary"
            : "pe-4 table-light";
    }
}
