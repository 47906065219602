import React, { Component, Fragment } from 'react';
import { OperationResultWithDataDTO, QDETicketDTO } from '../types/dto';
import { apiFetchResponse } from '../utilities/auth-api';
import { CalculateDates, EnhanceQDETickets } from '../utilities/data-enhancer';
import { AllowsFeature, Features } from '../utilities/features';
import authService from './api-authorization/AuthorizeService';
import { ProjectNumberContext } from './contexts';
import { QDETicketsList } from './QDETicketsList';

type ProjectQDETicketsProps = {
    isVisible: boolean
};

type ProjectQDETicketsState = {
    isLoading: boolean,
    tickets?: QDETicketDTO[],
    canEdit: boolean
};

export class ProjectQDETickets extends Component<ProjectQDETicketsProps, ProjectQDETicketsState> {
    static displayName = ProjectQDETickets.name;
    static contextType = ProjectNumberContext;

    constructor(props: ProjectQDETicketsProps) {
        super(props);

        const userProfile = authService.getUserProfileSync();

        this.state = {
            isLoading: false,
            canEdit: AllowsFeature(Features.QDEManagement, userProfile)
        };

        this.fetchTickets = this.fetchTickets.bind(this);
        this.onTicketChanged = this.onTicketChanged.bind(this);
    }

    componentDidUpdate(prevProps: ProjectQDETicketsProps) {
        if (!prevProps.isVisible
            && this.props.isVisible
            && this.state.tickets == undefined
        ) {
            this.fetchTickets();
        }
    }

    componentDidMount() {
        if (this.props.isVisible) {
            this.fetchTickets();
        }
    }

    async fetchTickets() {
        const projectNumber = this.context;

        this.setState({
            isLoading: true
        });

        const response = await apiFetchResponse(`project/${projectNumber}/qde-ticket`);

        this.setState({
            isLoading: false
        });

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<QDETicketDTO[]>;
            if (result.Success) {
                var tickets = result.Data || [];

                CalculateDates(tickets);
                EnhanceQDETickets(tickets);

                this.setState({
                    tickets
                })
            }
        }
    }

    onTicketChanged(ticket: QDETicketDTO) {
        // NOTE - don't fetch tickets because that can cause ticket do disapear
        const { tickets } = this.state;
        if (!tickets) {
            return;
        }

        const newTickets = [...tickets];
        const itemIndex = newTickets.findIndex(t => t.Id == ticket.Id);

        newTickets[itemIndex] = ticket;
        this.setState({
            tickets: newTickets
        });
    }

    render() {
        return (
            <>
                <div className='row'>
                    <div className='col-auto'>
                        <button className='btn btn-outline-secondary h-100' type='button' title='Odśwież' disabled={this.state.isLoading} onClick={this.fetchTickets}>
                            <span className={this.state.isLoading ? 'spinner-border' : 'fa-solid fa-redo'}></span>
                        </button>
                    </div>
                </div>
                <QDETicketsList
                    projectNumber={this.context as number}
                    tickets={this.state.tickets || []}
                    canEditStatus={this.state.canEdit}
                    onTicketChanged={this.onTicketChanged}
                    showSum={true}
                />
            </>
        );
    }
}
