import ExcelJS from 'exceljs';
import { FMListEntryDTO, OperationResultWithDataDTO } from '../types/dto';
import { NumberDictionary } from '../types/global';
import { apiFetchData } from './auth-api';
import { OperationResult, OperationResultWithData } from './operation-result';

type FMListEntryData = {
    // MatNr
    MaterialNumber: string,
    // Materialtext
    MaterialDescription: string
    // Menge
    Quantity: number,
    RKZ: string,
};

function determineIndexes(firstRow: ExcelJS.Row, result: OperationResult): NumberDictionary {
    const indexes = {} as NumberDictionary;

    const columnNames: string[] = [
        'MatNr',
        'Materialtext',
        'Menge',
        'RKZ'
    ];

    var values = firstRow.values as ExcelJS.CellValue[];
    if (!values) {
        result.AddError("Brak wartości nagłówka w wierszu");
    }

    if (!result.IsSuccess()) {
        return indexes;
    }

    var rowColumnsNames = values.map(v => v as string);

    columnNames.forEach((columnName: string) => {
        const columnIndex = rowColumnsNames.findIndex(rcn => rcn == columnName);
        if (columnIndex < 0) {
            result.AddError(`Nie znaleziono w arkuszu Excela kolumny o nazwie ${columnName}`);
        } else {
            indexes[columnName] = columnIndex;
        }
    });

    if (!result.IsSuccess()) {
        return indexes;
    }

    return indexes;
}

function getFMListDataFromRow(row: ExcelJS.Row,
    indexes: NumberDictionary,
    rowIndex: number,
    result: OperationResult): FMListEntryData {

    var values = row.values as ExcelJS.CellValue[];

    console.log('Values from row', rowIndex, values);

    let MaterialNumber: string = values[indexes['MatNr']] as string;
    if (!MaterialNumber) {
        result.AddError(`Błąd wyciągania wartości 'MatNr' z wiersza ${rowIndex}`);
    }

    let MaterialDescription: string = values[indexes['Materialtext']] as string;
    if (!MaterialDescription) {
        result.AddError(`Błąd wyciągania wartości 'Materialtext' z wiersza ${rowIndex}`);
    }

    let Quantity: number = values[indexes['Menge']] as number;
    if (!Quantity) {
        result.AddError(`Błąd wyciągania wartości 'Menge' z wiersza ${rowIndex}`);
    }

    let RKZ: string = values[indexes['RKZ']] as string;
    if (!RKZ) {
        result.AddError(`Błąd wyciągania wartości 'RKZ' z wiersza ${rowIndex}`);
    }

    return {
        MaterialNumber,
        MaterialDescription,
        Quantity,
        RKZ
    };
}

export async function importFMListExcelFile(file: File, projectNumber: number): Promise<OperationResultWithData<FMListEntryDTO[]>> {

    const result = new OperationResultWithData<FMListEntryDTO[]>();

    try {
        const workbook = new ExcelJS.Workbook();
        const arrayBuffer = await file.arrayBuffer();

        await workbook.xlsx.load(arrayBuffer);

        console.log('File loaded');

        const sheet = workbook.worksheets[0];
        const firstRow = sheet.getRow(1);
        let dataStartRow = 2;

        let indexes = determineIndexes(firstRow, result);
        if (!result.IsSuccess()) {
            result.ClearErrors();

            const secondRow = sheet.getRow(2);
            dataStartRow = 3;
            indexes = determineIndexes(secondRow, result);

            if (!result.IsSuccess()) {
                return result;
            }
        }

        const importData: FMListEntryData[] = [];

        for (let ri = dataStartRow; ri <= sheet.rowCount; ri++) {
            const row = sheet.getRow(ri);
            const data = getFMListDataFromRow(row, indexes, ri, result);

            if (!result.IsSuccess()) {
                return result;
            }

            console.log('Row data', ri, data, result);

            if (data) {
                importData.push(data);
            }
        }

        const response = await apiFetchData(`fm-list/project/${projectNumber}`, {
            body: JSON.stringify(importData),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST'
        }) as OperationResultWithDataDTO<FMListEntryDTO[]>;

        console.log('FMList import response', response);

        if (response.Success) {
            result.Data = response.Data;
        } else {
            if (response.Errors) {
                response.Errors?.forEach(error => result.AddError(error.Message));
            } else {
                result.AddError("Błąd importu listy");
            }
        }

        return result;

    } catch (ex: any) {
        console.error('Wyjątek podczas importu pliku', ex);

        result.AddError(`Wyjątek podczas importu pliku ${ex}`);
    }

    return result;
}
