import React, { Component, Fragment } from 'react';
import { PelikanStatusEnum } from '../types/domain';
import { pelikanStatusClass, PelikanStatusName } from '../utilities/domain-utils';

type PelikanStatusIndicatorProps = {
    status: PelikanStatusEnum
};

type PelikanStatusIndicatorState = {
};

export class PelikanStatusIndicator extends Component<PelikanStatusIndicatorProps, PelikanStatusIndicatorState> {
    static displayName = PelikanStatusIndicator.name;

    constructor(props: PelikanStatusIndicatorProps) {
        super(props);

        this.state = {
        };
    }

    render() {
        return <div className={`badge ${pelikanStatusClass(this.props.status)}`}>{PelikanStatusName(this.props.status)}</div>
    }
}
