import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
      <div>
        <h1>Witaj!</h1>
        <p>Witamy w menadżerze projektów firmy BAM</p>
      </div>
    );
  }
}
