import React from "react";
import { Link } from "react-router-dom";

type AdvancedProjectLinkProps = {
    projectNumber: number
    onOpen: () => void
};

export function AdvancedProjectLink(props: AdvancedProjectLinkProps) {

    return <div className="btn-group btn-group-sm">
        <button type="button" className="btn btn-outline-secondary" title="Otwórz tutaj" onClick={props.onOpen}>{props.projectNumber}</button>
        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false"></button>
        <ul className="dropdown-menu dropdown-menu-end">
            <li><Link className="dropdown-item" to={`/projects/${props.projectNumber}`}>Otwórz link</Link></li>
        </ul>
    </div>;
}
