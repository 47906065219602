import React, { Component } from 'react';

type ProjectPartialNumberPickerProps = {
    onProjectNumberChange: (partialProjectNumber: string) => void
};

type ProjectPartialNumberPickerState = {
    partialProjectNumber: string
};

export class ProjectPartialNumberPicker extends Component<ProjectPartialNumberPickerProps, ProjectPartialNumberPickerState> {
    static displayName = ProjectPartialNumberPicker.name;

    constructor(props: ProjectPartialNumberPickerProps) {
        super(props);

        this.state = {
            partialProjectNumber: ''
        };

        this.projectNumberChanged = this.projectNumberChanged.bind(this);

        this.props.onProjectNumberChange('');
        this.clearProjectNumber = this.clearProjectNumber.bind(this);
    }

    projectNumberChanged(event: React.ChangeEvent<HTMLInputElement>) {

        const partialProjectNumber = event.target.value || '';

        this.setState({
            partialProjectNumber
        });

        this.props.onProjectNumberChange(partialProjectNumber);
    }

    clearProjectNumber() {
        this.setState({
            partialProjectNumber: ''
        });

        this.props.onProjectNumberChange('');
    }

    render() {
        return (
            <div className='input-group'>
                <div className='form-floating'>
                    <input type="text" className='form-control form-control-sm' id='projectNumber' value={this.state.partialProjectNumber} onChange={this.projectNumberChanged} autoComplete="off" />
                    <label htmlFor="projectNumber">Fragment nr projektu</label>
                </div>
                <button className="btn btn-outline-secondary" type="button" onClick={this.clearProjectNumber}><i className='fa-solid fa-times' /></button>
            </div>
        );
    }
}
