type StringFilterProps = {
    value?: string
    onChange: (value?: string) => void
};

export function StringFilter(props: StringFilterProps) {

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value);
    };

    return <div className="input-group input-group-sm flex-nowrap">
        <input type="text" className="form-control" value={props.value || ''} onChange={onFilterChange} />
        <button type="button" className="btn btn-outline-secondary" onClick={() => props.onChange(undefined)}><i className="fa-solid fa-times"></i></button>
    </div>;
}