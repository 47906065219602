import React, { ChangeEvent, Component } from 'react';
import { WorktimeEntryDTO, OperationResultWithDataDTO, TesterNoteDTO, EmployeeBriefDTO, BAMProjectOptionDTO, CabinetModelSubtypeBriefDTO, ValidationErrorsResponse, DepartmentWorkTypeDTO } from '../types/dto';
import { WorkTypeEnum, WorkSubtypeEnum, KnownRolesEnum, KnownDepartmentsEnum } from '../types/domain';
import { DateStringToDate, DateToDateString, DateToWorkTimeString } from '../utilities/date-formatter';
import TimeDuration from '../utilities/time-duration';
import { IsSameDay } from '../utilities/date-utils';
import { apiFetchResponse } from '../utilities/auth-api';
import { ProjectWorkTypeFormControls } from './ProjectWorkTypeFormControls';
import { UpdateWorktimeForm, ValidationErrors } from '../types/forms';
import { EmployeePicker } from './EmployeePicker';
import { CalculateDates } from '../utilities/data-enhancer';
import { ProjectNumberAutocomplete } from './common/ProjectNumberAutocomplete';
import { fetchWorktimeOptions } from '../utilities/data-fetch';
import DepartmentWorkTypeFormControls from './DepartmentWorkTypeFormControls';
import { formSelectClass } from '../utilities/form-utils';
import { DepartmentName } from '../utilities/domain-utils';

type InputType = "start" | "end" | "duration";

type WorktimeFormProps = {
    worktime: WorktimeEntryDTO
    onSave?: (result: WorktimeEntryDTO) => void,
    projectNumber?: number,
    workTypes?: WorkTypeEnum[],
    allowSetDate?: boolean,
    allowSetEmployee?: boolean,
    allowSetProject?: boolean,
    allowSetDepartment?: boolean,
    testerNotes?: TesterNoteDTO[],
    employeesToSelect?: EmployeeBriefDTO[],
    cabinetModelSubTypes?: CabinetModelSubtypeBriefDTO[],
    departmentNumber?: KnownDepartmentsEnum,
    departmentIds?: KnownDepartmentsEnum[],
    departmentWorkTypes?: DepartmentWorkTypeDTO[],
};

type WorktimeFormState = {
    startTimeString: string,
    endTimeString: string
    durationString: string,
    startDate: Date,
    endDate: Date,
    duration: TimeDuration,
    lastChanged?: InputType,
    lastBlured?: InputType,
    selectedWorkTypeId: WorkTypeEnum,
    selectedWorkSubtype?: WorkSubtypeEnum,
    selectedTesterNoteId?: number,
    workTypeDetails: string,
    errorText?: string,
    dayDateString: string,
    dayDate: Date,
    fetchedEmployeesToSelect?: EmployeeBriefDTO[],
    selectedEmployeeId?: number,
    projectsToSelect?: BAMProjectOptionDTO[]
    selectedProject?: BAMProjectOptionDTO,
    fetchedTesterNotes?: TesterNoteDTO[],
    fetchedCabinetModelSubTypes?: CabinetModelSubtypeBriefDTO[],
    selectedCabinetModelSubTypeId?: number,
    isRequestInProgress?: boolean,
    selectedDepartmentWorkTypeId: number,
    departmentWorkTypes: DepartmentWorkTypeDTO[],
    selectedDepartmentId?: KnownDepartmentsEnum,
    isProjectSelectActive?: boolean
};

export class WorktimeForm extends Component<WorktimeFormProps, WorktimeFormState> {
    static displayName = WorktimeForm.name;

    constructor(props: WorktimeFormProps) {
        super(props);

        this.state = this.stateFromWorktime(
            props.worktime,
            props.departmentWorkTypes,
            props.allowSetProject,
            props.allowSetDepartment
        );

        this.onStartTimeChange = this.onStartTimeChange.bind(this);
        this.onEndTimeChange = this.onEndTimeChange.bind(this);
        this.onDurationChange = this.onDurationChange.bind(this);
        this.onWorkTypeSelected = this.onWorkTypeSelected.bind(this);
        this.onWorkSubtypeSelected = this.onWorkSubtypeSelected.bind(this);
        this.onWorkTypeDetailsChanged = this.onWorkTypeDetailsChanged.bind(this);
        this.onTesterNoteIdChange = this.onTesterNoteIdChange.bind(this);
        this.save = this.save.bind(this);
        this.onDayDateChange = this.onDayDateChange.bind(this);
        this.fetchEmployees = this.fetchEmployees.bind(this);
        this.onEmployeeSelected = this.onEmployeeSelected.bind(this);
        this.fetchProjectOptions = this.fetchProjectOptions.bind(this);
        this.onProjectSelected = this.onProjectSelected.bind(this);
        this.setTesterNotes = this.setTesterNotes.bind(this);
        this.setCabinetModelSubTypes = this.setCabinetModelSubTypes.bind(this);
        this.getTesterNotes = this.getTesterNotes.bind(this);
        this.getCabinetModelSubTypes = this.getCabinetModelSubTypes.bind(this);
        this.setupEmployees = this.setupEmployees.bind(this);
        this.setupWorktimeOptions = this.setupWorktimeOptions.bind(this);
        this.getEmployeesToSelect = this.getEmployeesToSelect.bind(this);
        this.fetchWorktimeOptions = this.fetchWorktimeOptions.bind(this);
        this.onCabinetModelSubTypeChange = this.onCabinetModelSubTypeChange.bind(this);
        this.onDepartmentWorkTypeChange = this.onDepartmentWorkTypeChange.bind(this);
        this.isProjectType = this.isProjectType.bind(this);
        this.isDepartmentType = this.isDepartmentType.bind(this);
        this.onSelectProjectSelection = this.onSelectProjectSelection.bind(this);
        this.onSelectDepartmentSelection = this.onSelectDepartmentSelection.bind(this);
        this.fetchDepartmentWorkTypes = this.fetchDepartmentWorkTypes.bind(this);
        this.onDepartmentSelected = this.onDepartmentSelected.bind(this);

        this.setupEmployees(props);

        if (props.allowSetProject) {
            this.fetchProjectOptions();
        }

        this.setupWorktimeOptions(props);

        if (props.worktime && props.worktime.Department) {
            this.fetchDepartmentWorkTypes(props.worktime.Department.Id);
        }
    }

    componentDidUpdate(prevProps: WorktimeFormProps) {
        if (prevProps.worktime != this.props.worktime) {
            this.setState(this.stateFromWorktime(
                this.props.worktime,
                this.props.departmentWorkTypes,
                this.props.allowSetProject,
                this.props.allowSetDepartment
            ));
            if (this.props.worktime && this.props.worktime.Department) {
                this.fetchDepartmentWorkTypes(this.props.worktime.Department.Id);
            }
        }
        if (prevProps.employeesToSelect != this.props.employeesToSelect) {
            this.setupEmployees(this.props);
        }
        if (prevProps.testerNotes != this.props.testerNotes) {
            this.setupWorktimeOptions(this.props);
        }
    }

    componentWillUnmount() {
    }

    setupEmployees(props: WorktimeFormProps) {
        // NOTE - employees from props has precedence
        // so try to fetch only when props not set
        if (props.allowSetEmployee) {
            if (!props.employeesToSelect) {
                this.fetchEmployees();
            }
        }
    }

    setupWorktimeOptions(props: WorktimeFormProps) {
        // NOTE - TesterNotes from props has precedence
        // so try to fetch only when props not set
        // NOTE - This will also get cabinetModelSubTypes

        if (!props.testerNotes) {
            if (props.worktime.Id) {
                this.fetchWorktimeOptions(undefined, props.worktime.Id);
            }
            else if (props.projectNumber) {
                this.fetchWorktimeOptions(props.projectNumber, undefined);
            }
        }
    }

    stateFromWorktime(
        worktime: WorktimeEntryDTO,
        departmentWorkTypes?: DepartmentWorkTypeDTO[],
        allowSetProject?: boolean,
        allowSetDepartment?: boolean
    ) {

        const duration = TimeDuration.FromDates(worktime.Start!, worktime.End!);
        const dayDateString = DateToDateString(worktime.Start!);

        let isProjectSelectActive: boolean | undefined;
        if (allowSetProject === true) {
            isProjectSelectActive = true;
        } else if (allowSetDepartment === true) {
            isProjectSelectActive = false;
        }

        return {
            startTimeString: DateToWorkTimeString(worktime.Start!),
            endTimeString: DateToWorkTimeString(worktime.End!),
            durationString: duration.ElapsedTimeString(),
            startDate: worktime.Start!,
            endDate: worktime.End!,
            duration: duration,
            selectedWorkTypeId: worktime.WorkType,
            selectedWorkSubtype: worktime.WorkSubtype,
            selectedTesterNoteId: worktime.TesterNote?.Id,
            selectedCabinetModelSubTypeId: worktime.CabinetModelSubType?.Id,
            workTypeDetails: worktime.Details || '',
            dayDateString: dayDateString,
            dayDate: DateStringToDate(dayDateString),
            selectedEmployeeId: worktime.Employee?.Id || undefined,
            selectedDepartmentWorkTypeId: worktime.DepartmentWorkType?.Id || 0,
            departmentWorkTypes: departmentWorkTypes || [],
            isProjectSelectActive
        };
    }

    async fetchEmployees() {

        const onlyRoles = [KnownRolesEnum.Monter, KnownRolesEnum.Tester];
        const queryParams = onlyRoles.map(r => `onlyRoles=${r}`).join("&");
        const response = await apiFetchResponse(`employee/brief?${queryParams}`);
        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<EmployeeBriefDTO[]>;
            if (result && result.Success) {
                const employeesToSelect = result.Data || [];
                const selectedEmployeeId = this.state.selectedEmployeeId
                    ? employeesToSelect.find(e => e.Id == this.state.selectedEmployeeId)?.Id
                    : this.state.selectedEmployeeId;

                this.setState({
                    fetchedEmployeesToSelect: employeesToSelect,
                    selectedEmployeeId
                });
            } else {
                console.error("Error fetching employees for WorktimeForm", result);
            }
        } else {
            console.error("Error fetching employees for WorktimeForm status=", response.status);
        }
    }

    async fetchProjectOptions() {
        const response = await apiFetchResponse('project/options-list');

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<BAMProjectOptionDTO[]>;
            if (result.Success) {
                this.setState({
                    projectsToSelect: result.Data
                });
            }
        }
    }

    async fetchDepartmentWorkTypes(departmentId: number) {
        const response = await apiFetchResponse(`department/${departmentId}/work-type`);

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<DepartmentWorkTypeDTO[]>;
            if (result.Success) {
                this.setState({
                    departmentWorkTypes: result.Data || []
                });
            }
        }
    }

    onStartTimeChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({
            startTimeString: event.target.value,
            lastChanged: "start"
        });
    }

    onEndTimeChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({
            endTimeString: event.target.value,
            lastChanged: "end"
        });
    }

    onDurationChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({
            durationString: event.target.value,
            lastChanged: "duration"
        });
    }

    onBlur(event: React.FocusEvent<HTMLInputElement, Element>, whatBlured: InputType): void {

        const baseDayDate = this.props.allowSetDate
            ? this.state.dayDate!
            : this.props.worktime.Start!

        // TODO - Refactor below
        switch (whatBlured) {
            case "duration":
                {
                    const duration = TimeDuration.FromString(this.state.durationString);
                    const startDate = TimeDuration.DateWithTimeString(baseDayDate, this.state.startTimeString);
                    const endDate = duration.CalcEndDate(startDate);
                    let errorText = undefined;
                    if (!IsSameDay(startDate, endDate)) {
                        errorText = 'Czasy Start i Stop muszą być w tym samym dniu';
                    }
                    this.setState({
                        duration,
                        startDate,
                        endDate,
                        endTimeString: DateToWorkTimeString(endDate),
                        lastBlured: whatBlured,
                        errorText
                    });
                }
                break;
            case "start":
                {
                    const startDate = TimeDuration.DateWithTimeString(baseDayDate, this.state.startTimeString);
                    const endDate = TimeDuration.DateWithTimeString(baseDayDate, this.state.endTimeString);
                    const duration = TimeDuration.FromDates(startDate, endDate);
                    let errorText = duration.IsNegative()
                        ? 'Czas Start musi być mniejszy niż czas Stop'
                        : undefined;

                    this.setState({
                        errorText,
                        startDate,
                        endDate,
                        duration,
                        durationString: duration.ElapsedTimeString(),
                        lastBlured: whatBlured
                    });
                }
                break;
            case "end":
                {
                    const startDate = TimeDuration.DateWithTimeString(baseDayDate, this.state.startTimeString);
                    const endDate = TimeDuration.DateWithTimeString(baseDayDate, this.state.endTimeString);
                    const duration = TimeDuration.FromDates(startDate, endDate);
                    let errorText = duration.IsNegative()
                        ? 'Czas Start musi być mniejszy niż czas Stop'
                        : undefined;

                    this.setState({
                        errorText,
                        startDate,
                        endDate,
                        duration,
                        durationString: duration.ElapsedTimeString(),
                        lastBlured: whatBlured
                    });
                }
                break;
        }
    }

    private getTesterNotes(): TesterNoteDTO[] {
        return this.props.testerNotes       // TesterNotes from props have precedence
            || this.state.fetchedTesterNotes
            || [];
    }

    private getCabinetModelSubTypes(): CabinetModelSubtypeBriefDTO[] {
        return this.props.cabinetModelSubTypes  // form props has precedence
            || this.state.fetchedCabinetModelSubTypes
            || [];
    }

    private getEmployeesToSelect(): EmployeeBriefDTO[] {
        return this.props.employeesToSelect
            || this.state.fetchedEmployeesToSelect
            || [];
    }

    private onWorkTypeSelected(wt: WorkTypeEnum) {
        const testerNotes = this.getTesterNotes();
        const selectedTesterNoteId = wt == WorkTypeEnum.Fixes && testerNotes.length > 0
            ? testerNotes[0].Id : undefined;

        this.setState({
            selectedWorkTypeId: wt,
            selectedTesterNoteId
        });
    }

    private onWorkSubtypeSelected(ws: WorkSubtypeEnum) {
        this.setState({
            selectedWorkSubtype: ws
        });
    }

    private onWorkTypeDetailsChanged(details: string) {
        this.setState({
            workTypeDetails: details
        });
    }

    private onCabinetModelSubTypeChange(cabinetModelSubTypeId: number) {
        this.setState({
            selectedCabinetModelSubTypeId: cabinetModelSubTypeId
        });
    }

    private onTesterNoteIdChange(testerNoteId?: number) {

        this.setState({
            selectedTesterNoteId: testerNoteId
        });
    }

    private onDepartmentWorkTypeChange(departmentWorkTypeId: number) {
        this.setState({
            selectedDepartmentWorkTypeId: departmentWorkTypeId
        });
    }

    private VerifyData(data: UpdateWorktimeForm): string[] {
        const errors: string[] = [];

        if (data.WorkType === WorkTypeEnum.NotSelected
            && !data.DepartmentWorkTypeId) {
            errors.push('Nalezy wybrać typ pracy');
        }
        if (data.WorkType == WorkTypeEnum.Cable && !data.WorkSubtype) {
            errors.push('Należy wybrać podtyp pracy');
        }
        if (data.StartDate >= data.EndDate) {
            errors.push('Czas Start musi być mniejszy niż czas Stop');
        } else {
            // Check if both dates are in same day
            const startDate = new Date(data.StartDate);
            const endDate = new Date(data.EndDate);

            if (!IsSameDay(startDate, endDate)) {
                errors.push('Czasy Start i Stop muszą być w tym samym dniu');
            }
        }

        if (this.props.allowSetEmployee && !data.EmployeeId) {
            errors.push('Należy wybrać pracownika');
        }

        if (this.getCabinetModelSubTypes().length > 0 && !data.CabinetModelSubTypeId) {
            errors.push('Należy wybrać szafę');
        }

        return errors;
    }

    private onDayDateChange(event: React.ChangeEvent<HTMLInputElement>) {

        if (!this.props.allowSetDate) {
            return;
        }

        const dayDateString = event.target.value || '';
        const dayDate = DateStringToDate(dayDateString);
        const startDate = TimeDuration.DateWithTimeString(dayDate, this.state.startTimeString);
        const endDate = TimeDuration.DateWithTimeString(dayDate, this.state.endTimeString);
        this.setState({
            dayDateString,
            dayDate,
            startDate,
            endDate
        });
    }

    async save(event: React.MouseEvent) {
        event.preventDefault();

        const data: UpdateWorktimeForm = {
            WorkType: this.state.selectedWorkTypeId,
            WorkSubtype: this.state.selectedWorkSubtype,
            Details: this.state.workTypeDetails || undefined,
            TesterNoteId: this.state.selectedTesterNoteId,
            StartDate: this.state.startDate.getTime(),
            EndDate: this.state.endDate.getTime(),
            CabinetModelSubTypeId: this.state.selectedCabinetModelSubTypeId,
            DepartmentWorkTypeId: this.state.selectedDepartmentWorkTypeId || undefined
        };

        // NOTE - when not providing employee in data,
        // current user is attached to Create Worktime
        // and in Update employee is not changed
        // so we do it only when flag is deliberately set
        if (this.props.allowSetEmployee) {
            data.EmployeeId = this.state.selectedEmployeeId
        }

        const errors: string[] = this.VerifyData(data);
        const we = this.props.worktime;
        let projectNumber: number | undefined = undefined;
        let departmentId: KnownDepartmentsEnum | undefined = undefined;
        const isCreatingNew = !we.Id;
        if (isCreatingNew) {
            if (this.isCreatingProjectWorktime()) {
                projectNumber = this.props.allowSetProject
                    ? this.state.selectedProject?.ProjectNumber
                    : this.props.projectNumber;
                if (!projectNumber) {
                    errors.push("Nie podano numeru projektu");
                }
            }
            if (this.isCreatingDepartmentWorktime()) {
                departmentId = this.props.allowSetDepartment
                    ? this.state.selectedDepartmentId
                    : this.props.departmentNumber;
                if (!departmentId) {
                    errors.push("Nie wybrano działu");
                }
            }
        }

        this.setState({
            errorText: errors.length > 0 ? errors.join('\n') : undefined
        });
        if (errors.length) {
            return;
        }

        this.setState({
            isRequestInProgress: true
        });

        let url = '';
        if (isCreatingNew) {
            if (this.isCreatingProjectWorktime()) {
                url = `project/${projectNumber}/worktime`;
            }
            if (this.isCreatingDepartmentWorktime()) {
                url = `department/${departmentId}/worktime`;
            }
        } else {
            url = `worktime/${we.Id}`;
        }

        const method = isCreatingNew ? 'POST' : 'PUT';

        const response = await apiFetchResponse(url, {
            method,
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        this.setState({
            isRequestInProgress: false
        });

        if (response.status != 200) {
            if (response.status == 400) {
                const validationErrorsResponse = await response.json() as ValidationErrorsResponse;
                const ve = new ValidationErrors();
                ve.SetFromValidationErrorsResponse(validationErrorsResponse);
                const errorText = ve.GetAsMultilineString();
                this.setState({
                    errorText
                });
            } else {
                this.setState({
                    errorText: 'Błąd operacji'
                });
            }

            return;
        }

        const result = await response.json() as OperationResultWithDataDTO<WorktimeEntryDTO>;

        if (result.Success) {

            const worktimeEntry = result.Data!;
            CalculateDates(worktimeEntry);

            if (this.props.onSave) {
                this.props.onSave(result.Data!);
            }
        } else {
            const errorText = result.Errors
                ? result.Errors.map(om => om.Message).join('\n')
                : 'Błąd operacji';
            this.setState({
                errorText
            });
        }
    }

    onEmployeeSelected(employee?: EmployeeBriefDTO) {
        this.setState({
            selectedEmployeeId: employee?.Id
        })
    }

    onProjectSelected(project?: BAMProjectOptionDTO) {
        this.setState({
            selectedProject: project,
            fetchedTesterNotes: project
                ? this.state.fetchedTesterNotes
                : []
        });

        if (project) {
            this.fetchWorktimeOptions(project.ProjectNumber, undefined);
        }
    }

    onDepartmentSelected(event: React.ChangeEvent<HTMLSelectElement>) {
        const selectedDepartmentId = parseInt(event.target.value) as KnownDepartmentsEnum;

        if (selectedDepartmentId != this.state.selectedDepartmentId) {
            this.setState({
                selectedDepartmentId,
                selectedDepartmentWorkTypeId: 0
            });

            this.fetchDepartmentWorkTypes(selectedDepartmentId);
        }
    }

    setTesterNotes(testerNotes: TesterNoteDTO[]) {
        const selectedTesterNoteId = this.state.selectedTesterNoteId
            ? testerNotes.find(tn => tn.Id == this.state.selectedTesterNoteId)?.Id
            : this.state.selectedTesterNoteId;

        this.setState({
            fetchedTesterNotes: testerNotes,
            selectedTesterNoteId
        });
    }

    setCabinetModelSubTypes(cabinetModelSubTypes: CabinetModelSubtypeBriefDTO[]) {
        const selectedCabinetModelSubTypeId = this.state.selectedCabinetModelSubTypeId
            ? cabinetModelSubTypes.find(cmst => cmst.Id == this.state.selectedCabinetModelSubTypeId)?.Id
            : this.state.selectedCabinetModelSubTypeId;

        this.setState({
            fetchedCabinetModelSubTypes: cabinetModelSubTypes,
            selectedCabinetModelSubTypeId
        });
    }

    async fetchWorktimeOptions(projectNumber?: number, worktimeId?: number) {
        const options = await fetchWorktimeOptions(projectNumber, undefined, worktimeId);
        if (options) {
            this.setTesterNotes(options.TesterNotes || []);
            this.setCabinetModelSubTypes(options.CabinetModelSubTypes || []);
        }
    }

    isWorkTypeSelected = () => this.state.selectedWorkTypeId > 0;

    isProjectType = () => !!this.props.projectNumber
        || (!!this.props.worktime && !!this.props.worktime.ProjectId);

    isDepartmentType = () => !!this.props.departmentNumber
        || (!!this.props.worktime && !!this.props.worktime.Department);

    disableSaveButton = () => {
        
        if (this.isProjectType()) {
            const needCabinetModelSubtype = this.getCabinetModelSubTypes().length > 0;
            const isCabinedModelSubtypeSelected = needCabinetModelSubtype
                ? this.state.selectedCabinetModelSubTypeId && this.state.selectedCabinetModelSubTypeId > 0
                : true;
            return !this.state.selectedWorkTypeId
                || !isCabinedModelSubtypeSelected
                || this.state.isRequestInProgress;
        }

        if (this.isDepartmentType()) {
            return !this.state.selectedDepartmentWorkTypeId;
        }

        return false;
    }

    onSelectProjectSelection(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            isProjectSelectActive: event.target.checked
        });
    }

    onSelectDepartmentSelection(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            isProjectSelectActive: !event.target.checked
        });
    }

    isProjectWorktime = () => (this.props.projectNumber && this.props.projectNumber > 0)
        || (this.props.worktime && this.props.worktime.ProjectId && this.props.worktime.ProjectId > 0);
    canShowProjectSelector = () => this.props.allowSetProject
        && this.state.projectsToSelect
        && !this.isProjectWorktime();
    isProjectSelectorVisible = () => this.canShowProjectSelector()
        && this.state.isProjectSelectActive !== false;
    isDepartmentWorktime = () => (this.props.departmentNumber && this.props.departmentNumber > 0)
        || (this.props.worktime && this.props.worktime.Department && this.props.worktime.Department.Id > 0);
    canShowDepartmentSelector = () => this.props.allowSetDepartment
        && this.props.departmentIds
        && this.props.departmentIds.length > 0
        && !this.isDepartmentWorktime();
    isDepartmentSelectorVisible = () => this.canShowDepartmentSelector()
        && this.state.isProjectSelectActive !== true;
    isCreatingProjectWorktime = () => !!this.props.projectNumber
        || (!!this.props.allowSetDepartment && this.state.isProjectSelectActive !== false);
    isCreatingDepartmentWorktime = () => !!this.props.departmentNumber
        || (!!this.props.allowSetDepartment && this.state.isProjectSelectActive !== true);

    render() {

        const workTypes = this.props.workTypes || [];

        return (
            <form id="worktime-form">
                {this.canShowProjectSelector() && this.canShowDepartmentSelector() &&
                    <div className="mb-2">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="projectDepartmentToggle" id="projdep-toggle-proj" checked={this.state.isProjectSelectActive === true} onChange={this.onSelectProjectSelection} />
                            <label className="form-check-label" htmlFor="projdep-toggle-proj">Projekt</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="projectDepartmentToggle" id="projdep-toggle-dep" checked={this.state.isProjectSelectActive === false} onChange={this.onSelectDepartmentSelection} />
                            <label className="form-check-label" htmlFor="projdep-toggle-dep">Dział</label>
                        </div>
                    </div>
                }
                {this.isProjectSelectorVisible() &&
                    <div className="mb-2">
                        <label htmlFor="project-number"><strong>Projekt</strong></label>
                        <ProjectNumberAutocomplete
                            projectsToSelect={this.state.projectsToSelect!}
                            onChange={this.onProjectSelected} />
                    </div>
                }
                {this.isDepartmentSelectorVisible() &&
                    <div className="mb-2">
                        <label htmlFor="department-id"><strong>Dział</strong></label>
                        <select id="department-id" className={formSelectClass("sm")} onChange={this.onDepartmentSelected} value={this.state.selectedDepartmentId || 0}>
                            <option value="0" disabled={true}>wybierz dział</option>
                            {this.props.departmentIds!.map(value =>
                                <option key={value} value={value}>{DepartmentName(value as KnownDepartmentsEnum)}</option>
                            )}
                        </select>
                    </div>
                }
                {this.props.allowSetDate &&
                    <div className="mb-2">
                        <label htmlFor="day-date"><strong>Data</strong></label>
                        <input id="day-date" className="form-control form-control-sm" type="date" value={this.state.dayDateString} onChange={this.onDayDateChange} />
                    </div>
                }
                {this.props.allowSetEmployee &&
                    <div className="mb-2">
                        <EmployeePicker
                            selectedEmployeeId={this.state.selectedEmployeeId}
                            employeesToSelect={this.getEmployeesToSelect()}
                            onEmployeeChanged={this.onEmployeeSelected}
                            label="Pracownik"
                            inputId="employee"
                            notFloatingLabel={true} />
                    </div>
                }
                <table className="table table-sm table-borderless">
                    <thead>
                        <tr>
                            <th><label htmlFor="starttime">Start</label></th>
                            <th><label htmlFor="endtime">Stop</label></th>
                            <th><label htmlFor="duration">Czas</label></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><input className="form-control form-control-sm" type="time" id="starttime" step="1" value={this.state.startTimeString} onChange={this.onStartTimeChange} onBlur={(event) => this.onBlur(event, "start")} /></td>
                            <td><input className="form-control form-control-sm" type="time" id="endtime" step="1" value={this.state.endTimeString} onChange={this.onEndTimeChange} onBlur={(event) => this.onBlur(event, "end")} /></td>
                            <td><input className="form-control form-control-sm" type="time" id="duration" step="1" value={this.state.durationString} onChange={this.onDurationChange} onBlur={(event) => this.onBlur(event, "duration")} /></td>
                        </tr>
                        <tr>
                            <td colSpan={3}>
                                {(this.isProjectWorktime() || this.isProjectSelectorVisible()) && <ProjectWorkTypeFormControls
                                    onWorkTypeChange={this.onWorkTypeSelected}
                                    workType={this.state.selectedWorkTypeId}
                                    workTypes={workTypes}
                                    onWorkSubtypeChange={this.onWorkSubtypeSelected}
                                    workSubtype={this.state.selectedWorkSubtype}
                                    onDetailsChange={this.onWorkTypeDetailsChanged}
                                    details={this.state.workTypeDetails}
                                    testerNotes={this.getTesterNotes()}
                                    onTesterNoteIdChange={this.onTesterNoteIdChange}
                                    testerNoteId={this.state.selectedTesterNoteId}
                                    size='sm'
                                    onCabinetModelSubTypeChange={this.onCabinetModelSubTypeChange}
                                    cabinetModelSubTypeId={this.state.selectedCabinetModelSubTypeId}
                                    cabinetModelSubTypes={this.getCabinetModelSubTypes()}
                                />}
                                {(this.isDepartmentWorktime() || this.isDepartmentSelectorVisible()) && <DepartmentWorkTypeFormControls
                                    workTypeId={this.state.selectedDepartmentWorkTypeId}
                                    details={this.state.workTypeDetails}
                                    size='sm'
                                    onWorkTypeChange={this.onDepartmentWorkTypeChange}
                                    onDetailsChange={this.onWorkTypeDetailsChanged}
                                    workTypes={this.state.departmentWorkTypes}
                                />}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="row">
                    <div className="col-auto ml-auto">
                        <button className="btn btn-sm btn-primary" disabled={this.disableSaveButton()} onClick={this.save} type="button">Zapisz</button>
                        {this.state.errorText && <div className="d-block invalid-feedback multi-line-text">{this.state.errorText}</div>}
                    </div>
                </div>
            </form>
        );
    }
}
