import {
    MajorProjectStateEnum,
    WorkTypeEnum,
    WorkSubtypeEnum,
    WorkFlags,
    WorkFlagValue,
    KnownRolesEnum,
    UnloadingFlags,
    PelikanStatusEnum,
    PelikanCategoryEnum,
    PelikanLogFlags,
    UnloadingFlagValue,
    QDETicketStatus,
    PelikanViewType,
    WorkTimeNormTypeEnum,
    QDETicketTypeEnum,
    WorkTypeDescriptor,
    ProjectCommandsEnum,
    ProjectStateFlags,
    FMListEntryStatus,
    EmployeeBreakEnum,
    KnownDepartmentsEnum
} from '../types/domain';
import { WorktimeEntryDTO, EmployeeWorktimeEntryDTO, EmployeeBriefDTO, PelikanDTO, PelikanLogEntryDTO, WorktimeReportEntryDTO, ProjectLogEntryDTO, WorkStatusWithDates, DepartmentWorkTypeExDTO } from '../types/dto';
import { getHoursDurationText } from './date-utils';
import TimeDuration from './time-duration';

export function MainStatusName(value: MajorProjectStateEnum): string {
    switch (value) {
        case MajorProjectStateEnum.Planned:
            return "Zaplanowany";
        case MajorProjectStateEnum.Unloaded:
            return "Rozładowany";
        case MajorProjectStateEnum.Production:
            return "Produkcja";
        case MajorProjectStateEnum.TestProduction:
            return "P1";
        case MajorProjectStateEnum.Fixes:
            return "Poprawki";
        case MajorProjectStateEnum.PrepareForSend:
            return "Przygotowanie do wysłania";
        case MajorProjectStateEnum.ReadyToSend:
            return "Gotowe do wysłania";
        case MajorProjectStateEnum.Finished:
            return "Wysłany (zakończony)";
        case MajorProjectStateEnum.Archive:
            return "Zarchiwizowany";
        default:
            return `Nieznany ${value}`;
    }
}

export function briefDetailsText(details?: string) {
    const maxBriefLenght = 20;
    if (!details || details.length <= maxBriefLenght) {
        return details;
    }

    return details.substr(0, maxBriefLenght) + "...";
}

export function renderWorktimeDescription(entry: WorktimeEntryDTO | EmployeeWorktimeEntryDTO, fullDetails: boolean = false) {
    let detailsText: string | undefined = undefined;
    let workTypeDescription: string | undefined = undefined;

    if (entry.ProjectId && entry.ProjectId > 0) {
        if (entry.WorkSubtype && entry.WorkSubtype > WorkSubtypeEnum.None) {
            detailsText = workSubtypeText(entry.WorkType, entry.WorkSubtype);
        } else if (entry.WorkType == WorkTypeEnum.Fixes) {
            detailsText = fullDetails
                ? entry.TesterNote?.NoteText
                : briefDetailsText(entry.TesterNote?.NoteText);
        } else {
            detailsText = fullDetails
                ? entry.Details
                : briefDetailsText(entry.Details);
        }
        workTypeDescription = workTypeText(entry.WorkType);
    } else {
        // This is department entry
        workTypeDescription = entry.DepartmentWorkType?.Name || `Nieznany typ weId=${entry.Id}`;
        detailsText = fullDetails
            ? entry.Details
            : briefDetailsText(entry.Details);
    }

    const hasDetailsText = !!detailsText;
    const subtypeName = entry.CabinetModelSubType?.SubTypeName;
    const hasSubtypeName = !!subtypeName;

    return(<>
            {workTypeDescription}
            {hasDetailsText && <><br />{detailsText}</>}
            {hasSubtypeName && <><br /><small>{subtypeName}</small></>}
        </>);
}

// OBSOLETE - TODO remove if not used
export function workTypeTextForEntry(entry: WorktimeEntryDTO | EmployeeWorktimeEntryDTO, fullDetails: boolean = false) {
    let detailsText: string | undefined = undefined;
    if (entry.WorkSubtype && entry.WorkSubtype > WorkSubtypeEnum.None) {
        detailsText = workSubtypeText(entry.WorkType, entry.WorkSubtype);
    } else if (entry.WorkType == WorkTypeEnum.Fixes) {
        detailsText = fullDetails
            ? entry.TesterNote?.NoteText
            : briefDetailsText(entry.TesterNote?.NoteText);
    } else {
        detailsText = fullDetails
            ? entry.Details
            : briefDetailsText(entry.Details);
    }

    const wtText = workTypeText(entry.WorkType);

    return detailsText
        ? wtText + " (" + detailsText + ")"
        : wtText;
}

export function workTypeText(workType: WorkTypeEnum): string {
    switch (workType) {
        case WorkTypeEnum.ControlBoard:
            return "Panel sterowniczy";
        case WorkTypeEnum.Assembly:
            return "Montaż";
        case WorkTypeEnum.Cable:
            return "Kablowanie";
        case WorkTypeEnum.Fixes:
            return "Poprawki";
        case WorkTypeEnum.QDE:
            return "QDE";
        case WorkTypeEnum.P0:
            return "P0";
        case WorkTypeEnum.Testing:
            return "Testowanie (P1)";
        case WorkTypeEnum.AdditionalWorks:
            return "Prace dodatkowe";
        default:
            return`Nieznany ${workType}`;
    }
}

export function workTypeAbbr(workType: WorkTypeEnum): string {
    switch (workType) {
        case WorkTypeEnum.ControlBoard:
            return "PS";
        case WorkTypeEnum.Assembly:
            return "M";
        case WorkTypeEnum.Cable:
            return "K";
        case WorkTypeEnum.Fixes:
            return "POPR";
        case WorkTypeEnum.QDE:
            return "QDE";
        case WorkTypeEnum.P0:
            return "P0";
        case WorkTypeEnum.Testing:
            return "P1";
        case WorkTypeEnum.AdditionalWorks:
            return "PD";
        default:
            return `U${workType}`;
    }
}


export function workSubtypeText(wt: WorkTypeEnum, ws: WorkSubtypeEnum) {
    if (wt == WorkTypeEnum.Cable) {
        switch (ws) {
            case WorkSubtypeEnum.None:
                return "brak podtypu";
            case WorkSubtypeEnum.Cable_HauptLess16qmm:
                return "HAUPT poniżej 16qmm";
            case WorkSubtypeEnum.Cable_HauptMore16qmm:
                return "HAUPT powyżej 16qmm";
            case WorkSubtypeEnum.Cable_ErdenLess16qmm:
                return "ERDEN poniżej 16qmm";
            case WorkSubtypeEnum.Cable_ErdenMore16qmm:
                return "ERDEN powyżej 16qmm";
            case WorkSubtypeEnum.Cable_230VAC:
                return "230V AC";
            case WorkSubtypeEnum.Cable_24VDC:
                return "24V DC";
            case WorkSubtypeEnum.Cable_Steuerung:
                return "Steuerung";
            case WorkSubtypeEnum.Cable_STV:
                return "STV";
            case WorkSubtypeEnum.Cable_Kabel:
                return "KABEL";
            default:
                return "nieznana wartość";
        }
    }

    return "";
}

export function workSubtypeAbbr(wt: WorkTypeEnum, ws: WorkSubtypeEnum): string {
    if (wt == WorkTypeEnum.Cable) {
        switch (ws) {
            case WorkSubtypeEnum.None:
                return "brak";
            case WorkSubtypeEnum.Cable_HauptLess16qmm:
                return "H<16";
            case WorkSubtypeEnum.Cable_HauptMore16qmm:
                return "H>16";
            case WorkSubtypeEnum.Cable_ErdenLess16qmm:
                return "E<16";
            case WorkSubtypeEnum.Cable_ErdenMore16qmm:
                return "E>16";
            case WorkSubtypeEnum.Cable_230VAC:
                return "230V";
            case WorkSubtypeEnum.Cable_24VDC:
                return "24V";
            case WorkSubtypeEnum.Cable_Steuerung:
                return "Str";
            case WorkSubtypeEnum.Cable_STV:
                return "STV";
            case WorkSubtypeEnum.Cable_Kabel:
                return "KAB";
            default:
                return "?";
        }
    }

    return "";
}

export function getWorkSubtypes(workType: WorkTypeEnum): WorkSubtypeEnum[] {
    if (workType == WorkTypeEnum.Cable) {
        return [
            WorkSubtypeEnum.None,
            WorkSubtypeEnum.Cable_ErdenLess16qmm,
            WorkSubtypeEnum.Cable_ErdenMore16qmm,
            WorkSubtypeEnum.Cable_HauptLess16qmm,
            WorkSubtypeEnum.Cable_HauptMore16qmm,
            WorkSubtypeEnum.Cable_230VAC,
            WorkSubtypeEnum.Cable_24VDC,
            WorkSubtypeEnum.Cable_Steuerung,
            WorkSubtypeEnum.Cable_STV,
            WorkSubtypeEnum.Cable_Kabel
        ];
    }

    return [];
}

export function getWorkflagText(workFlag: WorkFlags): string {
    switch (workFlag) {
        case WorkFlags.P1Approved:
            return "P1 Zatwierdzone";
        case WorkFlags.P1Visual:
            return "P1 Wizualne";
        case WorkFlags.VDLSigned:
            return "Podpisany VDL";
        case WorkFlags.Lashe5ForMonter:
            return "Lasche \"5\" (Monter)";
        case WorkFlags.Lashe5ForTester:
            return "Lasche \"5\" (Tester)";
        case WorkFlags.Lashe9:
            return "Lasche \"9\"";
        case WorkFlags.BeipackFinished:
            return "Beipack";
        case WorkFlags.Lashe12:
            return "Lasche \"12\"";
        case WorkFlags.FMLista:
            return "FM Lista";
        // OBSOLETE - this flag is no longer used
        case WorkFlags.PelicansTester:
            return "Pelikany (BAM_P)";
        case WorkFlags.PelicansOffice:
            return "Pelikany (BAM_TS/V1)";
        case WorkFlags.Pictures:
            return "Zdjęcia";
        case WorkFlags.VDLScan:
            return "Skan VDL";
        case WorkFlags.FMPelikan:
            return "FM Pelikan";
        case WorkFlags.ControlBoardFinished:
            return "Pulpit sterowniczy";
        case WorkFlags.AssemblyFinished:
            return "Montaż";
        case WorkFlags.CableFinished:
            return "Kablowanie";
        // NOTE - obsolete flag
        case WorkFlags.FixesFinished:
            return "Poprawki";
        case WorkFlags.TorquesBelow6qmm:
            return "Momenty do 6qmm";
        case WorkFlags.TorquesAbove6qmm:
            return "Momenty powyżej 6qmm";
        default:
            return `Nieznana flaga ${workFlag}`;
    }
}

export function getWorkflagValueText(value: WorkFlagValue, flag: WorkFlags | undefined = undefined): string {

    if (flag === WorkFlags.FMPelikan) {
        switch (value) {
            case WorkFlagValue.Empty:
                return "Nieukończone";
            case WorkFlagValue.ProjectCanLeave:
                return "Może jechać";
            case WorkFlagValue.ProjectCanNotLeave:
                return "Nie może jechać";
            case WorkFlagValue.LackingReported:
                return "Zgłoszony brak";
            case WorkFlagValue.MaterialComplete:
                return "Materiał kompletny";
        }
    }

    switch (value) {
        case WorkFlagValue.False:
            return "Nieukończone";
        case WorkFlagValue.True:
            return "Ukończone";
        case WorkFlagValue.Inapplicable:
            return "Nie dotyczy";
    }

    return "";
}

export function workTypeCanHaveDetails(workType: WorkTypeEnum): boolean {
    return workType == WorkTypeEnum.QDE
        || workType == WorkTypeEnum.P0
        || workType == WorkTypeEnum.AdditionalWorks;
}

export const allWorkTypes: WorkTypeEnum[] = [
    WorkTypeEnum.ControlBoard,
    WorkTypeEnum.Assembly,
    WorkTypeEnum.Cable,
    WorkTypeEnum.Fixes,
    WorkTypeEnum.QDE,
    WorkTypeEnum.P0,
    WorkTypeEnum.Testing,
    WorkTypeEnum.AdditionalWorks
];

export function roleName(roleId: number): string {
    switch (roleId) {
        case KnownRolesEnum.SuperAdmin:
            return "Superadmin";
        case KnownRolesEnum.Tester:
            return "Tester";
        case KnownRolesEnum.Office:
            return "Biuro";
        case KnownRolesEnum.Manager:
            return "Kierownik";
        case KnownRolesEnum.WarehouseKeeper:
            return "Magazynier";
        case KnownRolesEnum.Monter:
            return "Monter";
        case KnownRolesEnum.TeamLider:
            return "Team lider";
        case KnownRolesEnum.Apprentice:
            return "Praktykant";
        case KnownRolesEnum.WarehouseForeman:
            return "Brygadzista magazynu";
        case KnownRolesEnum.NotSelected:
            return "Nie wybrano";
        default:
            return '';
    }
}

export function getUnloadingFlagText(flag: UnloadingFlags): string {
    switch (flag) {
        case UnloadingFlags.CabinetBuilt:
            return "Szafa uzbrojona";
        case UnloadingFlags.DiagramFromVDL:
            return "Schemat z VDL";
        case UnloadingFlags.DescriptionsOfCablesAndDevices:
            return "Opisy: kabli, przewodów, urządzeń itp.";
        case UnloadingFlags.PreparedCablesKomax:
            return "Przygotowane przewody (Komax)";
        case UnloadingFlags.CablesMore16mm:
            return "Przewody powyżej 16mm2";
        case UnloadingFlags.Cables:
            return "Kable";
        case UnloadingFlags.Baipack:
            return "Baipack";
        case UnloadingFlags.AirConditioning:
            return "Klimatyzacja";
        case UnloadingFlags.DeskDoorsAndHinges:
            return "Pult drzwi i zawiasy";
        case UnloadingFlags.DeskShell:
            return "Pult korpus";
        case UnloadingFlags.DeskPanel:
            return "Pult blenda";
        case UnloadingFlags.DeskMaterial:
            return "Pult materiał";
        case UnloadingFlags.AdditionalMaterial:
            return "Pozostały materiał";
        case UnloadingFlags.Damages:
            return "Uszkodzenia";
        case UnloadingFlags.Covers:
            return "Dekle";
        case UnloadingFlags.Devices:
            return "Urządzenia";
        case UnloadingFlags.IsCabinedUnarmed:
            return "Typ szafy";
        default:
            return `Nieznana flaga ${flag}`;
    }
}

export function getUnloadingFlagValueText(value: UnloadingFlagValue, flag: UnloadingFlags) {
    if (flag == UnloadingFlags.IsCabinedUnarmed) {
        switch (value) {
            case UnloadingFlagValue.Yes:
                return "Nieuzbrojona";
            case UnloadingFlagValue.NotSet:
                return "Uzbrojona";
            default:
                return `Niepoprawna wartość ${value} dla flagi ${flag}`;
        }
    } else {
        switch (value) {
            case UnloadingFlagValue.Yes:
                return "Tak";
            case UnloadingFlagValue.Missing:
                return "Brak";
            case UnloadingFlagValue.Inapplicable:
                return "Nie dotyczy";
            case UnloadingFlagValue.NotSet:
                return "Nie";
        }
    }
}

export function PelikanStatusName(status: PelikanStatusEnum): string {
    switch (status) {
        case PelikanStatusEnum.Open:
            return "Otwarty";
        case PelikanStatusEnum.InProgress:
            return "Opracowywany";
        case PelikanStatusEnum.Solved:
            return "Rozwiązany";
        case PelikanStatusEnum.Removed:
            return "Usunięty";
        case PelikanStatusEnum.Archived:
            return "Zarchiwizowany";
        case PelikanStatusEnum.Installed:
            return "Zainstalowany";
        default:
            return `Nieznany status Pelikana ${status}`;
    }
};

export function IsPelikanStatusOpen(status: PelikanStatusEnum): boolean {
    return status < PelikanStatusEnum.Solved
        || status == PelikanStatusEnum.Installed;
};

export function PelikanCategoryName(category: PelikanCategoryEnum): string {
    switch (category) {
        case PelikanCategoryEnum.Problem:
            return "Problem";
        // Obsolete
        case PelikanCategoryEnum.UnloadingLackings:
            return "Braki w rozładunku";
        case PelikanCategoryEnum.MaterialLackings:
            return "Brak materiału";
        case PelikanCategoryEnum.StickerNotesLackings:
            return "Brak naklejek/opisów";
        case PelikanCategoryEnum.Remarks:
            return "Uwagi";
        default:
            return `Nieznana kategoria ${category}`;
    }
};

export function pelikanStatusClass(pelikanStatus: PelikanStatusEnum) {
    switch (pelikanStatus) {
        case PelikanStatusEnum.Open:
            return 'text-bg-info';
        case PelikanStatusEnum.InProgress:
        case PelikanStatusEnum.Installed:
            return 'text-bg-primary';
        case PelikanStatusEnum.Solved:
            return 'text-bg-success';
        case PelikanStatusEnum.Removed:
            return 'text-bg-light';
        default:
            return '';
    }
}

export const NOT_SELECTED_EMPLOYEE: EmployeeBriefDTO = {
    Id: 0,
    FirstName: '',
    Surname: '',
    FullName: 'brak'
};

export function CreateNewWorktimeEntry(): WorktimeEntryDTO {
    const now = new Date();
    const nowMilisecs = now.getTime();

    return {
        Id: 0,
        WorkType: WorkTypeEnum.NotSelected,
        Flags: 0,
        StartJsMilisecs: nowMilisecs,
        EndJsMilisecs: nowMilisecs,
        Employee: NOT_SELECTED_EMPLOYEE,
        Start: now,
        End: now,
        DurationMilisecs: 0,
        IsApprenticeTime: false
    };
}

export function addNotSelectedEmployee(employees: EmployeeBriefDTO[]) {
    employees.unshift(NOT_SELECTED_EMPLOYEE);
};

export function pelikanAssignmentText(pelikan: PelikanDTO): string {
    if (pelikan.AssignedRole) {
        return roleName(pelikan.AssignedRole);
    } else if (pelikan.AssignedEmployee) {
        return pelikan.AssignedEmployee.FullName;
    } else if (pelikan.AssignedToWorkers) {
        return "Pracownicy projektu";
    } else {
        // TODO - This situation should not occur
        return 'nieprzypisany'
    }
}

type LogEntryProp = {
    title: string,
    value?: string
};

export function BuildPelikanLogEntryProps(entry: PelikanLogEntryDTO) {

    const logEntryProps: LogEntryProp[] = [];

    if (entry.LogFlags & PelikanLogFlags.Created) {
        logEntryProps.push({
            title: "Utworzony"
        });
    }

    if (entry.NewStatus) {
        logEntryProps.push({
            title: "Status",
            value: PelikanStatusName(entry.NewStatus)
        });
    }
    if (entry.NewCategory) {
        logEntryProps.push({
            title: "Kategoria",
            value: PelikanCategoryName(entry.NewCategory)
        });
    }
    if (entry.NewAssignedRole) {
        logEntryProps.push({
            title: "Przypisany",
            value: roleName(entry.NewAssignedRole)
        });
    }
    if (entry.NewAssignedEmployee) {
        logEntryProps.push({
            title: "Przypisany",
            value: entry.NewAssignedEmployee.FullName
        });
    }
    if (entry.NewAssignedToWorkers) {
        logEntryProps.push({
            title: "Przypisany",
            value: "Pracownicy projektu"
        });
    }
    if (entry.NewNeedQDE !== undefined && entry.NewNeedQDE !== null) {
        logEntryProps.push({
            title: "Wymaga QDE",
            value: entry.NewNeedQDE ? "Tak" : "Nie"
        });
    }
    if (entry.NewQDE) {
        logEntryProps.push({
            title: "QDE",
            value: getHoursDurationText(entry.NewQDE)
        });
    }
    if (entry.NewDescription) {
        logEntryProps.push({
            title: "Opis",
            value: entry.NewDescription
        });
    }
    if (entry.NewSolution) {
        logEntryProps.push({
            title: "Rozwiązanie",
            value: entry.NewSolution
        });
    }
    if (entry.NewRemark) {
        let title = '';
        if (entry.LogFlags & PelikanLogFlags.AddedRemark) {
            title = "Dodano uwagę";
        } else if (entry.LogFlags & PelikanLogFlags.UpdatedRemark) {
            title = "Zaktualizowano uwagę"
        }

        logEntryProps.push({
            title,
            value: entry.NewRemark
        });
    }
    if (entry.NewCanMonterResolve !== undefined
        && entry.NewCanMonterResolve !== null) {
        logEntryProps.push({
            title: "Monter może rozwiązać",
            value: entry.NewCanMonterResolve ? "Tak" : "Nie"
        });
    }

    return logEntryProps;
}

export function doerName(employee: EmployeeBriefDTO | null | undefined) {
    if (employee) {
        return employee.FullName;
    } else {
        return "System";
    }
}

export function getPelikanCategoriesToSelect(isProjectPelikan: boolean): PelikanCategoryEnum[] {
    return isProjectPelikan
        ? [
            PelikanCategoryEnum.Problem,
            PelikanCategoryEnum.MaterialLackings,
            PelikanCategoryEnum.StickerNotesLackings,
            PelikanCategoryEnum.Remarks
        ]
        : [
            PelikanCategoryEnum.Problem,
            PelikanCategoryEnum.Remarks
        ];
;
}

export function QDETicketStatusName(status: QDETicketStatus) {
    switch (status) {
        case QDETicketStatus.New:
            return "Nowe";
        case QDETicketStatus.Reported:
            return "Zgłoszone";
        case QDETicketStatus.Accepted:
            return "Zaakceptowane";
        case QDETicketStatus.Rejected:
            return "Odrzucone";
        case QDETicketStatus.Removed:
            return "Usunięte";
    }
}

export function QDETicketTypeName(type: QDETicketTypeEnum) {
    switch (type) {
        case QDETicketTypeEnum.FromPelikan:
            return "Pelikan";
        case QDETicketTypeEnum.FromWorktime:
            return "Wpis czasu pracy";
    }
}

export function PelikanViewTypeText(pelikanViewType: PelikanViewType) {
    switch (pelikanViewType) {
        case PelikanViewType.All:
            return "Wszystkie";
        case PelikanViewType.AssignedToMe:
            return "Przypisane";
        case PelikanViewType.Unread:
            return "Nieprzeczytane";
    }
}

export function getWorkTimeNormText(workTimeNormType: WorkTimeNormTypeEnum) {
    switch (workTimeNormType) {
        case WorkTimeNormTypeEnum.Assembly:
            return "Montaż";
        case WorkTimeNormTypeEnum.Cable_HauptAndErdenMoreThan16:
            return "Haupty i erdy powyżej 16qmm";
        case WorkTimeNormTypeEnum.Cable_Steuerung:
            return "Steuerung";
        case WorkTimeNormTypeEnum.Cable_24VDC:
            return "Steuerleitungen - 24V DC";
        case WorkTimeNormTypeEnum.Cable_230VAC:
            return "Steuerleitungen - 230V AC";
        case WorkTimeNormTypeEnum.Cable_Hauptleitungen:
            return "Hauptleitungen";
        case WorkTimeNormTypeEnum.Cable_Kabel:
            return "Kabel";
        case WorkTimeNormTypeEnum.Cable_Erdverbindungen:
            return "Erdverbindungen";
        case WorkTimeNormTypeEnum.Cable_STV:
            return "STV";
        case WorkTimeNormTypeEnum.ControlBoard:
            return "Panel sterowniczy";
        case WorkTimeNormTypeEnum.AdditionalWorks:
            return "Prace dodatkowe";
        case WorkTimeNormTypeEnum.Testing:
            return "Testowanie (P1)";
        default:
            return `Nieobsługiwana wartość ${workTimeNormType}`;
    }
}

export function workTypeMatches(entry: WorktimeReportEntryDTO, wtc: WorkTypeDescriptor) {
    if (wtc.SubType !== undefined) {
        const entrySubtype = entry.WorkSubType || WorkSubtypeEnum.None;
        return entry.WorkType == wtc.Type
            && entrySubtype == wtc.SubType;
    }
    return entry.WorkType == wtc.Type;
}

export function depWorkTypeMatches(e: WorktimeReportEntryDTO, dt: DepartmentWorkTypeExDTO) {
    if (!e.DepartmentId) {
        return false;
    }

    if (!dt.Id) {
        // Check only matching department id
        return e.DepartmentId == dt.DepartmentId;
    }

    if (!e.DepartmentWorkType) {
        return false;
    }

    return e.DepartmentWorkType.Id == dt.Id;
}


export function getProjectStateFlagsText(projectStateFlags: ProjectStateFlags) {
    const textLines: string[] = [];

    if ((projectStateFlags & ProjectStateFlags.WorkInitiated) != 0) {
        textLines.push("Rozpoczęto prace");
    }
    if ((projectStateFlags & ProjectStateFlags.UnloadingLackingsPelikanCreated) != 0) {
        textLines.push("Utworzono pelikana o brakach");
    }
    if ((projectStateFlags & ProjectStateFlags.Urgent) != 0) {
        textLines.push("Projekt pilny");
    }

    return textLines.length > 0
        ? textLines.join(", ")
        : 'brak';
}

export function CommandToWorkFlagValue(commandId: ProjectCommandsEnum): WorkFlagValue {
    switch (commandId) {
        case ProjectCommandsEnum.SetWorkFlag:
            return WorkFlagValue.True;
        case ProjectCommandsEnum.ResetWorkFlag:
            return WorkFlagValue.False;
        case ProjectCommandsEnum.SetAsInapplicable:
            return WorkFlagValue.Inapplicable;
        default:
            return WorkFlagValue.Empty;
    }
}

export function CommandToUnloadFlagValue(commandId: ProjectCommandsEnum): UnloadingFlagValue {
    switch (commandId) {
        case ProjectCommandsEnum.SetUnloadFlagToYes:
            return UnloadingFlagValue.Yes;
        case ProjectCommandsEnum.SetUnloadFlagToMissing:
            return UnloadingFlagValue.Missing;
        case ProjectCommandsEnum.ResetUnloadFlag:
            return UnloadingFlagValue.NotSet;
        case ProjectCommandsEnum.SetUnloadFlagToInaplicable:
            return UnloadingFlagValue.Inapplicable;
        default:
            return UnloadingFlagValue.NotSet;
    }
}

export function CommandToDestinationState(command: ProjectCommandsEnum): MajorProjectStateEnum | undefined {
    switch (command) {
        case ProjectCommandsEnum.MoveToPlanned:
            return MajorProjectStateEnum.Planned;
        case ProjectCommandsEnum.MoveToUnloaded:
            return MajorProjectStateEnum.Unloaded;
        case ProjectCommandsEnum.MoveToProduction:
            return MajorProjectStateEnum.Production;
        case ProjectCommandsEnum.MoveToTesting:
            return MajorProjectStateEnum.TestProduction;
        case ProjectCommandsEnum.MoveToFixes:
            return MajorProjectStateEnum.Fixes;
        case ProjectCommandsEnum.MoveToPrepareForSend:
            return MajorProjectStateEnum.PrepareForSend;
        case ProjectCommandsEnum.MoveToReadyToSend:
            return MajorProjectStateEnum.ReadyToSend;
        case ProjectCommandsEnum.MoveToFinish:
            return MajorProjectStateEnum.Finished;
        case ProjectCommandsEnum.MoveToArchive:
            return MajorProjectStateEnum.Archive;
        default:
            return undefined;
    }
}

export function ProjectCommandIdText(
        commandId: ProjectCommandsEnum) {
    switch (commandId) {
        case ProjectCommandsEnum.MoveToPlanned:
        case ProjectCommandsEnum.MoveToUnloaded:
        case ProjectCommandsEnum.MoveToProduction:
        case ProjectCommandsEnum.MoveToTesting:
        case ProjectCommandsEnum.MoveToFixes:
        case ProjectCommandsEnum.MoveToPrepareForSend:
        case ProjectCommandsEnum.MoveToReadyToSend:
        case ProjectCommandsEnum.MoveToFinish:
        case ProjectCommandsEnum.MoveToArchive:
            {
                const status = CommandToDestinationState(commandId)!;
                return `Status: ${MainStatusName(status)}`;
            }
        case ProjectCommandsEnum.AssignWorker:
            return `Przypisano pracownika`;
        case ProjectCommandsEnum.UnassignWorker:
            return `Odpisano pracownika`;
        case ProjectCommandsEnum.CreateImportProject:
            return "Utworzono zaimportowany projekt";
        case ProjectCommandsEnum.AssignTransport:
            return "Przypisano do transportu";
        case ProjectCommandsEnum.UnassignTransport:
            return "Odpisano od transportu";
        case ProjectCommandsEnum.StartProjectWork:
            return "Rozpoczęto pracę";
        case ProjectCommandsEnum.FinishProjectWork:
            return "Zakończono pracę";
        case ProjectCommandsEnum.UpdateProjectWorkItem:
            return "Zaktualizowano wpis o pracy";
        case ProjectCommandsEnum.AddProjectWorkItem:
            return "Dodano wpis o pracy";
        case ProjectCommandsEnum.ModifyImportProject:
            return "Zmodyfikowano zaimportowany projekt";
        case ProjectCommandsEnum.SetWorkFlag:
        case ProjectCommandsEnum.ResetWorkFlag:
        case ProjectCommandsEnum.SetAsInapplicable:
            return "Zmieniono flagę pracy";
        case ProjectCommandsEnum.SetUnloadFlagToYes:
        case ProjectCommandsEnum.SetUnloadFlagToMissing:
        case ProjectCommandsEnum.ResetUnloadFlag:
        case ProjectCommandsEnum.SetUnloadFlagToInaplicable:
            return "Zmieniono flagę rozładunku";
        case ProjectCommandsEnum.Archive:
            return "Zarchiwizowano";
        case ProjectCommandsEnum.AddTesterNote:
            return "Dodano notatkę testera";
        case ProjectCommandsEnum.DeleteTesterNote:
            return "Usunięto notatkę testera";
        case ProjectCommandsEnum.UpdateTesterNote:
            return "Zaktualizowano notatkę testera";
        case ProjectCommandsEnum.SetTesterNoteStatus:
            return "Ustawiono status notatki testera";
        case ProjectCommandsEnum.AddRemark:
            return "Dodano komentarz";
        case ProjectCommandsEnum.DeleteRemark:
            return "Usunięto komentarz";
        case ProjectCommandsEnum.UpdateRemark:
            return "Zaktualizowano komentarz";
        case ProjectCommandsEnum.UpdateFMPelikanFlag:
            return "Zmieniono flagę pracy";
        case ProjectCommandsEnum.UpdateProjectStateFlag:
            return "Zaktualizowano flagę projektu";
        case ProjectCommandsEnum.DeleteProjectWorkItem:
            return "Usunięto wpis o pracy";
        case ProjectCommandsEnum.ImportFMList:
            return "Zaimportowano FMListę";
        case ProjectCommandsEnum.SetFMListItemStatus:
            return "Zmiana statusu elementu Listy FM";
        case ProjectCommandsEnum.RemoveFMList:
            return "Usunięto FMListę";
        default:
            return `Nieznana operacja ${commandId}`;
    }
}

export function ProjectCommandText(logEntry: ProjectLogEntryDTO) {
    const commandId = logEntry.CommandId;
    const relatedEmployeeName = logEntry.RelatedEmployee?.FullName || "nieznany";

    switch (commandId) {
        case ProjectCommandsEnum.MoveToPlanned:
        case ProjectCommandsEnum.MoveToUnloaded:
        case ProjectCommandsEnum.MoveToProduction:
        case ProjectCommandsEnum.MoveToTesting:
        case ProjectCommandsEnum.MoveToFixes:
        case ProjectCommandsEnum.MoveToPrepareForSend:
        case ProjectCommandsEnum.MoveToReadyToSend:
        case ProjectCommandsEnum.MoveToFinish:
        case ProjectCommandsEnum.MoveToArchive:
            {
                const status = CommandToDestinationState(commandId)!;
                return `Status: ${MainStatusName(status)}`;
            }
        case ProjectCommandsEnum.AssignWorker:
            return `Przypisano pracownika ${relatedEmployeeName}`;
        case ProjectCommandsEnum.UnassignWorker:
            return `Odpisano pracownika ${relatedEmployeeName}`;
        case ProjectCommandsEnum.CreateImportProject:
            return "Utworzono zaimportowany projekt";
        case ProjectCommandsEnum.AssignTransport:
            return "Przypisano do transportu";
        case ProjectCommandsEnum.UnassignTransport:
            return "Odpisano od transportu";
        case ProjectCommandsEnum.StartProjectWork:
            return "Rozpoczęto pracę";
        case ProjectCommandsEnum.FinishProjectWork:
            return "Zakończono pracę";
        case ProjectCommandsEnum.UpdateProjectWorkItem:
            return "Zaktualizowano wpis o pracy";
        case ProjectCommandsEnum.AddProjectWorkItem:
            return "Dodano wpis o pracy";
        case ProjectCommandsEnum.ModifyImportProject:
            return "Zmodyfikowano zaimportowany projekt";
        case ProjectCommandsEnum.SetWorkFlag:
        case ProjectCommandsEnum.ResetWorkFlag:
        case ProjectCommandsEnum.SetAsInapplicable:
            {
                const workFlag = (logEntry.RelatedFlags || 0) as WorkFlags;
                const value = CommandToWorkFlagValue(commandId);
                return `Flaga pracy ${getWorkflagText(workFlag)}: ${getWorkflagValueText(value)}`;
            }
            break;
        case ProjectCommandsEnum.SetUnloadFlagToYes:
        case ProjectCommandsEnum.SetUnloadFlagToMissing:
        case ProjectCommandsEnum.ResetUnloadFlag:
        case ProjectCommandsEnum.SetUnloadFlagToInaplicable:
            {
                const unloadFlag = (logEntry.RelatedFlags || 0) as UnloadingFlags;
                const value = CommandToUnloadFlagValue(commandId);
                return `Flaga rozładunku ${getUnloadingFlagText(unloadFlag)}: ${getUnloadingFlagValueText(value, unloadFlag)}`;
            }
        case ProjectCommandsEnum.Archive:
            return "Zarchiwizowano";
        case ProjectCommandsEnum.AddTesterNote:
            return "Dodano notatkę testera";
        case ProjectCommandsEnum.DeleteTesterNote:
            return "Usunięto notatkę testera";
        case ProjectCommandsEnum.UpdateTesterNote:
            return "Zaktualizowano notatkę testera";
        case ProjectCommandsEnum.SetTesterNoteStatus:
            return "Ustawiono status notatki testera";
        case ProjectCommandsEnum.AddRemark:
            return "Dodano komentarz";
        case ProjectCommandsEnum.DeleteRemark:
            return "Usunięto komentarz";
        case ProjectCommandsEnum.UpdateRemark:
            return "Zaktualizowano komentarz";
        case ProjectCommandsEnum.UpdateFMPelikanFlag:
            {
                const workFlag = WorkFlags.FMPelikan;
                const flagValue = (logEntry.RelatedFlags || 0) as WorkFlagValue;
                return `Flaga pracy ${getWorkflagText(workFlag)}: ${getWorkflagValueText(flagValue, workFlag)}`;
            }
        case ProjectCommandsEnum.UpdateProjectStateFlag:
            {
                const projectStateFlags = (logEntry.RelatedFlags || 0) as ProjectStateFlags;
                return `Zaktualizowano flagę projektu: ${getProjectStateFlagsText(projectStateFlags)}`;
            }
        case ProjectCommandsEnum.DeleteProjectWorkItem:
            return "Usunięto wpis o pracy";
        case ProjectCommandsEnum.ImportFMList:
            return "Zaimportowano FMListę";
        case ProjectCommandsEnum.SetFMListItemStatus:
            return "Zmiana statusu elementu Listy FM";
        case ProjectCommandsEnum.RemoveFMList:
            return "Usunięto FMListę";
        default:
            return `Nieznana operacja ${commandId}`;
    }
}

export function FMListEntryStatusText(status: FMListEntryStatus): string {
    switch (status) {
        case FMListEntryStatus.NotSet:
            return "Nieustawiony";
        case FMListEntryStatus.Present:
            return "Jest";
        case FMListEntryStatus.Missing:
            return "Brak";
        default:
            return "";
    }
}

export function EmployeeBreakText(breakId: EmployeeBreakEnum) {
    switch (breakId) {
        case EmployeeBreakEnum.NotSet:
            return "Nie wybrano";
        case EmployeeBreakEnum.Break1:
            return "Przerwa I 9:00-9:25";
        case EmployeeBreakEnum.Break2:
            return "Przerwa II 9:30-9:55";
        case EmployeeBreakEnum.Break3:
            return "Przerwa III 10:00-10:25";
        case EmployeeBreakEnum.Break4:
            return "Przerwa IV 10:30-10:55";
        case EmployeeBreakEnum.Break5:
            return "Przerwa V 11:00-11:25";
        default:
            return `Nieznana przerwa ${breakId}`;
    }
}

export type WorkStatusNowResult = {
    isInsideBreak: boolean,
    workDuration: TimeDuration,
    isAfterBreak: boolean,
    isBeforeWorkStart: boolean
};

export function DetermineWorkStatusNow(wsWithDates: WorkStatusWithDates, nowDate: Date): WorkStatusNowResult | null {

    if (!wsWithDates.TodayWorkStart) {
        return null;
    }

    const endDate = wsWithDates.LatestWorkEntry?.EndJsMilisecs
        ? new Date(wsWithDates.LatestWorkEntry.EndJsMilisecs)
        : nowDate;

    const workDuration = TimeDuration.FromDates(wsWithDates.TodayWorkStart, endDate);
    const result: WorkStatusNowResult = {
        isInsideBreak: false,
        workDuration,
        isAfterBreak: false,
        isBeforeWorkStart: workDuration.IsNegative()
    };

    if (wsWithDates.WorkBreakStartTime && wsWithDates.WorkBreakEndTime) {
        const startTime = TimeDuration.FromString(wsWithDates.WorkBreakStartTime);
        const endTime = TimeDuration.FromString(wsWithDates.WorkBreakEndTime);

        const startOfToday = new Date(nowDate.getTime());
        startOfToday.setHours(0, 0, 0, 0);

        const dayTimeMsDuration = TimeDuration.FromDates(startOfToday, endDate)
            .MillisecondsDuration();

        result.isInsideBreak = dayTimeMsDuration >= startTime.MillisecondsDuration()
            && dayTimeMsDuration < endTime.MillisecondsDuration();

        if (dayTimeMsDuration >= endTime.MillisecondsDuration()) {
            result.isAfterBreak = true;
            // When it is after break deduct 10 minutes from worktime
            result.workDuration.AddMinutes(-10);
        }
    }

    return result;
}

export function DepartmentName(departmentId: KnownDepartmentsEnum) {
    switch (departmentId) {
        case KnownDepartmentsEnum.AssemblyHall:
            return "Hala";
        case KnownDepartmentsEnum.Warehouse:
            return "Magazyn";
        case KnownDepartmentsEnum.Office:
            return "Biuro";
        default:
            return `Nieznany dział ${departmentId}`;
    }
}