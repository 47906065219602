import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Nav, Navbar, NavbarBrand, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { KnownDepartmentsEnum } from "../types/domain";
import { DepartmentWorkTypeDTO, OperationResultWithDataDTO } from "../types/dto";
import { apiFetchResponse } from "../utilities/auth-api";
import { DepartmentName } from "../utilities/domain-utils";
import { AllowsFeature, AllowsMeasureDepartmentTime, Features } from "../utilities/features";
import authService from "./api-authorization/AuthorizeService";
import DepartmentWorkTypes from "./DepartmentWorkTypes";
import { ProjectOrDepartmentPelikans } from "./ProjectOrDepartmentPelikans";
import { WorktimeReportView } from "./worktime-report/WorktimeReportView";
import WorkTimeMeasure from "./WorkTimeMeasure";

type DepartmentPageProps = {
    DepartmentId: KnownDepartmentsEnum
};

function ExactDepartmentPage(props: DepartmentPageProps) {

    const departmentId = props.DepartmentId;
    const userProfile = authService.getUserProfileSync();
    const haveRoleToStartWork = AllowsMeasureDepartmentTime(props.DepartmentId, userProfile);
    const [activeTabIndex, setActiveTab] = useState(haveRoleToStartWork ? 0 : 1);
    const [departmentWorkTypes, setDepartmentWorkTypes] = useState<DepartmentWorkTypeDTO[]>();

    const canEditWorkTypes = AllowsFeature(Features.EditOwnDepartmentWorkTypes, userProfile)
        || AllowsFeature(Features.ManageDepartments, userProfile);

    const onWorkTimeMeasureChange = function () {
        // TODO - Is this necessary ?
    };

    const fetchDepartmentWorkTypes = async function () {
        const response = await apiFetchResponse(`department/${props.DepartmentId}/work-type`);

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<DepartmentWorkTypeDTO[]>;
            if (result.Success) {
                setDepartmentWorkTypes(result.Data);
            }
        }
    };

    const onDepartmentWorkTypesChanged = function () {
        fetchDepartmentWorkTypes();
    };

    useEffect(() => {
        fetchDepartmentWorkTypes();
    }, [props.DepartmentId]);

    const hasWorkTypes = departmentWorkTypes && departmentWorkTypes.length > 0;

    return <>
        <Navbar>
            <Nav tabs>
                <NavbarBrand>{DepartmentName(departmentId)}</NavbarBrand>
                <NavItem>
                    <NavLink disabled={!haveRoleToStartWork} tag="button" active={activeTabIndex == 0} onClick={() => setActiveTab(0)}>Czas pracy</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag="button" active={activeTabIndex == 1} onClick={() => setActiveTab(1)}>Typy pracy</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag="button" active={activeTabIndex == 2} onClick={() => setActiveTab(2)}>Pelikany</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag="button" active={activeTabIndex == 3} onClick={() => setActiveTab(3)}>Raport czasu pracy</NavLink>
                </NavItem>
            </Nav>
        </Navbar>
        <TabContent activeTab={activeTabIndex}>
            <TabPane tabId={0}>
                <Row>
                    <Col md={4}>
                        {!hasWorkTypes && <i>Brak zdefiniowanych typów pracy</i>}
                        {hasWorkTypes && <WorkTimeMeasure
                            canStart={haveRoleToStartWork}
                            onChange={onWorkTimeMeasureChange}
                            departmentWorkTypes={departmentWorkTypes}
                            departmentId={departmentId}
                        />}
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId={1}>
                <Row>
                    <Col md={8}>
                        <DepartmentWorkTypes
                            departmentId={departmentId}
                            departmentWorkTypes={departmentWorkTypes || []}
                            onChaged={onDepartmentWorkTypesChanged}
                            canEdit={canEditWorkTypes}
                        />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tabId={2}>
                <ProjectOrDepartmentPelikans
                    departmentId={props.DepartmentId}
                    isVisible={activeTabIndex == 2}
                />
            </TabPane>
            <TabPane tabId={3}>
                <WorktimeReportView isVisible={activeTabIndex == 3} departmentId={props.DepartmentId} />
            </TabPane>
        </TabContent>
    </>;
}

export default function DepartmentPage() {
    const params = useParams();

    const departmentId = parseInt(params['id'] || '0');

    if (departmentId > 0) {
        return <ExactDepartmentPage DepartmentId={departmentId} />;
    }

    return null;
};
