import React, { Component } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import { Typeahead as TypeaheadComponent } from 'react-bootstrap-typeahead';
import { BAMProjectOptionDTO } from '../../types/dto';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';

type ProjectNumberAutocompleteProps = {
    projectsToSelect: BAMProjectOptionDTO[],
    onChange: (project?: BAMProjectOptionDTO) => void
};

type ProjectNumberAutocompleteState = {
};

export class ProjectNumberAutocomplete extends Component<ProjectNumberAutocompleteProps, ProjectNumberAutocompleteState> {
    static displayName = ProjectNumberAutocomplete.name;
    typeaheadRef: React.RefObject<Typeahead>;

    constructor(props: ProjectNumberAutocompleteProps) {
        super(props);

        this.typeaheadRef = React.createRef();

        this.state = {
        };

        this.onChange = this.onChange.bind(this);
    }

    componentWillUnmount() {
        this.typeaheadRef.current?.clear();
    }

    onChange(selected: any) {
        const selectedItems = selected as BAMProjectOptionDTO[];
        if (selectedItems) {
            if (selectedItems.length > 0) {
                this.props.onChange(selectedItems[0]);
            } else {
                this.props.onChange(undefined);
            }
        }
    }

    render() {
        return (
            <>
                <TypeaheadComponent
                    onChange={this.onChange}
                    options={this.props.projectsToSelect}
                    labelKey="ProjectNumberString"
                    id="project-number"
                    allowNew={false}
                    size="sm"
                    clearButton={true} placeholder="numer projektu" ref={this.typeaheadRef} />
            </>
        );
    }
}
