import { UserProfile } from "../components/api-authorization/UserManager";
import { KnownDepartmentsEnum, KnownRolesEnum } from "../types/domain";

export enum Features {
    ExtendedProjectView = 1,
    ImportProject,
    EditTodayWorktimes,
    EditWorktimes,
    ManageEmployees,
    Archive,
    WorktimeReport,
    CreateTransport,
    ManageProjectRemarks,
    ResolvePelikan,
    InstallPelikan,
    ManagePelikanStatus,
    OnlyMyProjects,
    QDEManagement,
    ShowOrderNumber,
    MyWorktimeReport,
    ManageCabinetSubTypes,
    ViewCableTimeReport,
    EditCableTimeReport,
    ManageFMList,
    ShowAssumedProjectTime,
    ManageDepartments,
    EditOwnDepartmentWorkTypes,
    ShowProjectLog
};

const featuresPerRole = new Map();

// TODO - This is second place besides Authenticate attributes
// where permissions are defined. Think it over so information is not doubled
featuresPerRole.set(KnownRolesEnum.Manager, [
    Features.ExtendedProjectView,
    Features.ImportProject,
    Features.EditWorktimes,
    Features.ManageEmployees,
    Features.Archive,
    Features.WorktimeReport,
    Features.CreateTransport,
    Features.ManageProjectRemarks,
    Features.ResolvePelikan,
    Features.ManagePelikanStatus,
    Features.QDEManagement,
    Features.ShowOrderNumber,
    Features.ManageCabinetSubTypes,
    Features.ViewCableTimeReport,
    Features.EditCableTimeReport,
    Features.ManageFMList,
    Features.ShowAssumedProjectTime,
    Features.ManageDepartments,
    Features.EditOwnDepartmentWorkTypes,
    Features.ShowProjectLog
]);

featuresPerRole.set(KnownRolesEnum.Monter, [
    Features.EditTodayWorktimes,
    Features.InstallPelikan,
    Features.OnlyMyProjects,
    Features.MyWorktimeReport
]);

featuresPerRole.set(KnownRolesEnum.Office, [
    Features.ExtendedProjectView,
    Features.ImportProject,
    Features.EditWorktimes,
    Features.ManageEmployees,
    Features.Archive,
    Features.WorktimeReport,
    Features.ManageProjectRemarks,
    Features.ResolvePelikan,
    Features.ManagePelikanStatus,
    Features.QDEManagement,
    Features.ShowOrderNumber,
    Features.ManageFMList,
    Features.ShowAssumedProjectTime,
    Features.ManageDepartments,
    Features.EditOwnDepartmentWorkTypes,
    Features.MyWorktimeReport
]);

featuresPerRole.set(KnownRolesEnum.SuperAdmin, [
]);

featuresPerRole.set(KnownRolesEnum.Tester, [
    Features.ExtendedProjectView,
    Features.EditTodayWorktimes,
    Features.ManageProjectRemarks,
    Features.ResolvePelikan,
    Features.InstallPelikan,
    Features.MyWorktimeReport,
    Features.Archive,
    Features.ShowAssumedProjectTime
]);

featuresPerRole.set(KnownRolesEnum.WarehouseKeeper, [
    Features.ExtendedProjectView,
    Features.MyWorktimeReport
]);

featuresPerRole.set(KnownRolesEnum.TeamLider, [
    Features.ViewCableTimeReport,
    Features.ShowAssumedProjectTime,
    Features.EditOwnDepartmentWorkTypes
]);

featuresPerRole.set(KnownRolesEnum.Apprentice, [
]);

featuresPerRole.set(KnownRolesEnum.WarehouseForeman, [
    Features.Archive,
    Features.ShowAssumedProjectTime,
    Features.EditOwnDepartmentWorkTypes,
    Features.ManageProjectRemarks,
    Features.ResolvePelikan
]);


const rolePerDepartment = new Map();


rolePerDepartment.set(KnownDepartmentsEnum.AssemblyHall, [
    KnownRolesEnum.TeamLider,
    KnownRolesEnum.Tester
]);

rolePerDepartment.set(KnownDepartmentsEnum.Office, [
    KnownRolesEnum.Office
]);

rolePerDepartment.set(KnownDepartmentsEnum.Warehouse, [
    KnownRolesEnum.WarehouseKeeper
]);

export function AllowsFeature(feat: Features, userProfile: UserProfile | null | undefined): boolean {
    let allowFeature = false;

    if (!userProfile) {
        return false;
    }

    userProfile.roleIds.forEach(roleId => {
        const allowedRoleFeatures = featuresPerRole.get(roleId) as Features[];
        if (allowedRoleFeatures.includes(feat)) {
            allowFeature = true;
        }
    });

    return allowFeature;
};

export function AllowsRolesMeasureDepartmentTime(departmentId: KnownDepartmentsEnum, roleIds: KnownRolesEnum[]) {

    const allowedRoles = rolePerDepartment.get(departmentId) as KnownRolesEnum[];

    return roleIds.some(roleId => allowedRoles.includes(roleId));
};

export function AllowsMeasureDepartmentTime(departmentId: KnownDepartmentsEnum, userProfile?: UserProfile): boolean {

    if (!userProfile) {
        return false;
    }

    return AllowsRolesMeasureDepartmentTime(departmentId, userProfile.roleIds);
};
