import React, { Component } from 'react';
import { OperationResultWithDataDTO, ProjectLogEntryDetailsDTO, ProjectLogEntryDTO } from '../types/dto';
import { apiFetchResponse } from '../utilities/auth-api';
import { DateToDateTimeString } from '../utilities/date-formatter';
import { doerName, ProjectCommandText } from '../utilities/domain-utils';

type ProjectLogRowProps = {
    logEntry: ProjectLogEntryDTO
};

type ProjectLogRowState = {
    isLoadingDetails: boolean,
    detailedEntry?: ProjectLogEntryDetailsDTO,
    isShowingDetails: boolean
};

export class ProjectLogRow extends Component<ProjectLogRowProps, ProjectLogRowState> {
    static displayName = ProjectLogRow.name;

    constructor(props: ProjectLogRowProps) {
        super(props);

        this.state = {
            isLoadingDetails: false,
            isShowingDetails: false
        };

        this.renderDetails = this.renderDetails.bind(this);
        this.loadDetails = this.loadDetails.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.toggleCollapseClass = this.toggleCollapseClass.bind(this);
    }

    async loadDetails() {

        this.setState({
            isLoadingDetails: true
        });

        const response = await apiFetchResponse(`project-log/${this.props.logEntry.Id}`);

        this.setState({
            isLoadingDetails: false
        });

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<ProjectLogEntryDetailsDTO>;
            if (result.Success) {
                this.setState({
                    detailedEntry: result.Data!
                });
            }
        }
    }

    renderDetails() {
        const detailedEntry = this.state.detailedEntry;

        if (this.state.isLoadingDetails) {
            return <i className="spinner-border spinner-border-sm"></i>;
        }

        if (detailedEntry) {
            const detailsDict = detailedEntry.Details;
            return <>
                {Object.keys(detailsDict).map((keyName) => <div><span>{keyName}:</span> <span>{detailsDict[keyName]}</span></div>)}
            </>
        }
        return null;
    }

    async toggleCollapse() {
        const isShowingDetails = !this.state.isShowingDetails;

        if (isShowingDetails && !this.state.detailedEntry) {
            await this.loadDetails();
        }

        this.setState({
            isShowingDetails
        });
    }

    toggleCollapseClass() {
        return this.state.isShowingDetails
            ? "fa-solid fa-caret-down"
            : "fa-solid fa-caret-right";
    }

    renderExpandCollapsePrompt() {
        return <button type="button" title="Szczegóły" style={{ width: "2em" }} className="btn btn-sm align-top" onClick={this.toggleCollapse}><i className={this.toggleCollapseClass()} /></button>
    }

    render() {
        const e = this.props.logEntry;
        return (
            <tr>
                <td>{DateToDateTimeString(e.TimestampDate!)}</td>
                <td>{doerName(e.Doer)}</td>
                <td>{e.HasDetails ? this.renderExpandCollapsePrompt() : null}<div className="d-inline-block">{ProjectCommandText(e)}{this.state.isShowingDetails && this.renderDetails()}</div></td>
            </tr>
        );
    }
}
