import React, { Component, Fragment } from 'react';
import { OperationResultWithDataDTO, PelikanDTO, EmployeeBriefDTO } from '../types/dto';
import { KnownRolesEnum, PelikanViewType, PelikanAssignmentData } from '../types/domain';
import { apiFetchResponse } from '../utilities/auth-api';
import { CalculateDates, EnhancePelikans } from '../utilities/data-enhancer';
import { fetchEmployeesToSelect } from '../utilities/data-fetch';
import { pelikanPromptService } from '../utilities/pelikan-prompt-service';
import { PelikansTable } from './PelikansTable';
import { PelikanAssignmentSelect } from './common/PelikanAssignmentSelect';
import { PelikanPageComponent } from './PelikanPage';
import { PelikanViewTypeSelect } from './PelikanViewTypeSelect.tsx';
import { useSearchParams } from 'react-router-dom';

type PelikansViewTypeString = 'all' | 'assigned' | 'unread';

type PelikansViewProps = {
    type: PelikansViewTypeString
};

type PelikansViewState = {
    Pelikans: PelikanDTO[],
    IsLoading: boolean,
    employeesToSelect: EmployeeBriefDTO[],
    createdByEmployeeId: number,
    assignedRole?: KnownRolesEnum,
    assignedEmployeeId?: number,
    assignedToWorkers?: boolean,
    lastUpdatedEmployeeId: number,
    selectedPelikanId?: number,
    wasPelikanModified: boolean,
    pelikanViewType: PelikanViewType
};

class PelikansViewComponent extends Component<PelikansViewProps, PelikansViewState> {
    static displayName = PelikansViewComponent.name;

    constructor(props: PelikansViewProps) {
        super(props);

        const employeesToSelect: EmployeeBriefDTO[] = []

        this.state = {
            Pelikans: [],
            IsLoading: true,
            employeesToSelect,
            createdByEmployeeId: 0,
            lastUpdatedEmployeeId: 0,
            wasPelikanModified: false,
            pelikanViewType: this.toPelikanViewEnum(props.type)
        };

        this.fetchPelikans = this.fetchPelikans.bind(this);
        this.onCreatedByChanged = this.onCreatedByChanged.bind(this);
        this.clearCreatedBy = this.clearCreatedBy.bind(this);
        this.onAssignedSelected = this.onAssignedSelected.bind(this);
        this.clearAssignment = this.clearAssignment.bind(this);
        this.onLastUpdatedEmployeeChanged = this.onLastUpdatedEmployeeChanged.bind(this);
        this.clearLastUpdatedEmployee = this.clearLastUpdatedEmployee.bind(this);
        this.onPelikanSelected = this.onPelikanSelected.bind(this);
        this.onClosePelikanPage = this.onClosePelikanPage.bind(this);
        this.onPelikanSaved = this.onPelikanSaved.bind(this);
        this.onPelikanViewTypeSelected = this.onPelikanViewTypeSelected.bind(this);
        this.getCurrentViewTypePelikans = this.getCurrentViewTypePelikans.bind(this);
    }

    toPelikanViewEnum = (pelikansViewType: PelikansViewTypeString): PelikanViewType => {
        switch (pelikansViewType) {
            case 'assigned':
                return PelikanViewType.AssignedToMe;
            case 'unread':
                return PelikanViewType.Unread;
            default:
                return PelikanViewType.All;
        }
    }

    async fetchEmployeesToSelect() {
        const employeesToSelect = await fetchEmployeesToSelect(false);

        this.setState({
            employeesToSelect
        });
    }

    componentDidMount() {
        pelikanPromptService.fetchNewData();

        this.fetchPelikans();
        this.fetchEmployeesToSelect();
    }

    componentDidUpdate(oldProps: PelikansViewProps) {
        if (oldProps.type != this.props.type) {
            this.setState({
                pelikanViewType: this.toPelikanViewEnum(this.props.type)
            });
        }
    }

    getCurrentViewTypePelikans(): PelikanDTO[] {
        switch (this.state.pelikanViewType) {
            case PelikanViewType.All:
                return this.state.Pelikans;
            case PelikanViewType.AssignedToMe:
                return this.state.Pelikans.filter(p => p.IsAssignedToMe);
            case PelikanViewType.Unread:
                return this.state.Pelikans.filter(p => p.IsAssignedToMe && !p.ReadConfirmation);
        }
    }

    async fetchPelikans() {
        this.setState({
            IsLoading: true,
            selectedPelikanId: undefined,
            wasPelikanModified: false
        });

        const data: Record<string, string> = {};

        if (this.state.createdByEmployeeId > 0) {
            data['CreatedEmployeeId'] = this.state.createdByEmployeeId.toString();
        }

        if (this.state.assignedRole && this.state.assignedRole > 0) {
            data['AssignedRole'] = this.state.assignedRole.toString();
        }

        if (this.state.assignedEmployeeId && this.state.assignedEmployeeId > 0) {
            data['AssignedEmployeeId'] = this.state.assignedEmployeeId.toString();
        }

        if (this.state.assignedToWorkers) {
            data['AssignedToWorkers'] = this.state.assignedToWorkers.toString();
        }

        if (this.state.lastUpdatedEmployeeId > 0) {
            data['LastUpdatedEmployeeId'] = this.state.lastUpdatedEmployeeId.toString();
        }

        const params = new URLSearchParams(data);
        const queryString = '?' + params.toString();

        const response = await apiFetchResponse(`pelikan${queryString}`);
        if (response.status == 200) {
            const data = await response.json() as OperationResultWithDataDTO<PelikanDTO[]>;

            const pelikans = data.Data || [];
            CalculateDates(pelikans);
            EnhancePelikans(pelikans);

            if (data.Success) {
                this.setState({
                    Pelikans: pelikans,
                    IsLoading: false
                });
                return;
            }
        }

        this.setState({
            IsLoading: false
        });

        // TODO - Handle errors
    }

    employeeOption(employee: EmployeeBriefDTO) {
        return <option key={employee.Id} value={employee.Id}>{employee.FullName}</option>;
    }

    onCreatedByChanged(event: React.ChangeEvent<HTMLSelectElement>) {
        const createdByEmployeeId: number = parseInt(event.currentTarget.value);
        this.setState({
            createdByEmployeeId
        });
    }

    clearCreatedBy() {
        this.setState({
            createdByEmployeeId: 0
        });
    }

    onAssignedSelected(assignment?: PelikanAssignmentData) {
        if (assignment) {
            this.setState(assignment);
        }
    }

    clearAssignment() {
        this.setState({
            assignedRole: undefined,
            assignedEmployeeId: undefined,
            assignedToWorkers: undefined
        });
    }

    onLastUpdatedEmployeeChanged(event: React.ChangeEvent<HTMLSelectElement>) {
        const lastUpdatedEmployeeId: number = parseInt(event.currentTarget.value);
        this.setState({
            lastUpdatedEmployeeId
        });
    }

    clearLastUpdatedEmployee() {
        this.setState({
            lastUpdatedEmployeeId: 0
        });
    }

    rolesToSelect(): KnownRolesEnum[] {
        return [
            KnownRolesEnum.WarehouseKeeper,
            KnownRolesEnum.Monter,
            KnownRolesEnum.Tester,
            KnownRolesEnum.Office,
            KnownRolesEnum.Manager,
            KnownRolesEnum.TeamLider,
            KnownRolesEnum.Apprentice,
            KnownRolesEnum.WarehouseForeman
        ];
    }

    onPelikanSelected(pelikan: PelikanDTO) {
        this.setState({
            selectedPelikanId: pelikan.Id
        });
    }

    onClosePelikanPage() {
        const wasPelikanModified = this.state.wasPelikanModified;

        this.setState({
            selectedPelikanId: undefined
        });

        if (wasPelikanModified) {
            this.fetchPelikans();
        }
    }

    onPelikanSaved() {
        this.setState({
            wasPelikanModified: true
        });
    }

    async onPelikanViewTypeSelected(pelikanViewType: PelikanViewType) {
        await this.setState({
            pelikanViewType
        });
    }

    render() {
        return (
            <Fragment>
                <div className='row'>
                    <div className='col-auto'>
                        <button className='btn btn-outline-secondary h-100' type='button' title='Odśwież' disabled={this.state.IsLoading} onClick={this.fetchPelikans}>
                            <span className={this.state.IsLoading ? 'spinner-border' : 'fa-solid fa-redo'}></span>
                        </button>
                    </div>
                    <div className='col-auto'>
                        <div className='input-group'>
                            <div className='form-floating'>
                                <select className='form-select form-select-sm' id='createdBySelect' value={this.state.createdByEmployeeId} onChange={this.onCreatedByChanged}>
                                    <option disabled={true} value="0">Nie wybrano</option>
                                    {this.state.employeesToSelect.map(this.employeeOption)}
                                </select>
                                <label htmlFor="createdBySelect">Utworzył</label>
                            </div>
                            <button className="btn btn-outline-secondary" type="button" onClick={this.clearCreatedBy}><i className='fa-solid fa-times' /></button>
                        </div>
                    </div>
                    <div className='col-auto'>
                        <div className='input-group'>
                            <div className='form-floating'>
                                <PelikanAssignmentSelect
                                    assignedRole={this.state.assignedRole}
                                    assignedEmployeeId={this.state.assignedEmployeeId}
                                    assignedToWorkers={this.state.assignedToWorkers}
                                    rolesToSelect={this.rolesToSelect()}
                                    employeesToSelect={this.state.employeesToSelect}
                                    showProjectWorkersOption={true}
                                    onChange={this.onAssignedSelected} />
                                <label htmlFor="assigned">Przypisany</label>
                            </div>
                            <button className="btn btn-outline-secondary" type="button" onClick={this.clearAssignment}><i className='fa-solid fa-times' /></button>
                        </div>
                    </div>
                    <div className='col-auto'>
                        <div className='input-group'>
                            <div className='form-floating'>
                                <select className='form-select form-select-sm' id='lastUpdatedEmployeeToSelect' value={this.state.lastUpdatedEmployeeId} onChange={this.onLastUpdatedEmployeeChanged}>
                                    <option disabled={true} value="0">Nie wybrano</option>
                                    {this.state.employeesToSelect.map(this.employeeOption)}
                                </select>
                                <label htmlFor="lastUpdatedEmployeeToSelect">Zmodyfikował</label>
                            </div>
                            <button className="btn btn-outline-secondary" type="button" onClick={this.clearLastUpdatedEmployee}><i className='fa-solid fa-times' /></button>
                        </div>
                    </div>
                    <div className='col-auto'>
                        <PelikanViewTypeSelect
                            pelikanViewType={this.state.pelikanViewType} onSelected={this.onPelikanViewTypeSelected} />
                    </div>
                    {this.state.IsLoading && <div className='col-auto'><span className='spinner-border'></span></div>}
                </div>
                {!this.state.selectedPelikanId &&
                    <PelikansTable
                        Pelikans={this.getCurrentViewTypePelikans()}
                        showProjectColumn={true}
                        showDepartmentColumn={true}
                        onPelikanSelected={this.onPelikanSelected} />}
                {this.state.selectedPelikanId &&
                    <PelikanPageComponent
                    pelikanId={this.state.selectedPelikanId}
                        onClosePage={this.onClosePelikanPage}
                        onSaved={this.onPelikanSaved} />}
            </Fragment>
        );
    }
}

export function PelikansView() {
    let [searchParams, setSearchParams] = useSearchParams();

    const type = searchParams.get('type') as PelikansViewTypeString;

    return <PelikansViewComponent type={type || 'all'} />
};