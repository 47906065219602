import React, { useEffect, useState } from 'react'
import authService from './AuthorizeService';
import { RequestState } from './AuthorizeService';
import { LoginActions, QueryParameterNames, ApplicationPaths } from './ApiAuthorizationConstants';
import FormTextField from '../common/FormTextField';
import { Row, Col } from 'reactstrap';
import { connect, ConnectedProps } from 'react-redux';
import { AppDispatch, useAppDispatch } from '../../store/app-store';
import { fetchWorkStatus } from '../../store/work-status-slice';

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        dispatch
    };
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type LoginProps = {
    action: string
};

type LoginState = {
    message: string | null | undefined;
    isLogging: boolean,
    errors: { [key: string]: string[] }
};

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export default function LoginComponent(props: LoginProps) {

    const [message, setMessage] = useState<string | null | undefined>();
    const [isLogging, setIsLogging] = useState(false);
    const [errors, setErrors] = useState<{ [key: string]: string[] }>();
    const dispatch = useAppDispatch();

    const redirectToApiAuthorizationPath = function (apiAuthorizationPath: string) {
        const redirectUrl = `${window.location.origin}/${apiAuthorizationPath}`;
        // It's important that we do a replace here so that when the user hits the back arrow on the
        // browser they get sent back to where it was on the app instead of to an endpoint on this
        // component.
        window.location.replace(redirectUrl);
    }

    const redirectToRegister = function() {
        redirectToApiAuthorizationPath(`${ApplicationPaths.IdentityRegisterPath}?${QueryParameterNames.ReturnUrl}=${encodeURI(ApplicationPaths.Login)}`);
    }

    const redirectToProfile = function() {
        redirectToApiAuthorizationPath(ApplicationPaths.IdentityManagePath);
    }

    useEffect(() => {
        const action = props.action;
        switch (action) {
            case LoginActions.Login:
                break;
            case LoginActions.LoginFailed:
                const params = new URLSearchParams(window.location.search);
                const error = params.get(QueryParameterNames.Message);
                setMessage(error);
                break;
            case LoginActions.Profile:
                redirectToProfile();
                break;
            case LoginActions.Register:
                redirectToRegister();
                break;
            default:
                throw new Error(`Invalid action '${action}'`);
        }
    }, [props.action]);

    const getReturnUrl = function(state ?: RequestState): string {
        const params = new URLSearchParams(window.location.search);
        const fromQuery = params.get(QueryParameterNames.ReturnUrl);
        if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
            // This is an extra check to prevent open redirects.
            throw new Error("Invalid return url. The return url needs to have the same origin as the current page.")
        }
        return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    }

    const navigateToReturnUrl = function(returnUrl: string) {
        // It's important that we do a replace here so that we remove the callback uri with the
        // fragment containing the tokens from the browser history.
        window.location.replace(returnUrl);
    }

    const login = async function(formData: FormData) {
        const returnUrl = getReturnUrl();
        const state = { returnUrl, formData };

        setIsLogging(true);

        const result = await authService.signIn(state);
        switch (result.status) {
            case 'redirect':
                setIsLogging(false);
                break;
            case 'success':
                await navigateToReturnUrl(returnUrl);
                break;
            case 'fail':
                setIsLogging(false);
                setMessage(result.message);
                break;
            default:
                throw new Error(`Invalid status result ${result.status}.`);
        }
    }

    const onSubmit = async function(formEvent: React.FormEvent<HTMLFormElement>): Promise < void> {
        formEvent.preventDefault();

        var formData = new FormData(formEvent.target as HTMLFormElement);

        await login(formData);

        await dispatch(fetchWorkStatus());
    };

    if (isLogging) {
        return <div>Logowanie...</div>;
    }

    return (
        <Row>
            <Col md={4}>
            <h3>Logowanie</h3>
                <form className="form" onSubmit={onSubmit}>
                    <FormTextField name="loginName" label="Login" errors={/*this.state.errors*/undefined} />
                    <FormTextField name="password" label="Hasło" type="password" defaultValue="" errors={errors} />
                    <div>
                        <button type="submit" className="btn btn-primary btn-block">Zaloguj</button>
                    </div>
                </form>
            </Col>
        </Row>
    );
}
