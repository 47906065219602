import { MajorProjectStateEnum } from "../../types/domain";
import { MainStatusName } from "../../utilities/domain-utils";
import { ClearableDropdown } from "./ClearableDropdown";

type ProjectStatusFilterProps = {
    value?: MajorProjectStateEnum,
    onChange: (status?: MajorProjectStateEnum) => void
    states?: MajorProjectStateEnum[]
};

export function ProjectStateFilter(props: ProjectStatusFilterProps) {
    const states = props.states || [
        MajorProjectStateEnum.Planned,
        MajorProjectStateEnum.Unloaded,
        MajorProjectStateEnum.Production,
        MajorProjectStateEnum.TestProduction,
        MajorProjectStateEnum.Fixes,
        MajorProjectStateEnum.PrepareForSend,
        MajorProjectStateEnum.ReadyToSend,
        MajorProjectStateEnum.Finished,
        MajorProjectStateEnum.Archive
    ];

    return <ClearableDropdown
        value={props.value}
        onChange={props.onChange}
        valueText={MainStatusName}
        values={states}
    />
}
