type CheckboxFilterProps = {
    value: boolean
    onChange: (value: boolean) => void
};

export function CheckboxFilter(props: CheckboxFilterProps) {

    const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.checked);
    };

    return <div>
        <input type="checkbox" className="form-check-input" checked={props.value} onChange={onFilterChange} />
    </div>;
}
