import React, { Component } from 'react';
import { OperationResultWithDataDTO, ProjectRemarkDTO, ValidationErrorsResponse } from '../types/dto';
import { ProjectNumberContext } from './contexts';
import { apiFetchResponse } from '../utilities/auth-api';
import { SingleNoteForm } from './common/SingleNoteForm';
import { FormValidationUtility } from './common/FormValidationUtility';
import { ValidationErrors } from '../types/forms';
import { ProjectRemarkTypeEnum } from '../types/domain';
import { CalculateDates } from '../utilities/data-enhancer';
import { DateToDateTimeString } from '../utilities/date-formatter';

type ProjectRemarksProps = {
    canEdit?: boolean
}

type ProjectRemarksState = {
    remarks?: ProjectRemarkDTO[],
    showForm: boolean,
    editedId?: number,
    initText: string,
    fvu?: FormValidationUtility
}

export class ProjectRemarks extends Component<ProjectRemarksProps, ProjectRemarksState> {
    static displayName = ProjectRemarks.name;
    static contextType = ProjectNumberContext;

    constructor(props: ProjectRemarksProps) {
        super(props);

        this.state = {
            showForm: false,
            initText: ''
        };

        this.startCreateNew = this.startCreateNew.bind(this);
        this.fetchRemarks = this.fetchRemarks.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
        this.onSubmitRemark = this.onSubmitRemark.bind(this);
        this.editRemark = this.editRemark.bind(this);
        this.remarkItem = this.remarkItem.bind(this);
        this.deleteRemark = this.deleteRemark.bind(this);
    }

    componentDidMount() {
        this.fetchRemarks();
    }

    async fetchRemarks() {
        const projectNumber = this.context;
        const response = await apiFetchResponse(`project/${projectNumber}/remark`);

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<ProjectRemarkDTO[]>;
            if (result.Success) {

                const remarks = result.Data;

                CalculateDates(remarks);

                this.setState({
                    remarks
                });
            }
        }
    }

    startCreateNew() {
        if (!this.props.canEdit) {
            return;
        }

        this.setState({
            showForm: true,
            editedId: undefined
        });
    }

    onFormCancel() {
        this.setState({
            showForm: false,
            editedId: undefined
        });
    }

    async onSubmitRemark(text: string) {
        const projectNumber = this.context;

        const formData = {
            Text: text
        };

        const url = this.state.editedId
            ? `project/${projectNumber}/remark/${this.state.editedId}`
            : `project/${projectNumber}/remark`;
        const method = this.state.editedId
            ? 'PUT' : 'POST';

        const response = await apiFetchResponse(url,
            {
                body: JSON.stringify(formData),
                method,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

        if (response.status == 200) {

            this.setState({
                initText: '',
                fvu: new FormValidationUtility(),
                showForm: false,
                editedId: undefined
            });

            const result = await response.json() as OperationResultWithDataDTO<ProjectRemarkDTO>;

            await this.fetchRemarks();
        } else if (response.status == 400) {
            // handle validation errors
            const validationErrors = await response.json() as ValidationErrorsResponse;
            const validator = new ValidationErrors();

            validator.SetFromValidationErrorsResponse(validationErrors);

            this.setState({
                fvu: new FormValidationUtility(validator)
            });
        }
    }

    canRemarkBeEdited(remark: ProjectRemarkDTO): boolean {
        return remark.Type != ProjectRemarkTypeEnum.ImportRemark;
    }

    editRemark(remark: ProjectRemarkDTO) {

        if (this.canRemarkBeEdited(remark)) {
            this.setState({
                showForm: true,
                editedId: remark.Id,
                initText: remark.Text
            });
        }
    }

    async deleteRemark(remark: ProjectRemarkDTO) {
        const projectNumber = this.context;
        const response = await apiFetchResponse(`project/${projectNumber}/remark/${remark.Id}`, {
            method: 'DELETE'
        });

        if (response.status == 200) {
            await this.fetchRemarks();
        }
    }

    remarkItem(remark: ProjectRemarkDTO) {
        const canEdit = this.props.canEdit
            && this.canRemarkBeEdited(remark);

        return <tr key={remark.Id}>
            <td>
                <p className="mb-0">{remark.Text}</p>
                <p className="mb-0"><small>{remark.Created ? `Dodano ${DateToDateTimeString(remark.Created)}` : ''}{remark.Modified ? `Zmodyfikowano ${DateToDateTimeString(remark.Modified) }` : ''}</small></p>
            </td>
            <td>
                {canEdit && <>
                    <button className="btn btn-sm btn-outline-primary" title="Edytuj" type="button" onClick={() => this.editRemark(remark)}><i className="fa-solid fa-edit"></i></button>
                    <button className="btn btn-sm btn-outline-danger ms-1" title="Usuń" type="button" onClick={() => this.deleteRemark(remark)}><i className="fa-solid fa-times"></i></button>
                </>
                }
            </td>
        </tr>
    }

    render() {

        if (!this.state.remarks) {
            return null;
        }

        return <>
            <div>
                <strong>Uwagi</strong>
                {!this.state.showForm && this.props.canEdit &&
                    <button className="btn btn-sm btn-outline-primary ms-2" onClick={this.startCreateNew}>Dodaj uwagę</button>
                }
            </div>
            {this.state.showForm &&
                <SingleNoteForm
                    id="remark-text"
                    name="remark-text"
                    label="Uwaga"
                    onCancel={this.onFormCancel}
                    onFormSubmit={this.onSubmitRemark}
                    initText={this.state.initText}
                    fvu={this.state.fvu}
                    />
            }
            {!this.state.showForm && this.state.remarks.length > 0 &&
                <table className="table table-sm">
                    <tbody>
                        {this.state.remarks.map(this.remarkItem)}
                    </tbody>
                </table>
            }
            {!this.state.showForm && this.state.remarks.length == 0 &&
                <i>Brak uwag</i>
            }
        </>;
    }
}
