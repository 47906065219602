import React, { Component, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { apiFetchData } from '../utilities/auth-api';
import { OperationResultWithDataDTO, EmployeeFullDTO } from '../types/dto';
import { EmployeeForm } from './EmployeeForm';
import { Nav, Navbar, NavItem, NavLink, NavbarBrand, TabContent, TabPane } from 'reactstrap';
import { EmployeeWorktimePage } from './EmployeeWorktimePage';
import { EmployeeBreakEnum } from '../types/domain';

type EmployeePageClassProps = {
    employeeId: number
};

type EmployeePageClassState = {
    employee?: EmployeeFullDTO,
    activeTabIndex: number
};

const newEmployee: EmployeeFullDTO = {
    Id: 0,
    FirstName: '',
    Surname: '',
    FullName: 'Nowy pracownik',
    User: {
        Id: 0,
        LoginName: '',
        IsActive: true,
        Roles: []
    },
    EmployeeBreakId: EmployeeBreakEnum.NotSet
}

class EmployeePageClass extends Component<EmployeePageClassProps, EmployeePageClassState> {
    static displayName = EmployeePage.name;

    constructor(props: EmployeePageClassProps) {
        super(props);

        this.state = {
            employee: this.props.employeeId == 0
                ? newEmployee
                : undefined,
            activeTabIndex: 0
        };

        this.onEmployeeChanged = this.onEmployeeChanged.bind(this);
        this.setActiveTab = this.setActiveTab.bind(this);

        if (this.props.employeeId > 0) {
            this.fetchEmployee = this.fetchEmployee.bind(this);
        }
    }

    componentDidMount() {
        this.fetchEmployee();
    }

    async fetchEmployee() {
        var result = await apiFetchData(`employee/${this.props.employeeId}`) as OperationResultWithDataDTO<EmployeeFullDTO>;

        if (result.Success) {
            this.setState({
                employee: result.Data
            });
        }
    }

    onEmployeeChanged(employee: EmployeeFullDTO) {
        this.setState({
            employee
        });
    }

    setActiveTab(activeTabIndex: number) {
        this.setState({
            activeTabIndex
        })
    }

    render() {
        const fullName = this.state.employee
            ? this.state.employee.FullName
            : '';

        if (!this.state.employee) {
            return null;
        }

        return (
            <Fragment>
                <Navbar>
                    <Nav tabs>
                        <NavbarBrand>{fullName}</NavbarBrand>
                        {this.props.employeeId > 0 && <NavItem active={this.state.activeTabIndex == 0}>
                            <NavLink tag="button" active={this.state.activeTabIndex == 0} onClick={() => this.setActiveTab(0)}>Szczegóły</NavLink>
                        </NavItem>}
                        {this.props.employeeId > 0 && <NavItem active={this.state.activeTabIndex == 1}>
                            <NavLink tag="button" active={this.state.activeTabIndex == 1} onClick={() => this.setActiveTab(1)}>Historia pracy</NavLink>
                        </NavItem>}
                    </Nav>
                </Navbar>
                <TabContent activeTab={this.state.activeTabIndex}>
                    <TabPane tabId={0}>
                        <div className="row">
                            <div className="col-4">
                                <EmployeeForm employee={this.state.employee} onChanged={this.onEmployeeChanged} />
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tabId={1}>
                        {!!this.state.employee && <EmployeeWorktimePage employee={this.state.employee} />}
                    </TabPane>
                </TabContent>
            </Fragment>
        );
    }
}

export default function EmployeePage() {
    const params = useParams();

    const employeeId = parseInt(params['id'] || '0');

    return <EmployeePageClass employeeId={employeeId}></EmployeePageClass>
};
