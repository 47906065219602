
export function getMaxEnumValue(enumObject: any): number {

    return Object.values(enumObject)
        .filter((v) => !isNaN(Number(v)))
        .reduce((acc, current) => {
            const maxValue = acc as number | undefined;
            const value = current as number;
            return (maxValue === undefined || maxValue < value)
                ? value
                : maxValue;
        }, undefined as number | undefined) as number;
}
