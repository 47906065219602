import { Button } from 'bootstrap';
import React, { Component, Fragment } from 'react';

type StateFlagSelectProps = {
    value: any,
    disabled?: boolean,
    availableValues: any[],
    containerClass?: string
    getButtonClass?: (value: any) => string,
    getIconClass?: (value: any) => string,
    getButtonText?: (value: any) => string,
    getButtonTitle?: (value: any) => string,
    getValueDescription?: (value: any) => string
    onSelected?: (value: any) => void,
    valueDescriptions?: ValueData[]
};

type StateFlagSelectState = {
};

export type ValueData = {
    value: any,
    buttonClass?: string,
    iconClass?: string,
    buttonText?: string,
    buttonTitle?: string,
    description?: string
}

export class StateFlagSelect extends Component<StateFlagSelectProps, StateFlagSelectState> {
    static displayName = StateFlagSelect.name;

    constructor(props: StateFlagSelectProps) {
        super(props);

        this.state = {
        };

        this.getValueClass = this.getValueClass.bind(this);
        this.getIconClass = this.getIconClass.bind(this);
        this.getButtonText = this.getButtonText.bind(this);
        this.getButtonTitle = this.getButtonTitle.bind(this);
        this.getValueDescription = this.getValueDescription.bind(this);
        this.onValueSelected = this.onValueSelected.bind(this);
        this.getValueData = this.getValueData.bind(this);
        this.getContainerClass = this.getContainerClass.bind(this);
    }

    getValueData(value: any): ValueData | undefined {
        return this.props.valueDescriptions?.find(vd => vd.value == value);
    }

    getValueClass(value: any, baseClass: string)
    {
        let valueClass = this.getValueData(value)?.buttonClass;

        if (!valueClass) {
            valueClass = this.props.getButtonClass
                ? this.props.getButtonClass(value)
                : undefined;
        }

        return valueClass ? `${valueClass} ${baseClass}` : baseClass;
    }

    getIconClass(value: any) {
        let iconClass = this.getValueData(value)?.iconClass;

        if (!iconClass) {
            iconClass = this.props.getIconClass
                ? this.props.getIconClass(value)
                : undefined;
        }

        return iconClass;
    }

    getButtonText(value: any) {
        let buttonText = this.getValueData(value)?.buttonText;

        if (!buttonText) {
            buttonText = this.props.getButtonText
                ? this.props.getButtonText(value)
                : undefined;
        }

        return buttonText;
    }

    getButtonTitle(value: any) {
        let buttonTitle = this.getValueData(value)?.buttonTitle;

        if (!buttonTitle) {
            buttonTitle = this.props.getButtonTitle
                ? this.props.getButtonTitle(value)
                : undefined;
        }

        return buttonTitle;
    }

    onValueSelected(value: any) {
        if (this.props.onSelected) {
            this.props.onSelected(value);
        }
    }

    getValueDescription(value: any) {
        let valueDescription = this.getValueData(value)?.description;

        if (!valueDescription) {
            return this.props.getValueDescription
                ? <>&nbsp;{this.props.getValueDescription(value)}</>
                : undefined;
        }

        return valueDescription;
    }

    getValueOption(value: any) {
        return <li key={value}>
            <button type="button" className={this.getValueClass(value, "dropdown-item")} onClick={() => this.onValueSelected(value)}>
                <i className={this.getIconClass(value)}>{this.getButtonText(value)}</i>&nbsp;{this.getValueDescription(value)}
            </button>
        </li>;
    }

    getContainerClass() {
        const baseClass = "btn-group btn-group-sm";
        return this.props.containerClass
            ? this.props.containerClass + " " + baseClass
            : "btn-group btn-group-sm";
    }

    render() {
        let buttonText = this.getButtonText(this.props.value);
        if (buttonText) {
            buttonText = " " + buttonText;
        }

        return (
            <div className={this.getContainerClass()} role="group">
                <button type="button" className={this.getValueClass(this.props.value, "dropdown-toggle")} data-bs-toggle="dropdown" aria-expanded="false" title={this.getButtonTitle(this.props.value)} disabled={this.props.disabled}>
                    <i className={this.getIconClass(this.props.value)}>{buttonText}</i>
                </button>
                <ul className="dropdown-menu dropdown-menu-sm-start">
                    {this.props.availableValues.map(value => this.getValueOption(value))}
                </ul>
            </div>
        );
    }
}
