import React from 'react';

type FileDropableButtonProps = {
    processFile: (file: File) => Promise<void>,
    inputId?: string,
    buttonId?: string,
    acceptFiles?: string
};

export function FileDropableButton(props: React.PropsWithChildren<FileDropableButtonProps>) {

    const [isFileOver, setIsFileOver] = React.useState(false);

    const onDrop = async function (event: React.DragEvent<HTMLButtonElement>) {
        event.preventDefault();
        setIsFileOver(false);

        const items = event.dataTransfer.items;
        if (items) {
            // Use DataTransferItemList interface to access the file(s)
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    if (file) {
                        await props.processFile(file);
                    }
                }
            }
        } else {
            const files = event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                await props.processFile(files[i]);
            }
        }
    }

    const onDrawOver = (event: React.DragEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }

    const onDragEnter = (sth: React.DragEvent<HTMLButtonElement>) => {
        setIsFileOver(true);
    }

    const onDragLeave = (sth: React.DragEvent<HTMLButtonElement>) => {
        setIsFileOver(false);
    }

    const inputId = props.inputId || "import-file-input";
    const buttonId = props.buttonId || "import-button";

    const onImportClicked = (event: React.MouseEvent) => {
        const element = document.getElementById(inputId);
        element?.click();
    }

    const onImportFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = event.target.files;
            for (let i = 0; i < files.length; i++) {
                await props.processFile(files[i]);
            }
        }
        event.target.files = null;
        event.target.value = '';
    }

    return <>
        <input type="file" id={inputId} accept=".xlsx" hidden={true} onChange={onImportFileSelected} />
        <button
            id={buttonId}
            type="button"
            className={'btn ' + (isFileOver ? 'btn-secondary' : 'btn-outline-secondary')}
            onDrop={onDrop}
            onDragOver={onDrawOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onClick={onImportClicked}>{props.children}</button>
    </>;
}
