import { PelikanStatusEnum } from "../../types/domain";
import { PelikanStatusName } from "../../utilities/domain-utils";
import { ClearableDropdown } from "./ClearableDropdown";

type PelikanStatusFilterProps = {
    value?: PelikanStatusEnum,
    onChange: (status?: PelikanStatusEnum) => void
};

export function PelikanStatusFilter(props: PelikanStatusFilterProps) {

    const values = [
        PelikanStatusEnum.Open,
        PelikanStatusEnum.InProgress
    ];

    return <ClearableDropdown
        value={props.value}
        onChange={props.onChange}
        valueText={PelikanStatusName}
        values={values}
    />;
}