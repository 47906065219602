import React, { Component } from 'react';
import { EmployeeBriefDTO } from '../types/dto';

type ProjectWorkersProps = {
    workers?: EmployeeBriefDTO[],
    canManage?: boolean,
    availableWorkers: EmployeeBriefDTO[],
    onAddWorker?: (employeeId: number) => void,
    onRemoveWorker?: (employeeId: number) => void
};

type ProjectWorkersState = {
    selectedEmployeeId: number
};

export class ProjectWorkers extends Component<ProjectWorkersProps, ProjectWorkersState> {
    static displayName = ProjectWorkers.name;

    constructor(props: ProjectWorkersProps) {
        super(props);

        this.state = {
            selectedEmployeeId: 0
        };

        this.onAssignWorker = this.onAssignWorker.bind(this);
        this.onRemoveWorker = this.onRemoveWorker.bind(this);
    }

    onAssignWorker(event: React.ChangeEvent<HTMLSelectElement>) {
        const id = parseInt(event.target.value);

        if (this.props.onAddWorker) {
            this.props.onAddWorker(id);
        }
    };

    onRemoveWorker(id: number) {
        if (this.props.onRemoveWorker) {
            this.props.onRemoveWorker(id);
        }
    }

    render() {
        if ((!this.props.workers || !this.props.workers.length) && !this.props.canManage) {
            return null;
        }

        return (
            <>
                <div><strong>Przydzieleni pracownicy do poprawek</strong></div>
                <ul className="list-group">
                    {(this.props.workers && this.props.workers.length > 0) && this.props.workers.map(worker =>
                        <li key={worker.Id} className="list-group-item">{worker.Surname} {worker.FirstName}{this.props.canManage === true && <button className="btn"><i className="fa-solid fa-remove" onClick={() => this.onRemoveWorker(worker.Id)} /></button>}</li>
                    )}
                    {this.props.canManage &&
                        <li className="list-group-item">
                            <select className="form-select" onChange={this.onAssignWorker} value={this.state.selectedEmployeeId}>
                                <option value="0" disabled={true}>wybierz pracownika</option>
                                {this.props.availableWorkers.map(w => <option key={w.Id} value={w.Id.toString()}>{w.Surname} {w.FirstName}</option>)}
                            </select>
                        </li>
                    }
                </ul>
            </>
        );
    }
}
