import { useParams } from 'react-router-dom';
import React, { Component, Fragment } from "react";
import { Row, Col, Nav, Navbar, NavItem, NavLink, NavbarBrand, TabContent, TabPane, Tooltip } from 'reactstrap';
import { KnownRolesEnum, MajorProjectStateEnum, ProjectCommandsEnum, ProjectStateFlags, TesterNoteStatusEnum, WorkTypeEnum } from '../types/domain';
import { BAMProjectDTO, EmployeeBriefDTO, ExportTransportDTO, GetProjectResult, OperationResultDTO, OperationResultWithDataDTO, ProjectCommandsDTO, WorktimeEntryDTO, TesterNoteDTO } from '../types/dto';
import { StatusIndicator } from "./StatusIndicator";
import { apiFetchData, apiFetchResponse } from '../utilities/auth-api';
import { EnhanceProject, CalculateDates } from '../utilities/data-enhancer';
import { getHoursDurationText, getHoursProgressPercent } from '../utilities/date-utils';
import { ProjectRemarks } from './ProjectRemarks';
import { ProjectWorkers } from './ProjectWorkers';
import WorkTimeMeasure from './WorkTimeMeasure';
import { AssignTransport } from './AssignTransport';
import authService from './api-authorization/AuthorizeService';
import { Features, AllowsFeature } from '../utilities/features';
import { ProjectWorkHistory } from './ProjectWorkHistory';
import { ProjectWorkFlags } from './ProjectWorkFlags';
import { UnloadingProtocol } from './UnloadingProtocol';
import { ProjectOrDepartmentPelikans } from './ProjectOrDepartmentPelikans';
import { WorktimeReportView } from './worktime-report/WorktimeReportView';
import { TesterNotes } from './TesterNotes';
import { ProjectNumberContext } from './contexts';
import { ProjectQDETickets } from './ProjectQDETickets';
import { CabinetSubTypes } from './CabinetSubTypes';
import { CableTimeReport } from './CableTimeReport';
import { ProjectLogView } from './ProjectLogView';
import { AlertComponent, AlertMessage } from './common/AlertComponent';
import { UpdateUnloadingFlagForm } from '../types/forms';
import { FMList } from './FMList';
import getHubInstance from '../utilities/refresh-hub-client';
import { ProjectCommandIdText } from '../utilities/domain-utils';

type ProjectPageComponentProps = {
    projectNumber: number,
    onClosePage?: () => void,
    onProjectLoaded?: (project: BAMProjectDTO) => void
    availableTransports?: ExportTransportDTO[],
};

type ProjectPageComponentState = {
    project?: BAMProjectDTO,
    operationNames?: string[],
    availableWorkers: EmployeeBriefDTO[],
    isExtendedView: boolean,
    emloyeeId: number,
    canEditTodayWorktimes: boolean,
    canEditAllWorktimes: boolean,
    availableWorkTypes: WorkTypeEnum[],
    activeTabIndex: number,
    canSeeWorktimeReport: boolean,
    testerNotes: TesterNoteDTO[],
    canManageRemarks: boolean,
    isMonter: boolean,
    isOffice: boolean,
    canManageCabinetSubtypes: boolean,
    canSeeCableTimeReport: boolean,
    canEditCableTimeReport: boolean,
    alertMessage?: AlertMessage,
    needsRefresh: boolean,
    lastCommandText: string,
    canManageFMList: boolean,
    showAssumedProjectTime: boolean,
    canSeeLog: boolean
};

type ProjectChangedArgs = {
    projectNumber: string
    commandId: ProjectCommandsEnum
};

export class ProjectPageComponent extends Component<ProjectPageComponentProps, ProjectPageComponentState>
{
    static displayName = ProjectPageComponent.name;

    constructor(props: ProjectPageComponentProps) {
        super(props);

        this.state = {
            project: undefined,
            availableWorkers: [],
            isExtendedView: false,
            emloyeeId: authService.getEmployeeIdSync(),
            canEditTodayWorktimes: false,
            canEditAllWorktimes: false,
            availableWorkTypes: [],
            activeTabIndex: 0,
            canSeeWorktimeReport: false,
            testerNotes: [],
            canManageRemarks: false,
            isMonter: authService.hasUserRoleSync(KnownRolesEnum.Monter),
            isOffice: authService.hasUserRoleSync(KnownRolesEnum.Office),
            canManageCabinetSubtypes: false,
            canSeeCableTimeReport: false,
            canEditCableTimeReport: false,
            needsRefresh: false,
            lastCommandText: '',
            canManageFMList: false,
            showAssumedProjectTime: false,
            canSeeLog: false
        };

        authService.getUser().then(userProfile => {
            this.setState({
                isExtendedView: AllowsFeature(Features.ExtendedProjectView, userProfile),
                canEditTodayWorktimes: AllowsFeature(Features.EditTodayWorktimes, userProfile),
                canEditAllWorktimes: AllowsFeature(Features.EditWorktimes, userProfile),
                canSeeWorktimeReport: AllowsFeature(Features.WorktimeReport, userProfile),
                canManageRemarks: AllowsFeature(Features.ManageProjectRemarks, userProfile),
                canManageCabinetSubtypes: AllowsFeature(Features.ManageCabinetSubTypes, userProfile),
                canSeeCableTimeReport: AllowsFeature(Features.ViewCableTimeReport, userProfile),
                canEditCableTimeReport: AllowsFeature(Features.EditCableTimeReport, userProfile),
                canManageFMList: AllowsFeature(Features.ManageFMList, userProfile),
                showAssumedProjectTime: AllowsFeature(Features.ShowAssumedProjectTime, userProfile),
                canSeeLog: AllowsFeature(Features.ShowProjectLog, userProfile)
            });
        });

        this.moveToState = this.moveToState.bind(this);
        this.onAddWorker = this.onAddWorker.bind(this);
        this.onRemoveWorker = this.onRemoveWorker.bind(this);
        this.fetchProjectData = this.fetchProjectData.bind(this);
        this.isUsersAndInProgress = this.isUsersAndInProgress.bind(this);
        this.isOthersInProgress = this.isOthersInProgress.bind(this);
        this.getCurrentEntryInProgress = this.getCurrentEntryInProgress.bind(this);
        this.getCurrentOthersInProgress = this.getCurrentOthersInProgress.bind(this);
        this.fetchProjectWorkHistory = this.fetchProjectWorkHistory.bind(this);
        this.onWorktimeChanged = this.onWorktimeChanged.bind(this);
        this.isProjectInWorkingPhase = this.isProjectInWorkingPhase.bind(this);
        this.onWorkFlagsChanged = this.onWorkFlagsChanged.bind(this);
        this.showUnloadingProtocol = this.showUnloadingProtocol.bind(this);
        this.initiallyCollapseUnloadingProtocol = this.initiallyCollapseUnloadingProtocol.bind(this);
        this.initiallyCollapseProjectWorkFlags = this.initiallyCollapseProjectWorkFlags.bind(this);
        this.onUnloadingFlagsChanged = this.onUnloadingFlagsChanged.bind(this);
        this.setActiveTab = this.setActiveTab.bind(this);
        this.canEditTesterNotes = this.canEditTesterNotes.bind(this);
        this.canCheckTesterNotes = this.canCheckTesterNotes.bind(this);
        this.showTesterNotes = this.showTesterNotes.bind(this);
        this.notDoneTesterNotes = this.notDoneTesterNotes.bind(this);
        this.onTesterNoteChecked = this.onTesterNoteChecked.bind(this);
        this.isTesterNotesInitialyCollapsed = this.isTesterNotesInitialyCollapsed.bind(this);
        this.updateUrgency = this.updateUrgency.bind(this);
        this.isUrgent = this.isUrgent.bind(this);
        this.onFMListStatusChanged = this.onFMListStatusChanged.bind(this);
        this.onAnyProjectChanged = this.onAnyProjectChanged.bind(this);
    }

    componentDidMount() {

        getHubInstance().subscribe(this.onAnyProjectChanged);

        this.fetchProjectData();
    }

    componentWillUnmount() {
        getHubInstance().unsubscribe(this.onAnyProjectChanged);
    }

    onAnyProjectChanged(event: Event) {

        const customEvent = event as CustomEvent<ProjectChangedArgs>;

        if (customEvent.detail.projectNumber == this.props.projectNumber.toString()) {
            this.setState({
                needsRefresh: true,
                lastCommandText: ProjectCommandIdText(customEvent.detail.commandId)
            });
        }
    }

    setActiveTab(activeTabIndex: number) {
        this.setState({
            activeTabIndex
        });
    }

    async fetchProjectData() {
        const result = await apiFetchData(`project/${this.props.projectNumber}`)as GetProjectResult;
        const commandsResult = await apiFetchData(`project/${this.props.projectNumber}/commands`) as OperationResultWithDataDTO<ProjectCommandsDTO>;
        const availableWorkersResult = await apiFetchData(`project/${this.props.projectNumber}/worker/available`) as OperationResultWithDataDTO<EmployeeBriefDTO[]>;
        const availableWorkTypes = await apiFetchData(`project/${this.props.projectNumber}/worktime/type`) as OperationResultWithDataDTO<WorkTypeEnum[]>;
        const testerNotesData = await apiFetchData(`project/${this.props.projectNumber}/tester-note`) as OperationResultWithDataDTO<TesterNoteDTO[]>;

        if (result.Project) {
            EnhanceProject(result.Project);
        }

        let alertMessage: AlertMessage | undefined = undefined;
        if (result.Message) {
            alertMessage = {
                text: result.Message,
                className: "alert-info",
                displayMs: 5000
            }
        }

        this.setState({
            project: result.Project,
            alertMessage,
            operationNames: commandsResult.Data?.ProjectCommandNames,
            availableWorkers: availableWorkersResult.Data || [],
            availableWorkTypes: availableWorkTypes.Data || [],
            testerNotes: testerNotesData.Data || [],
            needsRefresh: false
        });

        if (this.props.onProjectLoaded) {
            this.props.onProjectLoaded(result.Project);
        }
    }

    notDoneTesterNotes(): TesterNoteDTO[] {
        return this.state.testerNotes.filter(tn => tn.Status == TesterNoteStatusEnum.New);
    }

    canPerform(commandName: string) {
        return this.state.operationNames && this.state.operationNames.includes(commandName);
    }

    async moveToState(newState: MajorProjectStateEnum) {
        const data = {
            State: newState
        };

        const result = await apiFetchData(`project/${this.props.projectNumber}/state`, {
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST'
        });

        this.fetchProjectData();
    }

    // OBSOLETE
    //async assignMyself() {
    //    const result = await apiFetchData(`project/${this.props.projectNumber}/worker`, {
    //        method: 'PUT'
    //    }) as OperationResultDTO;

    //    this.fetchProjectData();
    //}

    //async unassignMyself() {
    //    const result = await apiFetchData(`project/${this.props.projectNumber}/worker`, {
    //        method: 'DELETE'
    //    }) as OperationResultDTO;

    //    this.fetchProjectData();
    //}

    async onAddWorker(id: number) {
        var result = await apiFetchData(`project/${this.props.projectNumber}/worker/${id}`, {
            method: 'PUT'
        }) as OperationResultDTO;

        this.fetchProjectData();
    }

    async onRemoveWorker(id: number) {
        var result = await apiFetchData(`project/${this.props.projectNumber}/worker/${id}`, {
            method: 'DELETE'
        }) as OperationResultDTO;

        this.fetchProjectData();
    }

    async onFMListStatusChanged() {
        const commandsResult = await apiFetchData(`project/${this.props.projectNumber}/commands`) as OperationResultWithDataDTO<ProjectCommandsDTO>;

        if (commandsResult.Success) {
            this.setState({
                operationNames: commandsResult.Data!.ProjectCommandNames
            });
        }
    }

    onWorkFlagsChanged() {
        this.fetchProjectData();
    }

    onUnloadingFlagsChanged(data: UpdateUnloadingFlagForm, result: OperationResultDTO) {
        if (result.Infos) {
            this.setState({
                alertMessage: {
                    text: result.Infos[0].Message,
                    className: "alert-info",
                    displayMs: 5000
                }
            })
        }

        this.fetchProjectData();
    }

    getCurrentEntryInProgress(worktimeEntries: WorktimeEntryDTO[]): WorktimeEntryDTO | undefined {
        return worktimeEntries.find(this.isUsersAndInProgress);
    }

    getCurrentOthersInProgress(worktimeEntries: WorktimeEntryDTO[]): WorktimeEntryDTO[] {
        return worktimeEntries.filter(this.isOthersInProgress);
    }

    getHistoryWorktimeEntries(worktimeEntries: WorktimeEntryDTO[]): WorktimeEntryDTO[] {
        return worktimeEntries.filter(this.isNotInProgress);
    }

    isNotInProgress(we: WorktimeEntryDTO): boolean {
        return we.End != undefined;
    }

    isUsersAndInProgress(we: WorktimeEntryDTO): boolean {

        const result = we.Employee.Id == this.state.emloyeeId
            && !we.End;

        return result;
    }

    isOthersInProgress(we: WorktimeEntryDTO): boolean {
        return we.Employee.Id != this.state.emloyeeId
            && !we.End;
    }

    async fetchProjectWorkHistory() {
        const result = await apiFetchData(`project/${this.props.projectNumber}/worktime?includeInProgress=true`) as OperationResultWithDataDTO<WorktimeEntryDTO[]>;

        CalculateDates(result.Data);

        var project = Object.assign({}, this.state.project);
        project.WorktimeEntries = result.Data || [];

        if (result.Success) {
            this.setState({
                project
            });
        } else {
            // TODO - Handle errors
        }
    }

    onWorktimeChanged(we: WorktimeEntryDTO): void {
        this.fetchProjectWorkHistory();
    }

    isProjectInWorkingPhase(): boolean {
        const project = this.state.project;
        return !!project
            && (project.MajorProjectState >= MajorProjectStateEnum.Production
                && project.MajorProjectState <= MajorProjectStateEnum.PrepareForSend);
    }

    showUnloadingProtocol(): boolean {
        const project = this.state.project;
        return !!project
            && project.MajorProjectState >= MajorProjectStateEnum.Planned;
    }

    initiallyCollapseUnloadingProtocol(): boolean {
        const project = this.state.project;
        return !!project
            && project.MajorProjectState != MajorProjectStateEnum.Unloaded;
    }

    initiallyCollapseProjectWorkFlags(): boolean {
        const project = this.state.project;

        if (!project) {
            return true;
        }

        return this.state.isMonter
            && project.MajorProjectState >= MajorProjectStateEnum.Fixes;
    }

    showTesterNotes(): boolean {
        const project = this.state.project;
        if (!project) {
            return false;
        }

        // Office should not see TesterNotes
        if (this.state.isOffice) {
            return false;
        }

        return project.MajorProjectState >= MajorProjectStateEnum.TestProduction;
    }

    canEditTesterNotes(): boolean {
        const project = this.state.project;
        if (!project) {
            return false;
        }

        return authService.hasUserRoleSync(KnownRolesEnum.Tester)
            && project.MajorProjectState >= MajorProjectStateEnum.Production
            && project.MajorProjectState <= MajorProjectStateEnum.Fixes;
    }

    canCheckTesterNotes(): boolean {
        const project = this.state.project;
        if (!project) {
            return false;
        }

        return authService.hasUserRoleSync(KnownRolesEnum.Monter)
            && (project.MajorProjectState == MajorProjectStateEnum.Production
            || project.MajorProjectState == MajorProjectStateEnum.Fixes);
    }

    onTesterNoteChecked(tn: TesterNoteDTO) {
        this.fetchProjectData();
    }

    isTesterNotesInitialyCollapsed(): boolean {
        const project = this.state.project;
        if (!project) {
            return true;
        }

        const isExpanded = project.MajorProjectState == MajorProjectStateEnum.TestProduction
            || project.MajorProjectState == MajorProjectStateEnum.Fixes;

        return !isExpanded;
    }

    async updateUrgency() {

        const project = this.state.project;
        if (!project) {
            return;
        }

        const data = {
            Flag: ProjectStateFlags.Urgent,
            FlagValue: !this.isUrgent()
        };

        const response = await apiFetchResponse(`project/${this.props.projectNumber}/project-state-flags`,
            {
                method: 'PATCH',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<ProjectStateFlags>;
            if (result.Success) {
                const project = Object.assign({}, this.state.project);
                project.ProjectStateFlags = result.Data!;

                this.setState({
                    project
                });
                return;
            }
        }
    }

    isUrgent(): boolean {

        const result = !!this.state.project
            && (this.state.project.ProjectStateFlags & ProjectStateFlags.Urgent) > 0;

        return result;
    }

    render() {
        const p = this.state.project;

        if (!p) {
            return null;
        }

        return <Fragment>
            <Navbar>
                <Nav tabs>
                    <NavbarBrand>
                        Projekt {this.props.projectNumber}
                        {this.isUrgent() && <i className="cone-icon" title="Pilny!"></i>}
                        {this.props.onClosePage && <button className="btn btn-sm btn-outline-secondary ms-2 align-top" onClick={this.props.onClosePage} title="Zamknij stronę projektu">Zamknij <i className="fa-solid fa-times" /></button>}
                    </NavbarBrand>
                    <NavItem>
                        <NavLink tag="button" active={this.state.activeTabIndex == 0} onClick={() => this.setActiveTab(0)}>Szczegóły</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag="button" active={this.state.activeTabIndex == 1} onClick={() => this.setActiveTab(1)}>Pelikany</NavLink>
                    </NavItem>
                    {this.state.canSeeWorktimeReport && <NavItem active={this.state.activeTabIndex == 1}>
                        <NavLink tag="button" active={this.state.activeTabIndex == 2} onClick={() => this.setActiveTab(2)}>Raport czasu pracy</NavLink>
                    </NavItem>}
                    {this.state.canSeeCableTimeReport && <NavItem>
                        <NavLink tag="button" active={this.state.activeTabIndex == 3} onClick={() => this.setActiveTab(3)}>Raport kablowania</NavLink>
                    </NavItem>}
                    <NavItem>
                        <NavLink tag="button" active={this.state.activeTabIndex == 4} onClick={() => this.setActiveTab(4)}>Historia czasu pracy</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag="button" active={this.state.activeTabIndex == 5} onClick={() => this.setActiveTab(5)}>Zgłoszenia QDE</NavLink>
                    </NavItem>
                    {this.state.canSeeLog && <NavItem>
                        <NavLink tag="button" active={this.state.activeTabIndex == 6} onClick={() => this.setActiveTab(6)}>Log</NavLink>
                    </NavItem>}
                </Nav>
                <div>
                    <button className="btn btn-sm btn-outline-secondary" id="refresh-project" onClick={this.fetchProjectData}>Odśwież <i className="fa-solid fa-redo" /></button>
                    <Tooltip
                        placement="left"
                        isOpen={this.state.needsRefresh}
                        autohide={false}
                        target="refresh-project">Projekt został zmodyfikowany. {this.state.lastCommandText}</Tooltip>
                </div>
            </Navbar>
            <TabContent activeTab={this.state.activeTabIndex}>
                <TabPane tabId={0}>
                    <AlertComponent alertMessage={this.state.alertMessage} />
                    <Row>
                        <Col md={4}>
                            <table className="table table-sm">
                                <tbody>
                                    <tr>
                                        <th>Projekt</th>
                                        <td>{p.ProjectNumber} {p.ClientName}</td>
                                    </tr>
                                    <tr>
                                        <th>Model</th>
                                        <td>{p.ModelDescription}</td>
                                    </tr>
                                    {((p.CabinetModelSubTypes && p.CabinetModelSubTypes.length > 0) || this.state.canManageCabinetSubtypes) && <tr>
                                        <th>Szafy</th>
                                        <td>
                                            <CabinetSubTypes
                                                subtypes={p.CabinetModelSubTypes}
                                                projectNumber={p.ProjectNumber}
                                                cabinetModelId={p.CabinetModelId}
                                                canManage={this.state.canManageCabinetSubtypes}
                                                onChange={this.fetchProjectData} />
                                        </td>
                                    </tr>}
                                    <tr>
                                        <th>Status</th>
                                        <td><StatusIndicator status={p.MajorProjectState} /></td>
                                    </tr>
                                    <tr>
                                        <th>Sztuk</th>
                                        <td>{p.CabinetCount}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <ProjectRemarks canEdit={this.state.canManageRemarks} />
                        </Col>
                        <Col md={4}>
                            <table className="table table-sm">
                                <tbody>
                                    {this.state.isExtendedView && <tr>
                                        <th>Daty</th>
                                        <td>{p.StartDateText} - {p.EndDateText}</td>
                                    </tr>}
                                    {this.state.showAssumedProjectTime && <tr>
                                        <th>Zakładany czas</th>
                                        <td>{p.ScheduledTimeHours}</td>
                                    </tr>}
                                    <tr>
                                        <th>Czas do rozliczeń</th>
                                        <td>
                                            <span title="wg QDE z Aquarisa">{getHoursDurationText(p.CostSettlementTimeHours)} {this.state.showAssumedProjectTime ? getHoursProgressPercent(p.ScheduledTimeHours, p.CostSettlementTimeHours) : null}</span>
                                            {p.CostSettlementTimeFromTickets != null && <>
                                                <br />
                                                <span className="text-secondary" title="wg QDE ze zgłoszeń">{getHoursDurationText(p.CostSettlementTimeFromTickets)} {this.state.showAssumedProjectTime ? getHoursProgressPercent(p.ScheduledTimeHours, p.CostSettlementTimeFromTickets) : null}</span>
                                            </>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Realny czas</th>
                                        <td>{getHoursDurationText(p.RealTimeSpentHours)}</td>
                                    </tr>
                                    <tr>
                                        <th>Dodatowy czas (QDE)</th>
                                        <td>{p.ExtraUnscheduledTimeHours}</td>
                                    </tr>
                                    <tr>
                                        <th>Transport</th>
                                        <td>
                                            <AssignTransport
                                                transports={this.props.availableTransports}
                                                project={p}
                                                canManage={this.canPerform("AssignTransport")}
                                                onChange={this.fetchProjectData} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col md={4}>
                            <div>
                                {this.canPerform("MoveToPlanned") && <button className="btn btn-outline-secondary" type="button" onClick={() => this.moveToState(MajorProjectStateEnum.Planned)}>Przenieś do planowanych</button>}
                                {this.canPerform("MoveToUnloaded") && <button className="btn btn-outline-secondary" type="button" onClick={() => this.moveToState(MajorProjectStateEnum.Unloaded)}>Rozładowany</button>}
                                {this.canPerform("MoveToProduction") && <button className="btn btn-outline-secondary" type="button" onClick={() => this.moveToState(MajorProjectStateEnum.Production)}>Rozpocznij produkcję</button>}
                                {this.canPerform("MoveToTesting") && <button className="btn btn-outline-secondary" type="button" onClick={() => this.moveToState(MajorProjectStateEnum.TestProduction)}>Zakończ produkcję (P1)</button>}
                                {this.canPerform("MoveToFixes") && <button className="btn btn-outline-secondary" type="button" onClick={() => this.moveToState(MajorProjectStateEnum.Fixes)}>Do poprawy</button>}
                                {this.canPerform("MoveToPrepareForSend") && <button className="btn btn-outline-secondary" type="button" onClick={() => this.moveToState(MajorProjectStateEnum.PrepareForSend)}>Przygotuj do wysłania</button>}
                                {this.canPerform("MoveToReadyToSend") && <button className="btn btn-outline-secondary" type="button" onClick={() => this.moveToState(MajorProjectStateEnum.ReadyToSend)}>Gotowe do wysłania</button>}
                                {this.canPerform("MoveToFinish") && <button className="btn btn-outline-secondary" type="button" onClick={() => this.moveToState(MajorProjectStateEnum.Finished)}>Wysłany (zakończony)</button>}
                                {this.canPerform("MoveToArchive") && <button className="btn btn-outline-secondary" type="button" onClick={() => this.moveToState(MajorProjectStateEnum.Archive)}>Zarchiwizuj</button>}
                                {this.canPerform("UpdateProjectStateFlag") && <button className="btn btn-outline-secondary" type="button" onClick={this.updateUrgency}>Projekt pilny <i className="cone-icon"></i> <i className={this.isUrgent() ? "fa-solid fa-toggle-on" : "fa-solid fa-toggle-off"}></i></button>}
                            </div>
                            <ProjectWorkers workers={p.AssignedWorkers} canManage={this.canPerform("AssignWorker")} availableWorkers={this.state.availableWorkers} onAddWorker={this.onAddWorker} onRemoveWorker={this.onRemoveWorker} />
                            <WorkTimeMeasure
                                currentEntry={this.getCurrentEntryInProgress(p.WorktimeEntries)}
                                currentOtherEntries={this.getCurrentOthersInProgress(p.WorktimeEntries)}
                                canStart={this.canPerform("StartProjectWork")}
                                projectNumber={p.ProjectNumber}
                                projectId={p.Id}
                                onChange={this.fetchProjectWorkHistory}
                                availableWorkTypes={this.state.availableWorkTypes}
                                testerNotes={this.notDoneTesterNotes()}
                                cabinetModelSubTypes={p.CabinetModelSubTypes}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4} className="d-grid gap-2">
                            {this.showUnloadingProtocol() &&
                                <>
                                <UnloadingProtocol
                                    projectNumber={p.ProjectNumber}
                                    majorProjectState={p.MajorProjectState}
                                    yesFlags={p.UnloadingYesFlags}
                                    missingFlags={p.UnloadingMissingFlags}
                                    inapplicableFlags={p.UnloadingInapplicableFlags}
                                    onFlagsChanged={this.onUnloadingFlagsChanged}
                                    initiallyCollapsed={this.initiallyCollapseUnloadingProtocol()} />
                                <FMList
                                    initiallyCollapsed={true}
                                    onFMListStatusChanged={this.onFMListStatusChanged}
                                    canManage={this.state.canManageFMList}
                                />
                                </>}
                            {this.isProjectInWorkingPhase() &&
                                <ProjectWorkFlags
                                    projectNumber={p.ProjectNumber}
                                    majorProjectState={p.MajorProjectState}
                                    workFinishedFlags={p.WorkFinishedFlags}
                                    workInapplicableFlags={p.WorkInapplicableFlags}
                                    onFlagsChanged={this.onWorkFlagsChanged}
                                    fmPelikanValueFlag={p.FMPelikanFlag}
                                    initiallyCollapsed={this.initiallyCollapseProjectWorkFlags()} />
                            }
                        </Col>
                        <Col md={8}>
                            {this.showTesterNotes() &&
                                <TesterNotes
                                    canEdit={this.canEditTesterNotes()}
                                    canCheck={this.canCheckTesterNotes()}
                                    onChecked={this.onTesterNoteChecked}
                                    initiallyCollapsed={this.isTesterNotesInitialyCollapsed()} />
                            }
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={1}>
                    <ProjectOrDepartmentPelikans
                        projectNumber={this.props.projectNumber}
                        project={this.state.project}
                        isVisible={this.state.activeTabIndex == 1}
                    />
                </TabPane>
                {this.state.canSeeWorktimeReport && <TabPane tabId={2}>
                    <WorktimeReportView isVisible={this.state.activeTabIndex == 2} projectNumber={this.props.projectNumber} />
                </TabPane>}
                {this.state.canSeeCableTimeReport && <TabPane tabId={3}>
                    <CableTimeReport
                        isVisible={this.state.activeTabIndex == 3}
                        projectNumber={this.props.projectNumber}
                        cabinetCount={this.state.project?.CabinetCount || 1}
                        canEdit={this.state.canEditCableTimeReport}
                    />
                </TabPane>}
                <TabPane tabId={4}>
                    <Row>
                        <Col md="auto">
                            <ProjectWorkHistory
                                employeeId={this.state.emloyeeId}
                                worktimeEntries={this.getHistoryWorktimeEntries(p.WorktimeEntries)}
                                projectNumber={this.props.projectNumber}
                                canEditTodays={this.state.canEditTodayWorktimes}
                                canEditAll={this.state.canEditAllWorktimes}
                                onWorktimeChanged={this.onWorktimeChanged}
                                testerNotes={this.state.testerNotes}
                                cabinetModelSubTypes={p.CabinetModelSubTypes}
                                noHeader={true}
                            />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId={5}>
                    <ProjectQDETickets isVisible={this.state.activeTabIndex==5} />
                </TabPane>
                <TabPane tabId={6}>
                    <ProjectLogView isVisible={this.state.activeTabIndex==6} />
                </TabPane>
            </TabContent>
        </Fragment>;
    }
}

export default function ProjectPage() {
    const params = useParams();

    const projectNumber = parseInt(params['projectNumber'] || '0');

    return <ProjectNumberContext.Provider value={projectNumber}>
        <ProjectPageComponent projectNumber={projectNumber} />
    </ProjectNumberContext.Provider>;
};
