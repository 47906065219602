import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import store from './store/app-store';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const root = createRoot(rootElement!);

root.render(
    <BrowserRouter basename={baseUrl || undefined}>
        <Provider store={store}>
            <App />
        </Provider>
  </BrowserRouter>);

registerServiceWorker();

