import React, { Component } from 'react';
import { WorkFlags, WorkFlagValue } from '../types/domain';
import { getWorkflagText, getWorkflagValueText } from '../utilities/domain-utils';
import { StateFlagSelect, ValueData } from './common/StateFlagSelect';

type ProjectWorkFlagRowProps = {
    workFlag: WorkFlags
    flagValue: WorkFlagValue,
    doSetValue: (workFlag: WorkFlags, value: WorkFlagValue) => void,
    canSet?: boolean,
    canReset?: boolean
};

type ProjectWorkFlagRowState = {
    showConfirmation: boolean,
    valueToSet: WorkFlagValue
};

export class ProjectWorkFlagRow extends Component<ProjectWorkFlagRowProps, ProjectWorkFlagRowState> {
    static displayName = ProjectWorkFlagRow.name;

    constructor(props: ProjectWorkFlagRowProps) {
        super(props);

        this.state = {
            showConfirmation: false,
            valueToSet: WorkFlagValue.False
        };

        this.onConfirmed = this.onConfirmed.bind(this);
        this.getAvailableValues = this.getAvailableValues.bind(this);
        this.canSetValue = this.canSetValue.bind(this);
    }

    onConfirmed(value: WorkFlagValue) {
        this.props.doSetValue(this.props.workFlag, value);
    }

    canSetValue(value: WorkFlagValue) {
        if (value == this.props.flagValue) {
            return false;
        }

        if (this.props.workFlag == WorkFlags.FMPelikan) {
            switch (value) {
                case WorkFlagValue.Empty:
                    return this.props.canReset === true;
                case WorkFlagValue.LackingReported:
                case WorkFlagValue.MaterialComplete:
                    return this.props.canSet === true;
                case WorkFlagValue.ProjectCanLeave:
                case WorkFlagValue.ProjectCanNotLeave:
                    return this.props.canSet === true
                        && this.props.flagValue != WorkFlagValue.Empty;
            }
        }

        switch (value) {
            case WorkFlagValue.False:
                return this.props.canReset === true;
            case WorkFlagValue.True:
            case WorkFlagValue.Inapplicable:
                return this.props.canSet === true
                    && this.props.flagValue == WorkFlagValue.False;
        }

        return false;
    }

    getAvailableValues(): WorkFlagValue[] {
        const allValues: WorkFlagValue[] = (this.props.workFlag == WorkFlags.FMPelikan)
            ? [
                WorkFlagValue.Empty,
                WorkFlagValue.MaterialComplete,
                WorkFlagValue.LackingReported,
                WorkFlagValue.ProjectCanLeave,
                WorkFlagValue.ProjectCanNotLeave
            ]
            : [
                WorkFlagValue.Inapplicable,
                WorkFlagValue.False,
                WorkFlagValue.True
            ];

        return allValues.filter(this.canSetValue);
    }

    valueDescriptors: ValueData[] = [
        {
            value: WorkFlagValue.False,
            buttonClass: "btn btn-outline-secondary",
            iconClass: "fa-regular fa-circle",
            buttonText: "",
            buttonTitle: getWorkflagValueText(WorkFlagValue.False),
            description: getWorkflagValueText(WorkFlagValue.False)
        },
        {
            value: WorkFlagValue.True,
            buttonClass: "btn btn-outline-primary",
            iconClass: "fa-solid fa-check-circle",
            buttonText: "",
            buttonTitle: getWorkflagValueText(WorkFlagValue.True),
            description: getWorkflagValueText(WorkFlagValue.True)
        },
        {
            value: WorkFlagValue.Inapplicable,
            buttonClass: "btn btn-outline-info",
            iconClass: "",
            buttonText: "N/A",
            buttonTitle: getWorkflagValueText(WorkFlagValue.Inapplicable),
            description: getWorkflagValueText(WorkFlagValue.Inapplicable)
        }];

    valueDescriptorsForFMPelikan: ValueData[] = [
        {
            value: WorkFlagValue.Empty,
            buttonClass: "btn btn-outline-secondary",
            iconClass: "fa-regular fa-circle",
            buttonText: "",
            buttonTitle: getWorkflagValueText(WorkFlagValue.Empty, WorkFlags.FMPelikan),
            description: getWorkflagValueText(WorkFlagValue.Empty, WorkFlags.FMPelikan)
        },
        {
            value: WorkFlagValue.ProjectCanLeave,
            buttonClass: "btn btn-outline-primary",
            iconClass: "fa-solid fa-truck",
            buttonText: "",
            buttonTitle: getWorkflagValueText(WorkFlagValue.ProjectCanLeave, WorkFlags.FMPelikan),
            description: getWorkflagValueText(WorkFlagValue.ProjectCanLeave, WorkFlags.FMPelikan)
        },
        {
            value: WorkFlagValue.MaterialComplete,
            buttonClass: "btn btn-outline-primary",
            iconClass: "fa-solid fa-check-circle",
            buttonText: "",
            buttonTitle: getWorkflagValueText(WorkFlagValue.MaterialComplete, WorkFlags.FMPelikan),
            description: getWorkflagValueText(WorkFlagValue.MaterialComplete, WorkFlags.FMPelikan)
        },
        {
            value: WorkFlagValue.LackingReported,
            buttonClass: "btn btn-outline-info",
            iconClass: "fa-solid fa-share-square",
            buttonText: "",
            buttonTitle: getWorkflagValueText(WorkFlagValue.LackingReported, WorkFlags.FMPelikan),
            description: getWorkflagValueText(WorkFlagValue.LackingReported, WorkFlags.FMPelikan)
        },
        {
            value: WorkFlagValue.ProjectCanNotLeave,
            buttonClass: "btn btn-outline-danger",
            iconClass: "fa-solid fa-ban",
            buttonText: "",
            buttonTitle: getWorkflagValueText(WorkFlagValue.ProjectCanNotLeave, WorkFlags.FMPelikan),
            description: getWorkflagValueText(WorkFlagValue.ProjectCanNotLeave, WorkFlags.FMPelikan)
        }];

    getValueDescriptors(): ValueData[] {
        return this.props.workFlag == WorkFlags.FMPelikan
            ? this.valueDescriptorsForFMPelikan
            : this.valueDescriptors;
    }

    render() {
        const flagValue = this.props.flagValue;
        const availableValues = this.getAvailableValues();
        const canChangeValue = availableValues.length > 0;

        return (
            <tr>
                <td>{getWorkflagText(this.props.workFlag)}</td>
                <td>
                    <StateFlagSelect
                        value={flagValue}
                        disabled={!canChangeValue}
                        availableValues={availableValues}
                        onSelected={this.onConfirmed}
                        valueDescriptions={this.getValueDescriptors()}
                        />
                </td>
            </tr>
        );
    }
}
