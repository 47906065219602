import React, { Component } from 'react';
import { QDETicketStatus } from '../types/domain';
import { OperationResultWithDataDTO, QDETicketDTO } from '../types/dto';
import { apiFetchResponse } from '../utilities/auth-api';
import { CalculateDates, EnhanceQDETicket } from '../utilities/data-enhancer';
import { QDETicketStatusName } from '../utilities/domain-utils';

type QDETicketStatusIndicatorProps = {
    ticketId: number,
    value: QDETicketStatus,
    canChange: boolean,
    onChange?: (ticket: QDETicketDTO) => void
};

type QDETicketStatusIndicatorState = {
};

export class QDETicketStatusIndicator extends Component<QDETicketStatusIndicatorProps, QDETicketStatusIndicatorState> {
    static displayName = QDETicketStatusIndicator.name;

    constructor(props: QDETicketStatusIndicatorProps) {
        super(props);

        this.state = {
        };

        this.buttonClass = this.buttonClass.bind(this);
        this.onSelectStatus = this.onSelectStatus.bind(this);
        this.renderOption = this.renderOption.bind(this);
        this.availableOptions = this.availableOptions.bind(this);
    }

    buttonClass(): string {

        let baseClass: string = "";

        switch (this.props.value) {
            case QDETicketStatus.New:
                baseClass = "btn-info";
                break;
            case QDETicketStatus.Reported:
                baseClass = "btn-primary";
                break;
            case QDETicketStatus.Accepted:
                baseClass = "btn-success";
                break;
            case QDETicketStatus.Rejected:
                baseClass = "btn-danger";
                break;
            case QDETicketStatus.Removed:
                baseClass = "btn-secondary";
                break;
        }

        return this.props.canChange && this.availableOptions().length > 0
            ? `btn btn-sm ${baseClass} dropdown-toggle`
            : `btn btn-sm ${baseClass}`;
    }

    availableOptions(): QDETicketStatus[] {
        switch (this.props.value) {
            case QDETicketStatus.New:
                return [
                    QDETicketStatus.Reported
                ];
            case QDETicketStatus.Reported:
                return [
                    QDETicketStatus.Accepted,
                    QDETicketStatus.Rejected
                ];
            default:
                return [];
        }
    }

    renderOption(status: QDETicketStatus) {
        return <li key={status}>
            <button className="dropdown-item" type="button" onClick={() => this.onSelectStatus(status)}>{QDETicketStatusName(status)}</button>
        </li>
    }

    async onSelectStatus(status: QDETicketStatus) {

        const data = {
            status
        };

        const response = await apiFetchResponse(`qde-ticket/${this.props.ticketId}/status`, {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<QDETicketDTO>;
            if (result.Success) {

                const ticket = result.Data!;

                CalculateDates(ticket);
                EnhanceQDETicket(ticket);

                if (this.props.onChange) {
                    this.props.onChange(ticket);
                }
            }
        }
    }

    render() {

        const availableOptions = this.availableOptions();

        if (!this.props.canChange || availableOptions.length == 0) {
            return <button className={this.buttonClass()} type="button">{QDETicketStatusName(this.props.value)}</button>
        }

        return (
            <div className="dropdown">
                <button className={this.buttonClass()} type="button" data-bs-toggle="dropdown" aria-expanded="false">{QDETicketStatusName(this.props.value)}</button>
                <ul className="dropdown-menu">
                    {this.availableOptions().map(this.renderOption)}
                </ul>
            </div>
        );
    }
}
