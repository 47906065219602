import React from "react";
import { FMListEntryStatus } from "../types/domain";
import { FMListEntryDTO, FMListLogEntryDTO, OperationResultWithDataDTO } from "../types/dto";
import { apiFetchResponse } from "../utilities/auth-api";
import { CalculateDates } from "../utilities/data-enhancer";
import { DateToDateTimeString } from "../utilities/date-formatter";
import { FMListEntryStatusText } from "../utilities/domain-utils";
import { StateFlagSelect, ValueData } from "./common/StateFlagSelect";

type FMListEntryRowProps = {
    entry: FMListEntryDTO,
    onEntryChanged: (entry: FMListEntryDTO) => void,
    canChangeValue: boolean
};

export function FMListEntryRow(props: FMListEntryRowProps) {

    const renderLogEntry = function (logEntry: FMListLogEntryDTO, index: number) {
        return <p key={index} className="mb-0"><small>{DateToDateTimeString(logEntry.Timestamp!)}, {logEntry.Doer?.FullName}: {FMListEntryStatusText(logEntry.Status)}</small></p>
    }

    const entry = props.entry;

    const getAvailableValues = function (status: FMListEntryStatus) {
        const allValues: FMListEntryStatus[] = [
            FMListEntryStatus.NotSet,
            FMListEntryStatus.Present,
            FMListEntryStatus.Missing
        ];

        return allValues.filter(v => v != status);
    }

    const availableDescriptions: ValueData[] = [
        {
            value: FMListEntryStatus.NotSet,
            buttonClass: "btn btn-outline-secondary",
            iconClass: "fa-regular fa-circle",
            buttonText: "",
            buttonTitle: FMListEntryStatusText(FMListEntryStatus.NotSet),
            description: FMListEntryStatusText(FMListEntryStatus.NotSet)
        },
        {
            value: FMListEntryStatus.Present,
            buttonClass: "btn btn-outline-primary",
            iconClass: "fa-solid fa-check-circle",
            buttonText: "",
            buttonTitle: FMListEntryStatusText(FMListEntryStatus.Present),
            description: FMListEntryStatusText(FMListEntryStatus.Present)
        },
        {
            value: FMListEntryStatus.Missing,
            buttonClass: "btn btn-outline-danger",
            iconClass: "fa-solid fa-ban",
            buttonText: "",
            buttonTitle: FMListEntryStatusText(FMListEntryStatus.Missing),
            description: FMListEntryStatusText(FMListEntryStatus.Missing)
        }
    ];

    const onValueSelected = async function (value: any) {
        const status = value as FMListEntryStatus;

        const response = await apiFetchResponse(`fm-list/entry/${entry.Id}/status/${status}`, {
            method: 'PATCH'
        });

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<FMListEntryDTO>;
            if (result.Success) {
                var changedEntry = result.Data!;
                CalculateDates(changedEntry);
                props.onEntryChanged(changedEntry);
            } else {
                // TODO - error
            }
        } else {
            // TODO - error
        }
    };

    return <tr>
        <td>
            <p className="mb-0">{entry.MaterialNumber} Szt: {entry.Quantity} RKZ: {entry.RKZ}</p>
            <p className="mb-0"><small>{entry.MaterialDescription}</small></p>
            {entry.LogEntries && entry.LogEntries.length && entry.LogEntries.map(renderLogEntry)}
        </td>
        <td>
            <StateFlagSelect
                value={entry.Status}
                disabled={!props.canChangeValue}
                availableValues={getAvailableValues(entry.Status)}
                valueDescriptions={availableDescriptions}
                onSelected={onValueSelected} />
        </td>
    </tr>;
}
