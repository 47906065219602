import React, { Component } from 'react';
import { OperationResultWithDataDTO, QDETicketDTO } from '../types/dto';
import { apiFetchResponse } from '../utilities/auth-api';
import { CalculateDates, EnhanceQDETickets } from '../utilities/data-enhancer';
import { QDETicketsListEditable } from './QDETicketsListEditable';

type QDETicketsViewProps = {
};

type QDETicketsViewState = {
    tickets: QDETicketDTO[],
    isLoading: boolean
};

export class QDETicketsView extends Component<QDETicketsViewProps, QDETicketsViewState> {
    static displayName = QDETicketsView.name;

    constructor(props: QDETicketsViewProps) {
        super(props);

        this.state = {
            tickets: [],
            isLoading: false
        };

        this.fetchTickets = this.fetchTickets.bind(this);
        this.onTicketChanged = this.onTicketChanged.bind(this);
    }

    componentDidMount() {
        this.fetchTickets();
    }

    async fetchTickets() {

        this.setState({
            isLoading: true
        });

        const response = await apiFetchResponse('qde-ticket');

        this.setState({
            isLoading: false
        });

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<QDETicketDTO[]>;
            if (result.Success) {

                const tickets = result.Data || [];

                CalculateDates(tickets);
                EnhanceQDETickets(tickets);

                this.setState({
                    tickets
                });
            }
        }
    }

    onTicketChanged(ticket: QDETicketDTO) {
        // NOTE - don't fetch tickets because that can cause ticket do disapear
        const { tickets } = this.state;
        if (!tickets) {
            return;
        }

        const newTickets = [...tickets];
        const itemIndex = newTickets.findIndex(t => t.Id == ticket.Id);

        newTickets[itemIndex] = ticket;
        this.setState({
            tickets: newTickets
        });
    }

    render() {
        return (
            <>
                <div className='row'>
                    <div className='col-auto'>
                        <button className='btn btn-outline-secondary h-100' type='button' title='Odśwież' disabled={this.state.isLoading} onClick={this.fetchTickets}>
                            <span className={this.state.isLoading ? 'spinner-border' : 'fa-solid fa-redo'}></span>
                        </button>
                    </div>
                </div>
                <QDETicketsListEditable
                    tickets={this.state.tickets}
                    canEditStatus={true}
                    onTicketChanged={this.onTicketChanged} />
            </>
        );
    }
}
