import React, { Component } from 'react';
import { CabinetModelSubtypeBriefDTO, TesterNoteDTO, WorktimeEntriesPerDay, WorktimeEntryDTO } from '../types/dto';
import { DateToDateString } from '../utilities/date-formatter';
import { WorktimeTableRow } from './WorktimeTableRow';
import { IsSameDay, GetDayDate, GetPreviousDay, ElapsedTimeInMilisecsText } from '../utilities/date-utils';
import { WorktimeForm } from './WorktimeForm';
import { allWorkTypes, CreateNewWorktimeEntry } from '../utilities/domain-utils';

type ProjectWorkHistoryProps = {
    worktimeEntries?: WorktimeEntryDTO[],
    canEditTodays: boolean,
    employeeId?: number
    canEditAll: boolean,
    projectNumber: number,
    onWorktimeChanged?: (we: WorktimeEntryDTO) => void,
    testerNotes: TesterNoteDTO[],
    cabinetModelSubTypes?: CabinetModelSubtypeBriefDTO[],
    noHeader?: boolean
};

type ProjectWorkHistoryState = {
    showHistory: boolean,
    showForm: boolean,
    newWorktimeEntry?: WorktimeEntryDTO
};

export class ProjectWorkHistory extends Component<ProjectWorkHistoryProps, ProjectWorkHistoryState> {
    static displayName = ProjectWorkHistory.name;

    constructor(props: ProjectWorkHistoryProps) {
        super(props);

        this.state = {
            showHistory: props.noHeader || false,
            showForm: false,
        };

        this.toggleHistory = this.toggleHistory.bind(this);
        this.onWorktimeChanged = this.onWorktimeChanged.bind(this);
        this.canEdit = this.canEdit.bind(this);
        this.startCreateNew = this.startCreateNew.bind(this);
        this.onNewWorktimeAdded = this.onNewWorktimeAdded.bind(this);
        this.getWorktimeEntriesPerDay = this.getWorktimeEntriesPerDay.bind(this);
    }

    canEdit(we: WorktimeEntryDTO) {

        if (this.props.canEditAll) {
            return this.props.canEditAll;
        }

        return this.props.canEditTodays
            && we.Employee.Id == this.props.employeeId
            && IsSameDay(we.Start!, new Date());
    }

    onWorktimeChanged(we: WorktimeEntryDTO) {
        if (this.props.onWorktimeChanged) {
            this.props.onWorktimeChanged(we);
        }
    }

    // NOTE - This date is used for rendering headers
    prevDayDate: Date | undefined;

    toggleHistory() {
        this.setState({
            showHistory: !this.state.showHistory
        });
    }

    renderEntryHeaderRow(dayDate: Date) {
        let headerString = null;
        const dateString = DateToDateString(dayDate);

        const today = GetDayDate(new Date());
        const yesterday = GetPreviousDay(today);

        if (IsSameDay(dayDate, today)) {
            headerString = 'Dziś';
        } else if (IsSameDay(dayDate, yesterday)) {
            headerString = "Wczoraj";
        } else {
            headerString = dateString;
        }

        return <tr key={"D" + dateString}>
            <td colSpan={3}><small><strong>{headerString}</strong></small></td>
        </tr>
    }

    startCreateNew() {

        const newWorktimeEntry = CreateNewWorktimeEntry();

        this.setState({
            showForm: true,
            newWorktimeEntry
        });
    }

    onNewWorktimeAdded(result: WorktimeEntryDTO) {
        this.setState({
            showForm: false,
            newWorktimeEntry: undefined
        });

        if (this.props.onWorktimeChanged) {
            this.props.onWorktimeChanged(result);
        }
    }

    getWorktimeEntriesPerDay(): WorktimeEntriesPerDay[] {
        const result: WorktimeEntriesPerDay[] = [];

        if (this.props.worktimeEntries) {
            this.props.worktimeEntries.forEach(we => {
                const prevDate = result.length > 0
                    ? result[result.length - 1].date
                    : undefined;

                const dayDate = GetDayDate(we.Start!);
                const durationMilisecs = we.DurationMilisecs;
                if (!prevDate || !IsSameDay(prevDate, dayDate)) {
                    result.push({
                        date: dayDate,
                        worktimeEntries: [we],
                        totalMilisecs: durationMilisecs
                    });
                } else {
                    const entriesPerDay = result[result.length - 1];
                    entriesPerDay.worktimeEntries.push(we);
                    entriesPerDay.totalMilisecs += durationMilisecs
                }
            });
        }

        return result;
    }

    render() {
        return (
            <>
                {this.props.canEditAll && !this.state.showForm &&
                    <button className="btn btn-sm btn-outline-primary ms-2" onClick={this.startCreateNew}>Dodaj wpis</button>
                }
                {this.state.showForm &&
                    <WorktimeForm
                    worktime={this.state.newWorktimeEntry!}
                    onSave={this.onNewWorktimeAdded}
                    projectNumber={this.props.projectNumber}
                    workTypes={allWorkTypes}
                    testerNotes={this.props.testerNotes}
                    allowSetDate={true} allowSetEmployee={true}
                    />
                }
                {!this.props.noHeader && <div><strong>Historia czasu pracy</strong>&nbsp;<button className="btn btn-sm btn-outline-primary" type="button" title="Widok historii" onClick={this.toggleHistory}><i className={this.state.showHistory ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"} /></button></div>}
                {this.state.showHistory && <table className="table table-sm">
                    
                    {this.getWorktimeEntriesPerDay().map((wepd, wepd_idx) =>
                        <tbody className={wepd_idx > 0 ? 'table-group-divider': ''} key={wepd_idx}>
                            {this.renderEntryHeaderRow(wepd.date)}
                            {wepd.worktimeEntries.map((we) =>
                                <WorktimeTableRow key={we.Id}
                                    worktimeEntry={we}
                                    canEdit={this.canEdit(we)}
                                    onWorktimeChanged={this.onWorktimeChanged}
                                    testerNotes={this.props.testerNotes}
                                    cabinetModelSubTypes={this.props.cabinetModelSubTypes}
                                    allowSetDateAndEmployee={this.props.canEditAll}
                                />
                            )}
                            <tr>
                                <td><small><strong>Suma</strong></small></td>
                                <td><small><strong>{ElapsedTimeInMilisecsText(wepd.totalMilisecs)}</strong></small></td>
                                <td></td>
                            </tr>
                        </tbody>
                        )}
                </table>}
            </>
        );
    }
}
