
export type FormInputSize = "sm" | "lg";

export function formSelectClass(size?: FormInputSize) {
    return size
        ? `form-select form-select-${size}`
        : "form-select";
}

export function formControlClass(size?: FormInputSize) {
    return size
        ? `form-control form-control-${size}`
        : "form-control";
}

export function detailsAreaClass(size?: FormInputSize) {
    return "wt-details mt-2 " + formControlClass(size);
}
