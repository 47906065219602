import React, { ChangeEvent, Component, Fragment } from 'react';
import { DateToDateString } from '../utilities/date-formatter';
import { EndOfDay, StartOfDay, EndOfMonth } from '../utilities/date-utils';

export enum DateRangeType {
    None,
    SingleDay,
    SingleMonth,
    DateRange
}

type MonthDescription = {
    id: number,
    startOfMonth: Date,
    endOfMonth: Date,
    description: string
};

type ReportDateRangePickerProps = {
    onDateChange: (dateFrom?: Date, dateTo?: Date) => void;
};

type ReportDateRangePickerState = {
    SelectedRangeType: DateRangeType,
    dateFrom: Date,
    dateTo: Date,
    selectedMonthId: number
};

export class ReportDateRangePicker extends Component<ReportDateRangePickerProps, ReportDateRangePickerState> {
    static displayName = ReportDateRangePicker.name;

    months: MonthDescription[] = [];
    rangeTypesToSelect: DateRangeType[] = [
        DateRangeType.SingleDay,
        DateRangeType.SingleMonth,
        DateRangeType.DateRange
    ];

    constructor(props: ReportDateRangePickerProps) {
        super(props);

        const today = new Date();
        const dateFrom = StartOfDay(today);
        const dateTo = EndOfDay(today);

        this.state = {
            SelectedRangeType: DateRangeType.None,
            dateFrom,
            dateTo,
            selectedMonthId: 0
        };

        this.selectRangeType = this.selectRangeType.bind(this);
        this.selectedRangeTypeText = this.selectedRangeTypeText.bind(this);
        this.clearDates = this.clearDates.bind(this);
        this.rangeTypeListEntry = this.rangeTypeListEntry.bind(this);
        this.rangeTypeText = this.rangeTypeText.bind(this);
        this.selectedRangeTypeText = this.selectedRangeTypeText.bind(this);
        this.onDateFromChange = this.onDateFromChange.bind(this);
        this.onDateToChange = this.onDateToChange.bind(this);
        this.dateFromText = this.dateFromText.bind(this);
        this.dateToText = this.dateToText.bind(this);
        this.onMonthSelected = this.onMonthSelected.bind(this);

        this.generateMonths();

        props.onDateChange(undefined, undefined);
    }

    generateMonths() {
        let dayOfMonth = new Date();
        let monthId = 0;

        while (monthId < 12) {

            const startOfMonth = new Date(dayOfMonth.getFullYear(), dayOfMonth.getMonth(), 1);
            this.months.push({
                id: monthId,
                startOfMonth,
                endOfMonth: EndOfMonth(startOfMonth),
                description: startOfMonth.toLocaleString('default', {
                    year: 'numeric',
                    month: 'long'
                })
            });
            monthId++;
            dayOfMonth = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth(), startOfMonth.getDate() - 1);
        }
    };

    selectRangeType(rangeType: DateRangeType) {
        if (rangeType == DateRangeType.SingleMonth) {

            const preDateFrom = this.state.dateFrom;
            let selectedMonthId = this.months
                .findIndex(value =>
                    preDateFrom >= value.startOfMonth
                    && preDateFrom <= value.endOfMonth);
            if (selectedMonthId < 0) selectedMonthId = 0;

            this.monthSelectedProcedure(this.months[selectedMonthId]);
        } else if (rangeType == DateRangeType.SingleDay) {
            const dateTo = EndOfDay(this.state.dateFrom);

            this.setState({
                SelectedRangeType: rangeType,
                dateTo: EndOfDay(this.state.dateFrom)
            });

            this.props.onDateChange(this.state.dateFrom, dateTo);
        } else if (rangeType == DateRangeType.DateRange) {
            this.setState({
                SelectedRangeType: rangeType
            });
        }
    }

    onDateFromChange(event: ChangeEvent<HTMLInputElement>) {
        // TODO - dateFrom can be bigger that dateTo
        const date = new Date(event.target.value);
        const dateFrom = StartOfDay(date);
        let dateTo = this.state.dateTo;
        let rangeType = this.state.SelectedRangeType;

        if (rangeType == DateRangeType.None
            || rangeType == DateRangeType.SingleDay) {
            dateTo = EndOfDay(dateFrom);
            rangeType = DateRangeType.SingleDay;
        }

        this.setState({
            dateFrom,
            dateTo,
            SelectedRangeType: rangeType
        });

        this.props.onDateChange(dateFrom, dateTo);
    }

    onDateToChange(event: ChangeEvent<HTMLInputElement>) {
        const date = new Date(event.target.value);
        const dateTo = EndOfDay(date);

        this.setState({
            dateTo
        });

        this.props.onDateChange(this.state.dateFrom, dateTo);
    }

    monthOption(month: MonthDescription) {
        return <option key={month.id} value={month.id}>{month.description}</option>;
    }

    rangeTypeText(rangeType: DateRangeType) {
        switch (rangeType) {
            case DateRangeType.SingleDay:
            default:
                return "Dzień";
            case DateRangeType.SingleMonth:
                return "Miesiąc";
            case DateRangeType.DateRange:
                return "Od - Do";
        }
    }

    selectedRangeTypeText(): string {
        return this.rangeTypeText(this.state.SelectedRangeType);
    }

    clearDates() {
        const today = new Date();
        const dateFrom = StartOfDay(today);
        const dateTo = EndOfDay(today);

        this.setState({
            SelectedRangeType: DateRangeType.None,
            dateFrom,
            dateTo
        });

        this.props.onDateChange(undefined, undefined);
    }

    rangeTypeListEntry(rangeType: DateRangeType) {
        return <li key={rangeType}>
            <button type="button" className="btn btn-outline-secondary dropdown-item" onClick={() => this.selectRangeType(rangeType)}>{this.rangeTypeText(rangeType)}</button>
        </li>;
    }

    dateFromText(): string {
        return this.state.SelectedRangeType == DateRangeType.None
            ? ''
            : DateToDateString(this.state.dateFrom);
    }

    dateToText(): string {
        return this.state.SelectedRangeType == DateRangeType.None
            ? ''
            : DateToDateString(this.state.dateTo);
    }

    onMonthSelected(event: ChangeEvent<HTMLSelectElement>) {
        const selectedMonthId = parseInt(event.target.value);
        const selectedMonth = this.months.find(value => value.id == selectedMonthId);

        if (selectedMonth) {
            this.monthSelectedProcedure(selectedMonth);
        }
    }

    monthSelectedProcedure(selectedMonth: MonthDescription) {
        const dateFrom = selectedMonth.startOfMonth;
        const dateTo = selectedMonth.endOfMonth;
        this.setState({
            SelectedRangeType: DateRangeType.SingleMonth,
            selectedMonthId: selectedMonth.id,
            dateFrom,
            dateTo
        });

        this.props.onDateChange(dateFrom, dateTo);
    }

    render() {
        return (
            <div className="input-group">
                <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{this.selectedRangeTypeText()}</button>
                <ul className="dropdown-menu">
                    {
                        this.rangeTypesToSelect.map(this.rangeTypeListEntry)
                    }
                </ul>
                {(this.state.SelectedRangeType == DateRangeType.SingleDay || this.state.SelectedRangeType == DateRangeType.None) &&
                    <div className='form-floating'>
                        <input type="date" id="single-date" className="form-control form-control-sm" value={this.dateFromText()} onChange={this.onDateFromChange} />
                        <label htmlFor="single-date">Dzień</label>
                    </div>
                }
                {this.state.SelectedRangeType == DateRangeType.SingleMonth &&
                    <div className='form-floating'>
                        <select className="form-control" id="single-month" value={this.state.selectedMonthId} onChange={this.onMonthSelected}>
                            {this.months.map(this.monthOption)}
                        </select>
                        <label htmlFor="single-month">Miesiąc</label>
                    </div>
                }
                {this.state.SelectedRangeType == DateRangeType.DateRange &&
                    <Fragment>
                        <div className='form-floating'>
                            <input className="form-control" type="date" id="date-from" value={this.dateFromText()} onChange={this.onDateFromChange} />
                            <label htmlFor="date-from">Od</label>
                        </div>
                        <div className='form-floating'>
                            <input className="form-control" type="date" id="date-to" value={this.dateToText()} onChange={this.onDateToChange} />
                            <label htmlFor="date-to">Do</label>
                        </div>
                    </Fragment>}
                <button className="btn btn-outline-secondary" type="button" onClick={this.clearDates}><i className="fa-solid fa-times"></i></button>
            </div>
        );
    }
}
