import { QDETicketTypeEnum } from "../../types/domain";
import { QDETicketTypeName } from "../../utilities/domain-utils";
import { ClearableDropdown } from "./ClearableDropdown";

type QDETicketTypeFilterProps = {
    value?: QDETicketTypeEnum,
    onChange: (value?: QDETicketTypeEnum) => void,
};

export function QDETicketTypeFilter(props: QDETicketTypeFilterProps) {

    const values = [
        QDETicketTypeEnum.FromPelikan,
        QDETicketTypeEnum.FromWorktime
    ];

    return <ClearableDropdown
        value={props.value}
        onChange={(value: QDETicketTypeEnum | undefined) => props.onChange(value)}
        valueText={QDETicketTypeName}
        values={values}
    />
}
