// import moment from 'moment';

export const FileDateFormat = 'DD_MM_YYYY';
export const FormDateFormat = 'yyyy-MM-DD';
const DateFormat = 'DD/MM/YYYY';
const TimeFormat = 'HH:mm';
const ExcelDateTimeFormat = DateFormat + ' ' + TimeFormat;
const DateTimeFormat = DateFormat + ' ' + TimeFormat;

const twoDigitsFormat = new Intl.NumberFormat('pl-PL', {
    minimumIntegerDigits: 2
});

export function DateStringToDate(dateString: string): Date {
    const dateParts = dateString.split("-");
    const year = dateParts.length > 0 ? parseInt(dateParts[0]) : 0;
    const month = dateParts.length > 1 ? parseInt(dateParts[1]) : 0;
    const day = dateParts.length > 2 ? parseInt(dateParts[2]) : 0;

    const result = new Date(year, month - 1, day);

    return result;
}

export function DateOptionalToDateString(date?: Date) {
    return date ? DateToDateString(date) : "brak";
}

export function DateOptionalToDateTimeString(date?: Date) {
    return date ? DateToDateTimeString(date) : "brak";
}

export function DateToDateString(date: Date) {
    return `${date.getFullYear()}-${twoDigitsFormat.format(date.getMonth() + 1)}-${twoDigitsFormat.format(date.getDate())}`;
}

export function DateToDateTimeString(date: Date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${twoDigitsFormat.format(date.getHours())}:${twoDigitsFormat.format(date.getMinutes())}`;
}

export function DateToWorkTimeString(date: Date, noSeconds?: boolean) {
    let textString = `${twoDigitsFormat.format(date.getHours())}:${twoDigitsFormat.format(date.getMinutes())}`;

    if (noSeconds !== true) {
        textString += `:${twoDigitsFormat.format(date.getSeconds())}`;
    }

    return textString;
}

export function DateRangeString(fromDate: Date, toDate: Date) {
    const fromYear = fromDate.getFullYear();
    const toYear = toDate.getFullYear();

    let commonPrefix = '';
    let fromText = '';
    let toText = '';

    if (fromYear == toYear) {
        commonPrefix = fromYear.toString();

        const fromMonth = fromDate.getMonth();
        const toMonth = toDate.getMonth();
        if (fromMonth == toMonth) {
            commonPrefix += `-${twoDigitsFormat.format(fromMonth + 1)}`;

            const fromDay = fromDate.getDate();
            const toDay = toDate.getDate();
            if (fromDay == toDay) {
                commonPrefix += `-${twoDigitsFormat.format(fromDay)}`;

                return commonPrefix;
            } else {
                fromText = `${twoDigitsFormat.format(fromDate.getDate())}`;
                toText = `${twoDigitsFormat.format(toDate.getDate())}`;
            }
        } else {
            fromText = `${twoDigitsFormat.format(fromDate.getMonth() + 1)}-${twoDigitsFormat.format(fromDate.getDate())}`;
            toText = `${twoDigitsFormat.format(toDate.getMonth() + 1)}-${twoDigitsFormat.format(toDate.getDate())}`;
        }
    } else {
        fromText = DateToDateString(fromDate);
        toText = DateToDateString(toDate);
    }

    if (commonPrefix) {
        return commonPrefix + ' ' + fromText + ' - ' + toText;
    } else {
        return fromText + ' - ' + toText;
    }
}

/**
 * Updates time part of date
 * @param date
 * @param timePart - time in format "HH:mm:ss"
 */
export function UpdateDateWithTime(date: Date, timeString: string) {
    const timeParts = timeString.split(":");
    const hours = timeParts.length > 0 ? parseInt(timeParts[0]) : 0;
    const minutes = timeParts.length > 1 ? parseInt(timeParts[1]) : 0;
    const seconds = timeParts.length > 2 ? parseInt(timeParts[2]) : 0;

    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
}

/*
export function DateToExcelDateTimeString(date: Date | null) : string {
    if (!date) return '';
    return moment(date).format(ExcelDateTimeFormat);
}

export function DateToDateTimeString(date: Date) : string {
    return moment(date).format(DateTimeFormat);
}

export function DateToDateString(date: Date) : string {
    return moment(date).format(DateFormat);
}

export function DateToTimeString(date: Date) : string {
    return moment(date).format(TimeFormat);
}

export function DateToFormDateString(date: Date) : string {
    return moment(date).format(FormDateFormat);
}
*/