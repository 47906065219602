const twoDigitFormat = new Intl.NumberFormat('pl-PL', {
    minimumIntegerDigits: 2
});

export type TimeParts = {
    isMinus: boolean,
    hour: number,
    minute: number,
    second: number
};

const EMPTY_TIME_STRING = '--:--:--';

export default class TimeDuration
{
    static msPerSecond: number = 1000;
    static msPerMinute: number = TimeDuration.msPerSecond * 60;
    static msPerHour: number = TimeDuration.msPerMinute * 60;
    static msPerDay: number = TimeDuration.msPerHour * 24;

    private milliseconds: number = 0;

    static FromDates(start: Date, end: Date): TimeDuration {
        const duration = new TimeDuration();

        duration.milliseconds = end.getTime() - start.getTime();

        return duration;
    }

    static FromString(timeString: string): TimeDuration {
        const duration = new TimeDuration();

        if (timeString.startsWith('-')) {
            duration.milliseconds = -1;
        } else {
            const timeParts = timeString.split(":");
            const hours = timeParts.length > 0 ? parseInt(timeParts[0]) : 0;
            const minutes = timeParts.length > 1 ? parseInt(timeParts[1]) : 0;
            const seconds = timeParts.length > 2 ? parseInt(timeParts[2]) : 0;

            duration.milliseconds = seconds * TimeDuration.msPerSecond
                + minutes * TimeDuration.msPerMinute
                + hours * TimeDuration.msPerHour;
        }

        return duration;
    }

    static MsToTimeParts(miliseconds: number): TimeParts {
        let isMinus = miliseconds < 0;
        let elapsedMilisecs = isMinus ? -miliseconds : miliseconds;

        const hours = Math.trunc(elapsedMilisecs / TimeDuration.msPerHour);
        elapsedMilisecs -= hours * TimeDuration.msPerHour;
        const minutes = Math.trunc(elapsedMilisecs / TimeDuration.msPerMinute);
        elapsedMilisecs -= minutes * TimeDuration.msPerMinute;
        const seconds = Math.trunc(elapsedMilisecs / TimeDuration.msPerSecond);

        return {
            isMinus,
            hour: hours,
            minute: minutes,
            second: seconds
        };
    }

    static DateWithTimeString(date: Date, timeString: string) {
        const resultDate = new Date(date.getTime());
        const duration = TimeDuration.FromString(timeString);
        const parts = TimeDuration.MsToTimeParts(duration.milliseconds);

        resultDate.setHours(parts.hour);
        resultDate.setMinutes(parts.minute);
        resultDate.setSeconds(parts.second, 0);

        return resultDate;
    }

    CalcEndDate(startDate: Date): Date {
        const time = startDate.getTime() + this.milliseconds;

        return new Date(time);
    }

    ElapsedTimeString(allowNegative?: boolean, hideSeconds?: boolean): string {

        if (allowNegative !== true && this.IsNegative()) {
            return EMPTY_TIME_STRING;
        }

        const timeParts = TimeDuration.MsToTimeParts(this.milliseconds);

        let textString = `${timeParts.isMinus ? '-' : ''}${twoDigitFormat.format(timeParts.hour)}:${twoDigitFormat.format(timeParts.minute)}`;
        if (hideSeconds !== true) {
            textString += `:${twoDigitFormat.format(timeParts.second)}`;
        }

        return textString;
    }

    IsNegative() {
        return this.milliseconds < 0;
    }

    MillisecondsDuration(): number {
        return this.milliseconds;
    }

    AddMinutes(minutes: number) {
        this.milliseconds += minutes * TimeDuration.msPerMinute;
    }
};
