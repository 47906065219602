import React, { Component, DragEvent, Fragment } from 'react';
import SortableTable, { ColumnOptionsCollection } from './common/SortableTable';
import { MajorProjectStateEnum, ProjectStateFlags } from '../types/domain';
import { getHoursDurationText } from '../utilities/date-utils';
import { StringDictionary } from '../types/global';
import { CalculateDates, EnhanceBriefTransports, EnhanceProjects } from '../utilities/data-enhancer';
import { importExcelProjectFile, ImportResult } from '../utilities/excel-project-file-importer';
import { StatusIndicator } from './StatusIndicator';
import { apiFetchData } from '../utilities/auth-api';
import { BAMProjectDTO, ExportTransportDTO, GetProjectListResult, GetTransportsResult } from '../types/dto';
import authService from './api-authorization/AuthorizeService';
import { Features, AllowsFeature } from '../utilities/features';
import { DateToDateString } from '../utilities/date-formatter';
import { UncontrolledTooltip } from "reactstrap";
import { OperationResultWithData } from '../utilities/operation-result';
import { ProjectStateFilter } from './common/ProjectStateFilter';
import { ProjectNumberContext } from './contexts';
import { ProjectPageComponent } from './ProjectPage';
import { AdvancedProjectLink } from './common/AdvancedProjectLink';
import { renderStringsAsList } from '../utilities/render-utils';
import { FileDropableButton } from './common/FileDropableButton';

type ProjectsViewProps = {
}

type ProjectsViewState = {
    Projects: BAMProjectDTO[],
    IsFileOver: boolean,
    IsLoading: boolean,
    showImport: boolean,
    isExtendedView: boolean,
    enableOnlyMy: boolean,
    onlyMy: boolean,
    LastImportDateText?: string,
    showOrderNumber: boolean,
    importResult?: OperationResultWithData<ImportResult>,
    selectedProjectNumber?: number,
    availableTransports: ExportTransportDTO[],
    showAssumedProjectTime: boolean
}

export class ProjectsView extends Component<ProjectsViewProps, ProjectsViewState> {
    static displayName = ProjectsView.name;
    tableRef: React.RefObject<SortableTable> | undefined = React.createRef<SortableTable>();

    constructor(props: ProjectsViewProps) {
        super(props);

        const onlyMy = authService.getUserSettings().onlyMyProjects || false;

        this.state = {
            Projects: [],
            IsFileOver: false,
            IsLoading: true,
            showImport: false,
            isExtendedView: false,
            enableOnlyMy: false,
            onlyMy,
            showOrderNumber: false,
            availableTransports: [],
            showAssumedProjectTime: false
        };

        authService.getUser().then(userProfile => {
            this.setState({
                showImport: AllowsFeature(Features.ImportProject, userProfile),
                isExtendedView: AllowsFeature(Features.ExtendedProjectView, userProfile),
                enableOnlyMy: AllowsFeature(Features.OnlyMyProjects, userProfile),
                showOrderNumber: AllowsFeature(Features.ShowOrderNumber, userProfile),
                showAssumedProjectTime: AllowsFeature(Features.ShowAssumedProjectTime, userProfile)
            });

            this.fetchProjects(onlyMy);
        });

        this.onOnlyMyChange = this.onOnlyMyChange.bind(this);
        this.projectRowClass = this.projectRowClass.bind(this);
        this.getImportTooltipText = this.getImportTooltipText.bind(this);
        this.showImportResult = this.showImportResult.bind(this);
        this.closeToast = this.closeToast.bind(this);
        this.renderImportResultMessage = this.renderImportResultMessage.bind(this);
        this.setStatusFilter = this.setStatusFilter.bind(this);
        this.onProjectOpen = this.onProjectOpen.bind(this);
        this.onCloseProjectPage = this.onCloseProjectPage.bind(this);
        this.onSelectedProjectLoaded = this.onSelectedProjectLoaded.bind(this);
        this.fetchTransports = this.fetchTransports.bind(this);
        this.importFileProcedure = this.importFileProcedure.bind(this);

        this.fetchTransports();
    }

    async fetchTransports() {
        const transportsResult = await apiFetchData('transport') as GetTransportsResult;
        if (transportsResult.ExportTransports) {
            EnhanceBriefTransports(transportsResult.ExportTransports);
        }

        this.setState({
            availableTransports: transportsResult.ExportTransports || [],
        });
    }

    private closeToast() {
        this.setState({
            importResult: undefined
        });
    }

    private renderImportResultMessage() {
        const importResult = this.state.importResult;
        if (!importResult) {
            return null;
        }

        const warnings = importResult.GetWarnings();
        const errors = importResult.GetErrors();
        const isSuccess = importResult.IsSuccess();
        const data = importResult.Data;

        const headerClass = isSuccess
            ? "alert-success"
            : "alert-danger";

        return <div className="card text-start position-absolute end-0" style={{ zIndex: 100 }}>
            <div className={"card-header alert alert-dismissible mb-0 " + headerClass}>
                <strong>{isSuccess ? "Sukces" : "Błąd importu !"}</strong>
                <button type="button" className="btn-close" onClick={this.closeToast}></button>
            </div>
            {isSuccess && data &&
                <ul className="list-group list-group-flush">
                    <li className="list-group-item"><strong>Odczytano projektów: </strong>{data.ProjectsToImportCount}</li>
                    <li className="list-group-item"><strong>Zmodyfikowano rekordów w bazie: </strong>{data.RecordsModified}</li>
                {renderStringsAsList("text-warning", warnings)}
                </ul>
            }
            {!isSuccess &&
                <ul className="list-group list-group-flush">
                    {renderStringsAsList("text-danger", errors)}
                    {renderStringsAsList("text-warning", warnings)}
                </ul>
            }
            </div>;
    }

    private async importFileProcedure(file: File) {
        const importResult = await importExcelProjectFile(file);

        this.showImportResult(importResult);

        if (importResult
            && importResult.IsSuccess()
            && importResult.Data
            && importResult.Data.RecordsModified > 0) {
                await this.fetchProjects(this.state.onlyMy);
            }
    }

    showImportResult(importResult: OperationResultWithData<ImportResult>) {
        this.setState({
            importResult
        });
    }

    projectRowClass(unknownData: any): string {
        const data = unknownData as BAMProjectDTO;

        if (data.AmIAssigned && data.MajorProjectState == MajorProjectStateEnum.Fixes) {
            return "table-info";
        }

        if ((data.ProjectStateFlags & ProjectStateFlags.WorkInitiated) > 0) {
            return "table-primary";
        }

        if ((data.ProjectStateFlags & ProjectStateFlags.Urgent) > 0) {
            return "table-warning";
        }

        return "";
    }

    onOnlyMyChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            onlyMy: event.target.checked
        });

        this.fetchProjects(event.target.checked);
        authService.setUserSettings({ onlyMyProjects: event.target.checked }, true);
    }

    getImportTooltipText(): string | undefined {
        return !!this.state.LastImportDateText
            ? `Ostatni import ${this.state.LastImportDateText}`
            : undefined;
    }

    private setStatusFilter(projectState?: MajorProjectStateEnum) {
        this.tableRef?.current?.setFilterValue('MajorProjectState', projectState);
    }

    private columns() {
        const columns = {} as StringDictionary;

        if (this.state.isExtendedView) {
            columns['ProjectNumber'] = 'Numer';
            if (this.state.showOrderNumber) {
                columns['OrderNumber'] = 'Zamówienie';
            }
            columns['MajorProjectState'] = 'Status';
            columns['StartDate'] = 'Start';
            columns['EndDate'] = 'End';
            columns['ClientName'] = 'Klient';
            if (this.state.showAssumedProjectTime) {
                columns['ScheduledTimeHours'] = 'Zapl. czas';
            }
            columns['ModelDescription'] = 'Model';
            columns['CabinetCount'] = 'Liczba szaf';
            columns['SuggestedWorkersCount'] = 'Liczba prac.';
            columns['RealTimeSpentHours'] = 'Przepr. czas';
            columns['ExtraUnscheduledTimeHours'] = 'QDE';
            columns['ExportTransport.ExportDate'] = 'Data wysyłki';

        } else {
            columns['ProjectNumber'] = 'Numer';
            columns['MajorProjectState'] = 'Status';
            columns['ClientName'] = 'Klient';
            if (this.state.showAssumedProjectTime) {
                columns['ScheduledTimeHours'] = 'Zapl. czas';
            }
            columns['ModelDescription'] = 'Model';
            columns['CabinetCount'] = 'Liczba szaf';
            columns['RealTimeSpentHours'] = 'Przepr. czas';
            columns['ExtraUnscheduledTimeHours'] = 'QDE';
            columns['ExportTransport.ExportDate'] = 'Data wysyłki';
        }

        return columns;
    }

    onProjectOpen(projectNumber: number) {
        this.setState({
            selectedProjectNumber: projectNumber
        });
    }

    private columnOptions(): ColumnOptionsCollection {
        const options = {} as ColumnOptionsCollection;

        options['ProjectNumber'] = {
            enableFilter: true,
            columnRenderer: (row) => {
                const project = row as BAMProjectDTO;

                const amIAssignedToFixes = project.AmIAssigned && project.MajorProjectState == MajorProjectStateEnum.Fixes;

                return <>
                    <div>
                        <AdvancedProjectLink projectNumber={project.ProjectNumber} onOpen={() => this.onProjectOpen(project.ProjectNumber)} />
                    </div>
                    {(project.ProjectStateFlags & ProjectStateFlags.Urgent) > 0 && <i className="cone-icon" title="Pilny!"></i>}
                    {amIAssignedToFixes && <i title="Przypisany do poprawek" className="fa-solid fa-user text-info" />}
                </>;
            }
        };

        if (this.state.showOrderNumber) {
            options['OrderNumber'] = {
                enableFilter: true,
                valueRenderer: (value) => {
                    const numberValue = value as number;
                    if (numberValue > 0) {
                        return <small>{numberValue}</small>
                    } else {
                        return '';
                    }
                }
            }
        }

        const availableFilterStates = [
            MajorProjectStateEnum.Planned,
            MajorProjectStateEnum.Unloaded,
            MajorProjectStateEnum.Production,
            MajorProjectStateEnum.TestProduction,
            MajorProjectStateEnum.Fixes,
            MajorProjectStateEnum.PrepareForSend,
            MajorProjectStateEnum.ReadyToSend,
            MajorProjectStateEnum.Finished
        ];

        options['MajorProjectState'] = {
            valueRenderer: (value) => <StatusIndicator status={value as MajorProjectStateEnum} />,
            filterRenderer: (filterValue) => <ProjectStateFilter
                value={filterValue}
                onChange={this.setStatusFilter}
                states={availableFilterStates} />
        };
        options['ClientName'] = {
            enableFilter: true,
            valueRenderer: (value) => <small>{value as string}</small>
        };
        options['ModelDescription'] = {
            enableFilter: true,
            valueRenderer: (value) => <small>{value as string}</small>
        };
        options['RealTimeSpentHours'] = {
            valueRenderer: (value: unknown) => {
                const hours = value as number;

                return getHoursDurationText(hours);
            }
        }

        return options;
    }

    async fetchProjects(onlyMy: boolean) {

        this.setState({ IsLoading: true });

        let url = 'project';
        if (onlyMy) {
            url += '?onlymy=true'
        }

        const data = await apiFetchData(url) as GetProjectListResult;

        CalculateDates(data);

        const projects = ((data && data.Projects) || []) as BAMProjectDTO[];

        EnhanceProjects(projects);

        this.setState({
            Projects: projects,
            IsLoading: false,
            LastImportDateText: data.LastImport ? DateToDateString(data.LastImport) : undefined
        });
    }

    onCloseProjectPage() {
        this.setState({
            selectedProjectNumber: undefined
        });
    }

    onSelectedProjectLoaded(project: BAMProjectDTO) {

        const newProjects = [...this.state.Projects];
        const itemIndex = newProjects.findIndex(listProject => listProject.Id == project.Id);

        newProjects[itemIndex] = project;

        this.setState({
            Projects: newProjects
        });
    }

    render() {
        return (
            <>
                <div className='row'>
                    {this.state.IsLoading && <div className='col-auto'><span className='spinner-border'></span></div>}
                    {this.state.enableOnlyMy &&
                        <div className="col-auto">
                            <div className="form-check" title="Projekty w których wykonywałem pracę">
                                <input id="only-my" className="form-check-input" type="checkbox" checked={this.state.onlyMy} onChange={this.onOnlyMyChange} />
                                <label className="form-check-label" htmlFor="only-my">Tylko moje projekty</label>
                            </div>
                        </div>
                    }
                    {this.state.showImport &&
                        <div className='col text-end'>
                            <FileDropableButton
                                processFile={this.importFileProcedure}
                                inputId="import-file-selector"
                                buttonId="import-button"
                                acceptFiles=".xlsx">Importuj projekty</FileDropableButton>
                            <UncontrolledTooltip autohide={true} placement="left" target="import-button">
                                {this.getImportTooltipText()}
                            </UncontrolledTooltip>
                            {!!this.state.importResult &&
                                this.renderImportResultMessage()
                            }
                        </div>
                    }
                </div>
                {this.state.selectedProjectNumber && 
                    <ProjectNumberContext.Provider value={this.state.selectedProjectNumber}>
                        <ProjectPageComponent
                            projectNumber={this.state.selectedProjectNumber}
                            onClosePage={this.onCloseProjectPage}
                            onProjectLoaded={this.onSelectedProjectLoaded}
                            availableTransports={this.state.availableTransports}
                        />
                    </ProjectNumberContext.Provider>
                }
                <Fragment>
                    <div className={(this.state.Projects && this.state.Projects.length > 0) ? 'd-none' : 'alert alert-info mt-2'}>Brak projektów do wyświetlenia</div>
                    <SortableTable idKey='Id'
                        ref={this.tableRef}
                        dataRows={this.state.Projects}
                        columns={this.columns()}
                        columnOptions={this.columnOptions()}
                        rowClassesFunc={this.projectRowClass}
                        isSmall={true}
                        isVisible={!this.state.selectedProjectNumber}
                    />
                </Fragment>
            </>
        );
    }
}
