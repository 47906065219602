
export enum MajorProjectStateEnum {
    Planned = 0,
    Unloaded = 200,
    Production = 300,
    TestProduction = 400,
    Fixes = 450,
    PrepareForSend = 500,
    ReadyToSend = 550,
    Finished = 600,
    Archive = 700
};

export enum ProjectStateFlags {
    WorkInitiated = 0x1,
    UnloadingLackingsPelikanCreated = 0x2,
    Urgent = 0x4
};

export enum KnownRolesEnum {
    NotSelected = 0,
    SuperAdmin = 1,
    Tester,
    Office,
    Manager,
    WarehouseKeeper,
    Monter,
    TeamLider,
    Apprentice,
    WarehouseForeman
};

export enum TransportAssignmentTypeEnum {
    Proposed = 1,
    HalfToHalf = 2
};

export enum WorkTypeEnum {
    NotSelected = 0,
    ControlBoard = 1,
    Assembly = 2,
    Cable = 3,
    Fixes = 4,
    QDE = 5,
    P0 = 6,
    Testing = 7,
    AdditionalWorks = 8
};

export enum WorkSubtypeEnum {
    None = 0,
    // Cable subtypes
    Cable_HauptLess16qmm = 1,
    Cable_HauptMore16qmm = 2,
    Cable_ErdenLess16qmm = 3,
    Cable_ErdenMore16qmm = 4,
    Cable_230VAC = 5,
    Cable_24VDC = 6,
    Cable_Steuerung = 7,
    Cable_STV = 8,
    Cable_Kabel = 9
};

export enum WorktimeEntryFlags {
    Manual = 1,
    Corrected = 2
};

export enum WorkFlags {
    P1Approved = 0x1,
    P1Visual = 0x2,
    VDLSigned = 0x4,
    Lashe5ForMonter = 0x8,
    Lashe5ForTester = 0x10,
    Lashe9 = 0x20,
    BeipackFinished = 0x40,
    Lashe12 = 0x80,
    FMLista = 0x100,
    // OBSOLETE - Below flag is no longer used
    PelicansTester = 0x200,
    PelicansOffice = 0x400,
    Pictures = 0x800,
    VDLScan = 0x1000,
    FMPelikan = 0x2000,
    ControlBoardFinished = 0x4000,
    AssemblyFinished = 0x8000,
    CableFinished = 0x10000,
    // TODO - Obsolete flag, remove
    FixesFinished = 0x20000,
    TorquesBelow6qmm = 0x40000,
    TorquesAbove6qmm = 0x80000
};

export enum WorkFlagValue {
    False = 0,
    True = 1,
    Inapplicable = 2,
    // Below values apply for FMPelikan flag
    Empty = 0,
    ProjectCanLeave = 1,
    MaterialComplete = 2,
    LackingReported = 3,
    ProjectCanNotLeave = 4
};

export enum ProjectRemarkTypeEnum {
    ImportRemark = 0,
    NormalRemark
};

export enum UnloadingFlags {
    CabinetBuilt = 0x1,
    DiagramFromVDL = 0x2,
    DescriptionsOfCablesAndDevices = 0x4,
    PreparedCablesKomax = 0x8,
    CablesMore16mm = 0x10,
    Cables = 0x20,
    Baipack = 0x40,
    AirConditioning = 0x80,
    DeskDoorsAndHinges = 0x100,
    DeskShell = 0x200,
    DeskPanel = 0x400,
    DeskMaterial = 0x800,
    AdditionalMaterial = 0x1000,
    Damages = 0x2000,
    Covers = 0x4000,
    Devices = 0x8000,
    IsCabinedUnarmed = 0x10000
};

export enum UnloadingFlagValue {
    NotSet = 0,
    Yes = 1,
    Missing = 2,
    Inapplicable = 3,
};

export enum PelikanStatusEnum {
    Open = 1,
    InProgress = 2,
    Solved = 3,
    Removed = 4,
    Archived = 5,
    Installed = 6
};

export enum PelikanCategoryEnum {
    NotSelected = 0,
    Problem = 1,
    // Obsolete
    UnloadingLackings = 2,
    MaterialLackings = 3,
    StickerNotesLackings = 4,
    Remarks = 5
};

export enum PelikanLogFlags {
    Created = 0x1,
    AddedRemark = 0x2,
    UpdatedRemark = 0x4,
};

export type PelikanAssignmentData = {
    assignedRole?: KnownRolesEnum,
    assignedEmployeeId?: number,
    assignedToWorkers?: boolean
};

export enum TesterNoteStatusEnum {
    New = 1,
    Done = 2
};

export enum QDETicketStatus {
    New = 0,
    Reported = 1,
    Accepted = 2,
    Rejected = 3,
    Removed = 4
};

export enum PelikanViewType {
    All = 0,
    AssignedToMe = 1,
    Unread = 2
};

export enum WorkTimeNormTypeEnum {
    Assembly = 1,
    Cable_HauptAndErdenMoreThan16 = 2,
    Cable_Steuerung = 3,
    Cable_24VDC = 4,
    Cable_230VAC = 5,
    Cable_Hauptleitungen = 6,       // Cable_HauptLess16qmm
    Cable_Kabel = 7,
    Cable_Erdverbindungen = 8,      // Cable_ErdenLess16qmm
    Cable_STV = 9,
    ControlBoard = 10,
    AdditionalWorks = 11,
    Testing = 12
};

export enum QDETicketTypeEnum {
    FromPelikan = 1,
    FromWorktime = 2
};

export type WorkTypeDescriptor = {
    Type: WorkTypeEnum,
    SubType?: WorkSubtypeEnum
};

export enum ProjectCommandsEnum {
    MoveToPlanned = 0,
    // MoveToUnloading = 1,
    MoveToUnloaded = 2,
    MoveToProduction = 3,
    MoveToTesting = 4,
    MoveToFixes = 5,
    MoveToPrepareForSend = 6,
    MoveToReadyToSend = 7,
    MoveToFinish = 8,
    MoveToArchive = 9,
    AssignWorker = 10,
    UnassignWorker = 11,
    // AssignMyself = 12,       OBSOLETE
    // UnassignMyself = 13,     OBSOLETE
    CreateImportProject = 14,
    AssignTransport = 15,
    UnassignTransport = 16,
    StartProjectWork = 17,
    FinishProjectWork = 18,
    UpdateProjectWorkItem = 19,
    AddProjectWorkItem = 20,
    ModifyImportProject = 21,
    SetWorkFlag = 22,
    ResetWorkFlag = 23,
    SetAsInapplicable = 24,
    SetUnloadFlagToYes = 25,
    SetUnloadFlagToMissing = 26,
    ResetUnloadFlag = 27,
    SetUnloadFlagToInaplicable = 28,
    Archive = 29,
    AddTesterNote = 30,
    DeleteTesterNote = 31,
    UpdateTesterNote = 32,
    SetTesterNoteStatus = 33,
    AddRemark = 34,
    DeleteRemark = 35,
    UpdateRemark = 36,
    UpdateFMPelikanFlag = 37,
    UpdateProjectStateFlag = 38,
    DeleteProjectWorkItem = 39,
    ImportFMList = 40,
    SetFMListItemStatus = 41,
    RemoveFMList = 42
    // IMPORTANT - Dont change this values as they are saved in database
    // always add new values at the end
    // NOTE - Mind that thre is a copy of this enum on frontend
}

export enum FMListEntryStatus {
    NotSet = 0,
    Present = 1,
    Missing = 2
}

export enum EmployeeBreakEnum {
    NotSet = 0,
    Break1 = 1,
    Break2,
    Break3,
    Break4,
    Break5
}

export enum EmployeeActivityStatusEnum {
    LoggedOut = 0x1,
    LoggedIn = 0x2,
    LoginExpired = 0x4,
    NoWorkEntries = 0x8,
    WorkInProgress = 0x10,
    WorkStopped = 0x20
}

export enum KnownDepartmentsEnum {
    AssemblyHall = 1,
    Warehouse = 2,
    Office = 3
}

export const allDepartmentIds: KnownDepartmentsEnum[] = [
    KnownDepartmentsEnum.AssemblyHall,
    KnownDepartmentsEnum.Warehouse,
    KnownDepartmentsEnum.Office
];
