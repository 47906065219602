import { WorkTypeEnum, WorkSubtypeEnum, KnownRolesEnum, PelikanStatusEnum, PelikanCategoryEnum, PelikanAssignmentData, UnloadingFlags, UnloadingFlagValue, EmployeeBreakEnum, KnownDepartmentsEnum } from './domain';
import { ValidationErrorsResponse, OperationMessage } from './dto';

type WorktimeFormBaseFields = {
    WorkType: WorkTypeEnum,
    WorkSubtype?: WorkSubtypeEnum,
    Details?: string,
    TesterNoteId?: number,
    CabinetModelSubTypeId?: number,
    DepartmentWorkTypeId?: number
};

export type StartProjectWorkForm = WorktimeFormBaseFields;

export type UpdateWorktimeForm = WorktimeFormBaseFields & {
    StartDate: number,
    EndDate: number,
    EmployeeId?: number
};

export type StartDepartmentWorkForm = {
    WorkTypeId: number,
    Details?: string
};

export type EmployeeDataForm = {
    firstName: string,
    surname: string,
    loginName: string,
    password: string,
    isActive: boolean,
    roles: KnownRolesEnum[],
    employeeBreakId: EmployeeBreakEnum
};

const GeneralErrorFieldName = '0';


// TODO - Handle field names regardless case
export class ValidationErrors implements ValidationErrorsResponse {
    Success: boolean;
    ValidationErrors: { [key: string]: OperationMessage[]; };

    constructor() {
        this.Success = true;
        this.ValidationErrors = {};
    }

    AddError(fieldName: string, message: string) {
        if (!this.ValidationErrors[fieldName]) {
            this.ValidationErrors[fieldName] = [];
        }

        this.ValidationErrors[fieldName].push({
            Message: message
        });
        this.Success = false;
    }

    AddGeneralError(message: string) {
        this.AddError(GeneralErrorFieldName, message);
    }

    GetGeneralErrors() {
        return this.ValidationErrors[GeneralErrorFieldName] || [];
    }

    ValidateNotEmpty(fieldName: string, value: any) {
        if (!value) {
            this.AddError(fieldName, 'Pole nie może być puste');
        }
    }

    ValidateArrayHasAnyValue(fieldName: string, arrayObject: any[], message?: string) {
        if (!arrayObject.length) {
            this.AddError(fieldName, message || 'Tablica jest pusta');
        }
    }

    HasErrors(fieldName: string): boolean {
        const result = !!this.ValidationErrors[fieldName]
            && this.ValidationErrors[fieldName].length > 0;
        return result;
    }

    SetFromValidationErrorsResponse(response: ValidationErrorsResponse) {
        this.Success = response.Success;
        this.ValidationErrors = response.ValidationErrors;
    }

    SetFromOperationErrors(errors: OperationMessage[]) {
        errors.forEach(error => this.AddGeneralError(error.Message));
    }

    GetAsMultilineString(): string {
        return Object.values(this.ValidationErrors)
            .map(v => v.map(e => e.Message).join('\n'))
            .join('\n');
    }
}


export type EmployeeWorktimeQueryForm = {
    StartDate?: number,
    EndDate?: number
}



export type CreatePelikanData = PelikanAssignmentData & {
    projectNumber?: number,
    departmentId?: KnownDepartmentsEnum,
    category: PelikanCategoryEnum,
    needQDE: boolean,
    QDEMinutes?: number,
    description: string,
    canMonterResolve: boolean
};

export type UpdatePelikanData = PelikanAssignmentData & {
    status?: PelikanStatusEnum,
    category?: PelikanCategoryEnum,
    needQDE?: boolean,
    QDEMinutes?: number,
    description?: string,
    solution?: string,
    canMonterResolve?: boolean
};

export type PelikanDataForm = {
    projectNumber?: number,
    departmentId?: KnownDepartmentsEnum,
    id: number,
    category: PelikanCategoryEnum,
    assignedRole?: KnownRolesEnum,
    assignedEmployeeId?: number,
    needQDE: boolean,
    QDEMinutes?: number,
    description: string,
    solution: string,
    canMonterResolve: boolean
};

export type UpdateUnloadingFlagForm = {
    UnloadingFlag: UnloadingFlags,
    Value: UnloadingFlagValue
};

export type AssignTransportForm = {
    TransportId?: number,
    NewTransportDate?: string,
    HalfToHalf: boolean
};
