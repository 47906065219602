import { forwardRef, useImperativeHandle } from "react";
import { DepartmentWorkTypeDTO, DepartmentWorkTypeExDTO, WorktimeReportEntryDTO, WorktimeReportSummaryDTO } from "../../types/dto";
import { DateToDateString } from "../../utilities/date-formatter";
import { ElapsedTimeInMilisecsText } from "../../utilities/date-utils";
import { DepartmentName, depWorkTypeMatches } from '../../utilities/domain-utils';
import { exportDepartmentWortimeReportToExcel } from "../../utilities/excel/excel-worktime-report";

type DepartmentWorktimeReportTableProps = {
    showTypes: boolean,
    reportDepartmentTypes: DepartmentWorkTypeExDTO[],
    reportDepartments: DepartmentWorkTypeExDTO[],
    entries: WorktimeReportEntryDTO[]
};

export type DepartmentWorktimeReportTableHandle = {
    exportToExcel: () => void
};

export const DepartmentWorktimeReportTable = forwardRef<DepartmentWorktimeReportTableHandle, DepartmentWorktimeReportTableProps>((props: DepartmentWorktimeReportTableProps, ref) => {

    useImperativeHandle(ref, () => ({
        exportToExcel: () => {
            // TODO - Must get proper rows and columns for Excel report
            exportDepartmentWortimeReportToExcel(
                props.showTypes ? props.reportDepartmentTypes : props.reportDepartments,
                filteredEntries()
            );
        }
    }));

    const renderWorkType = function (dpt: DepartmentWorkTypeExDTO) {

        if (!dpt.Id) {
            return DepartmentName(dpt.DepartmentId);
        } else {
            return <>{dpt.Name}<br />{DepartmentName(dpt.DepartmentId)}</>;
        }
    }

    const departmentWorkTypeTitle = function (dpt: DepartmentWorkTypeExDTO) {

        let resultString: string;
        if (!dpt.Id) {
            resultString = DepartmentName(dpt.DepartmentId);
        } else {
            resultString = `${dpt.Name}
${DepartmentName(dpt.DepartmentId)}`;
        }

        return resultString;
    }

    const departmentValueCellTitle = function (e: WorktimeReportEntryDTO, dt: DepartmentWorkTypeDTO) {
        let resultString = dt.Name;

        if (e.Details) {
            resultString += ' ' + e.Details;
        }

        return resultString;
    }

    const renderDepartmentValueCell = function (e: WorktimeReportEntryDTO, dt: DepartmentWorkTypeExDTO, idx: number): any {
        return depWorkTypeMatches(e, dt)
            ? <td key={idx} title={departmentValueCellTitle(e, dt)}>{ElapsedTimeInMilisecsText(e.TotalMilliseconds)}</td>
            : <td key={idx}></td>;
    }

    const renderDepartmentEntryRow = function (
        e: WorktimeReportEntryDTO,
        index: number,
        columns: DepartmentWorkTypeExDTO[]) {

        return <tr key={index} className={e.ByAppr ? "table-warning" : ""}>
            <td>{DateToDateString(e.Date!)}</td>
            <td>{e.Employee.FullName}</td>
            {columns.map((dt, idx) => renderDepartmentValueCell(e, dt, idx))}
        </tr>;
    }

    const renderDepartmentWorkTypesHeaderCells = function (withFilter: boolean, columns: DepartmentWorkTypeExDTO[]) {

        // TODO - withFilter is currently ignored

        return columns.map((dwt, index) =>
            <th key={index} title={departmentWorkTypeTitle(dwt)} className="align-top">
                {renderWorkType(dwt)}
            </th>);
    }

    const renderSummaryHeader = function (columns: DepartmentWorkTypeExDTO[]) {
        return <tr>
            <td colSpan={2}></td>
            {renderDepartmentWorkTypesHeaderCells(false, columns)}
        </tr>;
    }

    const renderSummaryRow = function (s: WorktimeReportSummaryDTO, columns: DepartmentWorkTypeExDTO[]) {
        return <tr>
            <th>Suma</th>
            <th>{ElapsedTimeInMilisecsText(s.TotalMilliseconds)}</th>
            {columns.map((wtc, index) => <th key={index}>{ElapsedTimeInMilisecsText(s.MillisecondsPerWorkType[index])}</th>)}
        </tr>
    }

    const filteredEntries = function (): WorktimeReportEntryDTO[] {
        // TODO - No filters so far
        return props.entries;
    }

    const getMatchingColumnIndex = function (
        columns: DepartmentWorkTypeExDTO[],
        we: WorktimeReportEntryDTO): number {

        return columns.findIndex(column => depWorkTypeMatches(we, column));
    }

    const columns = props.showTypes
        ? props.reportDepartmentTypes
        : props.reportDepartments;

    const buildSummary = function (columns: DepartmentWorkTypeExDTO[]): WorktimeReportSummaryDTO {

        const entries = filteredEntries();

        const summary: WorktimeReportSummaryDTO = {
            TotalMilliseconds: 0,
            MillisecondsPerWorkType: Array<number>(columns.length).fill(0),
            MilisecondsPerSubtype: []   // This is not used
        };

        entries.forEach(we => {
            // ApplyReportEntry to Summary
            summary.TotalMilliseconds += we.TotalMilliseconds;

            const idx = getMatchingColumnIndex(columns, we);

            if (idx >= 0) {
                summary.MillisecondsPerWorkType[idx] += we.TotalMilliseconds;
            }
        });

        return summary;
    }

    const summary = buildSummary(columns);

    return <table className='table table-striped'>
        <thead>
            <tr>
                <th className="align-top">Data</th>
                <th className="align-top">Pracownik</th>
                {renderDepartmentWorkTypesHeaderCells(true, columns)}
            </tr>
        </thead>
        <tbody className="table-group-divider">
            {filteredEntries().map((e, idx) => renderDepartmentEntryRow(e, idx, columns))}
        </tbody>
        <tfoot className="table-group-divider">
            {renderSummaryHeader(columns)}
            {renderSummaryRow(summary, columns)}
        </tfoot>
    </table>
});