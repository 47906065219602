type ClearableDropdownProps = {
    value?: any,
    onChange: (value?: any) => void
    valueText: (value: any) => string,
    values: any[]
};

export function ClearableDropdown(props: ClearableDropdownProps) {

    const dropdownText = props.value !== undefined
        ? props.valueText(props.value) : <i>&emsp;&emsp;&emsp;</i>;

    const renderSelectStatusItem = (value: any) =>
        <li key={value}>
            <button className="dropdown-item" type="button" onClick={() => props.onChange(value)}>{props.valueText(value)}</button>
        </li>;

    return <div className="input-group input-group-sm flex-nowrap">
        <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">{dropdownText}</button>
        <ul className="dropdown-menu">
            {props.values.map(renderSelectStatusItem)}
        </ul>
        <button type="button" className="btn btn-outline-secondary" onClick={() => props.onChange(undefined)}><i className="fa-solid fa-times"></i></button>
    </div>;
}
