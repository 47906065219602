import { QDETicketStatus } from "../../types/domain";
import { QDETicketStatusName } from "../../utilities/domain-utils";
import { ClearableDropdown } from "./ClearableDropdown";

type QDEStatusFilterProps = {
    value?: QDETicketStatus,
    onChange: (status?: QDETicketStatus) => void,
    statuses?: QDETicketStatus[]
};

export function QDEStatusFilter(props: QDEStatusFilterProps) {

    const statuses = props.statuses || [
        QDETicketStatus.New,
        QDETicketStatus.Reported,
        QDETicketStatus.Accepted,
        QDETicketStatus.Rejected
    ];

    return <ClearableDropdown
        value={props.value}
        onChange={props.onChange}
        valueText={QDETicketStatusName}
        values={statuses}
    />
}
