import React, { Component, Fragment } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { Col, Row } from 'reactstrap';
import { CabinetModelBriefDTO, CabinetNormCollectionDTO, CabinetNormCollectionsResponseDTO, CabinetWorkTimeNormDTO, OperationResultDTO, OperationResultWithDataDTO, ProjectWorktimeTotalForCabinetDTO } from '../types/dto';
import { apiFetchResponse } from '../utilities/auth-api';
import { FetchStatusEnum } from '../utilities/data-fetch';
import { workTypeText } from '../utilities/domain-utils';
import { CabinetWorkTimeNormData, CabinetWorkTimeNormRow } from './CabinetWorkTimeNormRow';
import { AlertComponent, AlertMessage } from './common/AlertComponent';

type CableTimeReportProps = {
    isVisible?: boolean,
    projectNumber: number,
    cabinetCount: number,
    canEdit: boolean
};

type CabinetNormCollectionData = Omit<CabinetNormCollectionDTO, "CabinetWorkTimeNorms">
    & {
    CabinetWorkTimeNorms: CabinetWorkTimeNormData[]
};


type CableTimeReportState = {
    normCollections: CabinetNormCollectionData[],
    cabinetWorktimeTotals: ProjectWorktimeTotalForCabinetDTO[],
    hasOwnNorms: boolean,
    hasModel: boolean,
    isSaving: boolean,
    alertMessage?: AlertMessage,
    fetchStatus: FetchStatusEnum,
    areModelsLoading: boolean,
    modelsWithNorms: CabinetModelBriefDTO[],
    selectedModel: Option[]
};

export class CableTimeReport extends Component<CableTimeReportProps, CableTimeReportState> {
    static displayName = CableTimeReport.name;

    constructor(props: CableTimeReportProps) {
        super(props);

        this.state = {
            normCollections: [],
            cabinetWorktimeTotals: [],
            hasOwnNorms: false,
            hasModel: false,
            isSaving: false,
            fetchStatus: FetchStatusEnum.Unitialised,
            areModelsLoading: false,
            modelsWithNorms: [],
            selectedModel: []
        };

        this.renderCabinetModelReport = this.renderCabinetModelReport.bind(this);
        this.deleteNormCollection = this.deleteNormCollection.bind(this);
        this.onConnectionCountChange = this.onConnectionCountChange.bind(this);
        this.onTimePerCabinetChange = this.onTimePerCabinetChange.bind(this);
        this.getSumTimeToAchieve = this.getSumTimeToAchieve.bind(this);
        this.getSumDifference = this.getSumDifference.bind(this);
        this.getTotalTimeToAchievePerCabinet = this.getTotalTimeToAchievePerCabinet.bind(this);
        this.getTotalTimeToAchieve = this.getTotalTimeToAchieve.bind(this);
        this.onCopyFromModelNameChanged = this.onCopyFromModelNameChanged.bind(this);
        this.copyNormsFromModel = this.copyNormsFromModel.bind(this);
        this.createModel = this.createModel.bind(this);
        this.onSearchModelsWithNorms = this.onSearchModelsWithNorms.bind(this);
        this.isModelSelected = this.isModelSelected.bind(this);
    }

    componentDidUpdate(prevProps: CableTimeReportProps) {
        if (this.props.isVisible &&
            (this.props.isVisible != prevProps.isVisible
                || this.props.projectNumber != prevProps.projectNumber))
        {
            this.fetchNormCollections();
        }
    }

    async onSearchModelsWithNorms(query: string) {

        if (!query || query.length < 3) {
            this.setState({
                modelsWithNorms: []
            });
            return;
        }

        this.setState({
            areModelsLoading: true
        });

        let modelsWithNorms: CabinetModelBriefDTO[] = [];

        const response = await apiFetchResponse(`cabinet-norm/search?PartOfModelName=${query}`);
        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<CabinetModelBriefDTO[]>;

            if (result.Success) {
                modelsWithNorms = result.Data || [];
            }
        }

        this.setState({
            modelsWithNorms,
            areModelsLoading: false
        });
    }

    async deleteNormCollection(normCollectionId: number) {
        const response = await apiFetchResponse(`cabinet-norm/${normCollectionId}`,
            {
                method: 'DELETE'
            });

        if (response.status == 200) {
            const result = await response.json() as OperationResultDTO;

            if (result.Success) {
                this.showSuccessMessage("Usunięto normę");
                await this.fetchNormCollections();
            } else {
                this.showErrorMessage(result.Errors![0].Message);
            }
        }
    }

    getTotalTimeToAchievePerCabinet() {
        return this.state.normCollections
            .reduce((value, nc) => value + this.getSumTimeToAchievePerCabinet(nc), 0);
    }

    getTotalTimeToAchieve() {
        return this.state.normCollections
            .reduce((value, nc) => value + this.getSumTimeToAchieve(nc), 0);
    }

    getTotalActualHours() {
        return this.state.normCollections
            .reduce((value, nc) => value + this.getSumActualHours(nc), 0);
    }

    getTotalActualHoursWithWorktimes() {
        let sum = 0;
        for (let i = 0; i < this.state.normCollections.length; i++) {
            sum += this.getSumActualHoursWithWorktimes(
                this.state.normCollections[i],
                this.state.cabinetWorktimeTotals[i]
            );
        }
        return sum;
    }

    getTotalDifference() {
        return this.state.normCollections
            .reduce((value, nc) => value + this.getSumDifference(nc), 0);
    }

    getSumTimeToAchievePerCabinet(normCollection: CabinetNormCollectionDTO) {
        return normCollection.CabinetWorkTimeNorms.reduce((value, cwtn) => {
            return value + cwtn.TimeToAchivePerCabinet;
        }, 0);
    }

    getSumTimeToAchieve(normCollection: CabinetNormCollectionDTO) {
        return this.getSumTimeToAchievePerCabinet(normCollection) * this.props.cabinetCount;
    }

    getSumActualHours(normCollection: CabinetNormCollectionDTO) {
        return normCollection.CabinetWorkTimeNorms.reduce((value, cwtn) => {
            return value + cwtn.ActualHours;
        }, 0);
    }

    getWorktimeTotalHours(worktimeTotal: ProjectWorktimeTotalForCabinetDTO) {
        return worktimeTotal.WorktimeTotals.reduce((value, wt) => value + wt.TotalHours, 0);
    }

    getSumActualHoursWithWorktimes(
        normCollection: CabinetNormCollectionDTO,
        worktimeTotal: ProjectWorktimeTotalForCabinetDTO
    ) {
        var sumActualHours = this.getSumActualHours(normCollection);
        var worktimeTotalHours = this.getWorktimeTotalHours(worktimeTotal);

        return sumActualHours + worktimeTotalHours;
    }

    getSumDifference(normCollection: CabinetNormCollectionDTO) {
        return normCollection.CabinetWorkTimeNorms.reduce((value, cwtn) => {
            return value + (cwtn.TimeToAchivePerCabinet * this.props.cabinetCount) - cwtn.ActualHours;
        }, 0);
    }

    onConnectionCountChange(
        normCollection: CabinetNormCollectionDTO,
        norm: CabinetWorkTimeNormDTO,
        newValue: string) {

        var normCollections = this.state.normCollections.map(nc => {
            if (nc === normCollection) {
                nc.CabinetWorkTimeNorms = nc.CabinetWorkTimeNorms.map(cwtn => {
                    if (cwtn === norm) {
                        cwtn.ConnectionCountString = newValue;
                        cwtn.ConnectionCount = parseInt(newValue) || 0
                    }
                    return cwtn;
                });
            }
            return nc;
        });

        this.setState({
            normCollections
        });
    }

    onTimePerCabinetChange(
        normCollection: CabinetNormCollectionDTO,
        norm: CabinetWorkTimeNormDTO,
        newValue: string) {

        var normCollections = this.state.normCollections.map(nc => {
            if (nc === normCollection) {
                nc.CabinetWorkTimeNorms = nc.CabinetWorkTimeNorms.map(cwtn => {
                    if (cwtn === norm) {
                        cwtn.TimeToAchivePerCabinetString = newValue;
                        cwtn.TimeToAchivePerCabinet = parseFloat(newValue) || 0;
                    }
                    return cwtn;
                });
            }
            return nc;
        });

        this.setState({
            normCollections
        });
    }

    renderCabinetModelReport(normCollection: CabinetNormCollectionData, index: number) {
        const normCollectionKey = 'nc_' + index + '_' + normCollection.Id;
        const cabinetWorktimeTotal = this.state.cabinetWorktimeTotals[index];
        const p0Total = cabinetWorktimeTotal.WorktimeTotals.length >= 1
            ? cabinetWorktimeTotal.WorktimeTotals[0] : undefined;
        const fixesTotal = cabinetWorktimeTotal.WorktimeTotals.length >= 2
            ? cabinetWorktimeTotal.WorktimeTotals[1] : undefined;

        const sumActualHours = this.getSumActualHours(normCollection);
        let sumTotalHours = sumActualHours;
        if (p0Total) {
            sumTotalHours += p0Total.TotalHours;
        }
        if (fixesTotal) {
            sumTotalHours += fixesTotal.TotalHours;
        }

        return (
            <Fragment key={normCollectionKey}>
                <tr className="table-group-divider">
                    <th colSpan={6}>
                        <input type="hidden" name="CabinetModelId" value={normCollection.CabinetModel.Id} />
                        {normCollection.Id > 0 && <input type="hidden" name="Id" value={normCollection.Id} />}
                        {normCollection.CabinetModelSubType && <input type="hidden" name="CabinetSubModelId" value={normCollection.CabinetModelSubType.Id} />}
                        {normCollection.CabinetModelSubType ? "Szafa: " + normCollection.CabinetModelSubType.SubTypeName: ""}
                    </th>
                    <th>
                        {normCollection.Id > 0 && this.props.canEdit && <button className="btn btn-outline-danger btn-sm" type="button" disabled={this.state.isSaving} onClick={() => this.deleteNormCollection(normCollection.Id)}>Usuń normę</button>}
                    </th>
                </tr>
                {normCollection.CabinetWorkTimeNorms.map((cwtn, idx) =>
                    <CabinetWorkTimeNormRow
                        key={normCollectionKey + '_' + cwtn.WorkTimeNormType}
                        norm={cwtn}
                        cabinetCount={this.props.cabinetCount}
                        onConnectionCountChange={(newValue) => this.onConnectionCountChange(normCollection, cwtn, newValue)}
                        onTimePerCabinetChange={(newValue) => this.onTimePerCabinetChange(normCollection, cwtn, newValue)}
                        canEdit={this.props.canEdit}
                    />)}
                <tr>
                    <th colSpan={2}>
                        Suma
                    </th>
                    <th>{this.getSumTimeToAchievePerCabinet(normCollection).toFixed(2)}</th>
                    <th></th>
                    <th>{this.getSumTimeToAchieve(normCollection).toFixed(2)}</th>
                    <th>{sumActualHours.toFixed(2)}</th>
                    <th>{this.getSumDifference(normCollection).toFixed(2)}</th>
                </tr>
                <tr className="table-group-divider text-secondary">
                    <td colSpan={7}><small><i>Pozostałe typy pracy</i></small></td>
                </tr>
                {p0Total && <tr>
                    <td colSpan={5}>{workTypeText(p0Total.WorkType)}</td>
                    <td>{p0Total.TotalHours.toFixed(2)}</td>
                    <td></td>
                </tr>}
                {fixesTotal && <tr>
                    <td colSpan={5}>{workTypeText(fixesTotal.WorkType)}</td>
                    <td>{fixesTotal.TotalHours.toFixed(2)}</td>
                    <td></td>
                </tr>}
                <tr>
                    <th colSpan={5}>Suma <i>(+pozostałe typy pracy)</i></th>
                    <th>{sumTotalHours.toFixed(2)}</th>
                    <td></td>
                </tr>
            </Fragment>);
    }

    async fetchNormCollections(createModel?: boolean, copyFromModelName?: string): Promise<boolean> {

        this.setState({
            fetchStatus: FetchStatusEnum.Fetching
        });

        const data: Record<string, string> = {};

        data['ProjectNumber'] = this.props.projectNumber.toString();
        if (copyFromModelName) {
            data['CopyFromModelName'] = copyFromModelName;
        }
        if (createModel) {
            data['CreateModel'] = "true";
        }

        const params = new URLSearchParams(data);
        const queryString = '?' + params.toString();

        const response = await apiFetchResponse(`cabinet-norm/` + queryString);
        this.setState({
            fetchStatus: FetchStatusEnum.Fetched
        });
        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<CabinetNormCollectionsResponseDTO>;

            if (result.Success) {
                const response = result.Data!;

                const cabinetNormCollections = response.CabinetNormCollections || [];
                const normCollections = cabinetNormCollections.map(cnc => {
                    const cn: CabinetWorkTimeNormData[] = cnc.CabinetWorkTimeNorms
                        .map(cwtn => {
                            return {
                                WorkTimeNormType: cwtn.WorkTimeNormType,
                                ConnectionCount: cwtn.ConnectionCount,
                                TimeToAchivePerCabinet: cwtn.TimeToAchivePerCabinet,
                                ActualHours: cwtn.ActualHours,
                                TimeToAchivePerCabinetString: cwtn.TimeToAchivePerCabinet.toString(),
                                ConnectionCountString: cwtn.ConnectionCount.toString()
                            };
                        });

                    return {
                        Id: cnc.Id,
                        CabinetModel: cnc.CabinetModel,
                        CabinetModelSubType: cnc.CabinetModelSubType,
                        CabinetWorkTimeNorms: cn
                    };
                });

                this.setState({
                    normCollections,
                    cabinetWorktimeTotals: response.CabinetWorktimeTotals,
                    hasOwnNorms: response.HasOwnNorms,
                    hasModel: response.HasModel
                });

                return true;
            } else {
                this.showErrorMessage(result.Errors![0].Message);
            }
        }

        return false;
    }

    buildDataObjectFromFormData(formData: FormData): any[] {
        const dataObject: any[] = [];
        let currentObject: any = {};
        let currentNorm: any = {};

        formData.forEach((value, key) => {
            const stringValue = value as string;
            if (key == "CabinetModelId") {
                currentObject = {
                    CabinetWorkTimeNorms: []
                };
                dataObject.push(currentObject);

                currentObject[key] = Number.parseInt(stringValue);
            } else if (key == "Id" || key == "CabinetSubModelId") {
                currentObject[key] = Number.parseInt(stringValue);
            } else if (key == "WorkTimeNormType") {
                currentNorm = {};
                currentObject.CabinetWorkTimeNorms.push(currentNorm);

                currentNorm[key] = Number.parseInt(stringValue);
            } else {
                currentNorm[key] = stringValue
                    ? Number.parseFloat(stringValue)
                    : 0;
            }
        });

        return dataObject;
    }

    showErrorMessage = (text: string) => {
        this.setState({
            alertMessage: {
                text,
                className: "alert-danger",
                displayMs: 5000
            }
        });
    }

    showSuccessMessage = (text: string) => {
        this.setState({
            alertMessage: {
                text,
                className: "alert-success",
                displayMs: 3000
            }
        });
    }

    async saveCabinetNormCollections(data: any) {

        this.setState({
            isSaving: true
        });

        const response = await apiFetchResponse('cabinet-norm', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        this.setState({
            isSaving: false
        });

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<CabinetNormCollectionDTO[]>;

            if (result.Success) {
                this.showSuccessMessage("Zapisano normy dla modelu");
            } else {
                this.showErrorMessage(result.Errors![0].Message);
            }
        } else {
            this.showErrorMessage("Błąd zapisu normy (połączenie z serwerem)");
        }
    }

    onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const formData = new FormData(event.currentTarget);
        const data = this.buildDataObjectFromFormData(formData);

        this.saveCabinetNormCollections(data);
    };

    clearMessage = () => {
        this.setState({
            alertMessage: undefined
        });
    }

    onCopyFromModelNameChanged(selectedModel: Option[]) {

        this.setState({
            selectedModel
        });
    }

    async copyNormsFromModel() {

        if (this.state.selectedModel.length == 0) {
            return;
        }

        const modelObject = this.state.selectedModel[0] as CabinetModelBriefDTO;

        if (await this.fetchNormCollections(false, modelObject.ModelName)) {
            this.setState({
                selectedModel: []
            });
        }
    }

    isModelSelected() {
        return this.state.selectedModel.length > 0;
    }

    createModel() {
        this.fetchNormCollections(true);
    }

    render() {
        const modelName = this.state.normCollections.length > 0
            ? this.state.normCollections[0].CabinetModel.ModelName
            : '';

        return (
            <>
                <AlertComponent alertMessage={this.state.alertMessage} messageHidden={this.clearMessage} />
                <Row>
                    {(this.state.isSaving || this.state.fetchStatus == FetchStatusEnum.Fetching) &&
                        <div className='col-auto'><span className='spinner-border'></span></div>
                    }
                    {!this.state.hasModel && this.state.fetchStatus == FetchStatusEnum.Fetched &&
                        <Col md="auto">
                            <button className="btn btn-outline-secondary" type="button" onClick={this.createModel}>Stwórz model</button>
                        </Col>
                    }
                    {!this.state.hasOwnNorms && this.state.fetchStatus == FetchStatusEnum.Fetched && this.props.canEdit &&
                        <Col md={4}>
                            <div className="input-group">
                                <button className="btn btn-outline-secondary" disabled={!this.isModelSelected()} type="button" onClick={this.copyNormsFromModel}>Kopiuj normy z modelu...</button>
                                <AsyncTypeahead
                                    id="search-model-input"
                                    delay={500}
                                    isLoading={this.state.areModelsLoading}
                                    promptText="nazwa modelu"
                                    selected={this.state.selectedModel}
                                    onSearch={this.onSearchModelsWithNorms}
                                    options={this.state.modelsWithNorms}
                                    onChange={this.onCopyFromModelNameChanged}
                                    labelKey="ModelName"
                                />
                            </div>
                        </Col>
                    }
                </Row>
                {!this.state.hasModel && this.state.fetchStatus == FetchStatusEnum.Fetched &&
                    <p className="mb-0"><small>Projekt nie ma utworzonego modelu z normami. Należy utworzyć model lub skopiować normy z innego modelu.</small></p>}
                {this.state.hasModel && <form onSubmit={this.onSubmit}>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th colSpan={6}>
                                    Model: {modelName}
                                </th>
                            </tr>
                            <tr>
                                <th>Czasy na jedną szafę</th>
                                <th>Ilość połączeń</th>
                                <th>Czas do osiągnięcia<br />na 1 szafę [h]</th>
                                <th>Ilość szaf</th>
                                <th>Czas do osiągnięcia<br />na projekt</th>
                                <th>Osiągnięty czas<br />na projekt [h]</th>
                                <th>Różnica</th>
                            </tr>
                        </thead>
                        <tbody>
                            <>
                                {this.state.normCollections.map(this.renderCabinetModelReport)}
                                <tr className="table-group-divider">
                                    <th colSpan={2}>Suma modelu</th>
                                    <th>{this.getTotalTimeToAchievePerCabinet().toFixed(2)}</th>
                                    <th></th>
                                    <th>{this.getTotalTimeToAchieve().toFixed(2)}</th>
                                    <th>{this.getTotalActualHours().toFixed(2)}</th>
                                    <th>{this.getTotalDifference().toFixed(2)}</th>
                                </tr>
                                <tr>
                                    <th colSpan={5}>Suma modelu <i>(+pozostałe typy pracy)</i></th>
                                    <th>{this.getTotalActualHoursWithWorktimes().toFixed(2)}</th>
                                    <td></td>
                                </tr>
                            </>
                        </tbody>
                    </table>
                    {this.props.canEdit && <Row>
                        <Col md="auto">
                            <button className="btn btn-primary" type="submit" disabled={this.state.isSaving}>Zapisz normy modelu</button>
                        </Col>
                    </Row>}
                </form>}
            </>
        );
    }
}
