import ExcelJS from 'exceljs';
import { NumberDictionary } from '../types/global';
import { apiFetchData } from './auth-api';
import { OperationResult, OperationResultWithData } from './operation-result';

function determineIndexes(firstRow: ExcelJS.Row, result: OperationResult): NumberDictionary {
    const indexes = {} as NumberDictionary;

    const columnNames: string[] = [
        'Start',
        'Ende',
        'Kunde',
        'Bestellung',
        'Fauf',
        'Kurztext',
        'Stk.',
        'Spl.',
        'Bemerkungen',
        'Soll h',
        'Offen h',
        'QDEErf.',
        'Vg',
        'Team'
    ];

    var values = firstRow.values as ExcelJS.CellValue[];
    if (!values) {
        result.AddError("Brak wartości w pierwszym wierszu");
    }

    if (!result.IsSuccess()) {
        return indexes;
    }

    var rowColumnsNames = values.map(v => v as string);

    columnNames.forEach((columnName: string) => {
        const columnIndex = rowColumnsNames.findIndex(rcn => rcn == columnName);
        if (columnIndex < 0) {
            result.AddError(`Nie znaleziono w arkuszu Excela kolumny o nazwie ${columnName}`);
        } else {
            indexes[columnName] = columnIndex;
        }
    });

    if (!result.IsSuccess()) {
        return indexes;
    }

    return indexes;
}

type ImportProjectData = {
    StartDate: number,
    EndDate: number,
    ClientName: string,
    OrderNumber?: number,
    ProjectNumber?: number,
    ModelDescription: string,
    CabinetCount: number,
    SuggestedWorkersCount: number,
    Remarks: string,
    ScheduledTimeHours: number,
    ExtraUnscheduledTimeHours: number,
    WorkTypeIdentifier: string,
    Team: string
}

function getProjectDataFromRow(
    row: ExcelJS.Row,
    indexes: NumberDictionary,
    rowIndex: number,
    result: OperationResult
): ImportProjectData {

    var values = row.values as ExcelJS.CellValue[];

    console.log('Values from row', rowIndex, values);

    let StartDate: Date | undefined = values[indexes['Start']] as Date;
    if (!StartDate || !(StartDate instanceof Date)) {
        StartDate = undefined;
        result.AddError(`Błąd wyciągania wartości 'Start' z wiersza ${rowIndex}`);
    }

    let EndDate: Date | undefined = values[indexes['Ende']] as Date;
    if (!EndDate || !(EndDate instanceof Date)) {
        EndDate = undefined;
        result.AddError(`Błąd wyciągania wartości 'Ende' z wiersza ${rowIndex}`);
    }

    const ClientName = values[indexes['Kunde']] as string;
    if (!ClientName) {
        result.AddError(`Błąd wyciągania wartości 'Kunde' z wiersza ${rowIndex}`);
    }

    const OrderNumber = values[indexes['Bestellung']] as number;

    const ProjectNumber = values[indexes['Fauf']] as number;
    if (!ProjectNumber) {
        result.AddError(`Błąd wyciągania wartości 'Fauf' z wiersza ${rowIndex}`);
    }

    const ModelDescription = values[indexes['Kurztext']] as string;
    if (!ModelDescription) {
        result.AddError(`Błąd wyciągania wartości 'Kurztext' z wiersza ${rowIndex}`);
    }

    const CabinetCount = values[indexes['Stk.']] as number;
    if (!CabinetCount) {
        result.AddError(`Błąd wyciągania wartości 'Stk.' z wiersza ${rowIndex}`);
    }

    const SuggestedWorkersCount = values[indexes['Spl.']] as number;
    if (!SuggestedWorkersCount) {
        result.AddError(`Błąd wyciągania wartości 'Spl.' z wiersza ${rowIndex}`);
    }

    // Remarks can be empty
    const Remarks = values[indexes['Bemerkungen']] as string;

    const ScheduledTimeHours = values[indexes['Soll h']] as number;
    if (!ScheduledTimeHours) {
        result.AddError(`Błąd wyciągania wartości 'Soll h' z wiersza ${rowIndex}`);
    }

    const ExtraUnscheduledTimeHours = values[indexes['QDEErf.']] as number;
    const WorkTypeIdentifier = values[indexes['Vg']] as string;
    if (!WorkTypeIdentifier) {
        result.AddError(`Błąd wyciągania wartości 'Vg' z wiersza ${rowIndex}`);
    }

    const Team = values[indexes['Team']] as string;
    if (!Team) {
        result.AddError(`Błąd wyciągania wartości 'Team' z wiersza ${rowIndex}`);
    }

    return {
        StartDate: StartDate?.getTime() || 0,
        EndDate: EndDate?.getTime() || 0,
        ClientName,
        OrderNumber,
        ProjectNumber,
        ModelDescription,
        CabinetCount,
        SuggestedWorkersCount,
        Remarks,
        ScheduledTimeHours,
        ExtraUnscheduledTimeHours,
        WorkTypeIdentifier,
        Team
    };
}

export type ImportResult = {
    ProjectsToImportCount: number,
    CreatedProjectNumbers: number[],
    CreatedClientNames: string[],
    RecordsModified: number
};

export async function importExcelProjectFile(file: File): Promise<OperationResultWithData<ImportResult>> {

    console.log('importFile', file);

    const result = new OperationResultWithData<ImportResult>();

    try {
        const workbook = new ExcelJS.Workbook();
        const arrayBuffer = await file.arrayBuffer();

        await workbook.xlsx.load(arrayBuffer);

        console.log('File loaded');

        const sheet = workbook.worksheets[0];
        const firstRow = sheet.getRow(1);
        const indexes = determineIndexes(firstRow, result);

        if (!result.IsSuccess()) {
            return result;
        }

        const importData: ImportProjectData[] = [];

        for (let ri = 2; ri <= sheet.rowCount; ri++) {
            const row = sheet.getRow(ri);
            const data = getProjectDataFromRow(row, indexes, ri, result);

            if (!result.IsSuccess()) {
                return result;
            }

            console.log('Row data', ri, data, result);

            if (data) {
                importData.push(data);
            }
        }

        const response = await apiFetchData('project/import', {
            body: JSON.stringify({
                Projects: importData
            }),
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST'
        });

        console.log('Project import result', response);

        result.Data = response as ImportResult;
    } catch (ex: any) {
        console.error('Wyjątek podczas importu pliku', ex);

        result.AddError(`Wyjątek podczas importu pliku ${ex}`);
    }

    return result;
}
