import * as React from 'react';
import { AnyDictionary, StringDictionary } from '../../types/global';

export type ColumnOptions = {
    precision?: number;         // If value is float this is a precission
    boolTrueText?: string;      // If value is boolean type this is text for true value
    boolFalseText?: string;     // If value is boolean type this is text for false value
    valueRenderer?: (value: unknown) => JSX.Element | string;
    columnRenderer?: (row: unknown) => JSX.Element | string | undefined;
    enableFilter?: boolean,
    filterRenderer?: (currentFilterValue: any) => JSX.Element;
    filterValueGetter?: (value: unknown) => any;
};

export interface ColumnOptionsCollection {
    [key:string] : ColumnOptions
}

const defaultPropsValues = {
    idKey: 'id' as string,
    renderActions: undefined as ((row: any) => JSX.Element | null) | undefined,
    renderNoData: undefined as (() => JSX.Element) | undefined,
    disableSorting: false as boolean,
    renderSummaryRow: undefined as ((rows: any[] | undefined) => JSX.Element | null) | undefined
};

type DefaultProps = Readonly<typeof defaultPropsValues>;

type SortableTableProps = {
    dataRows : any[] | undefined,
    columns: StringDictionary,
    columnOptions?: ColumnOptionsCollection,
    rowClassesFunc?(data: any): string,
    isSmall?: boolean,
    isVisible?: boolean
} & Partial<DefaultProps>;

type SortableTableState = {
    sortParameter: string,
    sortOrder: boolean,      // false - ascending, true - descending
    filterValues: AnyDictionary
}

export default class SortableTable extends React.PureComponent<SortableTableProps, SortableTableState>{
    static defaultProps = defaultPropsValues;

    constructor(props: SortableTableProps) {
        super(props);

        this.state = {
            sortParameter: '',
            sortOrder: false,
            filterValues: {}
        }

        this.header = this.header.bind(this);
        this.getFilterText = this.getFilterText.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.getTableClass = this.getTableClass.bind(this);
    }

    public setFilterValue(key: string, value: any) {
        const filterValues = Object.assign({}, this.state.filterValues);
        filterValues[key] = value;

        this.setState({
            filterValues
        });
    }

    public render()
    {
        if (this.props.isVisible === false) {
            return null;
        }

        if (!this.props.dataRows || this.props.dataRows.length === 0) {
            return this.props.renderNoData ?
                this.props.renderNoData() :
                null;
        }

        var columnKeys = Object.keys(this.props.columns);
        const noClassesFunc = (data: any) => "";
        const rowClassFunc = this.props.rowClassesFunc || noClassesFunc;

        return(
            <table className={this.getTableClass()}>
                <thead>
                    <tr>
                        {columnKeys.map(this.header)}
                    </tr>
                </thead>
                <tbody>
                    {this.processedRows()!.map((row: any) => {
                        const id = row[this.props.idKey || 'id'];
                        return (
                            <tr key={id} className={rowClassFunc(row)}>
                                {columnKeys.map((key) => {
                                    if (key === 'actions') {
                                        return (
                                            <td key={id+key} className="actions">
                                                {this.props.renderActions && this.props.renderActions(row)}
                                            </td>
                                        );
                                    } else {
                                        return (<td key={id+key}>{this.returnRenderValue(row, key)}</td>);
                                    }
                                })}
                            </tr>
                        );
                    })}
                </tbody>
                {this.props.renderSummaryRow
                    && <tfoot className="table-group-divider">
                        {this.props.renderSummaryRow(this.processedRows())}
                    </tfoot>
                }
            </table>
        );
    }

    private getTableClass() {
        return this.props.isSmall
            ? 'table table-striped table-sm'
            : 'table table-striped';
    }

    private sortClass(paramName: string)
    {
        const classes : string[] = ['sortable'];

        if (this.state.sortParameter === paramName) {
            classes.push(this.state.sortOrder ? 'asc' : 'desc');
        }
        return classes.join(' ');
    }

    private sortBy(paramName: string)
    {
        if (this.state.sortParameter === paramName)
        {
            this.setState({
                sortOrder: !this.state.sortOrder
            });
        } else {
            this.setState({
                sortOrder: false,
                sortParameter: paramName
            });
        }
    }

    private tryExtractSubProp(inputInstance: any, key: string) {
        const dotIndex = key.indexOf('.');
        if (dotIndex <= 0) {
            return null;
        }

        const objectKey = key.substring(0, dotIndex);
        const resultInstance = inputInstance && inputInstance[objectKey];

        return {
            resultInstance,
            key: key.substring(dotIndex + 1)
        };
    }

    private returnRenderValue(row: any, key: string): any {

        const valueOptions = this.props.columnOptions && this.props.columnOptions![key];
        if (valueOptions && valueOptions.columnRenderer) {
            return valueOptions.columnRenderer(row);
        }

        let value: any = null;

        // Handling value of subproperties
        const subPropData = this.tryExtractSubProp(row, key);
        if (subPropData) {
            if (subPropData.resultInstance) {
                value = this.returnRenderValue(
                    subPropData.resultInstance,
                    subPropData.key
                );
            } else {
                return '';
            }
        }

        value = value || row[key];

        if (valueOptions && valueOptions.valueRenderer) {
            return valueOptions.valueRenderer(value);
        }

        if (!subPropData && key.endsWith('Date')) {
            return row[key + 'Text'] || row[key + 'TimeText'] || '';
        }

        const valueType = typeof value;

        if (valueType === 'boolean') {
            if (valueOptions) {
                if (valueOptions!.boolTrueText && value) {
                    return valueOptions!.boolTrueText;
                }
                if (valueOptions!.boolFalseText && !value) {
                    return valueOptions!.boolFalseText;
                }
            }
            return row[key] ? (<span className="fa fa-check"></span>) : null;
        }

        if (valueType === 'number') {
            if (valueOptions) {
                if (valueOptions!.precision) {
                    return value.toFixed(valueOptions!.precision);
                }
            }
        }

        return value;
    }

    private compare(a: any, b : any) : number
    {
        return this.compareByPropName(a, b, this.state.sortParameter);
    }

    private compareByPropName(a: any, b: any, name: string): number {
        const subPropDataA = this.tryExtractSubProp(a, name);
        if (subPropDataA) {
            const subPropDataB = this.tryExtractSubProp(b, name);
            return this.compareByPropName(
                subPropDataA.resultInstance,
                subPropDataB?.resultInstance,
                subPropDataA.key);
        }

        if (a == null || a == undefined) {
            if (b == null || b == undefined) {
                return 0;
            }
            return this.state.sortOrder ? -1 : 1;
        } else {
            if (b == null || b == undefined) {
                return this.state.sortOrder ? 1 : -1;
            }
        }

        if (a[name] === b[name]) return 0;
        if (a[name] < b[name]) {
            return this.state.sortOrder ? 1 : -1;
        } else {
            return this.state.sortOrder ? -1 : 1;
        }
    }

    private getPropValue(dataRow: any, key: string): any {
        if (!dataRow) {
            return null;
        }

        const subPropData = this.tryExtractSubProp(dataRow, key);
        if (subPropData) {
            return this.getPropValue(subPropData.resultInstance, subPropData.key);
        }

        const columnOptions = this.props.columnOptions && this.props.columnOptions![key];
        if (columnOptions && columnOptions.filterValueGetter) {
            return columnOptions.filterValueGetter(dataRow[key]);
        }

        return dataRow[key];
    }

    private filterDataRow(dataRow: any): boolean {
        let passRow = true;

        Object.keys(this.state.filterValues).forEach((key) => {
            const filterValueNormal = this.state.filterValues[key];
            if (filterValueNormal !== undefined) {
                let dataValue = this.getPropValue(dataRow, key);

                if (dataValue !== undefined && dataValue !== null) {
                    if (typeof filterValueNormal == 'string') {
                        const filterValue = filterValueNormal.toLowerCase();
                        if (!dataValue.toString().toLowerCase().includes(filterValue)) {
                            passRow = false;
                        }
                    } else {
                        if (dataValue !== filterValueNormal) {
                            passRow = false;
                        }
                    }
                } else {
                    passRow = false;
                }
            }
        });

        return passRow;
    }

    private processedRows() : any[] | undefined
    {
        if (!this.props.dataRows) {
            return undefined;
        }

        const filtered = this.props.dataRows.filter(this.filterDataRow.bind(this));

        if (this.state.sortParameter === '') {
            return filtered;
        }

        return filtered.sort(this.compare.bind(this));
    }

    private getFilterText(key: string) {
        return this.state.filterValues[key] || '';
    }

    private onFilterChange(event: React.ChangeEvent<HTMLInputElement>, key: string) {
        this.setFilterValue(key, event.target.value);
    }

    private header(key: string) {

        const columnOptions = this.props.columnOptions && this.props.columnOptions![key];
        const isSortable = !(key === 'actions' || this.props.disableSorting);
        const onClickEvent = isSortable
            ? () => this.sortBy(key)
            : undefined;
        const labelClass = isSortable
            ? this.sortClass(key)
            : '';
        const labelHtml = (<div onClick={onClickEvent} className={labelClass}>{this.props.columns[key]}</div>);
        const isFilteringEnabled = (columnOptions && columnOptions?.enableFilter) || false;
        let filterHtml: React.ReactElement | undefined = undefined;
        if (isFilteringEnabled) {
            filterHtml = (<div className="input-group">
                <input type="text" className="form-control form-control-sm" value={this.getFilterText(key)} onChange={(event) => this.onFilterChange(event, key)} />
                <button className="inner-btn" onClick={() => this.setFilterValue(key, undefined)}><i className="fa-solid fa-times-circle" /></button>
            </div>);
        } else if (columnOptions && columnOptions.filterRenderer) {
            const filterValue = this.state.filterValues[key];
            filterHtml = columnOptions.filterRenderer(filterValue);
        }
        
        const innerHtml = (<>{labelHtml}{filterHtml}</>);

        return (<th key={'th_' + key} style={{ verticalAlign: "top" }} >{innerHtml}</th>);
    }
}