import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { pelikanPromptService } from '../utilities/pelikan-prompt-service';

type PelikanPromptProps = {
};

type PelikanPromptState = {
    hasData: boolean,
    assignedCount: number,
    unreadCount: number
};

export class PelikanPrompt extends Component<PelikanPromptProps, PelikanPromptState> {
    static displayName = PelikanPrompt.name;

    _subscription: number = 0;

    constructor(props: PelikanPromptProps) {
        super(props);

        this.state = {
            hasData: pelikanPromptService.hasData(),
            assignedCount: pelikanPromptService.assignedCount(),
            unreadCount: pelikanPromptService.unreadCount()
        };

        this.dataChanged = this.dataChanged.bind(this);
    }

    dataChanged(assignedCount: number, unreadCount: number) {

        this.setState({
            hasData: true,
            assignedCount: assignedCount,
            unreadCount: unreadCount
        });
    }

    componentDidMount() {
        this._subscription = pelikanPromptService.subscribe(this.dataChanged);

        pelikanPromptService.fetchNewData();
    }

    componentWillUnmount() {
        pelikanPromptService.unsubscribe(this._subscription);
    }

    render() {
        if (this.state.hasData) {
            return (
                <Fragment>
                    {this.state.assignedCount > 0 && <Link to="/pelikans?type=assigned"><span className="badge bg-info" title="Przypisanych"><i className="fa-solid fa-book-reader" /> {this.state.assignedCount}</span></Link>}&nbsp;
                    {this.state.unreadCount > 0 && <Link to="/pelikans?type=unread"><span className="badge bg-danger" title="Nieprzeczytanych"><i className="fa-solid fa-book-open" /> {this.state.unreadCount}</span></Link>}
                </Fragment>
            );
        }

        return null;
    }
}
