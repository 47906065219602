import { useState } from "react";
import { allDepartmentIds, KnownDepartmentsEnum } from "../types/domain";
import { DepartmentName } from "../utilities/domain-utils";

type DepartmentPickerProps = {
    onDepartmentSelected: (departmentId?: KnownDepartmentsEnum) => void
};

export function DepartmentPicker(props: DepartmentPickerProps) {

    const [departmentId, setDepartmentId] = useState<KnownDepartmentsEnum>();

    const onSetDepartmentId = function (departmentId?: KnownDepartmentsEnum) {
        props.onDepartmentSelected(departmentId);
        setDepartmentId(departmentId);
    }

    const onDepartmentSelected = function (event: React.ChangeEvent<HTMLSelectElement>) {
        const id = parseInt(event.target.value);
        const departmentId: KnownDepartmentsEnum | undefined = !!id
            ? id as KnownDepartmentsEnum : undefined;

        onSetDepartmentId(departmentId);
    }

    return <div className='input-group'>
        <div className="form-floating">
            <select className="form-select form-select-sm" id="department-id-select" onChange={onDepartmentSelected} value={departmentId || 0}>
                <option value="0" disabled={true}>Nie wybrano</option>
                {allDepartmentIds.map(depId => <option key={depId} value={depId}>{DepartmentName(depId)}</option>)}
            </select>
            <label htmlFor="department-id-select">Dział</label>
        </div>
        <button className="btn btn-outline-secondary" type="button" onClick={() => onSetDepartmentId(undefined)}><i className='fa-solid fa-times' /></button>
    </div>;
}