import { EmployeeBriefDTO, OperationResultWithDataDTO, TesterNoteDTO, WorktimeOptionsDTO } from '../types/dto';
import { apiFetchResponse } from './auth-api';
import { addNotSelectedEmployee } from './domain-utils';

export async function fetchEmployeesToSelect(addNotSelectedEntry: boolean): Promise<EmployeeBriefDTO[]> {

    var response = await apiFetchResponse('employee/brief');

    if (response.status == 200) {
        var result = await response.json() as OperationResultWithDataDTO<EmployeeBriefDTO[]>;

        const employeesToSelect = result.Data || [];
        if (addNotSelectedEntry) {
            addNotSelectedEmployee(employeesToSelect);
        }

        return employeesToSelect;
    } else {
        console.log('Problem fetching employees to select', response);
        return [];
    }
}

export async function fetchTesterNotesForWorktime(worktimeId: number): Promise<TesterNoteDTO[]> {
    const response = await apiFetchResponse(`worktime/${worktimeId}/tester-note`);

    if (response.status == 200) {
        const result = await response.json() as OperationResultWithDataDTO<TesterNoteDTO[]>;

        if (result.Success) {
            return result.Data || [];
        }
        else {
            console.log('Error fetching tester notes', result);
        }
    }
    else {
        console.log('Error fetching tester notes', response);
    }

    return [];
}

export async function fetchTesterNotes(projectNumber: number): Promise<TesterNoteDTO[]> {
    const response = await apiFetchResponse(`project/${projectNumber}/tester-note`);

    if (response.status == 200) {
        const result = await response.json() as OperationResultWithDataDTO<TesterNoteDTO[]>;

        if (result.Success) {
            return result.Data || [];
        }
        else {
            console.log('Error fetching tester notes', result);
        }
    }
    else {
        console.log('Error fetching tester notes', response);
    }

    return [];
}

export async function fetchWorktimeOptions(
    projectNumber?: number,
    projectId?: number,
    worktimeId?: number
): Promise<WorktimeOptionsDTO|undefined> {
    var data: Record<string, string> = {};
    if (projectNumber) {
        data['ProjectNumber'] = projectNumber.toString();
    }
    if (projectId) {
        data['ProjectId'] = projectId.toString();
    }
    if (worktimeId) {
        data['WorktimeId'] = worktimeId.toString();
    }

    const params = new URLSearchParams(data);
    const queryString = '?' + params.toString();

    const response = await apiFetchResponse(`worktime/options${queryString}`);
    if (response.status == 200) {
        const data = await response.json() as OperationResultWithDataDTO<WorktimeOptionsDTO>;

        if (data.Success) {
            return data.Data;
        }
    }

    return undefined;
}

export enum FetchStatusEnum {
    Unitialised = 0,
    Fetching,
    Fetched
};
