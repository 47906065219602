import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { KnownDepartmentsEnum, KnownRolesEnum, PelikanAssignmentData, PelikanCategoryEnum, PelikanStatusEnum } from '../types/domain';
import { BriefBAMProjectDTO, DetailedPelikanDTO, EmployeeBriefDTO, OperationResultWithDataDTO, PelikanDTO, PelikanRemarkDTO, QDETicketDTO, ValidationErrorsResponse } from '../types/dto';
import { CreatePelikanData, UpdatePelikanData, ValidationErrors } from '../types/forms';
import { apiFetchResponse } from '../utilities/auth-api';
import { CalculateDates, EnhanceDetailedPelikan } from '../utilities/data-enhancer';
import { fetchEmployeesToSelect } from '../utilities/data-fetch';
import { DepartmentName, doerName, getPelikanCategoriesToSelect, PelikanCategoryName } from '../utilities/domain-utils';
import { FormValidationUtility } from './common/FormValidationUtility';
import { PelikanLog } from './PelikanLog';
import { PelikanRemarks } from './PelikanRemarks';
import { Nav, Navbar, NavItem, NavLink, NavbarBrand, TabContent, TabPane } from 'reactstrap';
import { PelikanFormUtilities } from '../utilities/pelikan-form-utils';
import { PelikanStatusIndicator } from './PelikanStatusIndicator';
import { pelikanPromptService } from '../utilities/pelikan-prompt-service';
import { ProjectLink } from './common/ProjectLink';
import { QDEInput } from './common/QDEInput';
import { PelikanAssignmentSelect } from './common/PelikanAssignmentSelect';
import { PelikanStatusInput } from './common/PelikanStatusInput';
import authService from './api-authorization/AuthorizeService';
import { AllowsFeature, Features } from '../utilities/features';
import { AlertComponent, AlertMessage } from './common/AlertComponent';

type PelikanPageComponentProps = {
    pelikanId: number,
    projectNumber?: number,
    departmentId?: KnownDepartmentsEnum,
    project?: BriefBAMProjectDTO,
    onClosePage?: () => void,
    onSaved?: () => void,
    employeesToSelect?: EmployeeBriefDTO[],
    isVisible?: boolean
};

type PelikanPageComponentState = UpdatePelikanData & {
    isLoading: boolean,
    fetchPelikanId?: number,
    pelikan?: DetailedPelikanDTO,
    fvu: FormValidationUtility,
    employeesToSelect: EmployeeBriefDTO[],
    activeTabIndex: number,
    hasResolvePelikanFeature: boolean,
    canResolvePelikan: boolean,
    canInstallPelikan: boolean,
    canManageStatus: boolean,
    canManageQDE: boolean,
    alertMessage?: AlertMessage
};

export class PelikanPageComponent extends Component<PelikanPageComponentProps, PelikanPageComponentState> {
    static displayName = PelikanPageComponent.name;

    pelikanFormUtilities: PelikanFormUtilities = new PelikanFormUtilities();

    constructor(props: PelikanPageComponentProps) {
        super(props);

        const userProfile = authService.getUserProfileSync();
        const hasResolvePelikanFeature = AllowsFeature(Features.ResolvePelikan, userProfile);

        this.state = {
            isLoading: false,
            fvu: new FormValidationUtility(),
            employeesToSelect: [],
            activeTabIndex: 0,
            hasResolvePelikanFeature,
            canResolvePelikan: hasResolvePelikanFeature,
            canInstallPelikan: AllowsFeature(Features.InstallPelikan, userProfile),
            canManageStatus: AllowsFeature(Features.ManagePelikanStatus, userProfile),
            canManageQDE: AllowsFeature(Features.QDEManagement, userProfile)
        };

        this.fetchPelikanData = this.fetchPelikanData.bind(this);
        this.onDescriptionChanged = this.onDescriptionChanged.bind(this);
        this.onSolutionChanged = this.onSolutionChanged.bind(this);
        this.onPelikanRemarkSaved = this.onPelikanRemarkSaved.bind(this);
        this.onPelikanStatusChange = this.onPelikanStatusChange.bind(this);
        this.onCategorySelected = this.onCategorySelected.bind(this);
        this.fetchEmployeesToSelect = this.fetchEmployeesToSelect.bind(this);
        this.onAssignedSelected = this.onAssignedSelected.bind(this);
        this.onNeedQDEChange = this.onNeedQDEChange.bind(this);
        this.setActiveTab = this.setActiveTab.bind(this);
        this.savePelikan = this.savePelikan.bind(this);
        this.savePelikan = this.savePelikan.bind(this);
        this.canMarkAsRead = this.canMarkAsRead.bind(this);
        this.markAsRead = this.markAsRead.bind(this);
        this.onQDEChange = this.onQDEChange.bind(this);
        this.onCanMonterResolveChange = this.onCanMonterResolveChange.bind(this);
        this.canRemovePelikan = this.canRemovePelikan.bind(this);
        this.removePelikan = this.removePelikan.bind(this);
        this.setInitialPelikanData = this.setInitialPelikanData.bind(this);
        this.getCreateData = this.getCreateData.bind(this);
        this.getUpdateData = this.getUpdateData.bind(this);
        this.editedPelikanId = this.editedPelikanId.bind(this);
        this.canGenerateQDETicket = this.canGenerateQDETicket.bind(this);
        this.generateQDETicket = this.generateQDETicket.bind(this);
        this.setupComponent = this.setupComponent.bind(this);
    }

    componentDidMount() {
        if (this.props.isVisible || this.props.isVisible===undefined) {
            this.setupComponent();
        }
    }

    componentDidUpdate(prevProps: PelikanPageComponentProps) {
        if ((this.props.isVisible || this.props.isVisible === undefined)
            && prevProps.isVisible === false) {
            this.setupComponent();
        }
    }

    setupComponent() {
        if (this.props.employeesToSelect) {
            this.setState({
                employeesToSelect: this.props.employeesToSelect
            });
        } else if (this.state.employeesToSelect.length == 0) {
            this.fetchEmployeesToSelect();
        }
        if (this.props.pelikanId > 0 && !this.state.pelikan) {
            this.fetchPelikanData(this.props.pelikanId);
        } else if (this.props.pelikanId == 0) {
            this.setInitialPelikanData();
        }
    }

    setInitialPelikanData() {
        this.setState({
            pelikan: undefined,
            status: PelikanStatusEnum.Open,
            category: PelikanCategoryEnum.Problem,
            assignedRole: undefined,
            assignedEmployeeId: undefined,
            assignedToWorkers: undefined,
            needQDE: false,
            description: '',
            solution: '',
            canMonterResolve: false
        });
    }

    async fetchPelikanData(pelikanId: number) {

        this.setState({
            isLoading: true
        });

        const response = await apiFetchResponse(`pelikan/${pelikanId}`);

        this.setState({
            isLoading: false
        });

        if (response.status == 200) {
            var result = await response.json() as OperationResultWithDataDTO<DetailedPelikanDTO>;

            if (result.Success) {
                const pelikan = result.Data;

                if (pelikan) {
                    CalculateDates(pelikan);
                    EnhanceDetailedPelikan(pelikan);

                    this.setState({
                        pelikan,
                        status: pelikan.Status,
                        category: pelikan.Category,
                        assignedRole: pelikan.AssignedRole,
                        assignedEmployeeId: pelikan.AssignedEmployee?.Id,
                        assignedToWorkers: pelikan.AssignedToWorkers,
                        needQDE: pelikan.NeedQDE,
                        QDEMinutes: pelikan.QDEMinutes,
                        description: pelikan.Description,
                        solution: pelikan.Solution,
                        canMonterResolve: pelikan.CanMonterResolve,
                        canResolvePelikan: this.state.hasResolvePelikanFeature || pelikan.CanMonterResolve
                    });
                } else {
                    // TODO throw
                }
            }
        } else {
            // TODO - Handle unlikely errors
        }
    }

    async fetchEmployeesToSelect() {

        const employeesToSelect = await fetchEmployeesToSelect(false);

        this.setState({
            employeesToSelect
        });
    }

    onDescriptionChanged(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            description: event.target.value
        });
    }

    onSolutionChanged(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            solution: event.target.value
        });
    }

    onPelikanRemarkSaved(pelikanRemark: PelikanRemarkDTO) {
        this.fetchPelikanData(this.props.pelikanId);
    }

    onPelikanStatusChange(status?: PelikanStatusEnum) {
        this.setState({
            status
        });
    }

    getCategoryOption(category: PelikanCategoryEnum) {
        return <option key={category} value={category}>{PelikanCategoryName(category)}</option>;
    }

    onCategorySelected(event: React.ChangeEvent<HTMLSelectElement>) {
        const category: PelikanCategoryEnum = parseInt(event.currentTarget.value);
        const assignedRole = (category == PelikanCategoryEnum.StickerNotesLackings
            || category == PelikanCategoryEnum.MaterialLackings)
            && this.state.assignedRole
            ? KnownRolesEnum.Office : this.state.assignedRole;
        const assignedEmployeeId = !!assignedRole
            ? undefined : this.state.assignedEmployeeId;
        const assignedToWorkers = !!assignedRole
            ? undefined : this.state.assignedToWorkers

        const newState = {
            category,
            assignedRole,
            assignedEmployeeId,
            assignedToWorkers
        };

        this.setState(newState);
    }

    rolesToSelect(): KnownRolesEnum[] {
        if (this.state.category == PelikanCategoryEnum.StickerNotesLackings
            || this.state.category == PelikanCategoryEnum.MaterialLackings) {

            return [
                KnownRolesEnum.Office
            ];
        };

        return [
            KnownRolesEnum.WarehouseKeeper,
            KnownRolesEnum.Monter,
            KnownRolesEnum.Tester,
            KnownRolesEnum.Office,
            KnownRolesEnum.Manager,
            KnownRolesEnum.TeamLider,
            KnownRolesEnum.Apprentice,
            KnownRolesEnum.WarehouseForeman
        ];
    }

    onAssignedSelected(assignment?: PelikanAssignmentData) {
        if (assignment) {
            this.setState(assignment);
        }
    }

    onNeedQDEChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            needQDE: event.target.checked
        });
    }

    setActiveTab(activeTabIndex: number) {
        this.setState({
            activeTabIndex
        })
    }

    editedPelikanId() {
        return this.state.pelikan?.Id || this.props.pelikanId;
    }

    dataChanged() {
        if (!this.editedPelikanId()) {
            return true;
        }

        const pelikan = this.state.pelikan;

        if (!pelikan) {
            return false;
        }

        if (this.state.status && this.state.status !== pelikan.Status) {
            return true;
        }
        if (this.state.category && this.state.category !== pelikan.Category) {
            return true;
        }
        if (this.state.assignedRole != pelikan.AssignedRole) {
            return true;
        }
        if (this.state.assignedEmployeeId != pelikan.AssignedEmployee?.Id) {
            return true;
        }
        if (this.state.assignedToWorkers != pelikan.AssignedToWorkers) {
            return true;
        }
        if (this.state.needQDE !== undefined && this.state.needQDE !== pelikan.NeedQDE) {
            return true;
        }
        if ((this.state.QDEMinutes || pelikan.QDEMinutes) && this.state.QDEMinutes != pelikan.QDEMinutes) {
            return true;
        }
        if (this.state.description !== undefined && this.state.description != pelikan.Description) {
            return true;
        }
        if (this.state.solution !== undefined && this.state.solution != pelikan.Solution) {
            return true;
        }

        return false;
    }

    canMarkAsRead() {
        const pelikan = this.state.pelikan;

        return !!pelikan
            && pelikan.IsAssignedToMe
            && !pelikan.ReadConfirmation
            && !this.dataChanged();
    }

    async markAsRead() {
        const pelikanId = this.state.pelikan!.Id;
        const response = await apiFetchResponse(`pelikan/${pelikanId}/read-confirmation`, {
            method: 'POST'
        });

        if (response.status == 200) {
            await this.fetchPelikanData(pelikanId);
            await pelikanPromptService.fetchNewData();
        }
    }

    getUpdateData(): UpdatePelikanData {
        const pelikan = this.state.pelikan;

        const data: UpdatePelikanData = {
        };

        if (!pelikan) {
            return data;
        }

        if (this.state.status && this.state.status !== pelikan.Status) {
            data.status = this.state.status;
        }
        if (this.state.category && this.state.category !== pelikan.Category) {
            data.category = this.state.category;
        }
        if (this.state.assignedRole != pelikan.AssignedRole) {
            data.assignedRole = this.state.assignedRole;
        }
        if (this.state.assignedEmployeeId != pelikan.AssignedEmployee?.Id) {
            data.assignedEmployeeId = this.state.assignedEmployeeId;
        }
        if (this.state.assignedToWorkers != pelikan.AssignedToWorkers) {
            data.assignedToWorkers = this.state.assignedToWorkers;
        }
        if (this.state.needQDE !== undefined && this.state.needQDE !== pelikan.NeedQDE) {
            data.needQDE = this.state.needQDE;
        }

        // QDE need to be sent always
        data.QDEMinutes = this.state.QDEMinutes;

        if (this.state.description !== undefined && this.state.description != pelikan.Description) {
            data.description = this.state.description;
        }
        if (this.state.solution !== undefined && this.state.solution != pelikan.Solution) {
            data.solution = this.state.solution;
        }
        if (this.state.canMonterResolve !== undefined && this.state.canMonterResolve != pelikan.CanMonterResolve) {
            data.canMonterResolve = this.state.canMonterResolve;
        }

        return data;
    }

    getCreateData(): CreatePelikanData {
        return {
            projectNumber: this.props.projectNumber!,
            departmentId: this.props.departmentId,
            category: this.state.category!,
            assignedRole: this.state.assignedRole,
            assignedEmployeeId: this.state.assignedEmployeeId,
            assignedToWorkers: this.state.assignedToWorkers,
            needQDE: this.state.needQDE!,
            QDEMinutes: this.state.QDEMinutes,
            description: this.state.description!,
            canMonterResolve: this.state.canMonterResolve!
        }
    }

    async savePelikan() {

        const id = this.editedPelikanId();
        const isCreating = !id;

        let data: any;
        let url: string = '';
        let method: string = '';

        if (isCreating) {
            data = this.getCreateData();
            const validationErrors = this.pelikanFormUtilities.validateNewPelikanData(data);

            this.setState({
                fvu: new FormValidationUtility(validationErrors)
            });

            if (!validationErrors.Success) {
                return;
            }
            url = 'pelikan';
            method = 'POST';
        } else {
            const pelikan = this.state.pelikan;

            if (!pelikan) {
                return;
            }

            data = this.getUpdateData();

            const validationErrors = this.pelikanFormUtilities.validateUpdatePelikanData(data, pelikan);
            this.setState({
                fvu: new FormValidationUtility(validationErrors)
            });

            if (!validationErrors.Success) {
                return;
            }
            url = `pelikan/${pelikan.Id}`;
            method = 'PATCH';
        }

        const response = await apiFetchResponse(url, {
            body: JSON.stringify(data),
            method,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<PelikanDTO>;

            if (result.Success) {
                this.setState({
                    fvu: new FormValidationUtility()
                });

                await this.fetchPelikanData(result.Data!.Id);
                await pelikanPromptService.fetchNewData();

                if (this.props.onSaved) {
                    this.props.onSaved();
                }

            } else {
                const validator = new ValidationErrors();
                if (result.Errors) {
                    validator.SetFromOperationErrors(result.Errors);
                    this.setState({
                        fvu: new FormValidationUtility(validator)
                    });
                }
            }

        } else if (response.status == 400) {
            // handle validation errors
            const validationErrors = await response.json() as ValidationErrorsResponse;
            const validator = new ValidationErrors();

            validator.SetFromValidationErrorsResponse(validationErrors);

            this.setState({
                fvu: new FormValidationUtility(validator)
            });
        }
    }

    onQDEChange(QDEMinutes?: number) {
        this.setState({
            QDEMinutes
        });
    }

    onCanMonterResolveChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            canMonterResolve: event.target.checked
        });
    }

    async removePelikan() {
        const pelikan = this.state.pelikan;
        if (!pelikan) {
            return;
        }

        const response = await apiFetchResponse(`pelikan/${pelikan.Id}`,
            {
                method: 'DELETE'
            });
        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<PelikanDTO>;
            if (result.Success) {
                await this.fetchPelikanData(pelikan.Id);
                await pelikanPromptService.fetchNewData();

                if (this.props.onSaved) {
                    this.props.onSaved();
                }
            }
        }
    }

    canRemovePelikan() {
        return !!this.state.pelikan
            && this.state.canManageStatus
            && this.state.pelikan.Status !== PelikanStatusEnum.Removed;
    }

    canGenerateQDETicket(): boolean {
        const pelikan = this.state.pelikan;

        return !!pelikan && this.state.canManageQDE
            && pelikan.NeedQDE
            && !pelikan.HasQDETicket;
    }

    async generateQDETicket() {
        const pelikanId = this.state.pelikan!.Id;

        const data = {
            PelikanId: pelikanId
        };

        const response = await apiFetchResponse(`qde-ticket`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<QDETicketDTO>;
            if (result.Success) {
                await this.fetchPelikanData(pelikanId);

                this.setState({
                    alertMessage: {
                        text: "Wygenerowano zgłoszenie QDE",
                        className: "alert-success",
                        displayMs: 3000
                    }
                });
                return;
            }
        }

        this.setState({
            alertMessage: {
                text: "Błąd wygenerowania zgłoszenia QDE",
                className: "alert-danger",
                displayMs: 5000
            }
        });
    }

    render() {
        const fvu = this.state.fvu;
        const isEditing = !!this.state.pelikan;
        const isCreating = !isEditing && this.props.pelikanId == 0;
        const p = this.state.pelikan;
        const project = p ? p.Project : this.props.project;
        const projectNumber = project ? project.ProjectNumber : this.props.projectNumber;
        const isProjectPelikan = !!projectNumber;
        const departmentId = p ? p.DepartmentId : this.props.departmentId;

        return (
            <>
                <Navbar>
                    <Nav tabs>
                        <NavbarBrand>
                            {isCreating && <>Nowy Pelikan &nbsp;&nbsp;<PelikanStatusIndicator status={PelikanStatusEnum.Open} /></>}
                            {isEditing && <>Pelikan {p!.Id}&nbsp;&nbsp;<PelikanStatusIndicator status={p!.Status} /></>}
                            {this.state.isLoading && <>Pelikan <span className="spinner-border spinner-border-sm"></span></>}
                            {this.props.onClosePage && <button className="btn btn-sm btn-outline-secondary align-top ms-2" onClick={this.props.onClosePage} title="Zamknij stronę Pelikana">Zamknij <i className="fa-solid fa-times" /></button>}
                        </NavbarBrand>
                        <NavItem active={this.state.activeTabIndex == 0}>
                            <NavLink tag="button" active={this.state.activeTabIndex == 0} onClick={() => this.setActiveTab(0)}>Szczegóły</NavLink>
                        </NavItem>
                        {isEditing && <NavItem active={this.state.activeTabIndex == 1}>
                            <NavLink tag="button" active={this.state.activeTabIndex == 1} onClick={() => this.setActiveTab(1)}>Historia</NavLink>
                        </NavItem>}
                    </Nav>
                </Navbar>
                <TabContent activeTab={this.state.activeTabIndex}>
                    <TabPane tabId={0}>
                        <AlertComponent alertMessage={this.state.alertMessage} />
                        <Row>
                            <Col md={4}>
                                <table className="table table-sm">
                                    <tbody>
                                        {isProjectPelikan &&
                                            <tr>
                                                <th>Projekt</th>
                                                <td><ProjectLink projectNumber={projectNumber!} /> {project?.ClientName}</td>
                                            </tr>}
                                        {!!departmentId &&
                                            <tr>
                                                <th>Dział</th>
                                                <td>{DepartmentName(departmentId)}</td>
                                            </tr>}
                                        {isEditing && <>
                                        <tr>
                                            <th>Utworzył</th>
                                                <td>{doerName(p!.CreatedEmployee)}<br /><small>{p!.CreatedDateText}</small></td>
                                        </tr>
                                        <tr>
                                            <th>Zmodyfikował</th>
                                            <td>{doerName(p!.LastUpdatedEmployee)}<br /><small>{p!.LastUpdatedText}</small></td>
                                        </tr>
                                        <tr>
                                            <th>Status</th>
                                            <td>
                                                <PelikanStatusInput
                                                    initialStatus={this.state.pelikan?.Status}
                                                    fvu={fvu}
                                                    status={this.state.status}
                                                    onChange={this.onPelikanStatusChange}
                                                    canInstall={this.state.canInstallPelikan}
                                                    canResolve={this.state.canResolvePelikan}
                                                    canManage={this.state.canManageStatus} />
                                            </td>
                                            </tr></>}
                                        <tr>
                                            <th>Kategoria</th>
                                            <td>
                                                <select id="category" className={`form-select form-select-sm ${fvu.invalidClass('category')}`} onChange={this.onCategorySelected} value={this.state.category}>
                                                    {getPelikanCategoriesToSelect(isProjectPelikan).map(this.getCategoryOption)}
                                                </select>
                                                {fvu.fieldFeedback('category')}
                                            </td>
                                        </tr>
                                        {this.state.hasResolvePelikanFeature && isProjectPelikan && <tr>
                                            <th>Monter może rozwiązać</th>
                                            <td>
                                                <input type="checkbox" checked={this.state.canMonterResolve === true} onChange={this.onCanMonterResolveChange} />
                                            </td>
                                        </tr>}
                                        {isProjectPelikan && <tr>
                                            <th>Wymaga QDE</th>
                                            <td>
                                                <input type="checkbox" checked={this.state.needQDE === true} onChange={this.onNeedQDEChange} />
                                                <QDEInput qdeMinutes={this.state.QDEMinutes} disable={!this.state.needQDE} onQDEChange={this.onQDEChange} />
                                                {this.canGenerateQDETicket() && <button className="btn btn-sm mt-2 btn-outline-primary" onClick={this.generateQDETicket}>Wygeneruj Zgłoszenie QDE</button>}
                                            </td>
                                        </tr>}
                                        <tr>
                                            <th>Przypisany</th>
                                            <td>
                                                <PelikanAssignmentSelect
                                                    fvu={fvu}
                                                    assignedRole={this.state.assignedRole}
                                                    assignedEmployeeId={this.state.assignedEmployeeId}
                                                    assignedToWorkers={this.state.assignedToWorkers}
                                                    rolesToSelect={this.rolesToSelect()}
                                                    employeesToSelect={this.state.employeesToSelect}
                                                    showProjectWorkersOption={isProjectPelikan}
                                                    onChange={this.onAssignedSelected} />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button disabled={!this.dataChanged()} className="btn btn-sm btn-primary" onClick={this.savePelikan}>Zapisz zmiany</button>
                                {this.canMarkAsRead() && <button className="btn btn-sm btn-outline-info ms-2" onClick={this.markAsRead}><i className="fa-solid fa-book-reader" /> Przeczytany</button>}
                                {this.canRemovePelikan() && <button className="btn btn-sm btn-outline-danger ms-4" onClick={this.removePelikan}><i className="fa-solid fa-trash-alt" /> Usuń</button>}
                            </Col>
                            <Col md={4}>
                                <div className="mb-2">
                                    <label htmlFor="description" className="form-label">Opis</label>
                                    <textarea rows={10} id="description" className={`form-control form-control ${fvu.invalidClass('description')}`} onChange={this.onDescriptionChanged} value={this.state.description || ''} />
                                    {fvu.fieldFeedback('description')}
                                </div>
                            </Col>
                            <Col md={4}>
                                {isEditing && <div className="mb-2">
                                    <label htmlFor="solution" className="form-label">Rozwiązanie</label>
                                    <textarea rows={10} id="solution" className={`form-control form-control ${fvu.invalidClass('solution')}`} onChange={this.onSolutionChanged} value={this.state.solution || ''} />
                                    {fvu.fieldFeedback('solution')}
                                </div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                            </Col>
                            <Col md={4}>
                                {isEditing && <PelikanRemarks pelikanId={this.props.pelikanId} pelikanRemarks={p!.Remarks} onSaved={this.onPelikanRemarkSaved} />}
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId={1}>
                        <Row>
                            <Col md={4}>
                                {isEditing && <PelikanLog pelikanId={this.props.pelikanId} logEntries={p!.LogEntries} showCollapsableTitle={false} />}
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>
            </>
        );
    }
}

export default function PelikanPage() {
    const params = useParams();

    const pelikanId = parseInt(params['pelikanId'] || '0');

    return <PelikanPageComponent pelikanId={pelikanId}></PelikanPageComponent>
};
