import { DateRangeString, DateToDateString } from "../../utilities/date-formatter";

type DateRangeFilterProps = {
    fromDate?: Date,
    toDate?: Date,
    onFromDateChanged: (fromDateString: string, fromDate?: Date) => void,
    onToDateChanged: (toDateString: string, toDate?: Date) => void,
    dropdown?: boolean
};

export function DateRangeFilter(props: DateRangeFilterProps) {

    const dateRangeText = function () {

        if (props.fromDate && props.toDate) {
            return DateRangeString(props.fromDate, props.toDate);
        }

        const fromDateText = props.fromDate
            ? DateToDateString(props.fromDate) : '';
        const toDateText = props.toDate
            ? DateToDateString(props.toDate) : '';

        if (fromDateText || toDateText) {
            return `${fromDateText} - ${toDateText}`;
        }

        return 'brak zakresu';
    };

    const fromDateString = function () {
        return props.fromDate
            ? DateToDateString(props.fromDate) : '';
    }

    const toDateString = function () {
        return props.toDate
            ? DateToDateString(props.toDate) : '';
    }

    const clearFromDate = function () {
        props.onFromDateChanged('', undefined);
    };

    const onFromDateChange = function (event: React.ChangeEvent<HTMLInputElement>) {
        props.onFromDateChanged(event.target.value || '', event.target.valueAsDate || undefined);
    }

    const clearToDate = function () {
        props.onToDateChanged('', undefined);
    };

    const onToDateChange = function (event: React.ChangeEvent<HTMLInputElement>) {
        props.onToDateChanged(event.target.value || '', event.target.valueAsDate || undefined);
    }

    const clearAllDates = function () {
        props.onFromDateChanged('', undefined);
        props.onToDateChanged('', undefined);
    }

    if (props.dropdown) {
        return <div className="dropdown">
            <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                {dateRangeText()}
            </button>
            <form className="dropdown-menu p-2">
                <div className="input-group input-group-sm mb-2">
                    <span className="input-group-text px-1">Od</span>
                    <input type="date" className="form-control" value={fromDateString()} onChange={onFromDateChange} placeholder="Od" />
                    <button className="btn btn-outline-secondary" type="button" onClick={clearFromDate}><i className="fa-solid fa-times"></i></button>
                </div>
                <div className="input-group input-group-sm">
                    <span className="input-group-text px-1">Do</span>
                    <input type="date" className="form-control" value={toDateString()} onChange={onToDateChange} placeholder="Do" />
                    <button className="btn btn-outline-secondary" type="button" onClick={clearToDate}><i className="fa-solid fa-times"></i></button>
                </div>
            </form>
        </div>;
    } else {
        return <div className="input-group input-group-sm">
            <input type="date" className="form-control" value={fromDateString()} onChange={onFromDateChange} title="Od" />
            <input type="date" className="form-control" value={toDateString()} onChange={onToDateChange} title="Do" />
            <button className="btn btn-outline-secondary" type="button" onClick={clearAllDates}><i className="fa-solid fa-times"></i></button>
        </div>;
    }
}
