import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { MainHeader } from './MainHeader';
import { MainNavMenu } from './MainNavMenu';

export class Layout extends Component<{ children?: React.ReactNode }> {
    static displayName = Layout.name;

    render() {
        return (
            <div>
                <MainHeader />
                <Container fluid>
                    <Row xl="nowrap">
                        <Col xl="2" md="3">
                            <MainNavMenu />
                        </Col>
                        <Col tag="main" xl="10" md="9" className="pt-3">
                            <div>
                                {this.props.children}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
