import React, { useEffect, useRef, useState } from 'react';
import { CabinetModelSubtypeBriefDTO, DepartmentWorkTypeDTO, OperationResultWithDataDTO, TesterNoteDTO, ValidationErrorsResponse, WorkStatusWithDates, WorktimeEntryDTO, WorktimeEntryWithoutDatesDTO } from '../types/dto';
import { WorkTypeEnum, WorkSubtypeEnum, KnownDepartmentsEnum } from '../types/domain';
import { Worktime } from './Worktime';
import { apiFetchResponse } from '../utilities/auth-api';
import { DetermineWorkStatusNow, renderWorktimeDescription } from '../utilities/domain-utils';
import { StartDepartmentWorkForm, StartProjectWorkForm, ValidationErrors } from '../types/forms';
import { ProjectWorkTypeFormControls } from './ProjectWorkTypeFormControls';
import { WorktimeTableRow } from './WorktimeTableRow';
import { IRootState, useAppDispatch } from '../store/app-store';
import { fetchWorkStatus } from '../store/work-status-slice';
import { useSelector } from 'react-redux';
import DepartmentWorkTypeFormControls from './DepartmentWorkTypeFormControls';

type WorkTimeMeasureProps = {
    projectId?: number,
    projectNumber?: number,
    canStart?: boolean,
    onChange: () => void,
    currentEntry?: WorktimeEntryDTO,
    currentOtherEntries?: WorktimeEntryDTO[],
    availableWorkTypes?: WorkTypeEnum[],
    testerNotes?: TesterNoteDTO[],
    cabinetModelSubTypes?: CabinetModelSubtypeBriefDTO[],
    departmentId?: KnownDepartmentsEnum,
    departmentWorkTypes?: DepartmentWorkTypeDTO[]
};

export default function WorkTimeMeasure(props: WorkTimeMeasureProps) {

    const [selectedWorkTypeId, setSelectedWorkTypeId] = useState<WorkTypeEnum>(WorkTypeEnum.NotSelected);
    const [selectedWorkSubtype, setSelectedWorkSubtype] = useState<WorkSubtypeEnum>(WorkSubtypeEnum.None);
    const [workTypeDetails, setWorkTypeDetails] = useState<string>('');
    const [selectedTesterNoteId, setSelectedTesterNoteId] = useState<number | undefined>();
    const [cabinetModelSubTypeId, setCabinetModelSubTypeId] = useState<number>(0);
    const [isRequestInProgress, setIsRequestInProgress] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string | undefined>();
    const [isInBreak, setIsInBreak] = useState<boolean>(false);
    const [selectedDepartmentWorkType, setSelectedDepartmentWorkType] = useState<number>(0);
    const workStatus = useSelector((state: IRootState) => state.workStatus.value);
    const previousLatestWorkEntry = useRef<WorktimeEntryWithoutDatesDTO | undefined>();
    const dispatch = useAppDispatch();

    const isParentProject = function () { return !!props.projectId; }
    const isParentDepartment = function () {
        return !!props.departmentId;
    }

    const checkBreak = function () {
        const wsWithDates: WorkStatusWithDates | undefined = Object.assign({}, workStatus);
        wsWithDates.Day = new Date(wsWithDates.DayJsMilisecs);
        if (wsWithDates.TodayWorkStartJsMilisecs) {
            wsWithDates.TodayWorkStart = new Date(wsWithDates.TodayWorkStartJsMilisecs);
        }

        const wsNow = DetermineWorkStatusNow(wsWithDates, new Date());
        setIsInBreak(!!(wsNow && wsNow.isInsideBreak));
    };

    useEffect(() => {
        // This will be called when workStatus changes
        // check if change regards curent parent - project or department
        let regardsCurrentParent = false;
        const prevWorkEntry = previousLatestWorkEntry.current;
        const latestWorkEntry = workStatus?.LatestWorkEntry;

        if (props.projectId && props.projectId > 0) {
            if (prevWorkEntry) {
                if (prevWorkEntry.ProjectId === props.projectId) {
                    regardsCurrentParent = true;
                } else if (latestWorkEntry
                    && latestWorkEntry.ProjectId === props.projectId) {
                    regardsCurrentParent = true;
                }
            }
        } else if (props.departmentId && props.departmentId > 0) {
            if (prevWorkEntry) {
                if (prevWorkEntry.Department?.Id === props.departmentId) {
                    regardsCurrentParent = true;
                } else if (latestWorkEntry
                    && latestWorkEntry.Department?.Id === props.departmentId) {
                    regardsCurrentParent = true;
                }
            }
        }

        previousLatestWorkEntry.current = latestWorkEntry;

        if (regardsCurrentParent && props.onChange) {
            props.onChange();
        }

        checkBreak();

        const intervalId = setInterval(() => {
            checkBreak();
        }, 1000);   // call every second

        return () => {
            clearInterval(intervalId);
        }
    }, [workStatus]);

    const onWorkTypeSelected = function (wt: WorkTypeEnum) {
        const testerNotes = props.testerNotes;
        const selectedTesterNoteId = wt == WorkTypeEnum.Fixes && testerNotes && testerNotes.length > 0
            ? testerNotes[0].Id : undefined;

        setSelectedWorkTypeId(wt);
        setSelectedTesterNoteId(selectedTesterNoteId);
    }

    const onWorkSubtypeSelected = function (ws: WorkSubtypeEnum) {
        setSelectedWorkSubtype(ws);
    }

    const onWorkTypeDetailsChanged = function (details: string) {
        setWorkTypeDetails(details);
    }

    const onTesterNoteIdChange = function (testerNoteId?: number) {
        setSelectedTesterNoteId(testerNoteId);
    }

    const onCabinetModelSubTypeIdChange = function (cabinetModelSubTypeId: number) {
        setCabinetModelSubTypeId(cabinetModelSubTypeId);
    }

    const onDepartmentWorkTypeSelected = function (workTypeId: number) {

        setSelectedDepartmentWorkType(workTypeId);
    }

    const VerifyData = function (data: StartProjectWorkForm): string[] {
        const errors: string[] = [];

        if (data.WorkType === WorkTypeEnum.NotSelected) {
            errors.push('Nalezy wybrać typ pracy');
        }
        if (data.WorkType == WorkTypeEnum.Cable) {
            if (!data.WorkSubtype) {
                errors.push('Należy wybrać podtyp pracy');
            }
        }

        const cabinetModelSubTypes = props.cabinetModelSubTypes
            || [];
        if (cabinetModelSubTypes.length > 0 && !data.CabinetModelSubTypeId) {
            errors.push('Należy wybrać szafę');
        }

        return errors;
    }

    const VerifyDepartmentData = function (data: StartDepartmentWorkForm): string[] {
        const errors: string[] = [];

        if (data.WorkTypeId === 0) {
            errors.push('Nalezy wybrać typ pracy');
        }

        return errors;
    }

    const StartProjectWork = async function () {
        const data: StartProjectWorkForm = {
            WorkType: selectedWorkTypeId,
            WorkSubtype: selectedWorkSubtype || undefined,
            Details: workTypeDetails || undefined,
            TesterNoteId: selectedTesterNoteId,
            CabinetModelSubTypeId: cabinetModelSubTypeId || undefined
        };

        const errors: string[] = VerifyData(data);
        if (errors.length > 0) {
            setErrorText(errors.length > 0 ? errors.join('\n') : undefined)
            return undefined;
        }

        setIsRequestInProgress(true);

        return await apiFetchResponse(`project/${props.projectNumber}/start-work`, {
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST'
        });
    }

    const StartDepartmentWork = async function () {
        const data: StartDepartmentWorkForm = {
            WorkTypeId: selectedDepartmentWorkType,
            Details: workTypeDetails || undefined
        };

        const errors = VerifyDepartmentData(data);
        if (errors.length > 0) {
            setErrorText(errors.length > 0 ? errors.join('\n') : undefined)
            return undefined;
        }

        setIsRequestInProgress(true);

        return await apiFetchResponse(`department/${props.departmentId}/start-work`, {
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST'
        });
    }

    const onStartWork = async function () {

        if (isRequestInProgress) {
            return;
        }

        const response = isParentProject()
            ? await StartProjectWork()
            : await StartDepartmentWork();

        setIsRequestInProgress(false);

        if (!response) {
            return;
        }

        if (response.status != 200) {
            if (response.status == 400) {
                const validationErrorsResponse = await response.json() as ValidationErrorsResponse;
                const ve = new ValidationErrors();
                ve.SetFromValidationErrorsResponse(validationErrorsResponse);
                const errorText = ve.GetAsMultilineString();
                setErrorText(errorText);
            } else {
                setErrorText('Błąd operacji');
            }

            return;
        }

        const result = await response.json() as OperationResultWithDataDTO<WorktimeEntryDTO>;
        if (!result.Success) {
            const errorText = result.Errors
                ? result.Errors.map(om => om.Message).join('\n')
                : 'Błąd operacji';
            setErrorText(errorText);
            return;
        }

        setSelectedWorkTypeId(WorkTypeEnum.NotSelected);
        setSelectedWorkSubtype(WorkSubtypeEnum.None);
        setWorkTypeDetails('');
        setSelectedTesterNoteId(undefined);
        setCabinetModelSubTypeId(0);
        setErrorText(undefined);
        setSelectedDepartmentWorkType(0);

        // Not calling props.onChange() because useEffect hook is listening on latest worktime entry
        // which will change after fetchWorkStatus

        await dispatch(fetchWorkStatus());
    }

    let disableStartWorkButton = true;

    if (isParentProject()) {
        const isWorkTypeSelected = selectedWorkTypeId > 0;

        const needCabinetModelSubtype = props.cabinetModelSubTypes
            && props.cabinetModelSubTypes.length > 0;
        const isCabinedModelSubtypeSelected = needCabinetModelSubtype
            ? cabinetModelSubTypeId > 0
            : true;

        disableStartWorkButton = !isWorkTypeSelected
            || !isCabinedModelSubtypeSelected
            || isRequestInProgress;
    } else if (isParentDepartment()) {
        const isWorkTypeSelected = selectedDepartmentWorkType > 0;

        disableStartWorkButton = !isWorkTypeSelected
            || isRequestInProgress;
    }

    const projectWorkTypes = props.availableWorkTypes || [];
    const hasWorkTypes = isParentProject()
        ? projectWorkTypes.length > 0
        : props.departmentWorkTypes && props.departmentWorkTypes.length > 0;
    const canStart = props.canStart && hasWorkTypes && !isInBreak;
    const isInProgress = !!props.currentEntry;
    const otherEntries = props.currentOtherEntries || [];

    if (!canStart && !isInProgress && otherEntries.length == 0) {
        return null;
    }

    return (
        <>
            <div><strong>Pomiar czasu pracy</strong></div>
            <table className="table table-sm">
                <tbody>
                    {canStart &&
                    <tr>
                        <td colSpan={2}>
                            {isParentProject() && <ProjectWorkTypeFormControls
                                onWorkTypeChange={onWorkTypeSelected}
                                workType={selectedWorkTypeId}
                                workTypes={projectWorkTypes}
                                onWorkSubtypeChange={onWorkSubtypeSelected}
                                workSubtype={selectedWorkSubtype}
                                onDetailsChange={onWorkTypeDetailsChanged}
                                details={workTypeDetails}
                                testerNotes={props.testerNotes || []}
                                onTesterNoteIdChange={onTesterNoteIdChange}
                                testerNoteId={selectedTesterNoteId}
                                size='lg'
                                cabinetModelSubTypeId={cabinetModelSubTypeId}
                                cabinetModelSubTypes={props.cabinetModelSubTypes}
                                onCabinetModelSubTypeChange={onCabinetModelSubTypeIdChange}
                                />}
                            {isParentDepartment() && <DepartmentWorkTypeFormControls
                                workTypeId={selectedDepartmentWorkType}
                                details={workTypeDetails}
                                size='lg'
                                onWorkTypeChange={onDepartmentWorkTypeSelected}
                                onDetailsChange={onWorkTypeDetailsChanged}
                                workTypes={props.departmentWorkTypes || []}
                            />}
                            {errorText &&
                                <div className="d-block invalid-feedback multi-line-text">{errorText}</div>
                            }
                        </td>
                        <td>
                            <button type="button" disabled={disableStartWorkButton} className="btn btn-outline-primary btn-lg" title="Rozpocznij pracę" onClick={onStartWork}><i className="fa-solid fa-play" /></button>
                        </td>
                    </tr>}
                    {!!props.currentEntry &&
                        <tr>
                            <td>{renderWorktimeDescription(props.currentEntry)}</td>
                            <td><Worktime start={props.currentEntry.Start!} /></td>
                            <td>
                            </td>
                        </tr>
                    }
                    {
                        otherEntries.map(we =>
                            <WorktimeTableRow key={we.Id} worktimeEntry={we} canEdit={false} testerNotes={[]} />
                        )
                    }
                </tbody>
            </table>
        </>
    );
}
