import { FMListEntryStatus } from "../types/domain";

// NOTE - This function returns bad results
export function TicksToDate(ticks: number) {
    // Copied from https://www.codeproject.com/Questions/859061/How-do-I-convert-Csharp-DateTime-Ticks-to-javascri
    //ticks are in nanotime; convert to microtime
    var ticksToMicrotime = ticks / 10000;

    //ticks are recorded from 1/1/1; get microtime difference from 1/1/1/ to 1/1/1970
    var epochMicrotimeDiff = 2208988800000;

    // new date is ticks, converted to microtime, minus difference from epoch microtime
    return new Date(ticksToMicrotime - epochMicrotimeDiff);
}

const twoDigitFormat = new Intl.NumberFormat('pl-PL', {
    minimumIntegerDigits: 2
});

const percentFormat = new Intl.NumberFormat('pl-PL', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1
});

const msPerSecond = 1000;
const msPerMinute = msPerSecond * 60;
const msPerHour = msPerMinute * 60;

export function ElapsedTimeInMilisecsText(milisecs: number, showSeconds?: boolean) {

    let elapsedMilisecs = milisecs;
    const hours = Math.trunc(elapsedMilisecs / msPerHour);
    elapsedMilisecs -= hours * msPerHour;
    const minutes = Math.trunc(elapsedMilisecs / msPerMinute);

    let text = `${twoDigitFormat.format(hours)}:${twoDigitFormat.format(minutes)}`;
    if (showSeconds) {
        elapsedMilisecs -= minutes * msPerMinute;
        const seconds = Math.trunc(elapsedMilisecs / msPerSecond);

        text += `:${twoDigitFormat.format(seconds)}`;
    }

    return text;
}

export function ElapsedTimeText(start: Date, end: Date, showSeconds: boolean): string {

    const startMs = start.getTime();
    const endMs = end.getTime();
    let elapsedMilisecs = endMs - startMs;

    return ElapsedTimeInMilisecsText(elapsedMilisecs, showSeconds);
}

export function IsSameDay(d1: Date, d2: Date) {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
}

export function GetDayDate(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function StartOfDay(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

export function EndOfDay(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(),
        23, 59, 59);
}

export function EndOfMonth(date: Date): Date {
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    endOfMonth.setHours(23, 59, 59);

    return endOfMonth;
}

export function GetPreviousDay(date: Date) {
    const previous = new Date(date.getTime());

    previous.setDate(date.getDate() - 1);

    return previous;
}

export function getHoursDurationText(hours: number) {
    let signPrefix = '';
    if (hours < 0) {
        hours = -hours;
        signPrefix = '-';
    }

    const hoursInt = Math.trunc(hours);
    const hoursFract = hours - hoursInt;
    const minutes = Math.trunc(hoursFract * 60);

    if (hoursInt == 0) {
        return `${signPrefix}${minutes} min`;
    }

    return `${signPrefix}${hoursInt}h:${twoDigitFormat.format(minutes)}min`;
}

export function getHoursProgressPercent(scheduledHours: number, costSettlementHours: number): string {
    if (costSettlementHours == 0 || scheduledHours == 0) {
        return '';
    }

    const ratio = 100 * costSettlementHours / scheduledHours;

    return `${percentFormat.format(ratio)}%`;
}

const MILISECS_IN_A_DAY = 1000 * 60 * 60 * 24;

export function MilisecsToExcelTime(milisecs: number) {
    // 1.0f in Excel means time 24h

    return milisecs / MILISECS_IN_A_DAY;
}

export function DateWithTimePart(date: Date, timePartDate: Date) {
    const resultDate = new Date(date.getTime());
    const hour = timePartDate.getHours();
    const minutes = timePartDate.getMinutes();
    const seconds = timePartDate.getSeconds();

    resultDate.setHours(hour);
    resultDate.setMinutes(minutes);
    resultDate.setSeconds(seconds, 0);

    return resultDate;
}
