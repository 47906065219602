import React, { Component } from 'react';
import { OperationResultWithDataDTO, ProjectLogEntryDTO } from '../types/dto';
import { apiFetchResponse } from '../utilities/auth-api';
import { CalculateDates } from '../utilities/data-enhancer';
import { ProjectNumberContext } from './contexts';
import { ProjectLogRow } from './ProjectLogRow';

type ProjectLogViewProps = {
    isVisible: boolean
};

type ProjectLogViewState = {
    logEntries?: ProjectLogEntryDTO[]
};

export class ProjectLogView extends Component<ProjectLogViewProps, ProjectLogViewState> {
    static displayName = ProjectLogView.name;
    static contextType = ProjectNumberContext;

    constructor(props: ProjectLogViewProps) {
        super(props);

        this.state = {
        };

        this.renderRow = this.renderRow.bind(this);
    }

    componentDidUpdate(prevProps: ProjectLogViewProps) {
        if (!prevProps.isVisible
            && this.props.isVisible
            && this.state.logEntries == undefined
        ) {
            this.fetchProjectLog();
        }
    }

    async fetchProjectLog() {
        const projectNumber = this.context;

        const response = await apiFetchResponse(`project/${projectNumber}/log`);

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<ProjectLogEntryDTO[]>;

            CalculateDates(result.Data);

            this.setState({
                logEntries: result.Data
            })
        }
    }

    renderRow(logEntry: ProjectLogEntryDTO, idx: number) {
        return <ProjectLogRow key={idx} logEntry={logEntry} />;
    }

    render() {
        if (!this.state.logEntries) {
            return null;
        }

        const entries = this.state.logEntries || [];

        return (
            <table className="table table-sm table-striped table-hover">
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Wykonał</th>
                        <th>Operacja</th>
                    </tr>
                </thead>
                <tbody className="table-group-divider">
                    {entries.map(this.renderRow)}
                </tbody>
            </table>
        );
    }
}
