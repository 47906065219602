import React from "react";
import { PelikanViewType } from "../types/domain";
import { PelikanViewTypeText } from "../utilities/domain-utils";
import { StateFlagSelect, ValueData } from "./common/StateFlagSelect";

type PelikanViewTypeSelectProps = {
    pelikanViewType: PelikanViewType,
    onSelected: (value: PelikanViewType) => void
};

const PELIKAN_VIEW_TYPE_DESCRIPTORS: ValueData[] = [
    {
        value: PelikanViewType.All,
        buttonClass: "btn btn-outline-secondary",
        iconClass: "fa-solid fa-list",
        buttonText: "",
        buttonTitle: PelikanViewTypeText(PelikanViewType.All),
        description: PelikanViewTypeText(PelikanViewType.All)
    },
    {
        value: PelikanViewType.AssignedToMe,
        buttonClass: "btn btn-outline-info",
        iconClass: "fa-solid fa-book-reader",
        buttonText: "",
        buttonTitle: PelikanViewTypeText(PelikanViewType.AssignedToMe),
        description: PelikanViewTypeText(PelikanViewType.AssignedToMe)
    },
    {
        value: PelikanViewType.Unread,
        buttonClass: "btn btn-outline-danger",
        iconClass: "fa-solid fa-book-open",
        buttonText: "",
        buttonTitle: PelikanViewTypeText(PelikanViewType.Unread),
        description: PelikanViewTypeText(PelikanViewType.Unread)
    }
];

const AVAILABLE_VALUES: PelikanViewType[] = [
    PelikanViewType.All,
    PelikanViewType.AssignedToMe,
    PelikanViewType.Unread
];

export function PelikanViewTypeSelect(props: PelikanViewTypeSelectProps) {

    // NOTE - need to do this through timeout
    // otherwise list was not auto-closed
    // probably due to long props.onSelected processing
    const onItemSelected = (value: PelikanViewType) => {
        setTimeout(() => props.onSelected(value), 0);
    };

    return <StateFlagSelect
        value={props.pelikanViewType}
        valueDescriptions={PELIKAN_VIEW_TYPE_DESCRIPTORS}
        availableValues={AVAILABLE_VALUES}
        onSelected={onItemSelected}
        containerClass="h-100"
    />;
}
