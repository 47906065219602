import React, { Component } from 'react';
import { EmployeeBriefDTO } from '../types/dto';
import { fetchEmployeesToSelect } from '../utilities/data-fetch';

type EmployeePickerProps = {
    selectedEmployeeId?: number,
    employeesToSelect?: EmployeeBriefDTO[],
    fetchEmployees?: boolean,
    label: string,
    inputId: string,
    onEmployeeChanged: (employee?: EmployeeBriefDTO) => void,
    notFloatingLabel?: boolean
};

type EmployeePickerState = {
    employeesToSelect: EmployeeBriefDTO[],
    selectedEmployeeId: number
};

export class EmployeePicker extends Component<EmployeePickerProps, EmployeePickerState> {
    static displayName = EmployeePicker.name;

    constructor(props: EmployeePickerProps) {
        super(props);

        this.state = {
            employeesToSelect: props.employeesToSelect || [],
            selectedEmployeeId: props.selectedEmployeeId || 0
        };

        this.onEmployeeIdChanged = this.onEmployeeIdChanged.bind(this);
        this.clearEmployeeId = this.clearEmployeeId.bind(this);
        this.fetchEmployeesToSelect = this.fetchEmployeesToSelect.bind(this);
        this.getEmployeesToSelect = this.getEmployeesToSelect.bind(this);
        this.getSelectedEmployeeId = this.getSelectedEmployeeId.bind(this);
    }

    componentDidMount() {
        if (this.props.fetchEmployees) {
            this.fetchEmployeesToSelect();
        }
    }

    componentDidUpdate(prevProps: EmployeePickerProps) {

        if (prevProps.selectedEmployeeId != this.props.selectedEmployeeId) {
            console.log('componentDidUpdate', this.props);

            this.setState({
                selectedEmployeeId: this.props.selectedEmployeeId || 0
            });
        }
    }

    async fetchEmployeesToSelect() {
        const employeesToSelect = await fetchEmployeesToSelect(false);

        this.setState({
            employeesToSelect
        });
    }

    getEmployeesToSelect(): EmployeeBriefDTO[] {

        const result = this.props.fetchEmployees
            ? this.state.employeesToSelect
            : this.props.employeesToSelect || [];

        return result;
    }

    employeeOption(employee: EmployeeBriefDTO) {
        return <option key={employee.Id} value={employee.Id}>{employee.FullName}</option>;
    }

    onEmployeeIdChanged(event: React.ChangeEvent<HTMLSelectElement>) {

        let selectedEmployeeId: number = parseInt(event.currentTarget.value);

        const employeesToSelect = this.getEmployeesToSelect();
        const employeeData = employeesToSelect.find(value => value.Id == selectedEmployeeId);

        console.log('Employee picker onEmployeeIdChanged',
            selectedEmployeeId, employeesToSelect, employeeData);

        if (!employeeData) {
            selectedEmployeeId = 0;
        }

        this.setState({
            selectedEmployeeId
        });

        this.props.onEmployeeChanged(employeeData);
    }

    clearEmployeeId() {
        this.setState({
            selectedEmployeeId: 0
        });

        this.props.onEmployeeChanged(undefined);
    }

    getSelectedEmployeeId() {
        return this.state.selectedEmployeeId;
    }

    render() {

        if (this.props.notFloatingLabel) {
            return (
                <>
                    <label htmlFor={this.props.inputId}><strong>{this.props.label}</strong></label>
                    <div className='input-group'>
                        <select className='form-select form-select-sm' id={this.props.inputId} value={this.getSelectedEmployeeId()} onChange={this.onEmployeeIdChanged}>
                            <option disabled={true} value="0">Nie wybrano</option>
                            {this.getEmployeesToSelect().map(this.employeeOption)}
                        </select>
                        <button className="btn btn-outline-secondary" type="button" onClick={this.clearEmployeeId}><i className='fa-solid fa-times' /></button>
                    </div>
                </>
            );
        }

        return (
            <div className='input-group'>
                <div className='form-floating'>
                    <select className='form-select form-select-sm' id={this.props.inputId} value={this.state.selectedEmployeeId} onChange={this.onEmployeeIdChanged}>
                        <option disabled={true} value="0">Nie wybrano</option>
                        {this.getEmployeesToSelect().map(this.employeeOption)}
                    </select>
                    <label htmlFor={this.props.inputId}>{this.props.label}</label>
                </div>
                <button className="btn btn-outline-secondary" type="button" onClick={this.clearEmployeeId}><i className='fa-solid fa-times' /></button>
            </div>
        );
    }
}
