import { PelikansPromptDTO } from "../types/dto";
import { apiFetchResponse } from "./auth-api";

type CallbackFunc = (assignedCount: number, unreadCount: number) => void;

type CallbackSubscription = {
    callback: CallbackFunc,
    subscription: number
};
class PelikanPromptService {
    _callbacks: CallbackSubscription[] = [];
    _nextSubscriptionId: number = 0;
    _hasData: boolean = false;
    _assignedCount: number = 0;
    _unreadCount: number = 0;
    private _refreshDataInterval?: NodeJS.Timer;

    subscribe(callback: CallbackFunc) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId: number) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index!, 1);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback(this._assignedCount, this._unreadCount);
        }
    }

    hasData() {
        return this._hasData;
    }

    assignedCount() {
        return this._assignedCount;
    }

    unreadCount() {
        return this._unreadCount;
    }

    newData(assignedCount: number, unreadCount: number) {
        this._assignedCount = assignedCount;
        this._unreadCount = unreadCount;

        this.notifySubscribers();
    }

    async fetchNewData() {
        if (this._refreshDataInterval) {
            clearTimeout(this._refreshDataInterval);
            this._refreshDataInterval = undefined;
        }

        const response = await apiFetchResponse(`pelikan/prompt`);
        if (response.status == 200) {
            const data = await response.json() as PelikansPromptDTO;

            if (data) {
                this.newData(data.AssignedCount, data.UnreadCount);
            }
        }

        const fiveMinutesInterval = 5 * 60 * 1000;

        this._refreshDataInterval = setTimeout(this.fetchNewData.bind(this), fiveMinutesInterval);
    }
}

export const pelikanPromptService = new PelikanPromptService();
