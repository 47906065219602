import React, { Component } from 'react';
import { UnloadingFlags, UnloadingFlagValue, MajorProjectStateEnum, KnownRolesEnum } from '../types/domain';
import { UnloadingFlagRow } from './UnloadingFlagRow';
import { apiFetchData, apiFetchResponse } from '../utilities/auth-api';
import { OperationResultDTO, OperationResultWithDataDTO, UnloadingDataDTO } from '../types/dto';
import authService from './api-authorization/AuthorizeService';
import { CalculateDates } from '../utilities/data-enhancer';
import { ToggleCollapseButton } from './common/ToggleCollapseButton';
import { UpdateUnloadingFlagForm } from '../types/forms';

type UnloadingProtocolProps = {
    projectNumber: number,
    majorProjectState: MajorProjectStateEnum,
    yesFlags: UnloadingFlags,
    missingFlags: UnloadingFlags,
    inapplicableFlags: UnloadingFlags,
    onFlagsChanged?: (data: UpdateUnloadingFlagForm, result: OperationResultDTO) => void,
    initiallyCollapsed: boolean
};

type UnloadingProtocolState = {
    isCollapsed: boolean,
    isWarehouseKeeper: boolean,
    unloadingLogData?: UnloadingDataDTO[]
};

export class UnloadingProtocol extends Component<UnloadingProtocolProps, UnloadingProtocolState> {
    static displayName = UnloadingProtocol.name;

    constructor(props: UnloadingProtocolProps) {
        super(props);

        this.state = {
            isCollapsed: props.initiallyCollapsed,
            isWarehouseKeeper: authService.hasUserRoleSync(KnownRolesEnum.WarehouseKeeper)
        };

        this.flagValue = this.flagValue.bind(this);
        this.doSetValue = this.doSetValue.bind(this);
        this.canSetFlag = this.canSetFlag.bind(this);
        this.toggleCollapsed = this.toggleCollapsed.bind(this);
        this.isBetweenStates = this.isBetweenStates.bind(this);
        this.fetchUnloadingLogData = this.fetchUnloadingLogData.bind(this);
        this.getLogEntriesForFlag = this.getLogEntriesForFlag.bind(this);
    }

    componentDidMount() {
        if (!this.state.isCollapsed) {
            this.fetchUnloadingLogData();
        }
    }

    async fetchUnloadingLogData() {
        const response = await apiFetchResponse(`project/${this.props.projectNumber}/unloading/log`);
        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<UnloadingDataDTO[]>;
            if (result.Success) {
                const unloadingLogData = result.Data;

                CalculateDates(unloadingLogData);

                this.setState({
                    unloadingLogData
                });
            }
        } else {
            // TODO - Error
        }
    }

    flagValue(flag: UnloadingFlags): UnloadingFlagValue {
        if (flag & this.props.yesFlags) {
            return UnloadingFlagValue.Yes;
        } else if (flag & this.props.missingFlags) {
            return UnloadingFlagValue.Missing;
        } else if (flag & this.props.inapplicableFlags) {
            return UnloadingFlagValue.Inapplicable;
        } else {
            return UnloadingFlagValue.NotSet;
        }
    }

    async doSetValue(flag: UnloadingFlags, value: UnloadingFlagValue) {

        var data: UpdateUnloadingFlagForm = {
            UnloadingFlag: flag,
            Value: value
        };

        var result = await apiFetchData(`project/${this.props.projectNumber}/unloading/flag`, {
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'PATCH'
        }) as OperationResultDTO;

        if (result.Success) {
            if (this.props.onFlagsChanged) {
                this.props.onFlagsChanged(data, result);
            }

            this.fetchUnloadingLogData();
        }
    }

    getUnloadingFlags(): UnloadingFlags[] {
        if (this.state.isCollapsed) {
            return [];
        }

        const isCabinetUnarmed = (this.props.yesFlags & UnloadingFlags.IsCabinedUnarmed) > 0;

        return [
            UnloadingFlags.IsCabinedUnarmed,
            isCabinetUnarmed ? UnloadingFlags.Devices : UnloadingFlags.CabinetBuilt,
            UnloadingFlags.DiagramFromVDL,
            UnloadingFlags.DescriptionsOfCablesAndDevices,
            UnloadingFlags.PreparedCablesKomax,
            UnloadingFlags.CablesMore16mm,
            UnloadingFlags.Cables,
            UnloadingFlags.Baipack,
            UnloadingFlags.AirConditioning,
            UnloadingFlags.DeskDoorsAndHinges,
            UnloadingFlags.DeskShell,
            UnloadingFlags.DeskPanel,
            UnloadingFlags.DeskMaterial,
            UnloadingFlags.AdditionalMaterial,
            UnloadingFlags.Damages,
            UnloadingFlags.Covers
        ];
    }

    isBetweenStates(s1: MajorProjectStateEnum, s2: MajorProjectStateEnum) {
        return this.props.majorProjectState >= s1
            && this.props.majorProjectState <= s2;
    }

    canSetFlag(flag: UnloadingFlags): boolean {

        const baseCondition = this.state.isWarehouseKeeper
            && this.isBetweenStates(MajorProjectStateEnum.Planned, MajorProjectStateEnum.Fixes);

        if (!baseCondition) {
            return baseCondition;
        }

        if (flag == UnloadingFlags.IsCabinedUnarmed) {
            const cabinetBuiltAndDevicesFlagsState = (this.props.inapplicableFlags
                | this.props.missingFlags
                | this.props.yesFlags) & (UnloadingFlags.CabinetBuilt | UnloadingFlags.Devices);

            return cabinetBuiltAndDevicesFlagsState == 0;
        }

        return baseCondition;
    }

    toggleCollapsed() {

        if (this.state.isCollapsed && this.state.unloadingLogData === undefined) {
            this.fetchUnloadingLogData();
        }

        this.setState({
            isCollapsed: !this.state.isCollapsed
        });
    }

    getLogEntriesForFlag(flag: UnloadingFlags) {
        if (!this.state.unloadingLogData) {
            return [];
        }

        const logData = this.state.unloadingLogData.find(x => x.UnloadingFlag == flag);
        if (!logData) {
            return [];
        }

        return logData.LogEntries;
    }

    render() {

        return (
            <>
                <div><ToggleCollapseButton isCollapsed={this.state.isCollapsed} toggle={this.toggleCollapsed} />&nbsp;<strong>Protokół rozładunku</strong></div>
                {!this.state.isCollapsed && <table className="table table-sm width-100">
                    <tbody>
                        {this.getUnloadingFlags().map(flag =>
                            <UnloadingFlagRow
                                key={flag}
                                flag={flag}
                                flagValue={this.flagValue(flag)}
                                doSetValue={this.doSetValue}
                                canSet={this.canSetFlag(flag)}
                                logEntries={this.getLogEntriesForFlag(flag)} />
                        )}
                    </tbody>
                </table>}
            </>
        );
    }
}
