import React, { useState } from "react";
import { DepartmentWorkTypeDTO, OperationResultDTO, OperationResultWithDataDTO, ValidationErrorsResponse } from "../types/dto";
import { ValidationErrors } from "../types/forms";
import { apiFetchResponse } from "../utilities/auth-api";
import { FormValidationUtility } from "./common/FormValidationUtility";
import { SingleNoteForm } from "./common/SingleNoteForm";

type DepartmentWorkTypesProps = {
    departmentId: number,
    departmentWorkTypes: DepartmentWorkTypeDTO[],
    canEdit?: boolean,
    onChaged?: () => void
};

export default function DepartmentWorkTypes(props: DepartmentWorkTypesProps) {

    const [showForm, setShowForm] = useState<boolean>();
    const [editedId, setEditedId] = useState<number>();
    const [initText, setInitText] = useState<string>();
    const [fvu, setFvu] = useState<FormValidationUtility>();

    const startCreateNew = function () {
        if (!props.canEdit) {
            return;
        }

        setShowForm(true);
        setEditedId(undefined);
        setInitText('');
    }

    const editWorkType = function (wt: DepartmentWorkTypeDTO) {
        setShowForm(true);
        setEditedId(wt.Id);
        setInitText(wt.Name);
    }

    const onFormCancel = function() {
        setShowForm(false);
        setEditedId(undefined);
    }

    const deleteWorkType = async function (wt: DepartmentWorkTypeDTO) {
        const response = await apiFetchResponse(`department/${props.departmentId}/work-type/${wt.Id}`, {
            method: 'DELETE'
        });

        if (response.status == 200) {
            const result = await response.json() as OperationResultDTO;
            if (result.Success) {
                if (props.onChaged) {
                    props.onChaged();
                }
            }
        }
    }

    const renderWorkTypeItem = function (wt: DepartmentWorkTypeDTO) {

        return <tr key={wt.Id}>
            <td>
                <p className="mb-0">{wt.Name}</p>
            </td>
            <td>
                {props.canEdit && <>
                    <button className="btn btn-sm btn-outline-primary" title="Edytuj" type="button" onClick={() => editWorkType(wt)}><i className="fa-solid fa-edit"></i></button>
                    <button className="btn btn-sm btn-outline-danger ms-1" title="Usuń" type="button" onClick={() => deleteWorkType(wt)}><i className="fa-solid fa-times"></i></button>
                </>}
            </td>
        </tr>
    }

    const onSubmitWorkType = async function(text: string) {
        const formData = {
            Text: text
        };

        const url = editedId
            ? `department/${props.departmentId}/work-type/${editedId}`
            : `department/${props.departmentId}/work-type`;
        const method = editedId
            ? 'PUT' : 'POST';

        const response = await apiFetchResponse(url,
            {
                body: JSON.stringify(formData),
                method,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

        if (response.status == 200) {

            setInitText('');
            setFvu(new FormValidationUtility());
            setShowForm(false);
            setEditedId(undefined);

            const result = await response.json() as OperationResultWithDataDTO<DepartmentWorkTypeDTO>;
            if (result.Success) {
                if (props.onChaged) {
                    props.onChaged();
                }
            }
        } else if (response.status == 400) {
            // handle validation errors
            const validationErrors = await response.json() as ValidationErrorsResponse;
            const validator = new ValidationErrors();

            validator.SetFromValidationErrorsResponse(validationErrors);

            setFvu(new FormValidationUtility(validator));
        }
    }

    return <>
        <div>
            <strong>Typy pracy</strong>
            {!showForm && props.canEdit &&
                <button className="btn btn-sm btn-outline-primary ms-2" onClick={startCreateNew}>Dodaj typ pracy</button>
            }
        </div>
        {showForm &&
            <SingleNoteForm
                id="dep-worktype-text"
                name="dep-worktype-text"
                label="Nazwa"
                onCancel={onFormCancel}
                onFormSubmit={onSubmitWorkType}
                fvu={fvu}
                initText={initText || ''}
                useInputField={true}
            />
        }
        {!showForm && props.departmentWorkTypes.length > 0 &&
            <table className="table table-sm">
                <tbody>
                    {props.departmentWorkTypes.map(renderWorkTypeItem)}
                </tbody>
            </table>
        }
        {!showForm && props.departmentWorkTypes.length == 0 &&
            <i>Brak typów pracy</i>
        }
    </>;
}
