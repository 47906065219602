import React, { Component, Fragment } from 'react';
import { apiFetchData } from '../utilities/auth-api';
import { OperationResultWithDataDTO, EmployeeDataRowDTO, RoleDTO } from '../types/dto';
import SortableTable, { ColumnOptionsCollection } from './common/SortableTable';
import ButtonWithRoute from './common/ButtonWithRoute';
import { StringDictionary } from '../types/global';
import authService from './api-authorization/AuthorizeService';
import { AllowsFeature, Features } from '../utilities/features';
import { EmployeeActivityStatusEnum } from '../types/domain';
import { EmployeeActivityStatusPrompt } from './EmployeeActivityStatusPrompt';

type EmployeesViewProps = {
};

type EmployeesViewState = {
    includeInactive: boolean
    employees: EmployeeDataRowDTO[],
    canManage: boolean,
    isLoading: boolean
};

const sortRolesByName = function(roleA: RoleDTO, roleB: RoleDTO): number {
    if (roleA.Name < roleB.Name) {
        return -1;
    } else if (roleA.Name > roleB.Name) {
        return 1;
    } else {
        return 0;
    }
}

export class EmployeesView extends Component<EmployeesViewProps, EmployeesViewState> {
    static displayName = EmployeesView.name;

    constructor(props: EmployeesViewProps) {
        super(props);

        this.state = {
            includeInactive: false,
            employees: [],
            canManage: AllowsFeature(Features.ManageEmployees, authService.getUserProfileSync()),
            isLoading: false
        };

        this.onIncludeInactiveChange = this.onIncludeInactiveChange.bind(this);
    }

    componentDidMount() {
        this.fetchEmployees(this.state.includeInactive);
    }

    async fetchEmployees(includeInactive: boolean) {
        this.setState({
            isLoading: true
        });

        var result = await apiFetchData(`employee?includeInactive=${includeInactive}`) as OperationResultWithDataDTO<EmployeeDataRowDTO[]>;

        if (result.Success) {

            const employees = result.Data || [];

            this.setState({
                isLoading: false,
                employees
            });
        } else {
            this.setState({
                isLoading: false
            });
        }
    }

    columns() {
        const columns: StringDictionary = {};

        columns['FullName'] = "Imię i nazwisko";
        columns['LoginName'] = "Login";
        columns['RolesString'] = "Role";
        columns['EmployeeActivityStatus'] = "Status";
        columns['AssignedPelikansCount'] = "Pelikany Przypisane";
        columns['UnreadPelikansCount'] = "Pelikany Nieprzeczytane";
        columns['IsActive'] = "Aktywny";
        columns['actions'] = 'Akcje';

        return columns;
    }

    onIncludeInactiveChange(event: React.ChangeEvent<HTMLInputElement>) {
        const includeInactive = event.target.checked;

        this.setState({
            includeInactive
        })

        this.fetchEmployees(includeInactive);
    }

    private renderActions(data: unknown) {
        const employee = data as EmployeeDataRowDTO;
        if (!employee) {
            return null;
        }

        return (
            <div>
                <ButtonWithRoute className="btn btn-outline-primary btn-sm" path={'/employees/' + employee.Id}>Szczególy</ButtonWithRoute>
            </div>
        );
    }

    columnOptions(): ColumnOptionsCollection {
        const options: ColumnOptionsCollection = {};

        options['FullName'] = {
            enableFilter: true
        };
        options['AssignedPelikansCount'] = {
            valueRenderer: (value: any) => <span className="badge bg-info"><i className="fa-solid fa-book-reader" /> {value}</span>
        };
        options['UnreadPelikansCount'] = {
            valueRenderer: (value: any) => <span className="badge bg-danger"><i className="fa-solid fa-book-open" /> {value}</span>
        };
        options['EmployeeActivityStatus'] = {
            valueRenderer: (value: any) => <EmployeeActivityStatusPrompt status={value as EmployeeActivityStatusEnum} />
        };

        return options;
    }

    render() {
        return (
            <Fragment>
                <div className="row">
                    {this.state.canManage && <div className="col-auto">
                        <ButtonWithRoute className="btn btn-outline-primary btn-sm" path={'/employees/0'}>Nowy pracownik&nbsp;<span className="fa-solid fa-plus"></span></ButtonWithRoute>
                    </div>}
                    <div className="col-auto">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="include-inactive-check" checked={this.state.includeInactive} onChange={this.onIncludeInactiveChange} />
                            <label className="form-check-label" htmlFor="include-inactive-check">
                                Pokaż nieaktywnych
                            </label>
                        </div>
                    </div>
                    {this.state.isLoading && <div className='col-auto'><span className='spinner-border'></span></div>}
                </div>
                <SortableTable idKey='Id'
                    dataRows={this.state.employees}
                    columns={this.columns()}
                    columnOptions={this.columnOptions()}
                    renderActions={this.renderActions.bind(this)}
                />
            </Fragment>
        );
    }
}
