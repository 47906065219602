import React, { Component, Fragment } from 'react';
import { KnownRolesEnum, EmployeeBreakEnum } from '../types/domain';
import { EmployeeFullDTO, OperationResultWithDataDTO, OperationResultDTO, ValidationErrorsResponse } from '../types/dto';
import { EmployeeBreakText, roleName } from '../utilities/domain-utils';
import { apiFetchResponse } from '../utilities/auth-api';
import { EmployeeDataForm, ValidationErrors } from '../types/forms';
import { FormValidationUtility } from './common/FormValidationUtility';

type EmployeeFormProps = {
    employee: EmployeeFullDTO,
    onChanged?: (employee: EmployeeFullDTO) => void
};

type EmployeeFormState = {
    firstName?: string,
    surname?: string,
    loginName?: string,
    password?: string,
    isActive?: boolean,
    roles?: KnownRolesEnum[],
    latestSaveResult?: OperationResultDTO,
    timeout?: NodeJS.Timeout,
    fvu: FormValidationUtility,
    employeeBreakId: EmployeeBreakEnum
};

export class EmployeeForm extends Component<EmployeeFormProps, EmployeeFormState> {
    static displayName = EmployeeForm.name;

    constructor(props: EmployeeFormProps) {
        super(props);

        this.rolesToSelect = this.rolesToSelect.bind(this);
        this.onRoleSelected = this.onRoleSelected.bind(this);
        this.onRoleRemove = this.onRoleRemove.bind(this);
        this.getRoleBadge = this.getRoleBadge.bind(this);
        this.getEmployeeData = this.getEmployeeData.bind(this);
        this.onFirstNameChange = this.onFirstNameChange.bind(this);
        this.onSurnameChange = this.onSurnameChange.bind(this);
        this.onLoginNameChange = this.onLoginNameChange.bind(this);
        this.onLoginPasswordChange = this.onLoginPasswordChange.bind(this);
        this.onIsActiveChange = this.onIsActiveChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.validateNewEmployeeData = this.validateNewEmployeeData.bind(this);
        this.validateUpdateEmployeeData = this.validateUpdateEmployeeData.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
        this.onBreakChange = this.onBreakChange.bind(this);

        this.state = {
            ...this.getEmployeeData(),
            fvu: new FormValidationUtility()
        };
;
    }

    getEmployeeData(): EmployeeDataForm {
        const employee = this.props.employee;

        return {
            firstName: !this.state || this.state.firstName === undefined
                ? employee.FirstName
                : this.state.firstName,
            surname: !this.state || this.state.surname === undefined
                ? employee.Surname
                : this.state.surname,
            loginName: !this.state || this.state.loginName === undefined
                ? employee.User.LoginName
                : this.state.loginName,
            password: !this.state || this.state.password === undefined
                ? ''
                : this.state.password,
            isActive: !this.state || this.state.isActive === undefined
                ? employee.User.IsActive
                : this.state.isActive,
            roles: !this.state || this.state.roles === undefined
                ? employee.User.Roles.map(r => r.Id)
                : this.state.roles,
            employeeBreakId: !this.state || this.state.employeeBreakId === undefined
                ? employee.EmployeeBreakId
                : this.state.employeeBreakId
        };
    }

    onRoleSelected(event: React.ChangeEvent<HTMLSelectElement>) {
        const value: KnownRolesEnum = parseInt(event.currentTarget.value);
        const employeeData = this.getEmployeeData();
        const roles = employeeData.roles;

        if (roles) {
            roles.push(value);
        }

        if (value != KnownRolesEnum.NotSelected) {
            this.setState({
                roles,
                fvu: new FormValidationUtility()
            });
        }
    }

    onRoleRemove(event: React.MouseEvent, role: KnownRolesEnum) {
        const employeeData = this.getEmployeeData();
        let roles = employeeData.roles;

        const index = roles.indexOf(role);

        if (index >= 0) {
            roles.splice(index, 1);
            this.setState({
                roles,
                fvu: new FormValidationUtility()
            });
        }
    }

    validateNewEmployeeData(data: EmployeeDataForm): boolean {
        const validator = new ValidationErrors();

        validator.ValidateNotEmpty('firstName', data.firstName);
        validator.ValidateNotEmpty('surname', data.surname);
        validator.ValidateNotEmpty('loginName', data.loginName);
        validator.ValidateNotEmpty('password', data.password);
        validator.ValidateArrayHasAnyValue('roles', data.roles, 'Należy wybrać wartość');

        this.setState({
            fvu: new FormValidationUtility(validator)
        });

        return validator.Success;
    }

    validateUpdateEmployeeData(data: EmployeeDataForm): boolean {
        const validator = new ValidationErrors();

        validator.ValidateNotEmpty('firstName', data.firstName);
        validator.ValidateNotEmpty('surname', data.surname);
        validator.ValidateNotEmpty('loginName', data.loginName);
        // NOTE - Password can be empty on update
        validator.ValidateArrayHasAnyValue('roles', data.roles, 'Należy wybrać wartość');

        this.setState({
            fvu: new FormValidationUtility(validator)
        });

        return validator.Success;
    }

    hideMessage() {
        this.setState({
            latestSaveResult: undefined,
            timeout: undefined
        });
    }

    componentWillUnmount() {
        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }
    }

    async onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const employeeId = this.props.employee.Id;
        const data = this.getEmployeeData();

        const validationResult = employeeId > 0
            ? this.validateUpdateEmployeeData(data)
            : this.validateNewEmployeeData(data);
        if (!validationResult) {
            return;
        }

        const url = this.props.employee.Id
            ? `employee/${this.props.employee.Id}`
            : `employee`;
        const method = this.props.employee.Id
            ? 'PUT'
            : 'POST';

        const response = await apiFetchResponse(url, {
            body: JSON.stringify(data),
            method,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<EmployeeFullDTO>;
            this.setState({
                latestSaveResult: result,
                timeout: setTimeout(this.hideMessage, 3000)
            });

            if (result.Success) {
                if (this.props.onChanged) {
                    this.props.onChanged(result.Data!);
                }
            } else {
                const validator = new ValidationErrors();
                if (result.Errors) {
                    validator.SetFromOperationErrors(result.Errors);
                    this.setState({
                        fvu: new FormValidationUtility(validator)
                    });
                }
            }
        } else if (response.status == 400) {
            // handle validation errors
            const validationErrors = await response.json() as ValidationErrorsResponse;

            const validator = new ValidationErrors();
            validator.SetFromValidationErrorsResponse(validationErrors);

            this.setState({
                fvu: new FormValidationUtility(validator)
            });
        }
    }

    onFirstNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            firstName: event.target.value,
            fvu: new FormValidationUtility()
        });
    }

    onSurnameChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            surname: event.target.value,
            fvu: new FormValidationUtility()
        });
    }

    onBreakChange(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({
            employeeBreakId: event.target.value
                ? parseInt(event.target.value) as EmployeeBreakEnum
                : EmployeeBreakEnum.NotSet,
            fvu: new FormValidationUtility()
        });
    }

    onLoginNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            loginName: event.target.value,
            fvu: new FormValidationUtility()
        });
    }

    onLoginPasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            password: event.target.value,
            fvu: new FormValidationUtility()
        });
    }

    onIsActiveChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            isActive: event.target.checked,
            fvu: new FormValidationUtility()
        });
    }

    getRoleBadge(role: KnownRolesEnum) {
        return (<div key={role} className="badge text-bg-primary">{roleName(role)}&nbsp;<button className="border border-0 fa-solid fa-times bg-transparent" onClick={(event) => this.onRoleRemove(event, role)}></button></div>)
    }

    getRoleOption(role: KnownRolesEnum) {
        if (role == KnownRolesEnum.NotSelected) {
            return <option key={role} value={role} disabled={true}>Dodaj rolę</option>
        } else {
            return <option key={role} value={role}>{roleName(role)}</option>;
        }
    }

    rolesToSelect(): KnownRolesEnum[] {
        const allRoles: KnownRolesEnum[] = [
            KnownRolesEnum.NotSelected,
            KnownRolesEnum.Monter,
            KnownRolesEnum.WarehouseKeeper,
            KnownRolesEnum.Tester,
            KnownRolesEnum.Office,
            KnownRolesEnum.Manager,
            KnownRolesEnum.TeamLider,
            KnownRolesEnum.Apprentice,
            KnownRolesEnum.WarehouseForeman
        ];

        const employeeData = this.getEmployeeData();

        return allRoles.filter(role => !employeeData.roles.includes(role));
    }

    breaksToSelect(): EmployeeBreakEnum[] {

        return [
            EmployeeBreakEnum.NotSet,
            EmployeeBreakEnum.Break1,
            EmployeeBreakEnum.Break2,
            EmployeeBreakEnum.Break3,
            EmployeeBreakEnum.Break4,
            EmployeeBreakEnum.Break5
        ];
    }

    render() {
        const employeeData = this.getEmployeeData();
        const isSaved = this.state.latestSaveResult && this.state.latestSaveResult.Success;
        const generalErrors = this.state.fvu.validationErrors?.GetGeneralErrors() || [];
        const hasGeneralErrors = generalErrors.length > 0;
        const fvu = this.state.fvu;

        return (
            <form onSubmit={this.onFormSubmit}>
                {isSaved && <div className="alert alert-success fade show">Zapisano zmiany</div>}
                {hasGeneralErrors && generalErrors.map((e, index) => <div key={index} className="alert alert-danger"><span>{e.Message}</span><button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>)}
                <div className="mb-2">
                    <label htmlFor="firstName" className="form-label">Imię</label>
                    <input id="firstName" type="text" className={`form-control form-control-sm ${fvu.invalidClass('firstName')}`} value={employeeData.firstName} onChange={this.onFirstNameChange} />
                    {fvu.fieldFeedback('firstName')}
                </div>
                <div className="mb-2">
                    <label htmlFor="surname" className="form-label">Nazwisko</label>
                    <input id="surname" type="text" className={`form-control form-control-sm ${fvu.invalidClass('surname')}`} value={employeeData.surname} onChange={this.onSurnameChange} />
                    {fvu.fieldFeedback('surname')}
                </div>
                <div className="mb-2">
                    <label htmlFor="loginName" className="form-label">Login</label>
                    <input id="loginName" type="text" className={`form-control form-control-sm ${fvu.invalidClass('loginName')}`} value={employeeData.loginName} onChange={this.onLoginNameChange} />
                    {fvu.fieldFeedback('loginName')}
                </div>
                <div className="mb-2">
                    <label htmlFor="password" className="form-label">Hasło</label>
                    <input id="password" type="text" className={`form-control form-control-sm ${fvu.invalidClass('password')}`} value={employeeData.password} onChange={this.onLoginPasswordChange} placeholder="Wpisz by zmienić" autoComplete="new-password" />
                    {fvu.fieldFeedback('password')}
                </div>
                <div className="mb-2">
                    <label className="form-label" htmlFor="roles">Role</label>
                    <div className="input-group input-group-sm">
                        <select id="roles" className={`form-select form-select-sm ${fvu.invalidClass('Roles')}`} onChange={this.onRoleSelected} value={KnownRolesEnum.NotSelected}>
                            {this.rolesToSelect().map(this.getRoleOption)}
                        </select>
                        <span className="input-group-text">{employeeData.roles.map(this.getRoleBadge)}</span>
                        {fvu.fieldFeedback('Roles')}
                    </div>
                </div>
                <div className="mb-2">
                    <label className="form-label" htmlFor="employee-break">Przerwa</label>
                    <select id="employee-break" className="form-select form-select-sm" value={employeeData.employeeBreakId} onChange={this.onBreakChange}>
                        {this.breaksToSelect().map(breakId => <option key={breakId} value={breakId}>{EmployeeBreakText(breakId)}</option>)}
                    </select>
                </div>
                <div className="mb-2 form-check form-check-sm">
                    <input id="is-active" className="form-check-input" type="checkbox" checked={employeeData.isActive} onChange={this.onIsActiveChange} />
                    <label className="form-check-label" htmlFor="is-active">Aktywny</label>
                </div>
                <div>
                    <button className="btn btn-primary" type="submit">Zapisz</button>
                </div>
            </form>
        );
    }
}
