import React from 'react';
import { WorkTimeNormTypeEnum } from '../types/domain';
import { CabinetWorkTimeNormDTO } from '../types/dto';
import { getWorkTimeNormText } from '../utilities/domain-utils';

export type CabinetWorkTimeNormData = CabinetWorkTimeNormDTO & {
    TimeToAchivePerCabinetString: string,
    ConnectionCountString: string
};

type CabinetWorkTimeNormRowProps = {
    norm: CabinetWorkTimeNormData,
    cabinetCount: number,
    onConnectionCountChange: (connectionCountString: string) => void,
    onTimePerCabinetChange: (timePerCabinetString: string) => void,
    canEdit: boolean
};

export function CabinetWorkTimeNormRow(props: CabinetWorkTimeNormRowProps) {
    const norm = props.norm;

    const timeToAchieve = props.cabinetCount * norm.TimeToAchivePerCabinet;
    const timeDiff = timeToAchieve - norm.ActualHours;

    const isNormWithConnectionCount = (normType: WorkTimeNormTypeEnum) => {
        return normType >= WorkTimeNormTypeEnum.Cable_HauptAndErdenMoreThan16
            && normType <= WorkTimeNormTypeEnum.Cable_STV;
    };

    return (
        <tr>
            <td>
                <input type="hidden" name="WorkTimeNormType" value={norm.WorkTimeNormType} />
                {getWorkTimeNormText(norm.WorkTimeNormType)}
            </td>
            <td>{isNormWithConnectionCount(norm.WorkTimeNormType)
                ? <input
                    className="form-control form-control-sm"
                    type="number"
                    name="ConnectionCount"
                    value={norm.ConnectionCountString}
                    onChange={(event) => props.onConnectionCountChange(event.target.value)}
                    disabled={!props.canEdit}
                />
                : ''}</td>
            <td>
                <input
                    className="form-control form-control-sm"
                    type="number"
                    name="HoursToAchievePerCabinet"
                    step="0.1"
                    value={norm.TimeToAchivePerCabinetString}
                    onChange={(event) => props.onTimePerCabinetChange(event.target.value)}
                    disabled={!props.canEdit}
                />
            </td>
            <td>
                {props.cabinetCount}
            </td>
            <td>
                {timeToAchieve.toFixed(2)}
            </td>
            <td>
                {norm.ActualHours.toFixed(2)}
            </td>
            <td>
                {timeDiff.toFixed(2)}
            </td>
        </tr>
    );
}
