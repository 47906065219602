import React from "react";
import { Link } from "react-router-dom";

type AdvancedPelikanLinkProps = {
    id: number
    onOpen: () => void
};

export function AdvancedPelikanLink(props: AdvancedPelikanLinkProps) {

    return <div className="btn-group btn-group-sm">
        <button type="button" className="btn btn-outline-secondary" title="Otwórz tutaj" onClick={props.onOpen}>{props.id}</button>
        <button type="button" className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false"></button>
        <ul className="dropdown-menu dropdown-menu-end">
            <li><Link className="dropdown-item" to={`/pelikans/${props.id}`}>Otwórz link</Link></li>
        </ul>
    </div>;
}
