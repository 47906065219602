
type ToggleCollapseButtonProps = {
    isCollapsed: boolean,
    toggle: () => void
};

export function ToggleCollapseButton(props: ToggleCollapseButtonProps) {

    return <button style={{ width: "2em"}} className="btn btn-sm btn-outline-primary" type="button" onClick={props.toggle}><i className={props.isCollapsed ? "fa-solid fa-caret-right" : "fa-solid fa-caret-down"} /></button>
}
