import ApiAuthorzationRoutes, { AppRouteProps } from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { Home } from "./components/Home";
import ProjectPage from './components/ProjectPage';
import { ProjectsView } from './components/ProjectsView';
import { ProfilePage } from './components/api-authorization/ProfilePage';
import { TransportsView } from './components/TransportsView';
import { EmployeesView } from './components/EmployeesView';
import EmployeePage from './components/EmployeePage';
import { PelikansView } from './components/PelikansView';
import PelikanPage from './components/PelikanPage';
import { ProjectArchive } from './components/ProjectArchive';
import { WorktimeReportView } from './components/worktime-report/WorktimeReportView';
import { QDETicketsView } from './components/QDETicketsView';
import DepartmentPage from "./components/DepartmentPage";

const AppRoutes: AppRouteProps[] = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/projects/:projectNumber',
        requireAuth: true,
        element: <ProjectPage />
    },
    {
        path: '/projects',
        requireAuth: true,
        element: <ProjectsView />
    },
    {
        path: '/archive',
        requireAuth: true,
        element: <ProjectArchive />
    },
    {
        path: '/pelikans/:pelikanId',
        requireAuth: true,
        element: <PelikanPage />
    },
    {
        path: '/pelikans',
        requireAuth: true,
        element: <PelikansView />
    },
    {
        path: '/transports',
        requireAuth: true,
        element: <TransportsView />
    },
    {
        path: '/reports/worktimes',
        requireAuth: true,
        element: <WorktimeReportView isVisible={true} />
    },
    {
        path: '/reports/my-worktimes',
        requireAuth: true,
        element: <WorktimeReportView isVisible={true} myWorktime={true} />
    },
    {
        path: '/employees/:id',
        requireAuth: true,
        element: <EmployeePage />
    },
    {
        path: '/employees',
        requireAuth: true,
        element: <EmployeesView />
    },
    {
        path: '/qde-tickets',
        requireAuth: true,
        element: <QDETicketsView />
    },
    {
        path: ApplicationPaths.IdentityManagePath,
        requireAuth: true,
        element: <ProfilePage />
    },
    {
        path: 'department/:id',
        requireAuth: true,
        element: <DepartmentPage />
    },
    ...ApiAuthorzationRoutes
];

export default AppRoutes;
