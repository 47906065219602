import { UserManager, UserData, UserProfile } from './UserManager';
import { KnownRolesEnum } from '../../types/domain';
import { SettingsData, UserSettings } from './UserSettings';
import { OperationResultDTO } from '../../types/dto';

type AuthDTO = {
    authToken: string,
    logoutToken: string,
    userName: string,
    roleIds: number[],
    employeeId: number
};

export type RequestResult = {
    status: AuthenticationResultStatus,
    message?: string,
    state?: RequestState,
    errors: { [key: string]: string[] }
};

export type RequestState = {
    returnUrl: string,
    formData?: FormData
};

type CallbackFunc = () => void;

type CallbackSubscription = {
    callback: CallbackFunc,
    subscription: number
};

export class AuthorizeService {
    _callbacks: CallbackSubscription[] = [];
    _nextSubscriptionId: number = 0;
    _user?: UserData = undefined;
    _isAuthenticated: boolean = false;
    userManager: UserManager = new UserManager();
    userSettings: UserSettings = new UserSettings(this.userManager);

    constructor() {
    }

    // By default pop ups are disabled because they don't work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    _popUpDisabled = true;

    async isAuthenticated() {
        const user = await this.getUser();
        return !!user;
    }

    getUserProfileSync(): UserProfile {
        return this.userManager.getUserProfileSync();
    }

    getEmployeeIdSync(): number {
        return this.userManager.getUserProfileSync().employeeId;
    }

    hasUserRoleSync(role: KnownRolesEnum): boolean {
        const profile = this.getUserProfileSync();

        return profile.roleIds.includes(role);
    }


    // TODO - Rename to getUserProfile
    async getUser() {
        if (this._user && this._user!.profile) {
            return this._user.profile;
        }

        // await this.ensureUserManagerInitialized();
        const user = await this.userManager.getUser();
        return user && user.profile;
    }

    async getAccessToken() {
        // await this.ensureUserManagerInitialized();
        const user = await this.userManager.getUser();
        return user && user.access_token;
    }

    // We try to authenticate the user in three different ways:
    // 1) We try to see if we can authenticate the user silently. This happens
    //    when the user is already logged in on the IdP and is done using a hidden iframe
    //    on the client.
    // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
    //    redirect flow.
    async signIn(state: RequestState): Promise<RequestResult> {

        const response = await fetch('api/auth/login',
            { method: 'post', body: state.formData }
        );

        if (response.status != 200) {
            const errorData = await response.json();    // TODO as ValidationFailedResult
            console.error('Login error', errorData);

            return this.error("Błąd logowania");
            //    return {
            //        status: 'fail',
            //        errors: {}  // TODO - put errors here
            //    }
        }

        const resultData = await response.json() as AuthDTO;
        const user = this.buildUserFromLoginResponse(resultData);

        await this.userManager.loginUser(user);

        this.userSettings.loadSettings();

        this.updateState(user);

        return this.success(state);
    }

    // We try to sign out the user in two different ways:
    // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
    //    post logout redirect flow.
    async signOut(state: RequestState): Promise<RequestResult> {
        // await this.ensureUserManagerInitialized();

        this.userSettings.saveAndUnload();

        //const response = await fetch('api/auth/logout',
        //    { method: 'post' }
        //);

        //if (response.status != 200) {
        //    const errorData = await response.json();
        //    console.error('Logout error', errorData);

        //    // NOTE - This may happen when user is already logged out (token has expired)
        //    // but dont return with error, just proceed
        //} else {
        //    const result = await response.json() as OperationResultDTO;

        //    if (!result || !result.Success) {
        //        console.error('Logout error', result.Errors);
        //    } else {
        //        console.log('Logout successful');
        //    }
        //    // NOTE - This may happen when user is already logged out (token has expired)
        //    // but dont return with error, just proceed
        //}

        await this.userManager.logout();

        this.updateState(undefined);

        return this.success(state);
    }

    updateState(user?: UserData) {
        this._user = user;
        this._isAuthenticated = !!this._user;
        this.notifySubscribers();
    }

    subscribe(callback: () => void) {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId: number) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length !== 1) {
            throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }

        this._callbacks.splice(subscriptionIndex[0].index!, 1);
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    error(message: string): RequestResult {
        return {
            status: 'fail', message, errors: {} };
    }

    success(state: RequestState): RequestResult {
        return {
            status: 'success', state, errors: {} };
    }

    redirect(): RequestResult {
        return {
            status: 'redirect', errors: {} };
    }

    private buildUserFromLoginResponse(response: AuthDTO): UserData | undefined {
        return {
            profile: {
                userName: response.userName,
                roleIds: response.roleIds,
                employeeId: response.employeeId
            },
            access_token: response.authToken
        };
    }

    getUserSettings(): SettingsData {
        return this.userSettings.get();
    }

    setUserSettings(settings: any, save: boolean) {
        this.userSettings.set(settings, save);
    }

    static get instance() { return authService }
}

const authService = new AuthorizeService();

export default authService;

export type AuthenticationResultStatus = 'redirect' | 'success' | 'fail';
