export type UserProfile = {
    userName: string
    roleIds: number[],
    employeeId: number
};

export type UserData = {
    profile: UserProfile,
    access_token: string,
};

export const nullUserProfile: UserProfile = {
    userName: '',
    roleIds: [],
    employeeId: 0
};

export class UserManager {
    _currentUser?: UserData;

    constructor() {
        const cuString = localStorage.getItem("currentUser");
        if (cuString && cuString !== 'undefined') {
            this._currentUser = JSON.parse(cuString);
        }
    }

    async getUser(): Promise<UserData | undefined> {
        return this._currentUser;
    }

    async loginUser(user: UserData | undefined): Promise<UserData|undefined> {
        this._currentUser = user;

        // TODO save as cookie
        localStorage.setItem("currentUser", JSON.stringify(this._currentUser));

        return await this.getUser();
    }

    async logout(): Promise<UserData|undefined> {
        this._currentUser = undefined;
        localStorage.setItem("currentUser", JSON.stringify(this._currentUser));
        return await this.getUser();
    }

    getUserProfileSync(): UserProfile {
        return (this._currentUser && this._currentUser.profile) || nullUserProfile;
    }
}
