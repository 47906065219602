import React, { Component, Fragment } from 'react';
import { MajorProjectStateEnum, ProjectStateFlags } from '../types/domain';
import { BAMProjectDTO, OperationResultWithDataDTO } from '../types/dto';
import { StringDictionary } from '../types/global';
import { apiFetchResponse } from '../utilities/auth-api';
import { EnhanceProjects } from '../utilities/data-enhancer';
import { DateToDateString } from '../utilities/date-formatter';
import { getHoursDurationText } from '../utilities/date-utils';
import { AllowsFeature, Features } from '../utilities/features';
import authService from './api-authorization/AuthorizeService';
import { ProjectLink } from './common/ProjectLink';
import { ProjectPartialNumberPicker } from './common/ProjectPartialNumberPicker';
import SortableTable, { ColumnOptionsCollection } from './common/SortableTable';
import { StatusIndicator } from './StatusIndicator';

type ProjectArchiveProps = {
};

type ProjectArchiveState = {
    Projects: BAMProjectDTO[],
    IsLoading: boolean,
    PartialProjectNumber: string
    DateFrom: string,
    DateTo: string,
    isExtendedView: boolean
};

export class ProjectArchive extends Component<ProjectArchiveProps, ProjectArchiveState> {
    static displayName = ProjectArchive.name;

    constructor(props: ProjectArchiveProps) {
        super(props);

        const date = new Date();
        date.setMonth(date.getMonth() - 1);

        this.state = {
            Projects: [],
            IsLoading: false,
            isExtendedView: AllowsFeature(Features.ExtendedProjectView, authService.getUserProfileSync()),
            PartialProjectNumber: '',
            DateFrom: DateToDateString(date),
            DateTo: ''
        };

        this.fetchProjects = this.fetchProjects.bind(this);
        this.dateFromChanged = this.dateFromChanged.bind(this);
        this.clearDateFrom = this.clearDateFrom.bind(this);
        this.dateToChanged = this.dateToChanged.bind(this);
        this.clearDateTo = this.clearDateTo.bind(this);
        this.onProjectNumberChange = this.onProjectNumberChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.fetchProjects();
    }

    private columns() {
        const columns = {} as StringDictionary;

        if (this.state.isExtendedView) {
            columns['ProjectNumber'] = 'Numer';
            columns['MajorProjectState'] = 'Status';
            columns['StartDate'] = 'Start';
            columns['EndDate'] = 'End';
            columns['ClientName'] = 'Klient';
            columns['ScheduledTimeHours'] = 'Zapl. czas';
            columns['ModelDescription'] = 'Model';
            columns['CabinetCount'] = 'Liczba szaf';
            columns['SuggestedWorkersCount'] = 'Liczba prac.';
            columns['RealTimeSpentHours'] = 'Przepr. czas';
            columns['ExtraUnscheduledTimeHours'] = 'QDE';
            columns['ExportTransport.ExportDate'] = 'Data wysyłki';

        } else {
            columns['ProjectNumber'] = 'Numer';
            columns['MajorProjectState'] = 'Status';
            columns['ClientName'] = 'Klient';
            columns['ScheduledTimeHours'] = 'Zapl. czas';
            columns['ModelDescription'] = 'Model';
            columns['CabinetCount'] = 'Liczba szaf';
            columns['RealTimeSpentHours'] = 'Przepr. czas';
            columns['ExtraUnscheduledTimeHours'] = 'QDE';
            columns['ExportTransport.ExportDate'] = 'Data wysyłki';
        }

        return columns;
    }

    private columnOptions(): ColumnOptionsCollection {
        const options = {} as ColumnOptionsCollection;

        options['ProjectNumber'] = {
            enableFilter: true,
            valueRenderer: (value) => <ProjectLink projectNumber={value as number} />
        };
        options['MajorProjectState'] = {
            valueRenderer: (value) => (<StatusIndicator status={value as MajorProjectStateEnum} />)
        };
        options['ClientName'] = {
            enableFilter: true
        };
        options['ModelDescription'] = {
            enableFilter: true
        };
        options['RealTimeSpentHours'] = {
            valueRenderer: (value: unknown) => {
                const hours = value as number;

                return getHoursDurationText(hours);
            }
        }

        return options;
    }

    projectRowClass(unknownData: any): string {
        const data = unknownData as BAMProjectDTO;

        if ((data.ProjectStateFlags & ProjectStateFlags.WorkInitiated) > 0) {
            return "table-primary";
        }
        return "";
    }

    async fetchProjects() {

        const data: Record<string, string> = {};

        if (this.state.PartialProjectNumber) {
            data['partialProjectNumber'] = this.state.PartialProjectNumber;
        }

        if (this.state.DateFrom) {
            const dateFrom = new Date(this.state.DateFrom);
            data['dateFrom'] = dateFrom.getTime().toString();
        }
        if (this.state.DateTo) {
            const dateTo = new Date(this.state.DateTo);
            data['dateTo'] = dateTo.getTime().toString();
        }

        const params = new URLSearchParams(data);
        const queryString = '?' + params.toString();

        this.setState({ IsLoading: true });

        const response = await apiFetchResponse(`project/archive${queryString}`);
        let projects: BAMProjectDTO[] = [];

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<BAMProjectDTO[]>;

            projects = result.Success
                ? result.Data!
                : [];

            EnhanceProjects(projects);
        }

        this.setState({
            Projects: projects,
            IsLoading: false
        });
    }

    private onSubmit(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        this.fetchProjects();
    };

    dateFromChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            DateFrom: event.target.value
        });
    }

    clearDateFrom() {
        this.setState({
            DateFrom: ''
        });
    }

    dateToChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            DateTo: event.target.value
        });
    }

    clearDateTo() {
        this.setState({
            DateTo: ''
        });
    }

    onProjectNumberChange(partialProjectNumber: string) {
        this.setState({
            PartialProjectNumber: partialProjectNumber
        });
    }

    render() {
        return (
            <Fragment>
                <form onSubmit={this.onSubmit}>
                    <div className='row'>
                        <div className='col-auto'>
                            <button className='btn btn-outline-secondary h-100' type='submit' title='Wyszukaj' disabled={this.state.IsLoading}>
                                <span className={this.state.IsLoading ? 'spinner-border' : 'fa-solid fa-search'}></span>
                            </button>
                        </div>
                        <div className='col-auto'>
                            <div className='input-group'>
                                <div className='form-floating'>
                                    <input type="date" className='form-control form-control-sm' id='dateFrom' value={this.state.DateFrom} onChange={this.dateFromChanged} />
                                    <label htmlFor="dateFrom">Zmiany od</label>
                                </div>
                                <button className="btn btn-outline-secondary" type="button" onClick={this.clearDateFrom}><i className='fa-solid fa-times' /></button>
                            </div>
                        </div>
                        <div className='col-auto'>
                            <div className='input-group'>
                                <div className='form-floating'>
                                    <input type="date" className='form-control form-control-sm' id='dateTo' value={this.state.DateTo} onChange={this.dateToChanged} />
                                    <label htmlFor="dateTo">Zmiany do</label>
                                </div>
                                <button className="btn btn-outline-secondary" type="button" onClick={this.clearDateTo}><i className='fa-solid fa-times' /></button>
                            </div>
                        </div>
                        <div className='col-auto'>
                            <ProjectPartialNumberPicker onProjectNumberChange={this.onProjectNumberChange} />
                        </div>
                    </div>
                </form>
                <SortableTable idKey='Id'
                    dataRows={this.state.Projects}
                    columns={this.columns()}
                    columnOptions={this.columnOptions()}
                    rowClassesFunc={this.projectRowClass}
                />
            </Fragment>
        );
    }
}
