import { useEffect, useState } from "react";
import TimeDuration from "../utilities/time-duration";
import { ProjectLink } from "./common/ProjectLink";
import { useSelector } from 'react-redux';
import { IRootState, useAppDispatch } from '../store/app-store';
import { OperationResultWithDataDTO, WorkStatusWithDates, WorktimeEntryDTO } from "../types/dto";
import authService from "./api-authorization/AuthorizeService";
import { IsSameDay } from "../utilities/date-utils";
import { fetchWorkStatus } from "../store/work-status-slice";
import { Dropdown } from 'reactstrap';
import { apiFetchResponse } from "../utilities/auth-api";
import { DepartmentName, DetermineWorkStatusNow } from "../utilities/domain-utils";
import { DateToWorkTimeString } from "../utilities/date-formatter";

export function WorkStatusPane() {

    const [description, setDescription] = useState('');
    const [projectNumber, setProjectNumber] = useState<number | undefined>();
    const [departmentId, setDepartmentId] = useState<number | undefined>();
    const [isLoading, setIsLoading] = useState(false);
    const ws = useSelector((state: IRootState) => state.workStatus.value);
    const fetchStatus = useSelector((state: IRootState) => state.workStatus.fetchStatus);
    const dispatch = useAppDispatch();
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    const toggleDropdown = () => setIsDropdownOpen((prevState) => !prevState);

    const wsWithDates: WorkStatusWithDates | undefined = Object.assign({}, ws);
    wsWithDates.Day = new Date(wsWithDates.DayJsMilisecs);
    if (wsWithDates.TodayWorkStartJsMilisecs) {
        wsWithDates.TodayWorkStart = new Date(wsWithDates.TodayWorkStartJsMilisecs);
    }

    // Check if refetching is needed
    const isWorkStatusInvalid = function (currentWorkStatus?: WorkStatusWithDates): boolean {

        const employeeId = authService.getEmployeeIdSync();

        let isInvalid = false;
        const nowDate = new Date();

        if (!currentWorkStatus) {
            // console.log('No currentWorkStatus. So fetch it.');
            isInvalid = true;
        }
        else if (currentWorkStatus.EmployeeId != employeeId) {
            // console.log('Is not same employee', currentWorkStatus.EmployeeId, employeeId);
            isInvalid = true;
        } else if (!currentWorkStatus.Day || !IsSameDay(currentWorkStatus.Day, nowDate)) {
            // console.log('Is was not retrieved today', currentWorkStatus.Day, nowDate);
            isInvalid = true;
        }

        return isInvalid;
    }

    const updateView = async function () {

        setIsLoading(fetchStatus == 'loading');

        if (isWorkStatusInvalid(wsWithDates)) {
            await dispatch(fetchWorkStatus());
        }

        const nowDate = new Date();

        if (!wsWithDates || !wsWithDates.LatestWorkEntry) {
            setDescription("");
            setProjectNumber(undefined);
        } else if (!wsWithDates.TodayWorkStart) {
            if (!wsWithDates.LatestWorkEntry.EndJsMilisecs) {
                const latestWorkStart = new Date(wsWithDates.LatestWorkEntry.StartJsMilisecs);
                const workDuration = TimeDuration.FromDates(latestWorkStart, nowDate);
                setDescription(`Wciąż trwa praca! ${workDuration.ElapsedTimeString()}`);
            } else {
                setDescription("Nie rozpoczęto pracy");
            }
            setProjectNumber(wsWithDates.LatestProjectNumber);
            setDepartmentId(wsWithDates.LatestWorkEntry?.Department?.Id);
        } else {    // Ustawiono TodayWorkStart

            setProjectNumber(wsWithDates.LatestProjectNumber);
            setDepartmentId(wsWithDates.LatestWorkEntry?.Department?.Id);

            const workStatusNow = DetermineWorkStatusNow(wsWithDates, nowDate);

            if (workStatusNow) {
                if (workStatusNow.isBeforeWorkStart) {
                    setDescription(`Praca rozpocznie się o ${DateToWorkTimeString(wsWithDates.TodayWorkStart, true)}`);
                } else if (workStatusNow.isInsideBreak) {
                    setDescription(`Przerwa ${wsWithDates.WorkBreakStartTime}-${wsWithDates.WorkBreakEndTime}`);
                } else {
                    setDescription(`Praca ${workStatusNow.workDuration.ElapsedTimeString()}`);
                }
            }
        }
    };

    // TODO - Use useEffectEvent when available
    // https://react.dev/reference/react/useEffect#reading-the-latest-props-and-state-from-an-effect
    useEffect(() => {

        updateView();

        const intervalId = setInterval(() => {
            updateView();
        }, 1000);   // call every second

        return () => {
            clearInterval(intervalId);
        }
    }, [ws]);

    const showStopButton = !!(wsWithDates
        && wsWithDates.LatestWorkEntry
        && !wsWithDates.LatestWorkEntry.EndJsMilisecs);

    const onStopCurrentWork = async function () {
        var response = await apiFetchResponse(`worktime/current/stop`, {
            method: 'PATCH'
        });

        // TODO - handle error result
        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<WorktimeEntryDTO>;
            if (result.Success) {

                setIsDropdownOpen(false);

                await dispatch(fetchWorkStatus());

                // TODO - If on project page, refresh it
            }
        }
    }

    if (!description && !projectNumber) {
        return null;
    } else {
        return <div className="text-dark nav-link">
            {description} {showStopButton && <Dropdown className="d-inline-block" isOpen={isDropdownOpen} toggle={toggleDropdown}>
                <button className="btn btn-sm btn-outline-primary dropdown-toggle" data-bs-auto-close="outside" data-bs-toggle="dropdown" aria-expanded="false" type="button" title="Zakończ pracę"><i className="fa-solid fa-stop" /></button>
                <div className="dropdown-menu dropdown-menu-end p-2">
                    <p>Wybierz tę opcję w przypadku, gdy kończysz pracę w danym dniu</p>
                    <p>Czy na pewno chcesz zakończyć pracę?</p>
                    <button className="btn btn-primary" onClick={onStopCurrentWork}>Zakończ pracę</button>
                </div>
            </Dropdown>} {projectNumber && <>, Projekt <ProjectLink projectNumber={projectNumber} /></>} {departmentId && DepartmentName(departmentId)} {isLoading && <small className='spinner-border'></small>}
        </div>;
    }
}
