import React, { Component, Fragment } from 'react';

type ProjectNumberPickerProps = {
    onProjectNumberChange: (projectNumber?: number) => void
};

type ProjectNumberPickerState = {
    projectNumber: number
};

export class ProjectNumberPicker extends Component<ProjectNumberPickerProps, ProjectNumberPickerState> {
    static displayName = ProjectNumberPicker.name;

    constructor(props: ProjectNumberPickerProps) {
        super(props);

        this.state = {
            projectNumber: 0
        };

        this.projectNumberChanged = this.projectNumberChanged.bind(this);

        this.props.onProjectNumberChange(undefined);
        this.clearProjectNumber = this.clearProjectNumber.bind(this);
    }

    projectNumberChanged(event: React.ChangeEvent<HTMLInputElement>) {
        let projectNumber: number = 0;

        if (event.target.value) {
            projectNumber = parseInt(event.target.value);
        }

        this.setState({
            projectNumber
        });

        this.props.onProjectNumberChange(projectNumber || undefined);
    }

    clearProjectNumber() {
        this.setState({
            projectNumber: 0
        });

        this.props.onProjectNumberChange(undefined);
    }

    render() {
        return (
            <div className='input-group'>
                <div className='form-floating'>
                    <input type="number" className='form-control form-control-sm' id='projectNumber' value={this.state.projectNumber} onChange={this.projectNumberChanged} />
                    <label htmlFor="projectNumber">Numer projektu</label>
                </div>
                <button className="btn btn-outline-secondary" type="button" onClick={this.clearProjectNumber}><i className='fa-solid fa-times' /></button>
            </div>
        );
    }
}
