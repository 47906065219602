import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import workStatusReducer from './work-status-slice';

const store = configureStore({
    reducer: {
        workStatus: workStatusReducer
    }
});

export default store;

export type IRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch // Export a hook that can be reused to resolve types
