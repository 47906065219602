import React, { Component, createRef } from 'react';
import { WorkSubtypeEnum, WorkTypeEnum, WorkTypeDescriptor, KnownDepartmentsEnum } from '../../types/domain';
import { DepartmentWorkTypeExDTO, EmployeeBriefDTO, OperationResultWithDataDTO, WorktimeReportDTO, WorktimeReportEntryDTO } from '../../types/dto';
import { apiFetchResponse } from '../../utilities/auth-api';
import { CalculateDates } from '../../utilities/data-enhancer';
import authService from '../api-authorization/AuthorizeService';
import { EmployeePicker } from '../EmployeePicker';
import { ProjectNumberPicker } from '../ProjectNumberPicker';
import { ProjectWorktimeReportTable, ProjectWorktimeReportTableHandle } from './ProjectWorktimeReportTable';
import { ReportDateRangePicker } from '../ReportDateRangePicker';
import { DepartmentWorktimeReportTable, DepartmentWorktimeReportTableHandle } from './DepartmentWorktimeReportTable';
import { ElapsedTimeInMilisecsText } from '../../utilities/date-utils';
import { DepartmentPicker } from '../DepartmentPicker';

type WorktimeReportViewProps = {
    projectNumber?: number,
    isVisible: boolean,
    myWorktime?: boolean,
    departmentId?: KnownDepartmentsEnum
};


type ProjectDepartmentViewType = "project" | "department";

type WorktimeReportViewState = {
    IsLoading: boolean,
    dateFrom?: Date,
    dateTo?: Date,
    projectNumber?: number,
    departmentId?: KnownDepartmentsEnum,
    employeeId?: number,
    report?: WorktimeReportDTO,
    reportDepartmentTypes: DepartmentWorkTypeExDTO[],
    reportDepartmens: DepartmentWorkTypeExDTO[],
    hasProjectEntries: boolean,
    hasDepartmentEntries: boolean,
    showSubtypes: boolean,
    showDepartmentTypes: boolean,
    viewType: ProjectDepartmentViewType,
    totalProjectsMilliseconds?: number,
    totalDepartmentsMilliseconds?: number
};

export class WorktimeReportView extends Component<WorktimeReportViewProps, WorktimeReportViewState> {
    static displayName = WorktimeReportView.name;

    workTypeColumns: WorkTypeDescriptor[] = [
        {
            Type: WorkTypeEnum.ControlBoard
        },
        {
            Type: WorkTypeEnum.Assembly
        },
        {
            Type: WorkTypeEnum.Cable
        },
        {
            Type: WorkTypeEnum.Fixes
        },
        {
            Type: WorkTypeEnum.QDE
        },
        {
            Type: WorkTypeEnum.P0
        },
        {
            Type: WorkTypeEnum.Testing
        },
        {
            Type: WorkTypeEnum.AdditionalWorks
        }
    ];

    workTypeAndSubtypeColumns: WorkTypeDescriptor[] = [
        {
            Type: WorkTypeEnum.ControlBoard
        },
        {
            Type: WorkTypeEnum.Assembly
        },
        {
            Type: WorkTypeEnum.Cable,
            SubType: WorkSubtypeEnum.None
        },
        {
            Type: WorkTypeEnum.Cable,
            SubType: WorkSubtypeEnum.Cable_HauptLess16qmm
        },
        {
            Type: WorkTypeEnum.Cable,
            SubType: WorkSubtypeEnum.Cable_HauptMore16qmm
        },
        {
            Type: WorkTypeEnum.Cable,
            SubType: WorkSubtypeEnum.Cable_ErdenLess16qmm
        },
        {
            Type: WorkTypeEnum.Cable,
            SubType: WorkSubtypeEnum.Cable_ErdenMore16qmm
        },
        {
            Type: WorkTypeEnum.Cable,
            SubType: WorkSubtypeEnum.Cable_230VAC
        },
        {
            Type: WorkTypeEnum.Cable,
            SubType: WorkSubtypeEnum.Cable_24VDC
        },
        {
            Type: WorkTypeEnum.Cable,
            SubType: WorkSubtypeEnum.Cable_Steuerung
        },
        {
            Type: WorkTypeEnum.Cable,
            SubType: WorkSubtypeEnum.Cable_STV
        },
        {
            Type: WorkTypeEnum.Cable,
            SubType: WorkSubtypeEnum.Cable_Kabel
        },
        {
            Type: WorkTypeEnum.Fixes
        },
        {
            Type: WorkTypeEnum.QDE
        },
        {
            Type: WorkTypeEnum.P0
        },
        {
            Type: WorkTypeEnum.Testing
        },
        {
            Type: WorkTypeEnum.AdditionalWorks
        }
    ];

    projectTableRef = createRef<ProjectWorktimeReportTableHandle>();
    departmentTableRef = createRef<DepartmentWorktimeReportTableHandle>();

    constructor(props: WorktimeReportViewProps) {
        super(props);

        let employeeId: number | undefined = undefined;

        if (props.myWorktime) {
            const userProfile = authService.getUserProfileSync();
            employeeId = userProfile.employeeId;
        }

        let viewType: ProjectDepartmentViewType = "project";
        if (!!props.departmentId) {
            viewType = "department";
        }

        this.state = {
            IsLoading: false,
            projectNumber: props.projectNumber,
            reportDepartmentTypes: [],
            reportDepartmens: [],
            hasProjectEntries: false,
            hasDepartmentEntries: false,
            showSubtypes: false,
            employeeId,
            showDepartmentTypes: false,
            viewType
        };

        this.onDateChange = this.onDateChange.bind(this);
        this.onProjectNumberChange = this.onProjectNumberChange.bind(this);
        this.onDepartmentSelected = this.onDepartmentSelected.bind(this);
        this.onEmployeeChanged = this.onEmployeeChanged.bind(this);
        this.fetchWorktimes = this.fetchWorktimes.bind(this);
        this.getProjectWorkTypeColumns = this.getProjectWorkTypeColumns.bind(this);
        this.showSubtypesToggleClass = this.showSubtypesToggleClass.bind(this);
        this.toggleShowSubtypes = this.toggleShowSubtypes.bind(this);
        this.getEmployeesToSelect = this.getEmployeesToSelect.bind(this);
        this.shouldPickerFetchEmployees = this.shouldPickerFetchEmployees.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
        this.toggleViewType = this.toggleViewType.bind(this);
    }

    componentDidUpdate(prevProps: WorktimeReportViewProps) {
        if (this.props.isVisible
            && ((!prevProps.isVisible
            && this.state.report == undefined)
            || this.props.projectNumber != prevProps.projectNumber
            || this.props.departmentId != prevProps.departmentId
            )) {
            this.fetchWorktimes();
        }
    }

    getReportDepartmentTypes(report?: WorktimeReportDTO): DepartmentWorkTypeExDTO[] {
        const resultWorkTypes: DepartmentWorkTypeExDTO[] = [];

        if (report) {
            const entries = report.Entries;

            entries.forEach(wre => {
                const entryDepWorkType = wre.DepartmentWorkType;
                if (entryDepWorkType) {
                    const existingWt = resultWorkTypes.find(rwt => rwt.Id == wre.DepartmentWorkType!.Id);
                    if (!existingWt) {
                        resultWorkTypes.push({
                            ...entryDepWorkType,
                            DepartmentId: wre.DepartmentId!
                        });
                    }
                }
            });

            resultWorkTypes.sort((a, b) => {
                // First by department Id
                if (a.DepartmentId < b.DepartmentId) return -1;
                if (a.DepartmentId > b.DepartmentId) return 1;

                // Then by Id
                if (a.Id < b.Id) return -1;
                if (a.Id > b.Id) return 1;
                return 0;
            });
        }

        return resultWorkTypes;
    }

    getProjectWorkTypeColumns(): WorkTypeDescriptor[] {
        return this.state.showSubtypes
            ? this.workTypeAndSubtypeColumns
            : this.workTypeColumns;
    }

    getDepartmentWorkTypeColumns(): DepartmentWorkTypeExDTO[] {
        return this.state.showDepartmentTypes
            ? this.state.reportDepartmentTypes
            : this.state.reportDepartmens;
    }

    calculateTotalProjectsMilliseconds = (report?: WorktimeReportDTO) => {
        const entries = this.projectEntries(report);

        return entries.length
            ? entries.reduce((total, item) => {
                return total += item.TotalMilliseconds
            }, 0)
            : undefined;
    }

    calculateTotalDepartmentsMilliseconds = (report?: WorktimeReportDTO) => {
        const entries = this.departmentEntries(report);
        return entries.length
            ? entries.reduce((total, item) => {
                return total += item.TotalMilliseconds
            }, 0)
            : undefined;
    }

    async fetchWorktimes() {
        this.setState({
            IsLoading: true
        });

        const data: Record<string, string> = {};

        if (this.state.dateFrom) {
            data['dateFrom'] = this.state.dateFrom.getTime().toString();
        }
        if (this.state.dateTo) {
            data['dateTo'] = this.state.dateTo.getTime().toString();
        }
        if (this.state.projectNumber) {
            data['projectNumber'] = this.state.projectNumber.toString();
        }
        if (this.state.employeeId && !this.props.myWorktime) {
            data['employeeIds'] = this.state.employeeId.toString()
        }
        if (this.props.departmentId) {
            data['departmentId'] = this.props.departmentId.toString();
        } else if (this.state.departmentId) {
            data['departmentId'] = this.state.departmentId.toString();
        }

        const params = new URLSearchParams(data);
        const queryString = '?' + params.toString();
        const baseUrl = this.props.myWorktime
            ? 'worktime/my-report'
            : 'worktime/report';

        const response = await apiFetchResponse(`${baseUrl}${queryString}`);

        this.setState({
            IsLoading: false
        });

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<WorktimeReportDTO>;

            const report = result.Success
                ? result.Data!
                : undefined;

            CalculateDates(report);
            const reportDepartmentTypes = this.getReportDepartmentTypes(report);
            const reportDepartmens = this.getReportDepartments(reportDepartmentTypes);
            const hasProjectEntries = this.getHasProjectEntries(report);
            const hasDepartmentEntries = this.getHasDepartmentEntries(report);
            const totalProjectsMilliseconds = this.calculateTotalProjectsMilliseconds(report);
            const totalDepartmentsMilliseconds = this.calculateTotalDepartmentsMilliseconds(report);

            let viewType = this.state.viewType;
            // change viewType for not applicable data
            if (hasProjectEntries && !hasDepartmentEntries && viewType != "project") {
                viewType = "project";
            }
            else if (hasDepartmentEntries && !hasProjectEntries && viewType != "department") {
                viewType = "department";
            }

            this.setState({
                report,
                reportDepartmentTypes,
                reportDepartmens,
                hasProjectEntries,
                hasDepartmentEntries,
                viewType,
                totalProjectsMilliseconds,
                totalDepartmentsMilliseconds
            });

        }
    }

    getHasProjectEntries(report: WorktimeReportDTO | undefined): boolean {
        if (report && report.Entries && report.Entries.length) {
            return report.Entries
                .some(e => e.WorkType > WorkTypeEnum.NotSelected);
        }

        return false;
    }

    getHasDepartmentEntries(report: WorktimeReportDTO | undefined): boolean {
        if (report && report.Entries && report.Entries.length) {
            return report.Entries
                .some(e => e.DepartmentId);
        }

        return false;
    }

    getReportDepartments(reportDepartmentTypes: DepartmentWorkTypeExDTO[]): DepartmentWorkTypeExDTO[] {
        const result: KnownDepartmentsEnum[] = [];

        reportDepartmentTypes.forEach(rdt => {
            if (!result.includes(rdt.DepartmentId)) {
                result.push(rdt.DepartmentId);
            }
        });

        result.sort();

        return result.map(dId => {
            return {
                DepartmentId: dId,
                Id: 0,
                Name: '',
                Deleted: false
            }
        });
    }

    onDateChange(dateFrom?: Date, dateTo?: Date) {
        this.setState({
            dateFrom,
            dateTo
        });
    }

    onProjectNumberChange(projectNumber?: number) {
        this.setState({
            projectNumber
        });
    }

    onDepartmentSelected(departmentId?: KnownDepartmentsEnum) {
        this.setState({
            departmentId
        });
    }

    onEmployeeChanged(employee?: EmployeeBriefDTO) {
        this.setState({
            employeeId: employee?.Id
        });
    }

    showSubtypesToggleClass() {
        return this.state.showSubtypes
            ? "fa-solid fa-toggle-on"
            : "fa-solid fa-toggle-off";
    }

    showDepartmentTypesToggleClass = () => this.state.showDepartmentTypes
        ? "fa-solid fa-toggle-on"
        : "fa-solid fa-toggle-off";

    toggleShowSubtypes() {
        this.setState({
            showSubtypes: !this.state.showSubtypes
        });
    }

    toggleShowDepartmentTypes = () => {
        this.setState({
            showDepartmentTypes: !this.state.showDepartmentTypes
        });
    }

    toggleViewType() {
        this.setState({
            viewType: this.state.viewType == "project"
                ? "department" : "project"
        });
    }

    getEmployeesToSelect() {
        if (this.props.projectNumber) {
            return this.state.report && this.state.report.EmployeesToSelect
                ? this.state.report.EmployeesToSelect
                : [];
        }

        return undefined;
    }

    shouldPickerFetchEmployees() {
        return !this.props.projectNumber;
    }

    exportToExcel = () => {

        // TODO - For now only Export of project entries is implemented
        if (this.isProjectViewType()) {
            this.projectTableRef.current?.exportToExcel();
        } else if (this.isDepartmentViewType()) {
            this.departmentTableRef.current?.exportToExcel();
        }
    }

    projectEntries(report?: WorktimeReportDTO): WorktimeReportEntryDTO[] {
        const hasReportData = !!report
            && report.Entries.length > 0;

        return hasReportData
            ? report!.Entries.filter(e => e.WorkType)
            : [];
    }

    departmentEntries(report?: WorktimeReportDTO): WorktimeReportEntryDTO[] {
        const hasReportData = !!report
            && report.Entries.length > 0;

        return hasReportData
            ? report!.Entries.filter(e => e.DepartmentId)
            : [];
    }

    onSwitchToProjectViewTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            viewType: event.target.checked
                ? "project" : "department"
        });
    }

    onSwitchToDepartmentViewTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            viewType: event.target.checked
                ? "department" : "project"
        });
    }

    isProjectNumberGiven = () => !!this.props.projectNumber;
    isProjectViewType = () => this.state.viewType == "project";
    isDepartmentViewType = () => this.state.viewType == "department";
    hasReportData = () => !!this.state.report
        && this.state.report.Entries.length > 0;

    render() {

        return (
            <>
                <div className='row'>
                    <div className='col-auto'>
                        <button className='btn btn-outline-secondary h-100' type='button' title='Odśwież' disabled={this.state.IsLoading} onClick={this.fetchWorktimes}>
                            <span className={this.state.IsLoading ? 'spinner-border' : 'fa-solid fa-redo'}></span>
                        </button>
                    </div>
                    <div className='col-auto'>
                        <ReportDateRangePicker onDateChange={this.onDateChange} />
                    </div>
                    {!this.props.projectNumber && !this.props.departmentId && <div className='col-auto'>
                        <ProjectNumberPicker onProjectNumberChange={this.onProjectNumberChange} />
                    </div>}
                    {!this.props.projectNumber && !this.props.departmentId && <div className='col-auto'>
                        <DepartmentPicker onDepartmentSelected={this.onDepartmentSelected} />
                    </div>}
                    {!this.props.myWorktime && < div className='col-auto'>
                        <EmployeePicker
                            onEmployeeChanged={this.onEmployeeChanged}
                            label="Pracownik"
                            inputId="employee-id"
                            employeesToSelect={this.getEmployeesToSelect()}
                            fetchEmployees={this.shouldPickerFetchEmployees()} />
                    </div>}
                    {this.isProjectViewType() && <div className="col-auto">
                        <button className='btn btn-outline-secondary h-100' onClick={this.toggleShowSubtypes}>Wyświetl podtypy <i className={this.showSubtypesToggleClass()}></i></button>
                    </div>}
                    {this.isDepartmentViewType() && <div className="col-auto">
                        <button className='btn btn-outline-secondary h-100' onClick={this.toggleShowDepartmentTypes}>Wyświetl typy <i className={this.showDepartmentTypesToggleClass()}></i></button>
                    </div>}
                    {this.hasReportData() && <div className="col-auto">
                        <button className="btn btn-outline-secondary h-100" onClick={this.exportToExcel}>Export <span className="fa-solid fa-file-excel"></span></button>
                    </div>}
                    {this.state.hasProjectEntries && this.state.hasDepartmentEntries && <div className="col text-end">
                        <div className="btn-group" role="group">
                            <input type="radio" className="btn-check" id="project-view-type-radio" checked={this.isProjectViewType()} onChange={this.onSwitchToProjectViewTypeChange} />
                            <label className="btn btn-outline-secondary" htmlFor="project-view-type-radio">Projekty</label>
                            <input type="radio" className="btn-check" id="department-view-type-radio" checked={this.isDepartmentViewType()} onChange={this.onSwitchToDepartmentViewTypeChange} />
                            <label className="btn btn-outline-secondary" htmlFor="department-view-type-radio">Działy</label>
                        </div>
                    </div>}
                </div>
                <div className={this.hasReportData() ? 'd-none' : 'alert alert-info mt-2'}>Brak danych do wyświetlenia</div>
                {this.hasReportData() && this.isProjectViewType() &&
                    <ProjectWorktimeReportTable
                    ref={this.projectTableRef}
                    showProjectColumn={!this.isProjectNumberGiven()}
                    projectWorkTypeColumns={this.getProjectWorkTypeColumns()}
                    entries={this.projectEntries(this.state.report)}
                    showSubtypes={this.state.showSubtypes}
                    />}
                {this.hasReportData() && this.isDepartmentViewType() &&
                    <DepartmentWorktimeReportTable
                    ref={this.departmentTableRef}
                    showTypes={this.state.showDepartmentTypes}
                    reportDepartmentTypes={this.getDepartmentWorkTypeColumns()}
                    entries={this.departmentEntries(this.state.report)}
                    reportDepartments={this.state.reportDepartmens}
                    />}
                {this.state.totalProjectsMilliseconds && this.state.totalDepartmentsMilliseconds &&
                    <>
                        <h6>Suma czasu w projektach: {ElapsedTimeInMilisecsText(this.state.totalProjectsMilliseconds)}</h6>
                        <h6>Suma czasu w działach: {ElapsedTimeInMilisecsText(this.state.totalDepartmentsMilliseconds)}</h6>
                    </>
                }
            </>
        );
    }
}
