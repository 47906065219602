import { KnownRolesEnum, PelikanStatusEnum } from "../types/domain";
import { PelikanDTO } from "../types/dto";
import { CreatePelikanData, UpdatePelikanData, ValidationErrors } from "../types/forms";

export class PelikanFormUtilities {

    public validateUpdatePelikanData(data: UpdatePelikanData, pelikan: PelikanDTO): ValidationErrors {
        const validator = new ValidationErrors();
        if (
            data.status === undefined
            && data.category === undefined
            && data.assignedEmployeeId === undefined
            && data.assignedRole === undefined
            && data.assignedToWorkers === undefined
            && data.needQDE === undefined
            && data.description === undefined
            && data.solution === undefined
            && data.QDEMinutes === undefined
            && data.canMonterResolve === undefined
        ) {
            validator.AddGeneralError("Nie dokonano żadnych zmian");
        } else {
            if (data.category !== undefined) {
                validator.ValidateNotEmpty('category', data.category);
            }
            if (data.description !== undefined) {
                validator.ValidateNotEmpty('description', data.description);
            }
            if (pelikan.Status === PelikanStatusEnum.Solved && data.solution !== undefined) {
                validator.ValidateNotEmpty('solution', data.solution);
            }
        }

        return validator;
    }

    public validateNewPelikanData(data: CreatePelikanData): ValidationErrors {
        const validator = new ValidationErrors();

        validator.ValidateNotEmpty('category', data.category);
        if (data.assignedRole == KnownRolesEnum.NotSelected
            && !data.assignedEmployeeId
            && !data.assignedToWorkers
        ) {
            const errorText = 'Należy przypisać rolę lub pracownika';
            validator.AddError('assignedRole', errorText);
            validator.AddError('assignedEmployeeId', errorText);
        }
        validator.ValidateNotEmpty('description', data.description);

        return validator;
    }
}
