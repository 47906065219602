import React, { Component } from 'react';
import { apiFetchResponse } from '../utilities/auth-api';
import { OperationResultWithDataDTO, EmployeeWorktimeEntryDTO, WorktimeEntryDTO, OperationResultDTO, EmployeeBriefDTO, EmployeeWorktimeReportDTO, EmployeeFullDTO } from '../types/dto';
import { allDepartmentIds, KnownRolesEnum, WorktimeEntryFlags } from '../types/domain';
import { allWorkTypes, CreateNewWorktimeEntry, renderWorktimeDescription } from '../utilities/domain-utils';
import { DateRangeString, DateToDateString } from '../utilities/date-formatter';
import SortableTable, { ColumnOptionsCollection } from './common/SortableTable';
import { StringDictionary } from '../types/global';
import { Worktime } from './Worktime';
import { CalculateDates } from '../utilities/data-enhancer';
import { ProjectLink } from './common/ProjectLink';
import authService from './api-authorization/AuthorizeService';
import { AllowsFeature, AllowsRolesMeasureDepartmentTime, Features } from '../utilities/features';
import { Dropdown } from 'bootstrap';
import { WorktimeForm } from './WorktimeForm';
import { ElapsedTimeInMilisecsText } from '../utilities/date-utils';

type EmployeeWorktimePageProps = {
    employee: EmployeeFullDTO
};

type EmployeeWorktimePageState = {
    startDate: string,
    endDate: string,
    report?: EmployeeWorktimeReportDTO,
    canManageEntries: boolean,
    editedDropdown: Dropdown | null,
    editedId?: number,
    editedNewWorktime?: WorktimeEntryDTO
};

export class EmployeeWorktimePage extends Component<EmployeeWorktimePageProps, EmployeeWorktimePageState> {
    static displayName = EmployeeWorktimePage.name;

    constructor(props: EmployeeWorktimePageProps) {
        super(props);

        const userProfile = authService.getUserProfileSync();

        this.state = {
            startDate: '',
            endDate: '',
            canManageEntries: AllowsFeature(Features.EditWorktimes, userProfile),
            editedDropdown: null
        };

        this.fetchWorktimes = this.fetchWorktimes.bind(this);
        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.clearStartDate = this.clearStartDate.bind(this);
        this.clearEndDate = this.clearEndDate.bind(this);
        this.startEditEntry = this.startEditEntry.bind(this);
        this.startAddEntry = this.startAddEntry.bind(this);
        this.renderActions = this.renderActions.bind(this);
        this.onWorktimeSaved = this.onWorktimeSaved.bind(this);
        this.deleteEntry = this.deleteEntry.bind(this);
        this.renderSummaryRow = this.renderSummaryRow.bind(this);
        this.allowedDepartmentIdsForEmployee = this.allowedDepartmentIdsForEmployee.bind(this);
    }

    componentDidMount() {
        this.fetchWorktimes();
    }

    allowedDepartmentIdsForEmployee() {
        const roleIds = this.props.employee.User.Roles.map(r => r.Id as KnownRolesEnum);

        return allDepartmentIds.filter(depId => AllowsRolesMeasureDepartmentTime(depId, roleIds));
    }

    async fetchWorktimes() {

        const data: Record<string, string> = {};
        let hasData: boolean = false;

        if (this.state.startDate) {
            const date = new Date(this.state.startDate);
            data['StartDate'] = date.getTime().toString();
            hasData = true;
        }
        if (this.state.endDate) {
            const date = new Date(this.state.endDate);
            data['EndDate'] = date.getTime().toString();
            hasData = true;
        }

        let queryString = '';
        if (hasData) {
            const params = new URLSearchParams(data);
            queryString = '?' + params.toString();
        }

        const employeeId = this.props.employee.Id;

        var response = await apiFetchResponse(`employee/${employeeId}/worktime${queryString}`);

        if (response.status == 200) {
            var result = await response.json() as OperationResultWithDataDTO<EmployeeWorktimeReportDTO>;
            if (result.Success) {

                CalculateDates(result.Data);

                this.setState({
                    report: result.Data
                });
            }
        }
    }

    onStartDateChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            startDate: event.target.value || ''
        });
    }

    onEndDateChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            endDate: event.target.value || ''
        });
    }

    columns(): StringDictionary {
        const columns: StringDictionary = {
            'ProjectNumber': 'Nr projektu',
            'ProjectClientName': 'Klient',
            'ProjectModelDescription': 'Model',
            'Department.Name': 'Dział',
            'WorkType': 'Typ pracy',
            'Start': 'Data',
            'Worktime': 'Czas pracy',
            'Flags': 'Flagi'
        };

        if (this.state.canManageEntries) {
            columns['actions'] = "Akcje";
        }

        return columns;
    }

    columnOptions(): ColumnOptionsCollection {
        const options: ColumnOptionsCollection = {
            'ProjectNumber': {
                enableFilter: true,
                valueRenderer: (value) => value
                    ? <ProjectLink projectNumber={value as number} />
                    : ''
            },
            'ProjectClientName': {
                enableFilter: true
            },
            'ProjectModelDescription': {
                enableFilter: true
            },
            'Department.Name': {
                enableFilter: true
            },
            //'ProjectState': {
            //    valueRenderer: (value: unknown) => {
            //        const status = value as MajorProjectStateEnum;

            //        return <StatusIndicator status={status} />
            //    }
            //},
            'WorkType': {
                columnRenderer: (row: unknown) => {
                    return renderWorktimeDescription(row as EmployeeWorktimeEntryDTO, true);
                }
            },
            'Start': {
                columnRenderer: (row: unknown) => {
                    const entry = row as EmployeeWorktimeEntryDTO;

                    if (entry.Start && entry.End) {
                        return DateRangeString(entry.Start, entry.End);
                    } else if (entry.Start) {
                        return DateToDateString(entry.Start);
                    }
                    return '';
                }
            },
            'Worktime': {
                columnRenderer: (row: unknown) => {
                    const w = row as EmployeeWorktimeEntryDTO;
                    return <Worktime start={w.Start!} end={w.End} showStartEnd={true} />
                }
            },
            'Flags': {
                valueRenderer: (value: unknown) => {
                    const flags = value as WorktimeEntryFlags;

                    return <>
                        {(flags & WorktimeEntryFlags.Manual) > 0 && <i className="fa-regular fa-calendar-plus" title="Dodany ręcznie" />}
                        {(flags & WorktimeEntryFlags.Corrected) > 0 && <i className="fa-regular fa-edit" title="Edytowany" />}
                    </>
                }
            }
        };

        return options;
    }

    clearStartDate() {
        this.setState({
            startDate: ''
        });
    }

    clearEndDate() {
        this.setState({
            endDate: ''
        });
    }

    async startEditEntry(event: React.MouseEvent, entry: EmployeeWorktimeEntryDTO) {
        const element = event.currentTarget as Element;
        const dropdown = Dropdown.getInstance(element)!;

        this.setState({
            editedId: entry.Id,
            editedDropdown: dropdown,
            editedNewWorktime: undefined
        });

        dropdown.show();
    }

    // TODO - need to catch dropdown hide event
    // to clear all data so it reopens clear
    async startAddEntry(event: React.MouseEvent) {
        const element = event.currentTarget as Element;
        const dropdown = Dropdown.getInstance(element)!;

        const editedNewWorktime = CreateNewWorktimeEntry();

        editedNewWorktime.Employee = Object.assign({}, this.props.employee);

        this.setState({
            editedId: undefined,
            editedDropdown: dropdown,
            editedNewWorktime
        });
    }

    onWorktimeSaved(savedEntry: WorktimeEntryDTO) {
        if (this.state.editedDropdown) {
            this.state.editedDropdown.hide();
        }

        // Loading everything again
        this.fetchWorktimes();
    }

    async deleteEntry(we: EmployeeWorktimeEntryDTO) {
        const response = await apiFetchResponse(`worktime/${we.Id}`, {
            method: 'DELETE'
        });

        if (response.status == 200) {
            const result = await response.json() as OperationResultDTO;
            if (result.Success) {
                await this.fetchWorktimes();
            }
        }
    }

    renderActions(data: any) {
        const we = data as EmployeeWorktimeEntryDTO;
        return <>
            <div className="dropdown d-inline-block">
                <button className="btn btn-sm btn-outline-primary dropdown-toggle"
                    title="Edytuj wpis"
                    type="button"
                    data-bs-auto-close="outside"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={(event) => this.startEditEntry(event, we)}><i className="fa-solid fa-edit"></i></button>
                <div className="dropdown-menu dropdown-menu-end p-2">
                    {this.state.editedId == we.Id &&
                        <WorktimeForm
                            worktime={we}
                            onSave={this.onWorktimeSaved}
                            workTypes={allWorkTypes}
                            allowSetDate={true} />
                    }
                </div>
            </div>
        </>;
        // TODO - Code below will add Remove functionality
        // which is not fully implemented
        // <button className="btn btn-sm btn-outline-danger ms-2" title="Usuń wpis" onClick={() => this.deleteEntry(we)}><i className="fa-solid fa-times"></i></button>
    }

    renderSummaryRow(rows?: any[]) {
        if (!this.state.report || !rows) {
            return null;
        }

        const totalMilisecs = rows.reduce((acc, currentValue) => {
            const row = currentValue as EmployeeWorktimeEntryDTO;
            return acc + row.DurationMilisecs;
        }, 0);

        const columnNumber = this.state.canManageEntries
            ? 9 : 8;

        return <tr>
            <th colSpan={columnNumber}>Suma: {ElapsedTimeInMilisecsText(totalMilisecs)}</th>
        </tr>;
    }

    render() {

        return (
            <>
                <div className="row">
                    <div className="col-auto">
                        <label htmlFor="worktimeStartDate" className="col-form-label col-form-label-sm">Od:</label>
                    </div>
                    <div className="col-auto">
                        <div className="input-group input-group-sm">
                            <input type="date" className="form-control" id="worktimeStartDate" value={this.state.startDate} onChange={this.onStartDateChange} />
                            <button className="btn btn-outline-secondary" type="button" onClick={this.clearStartDate}><i className="fa-solid fa-times" /></button>
                        </div>
                    </div>
                    <div className="col-auto">
                        <label htmlFor="worktimeEndDate" className="col-form-label col-form-label-sm">Do:</label>
                    </div>
                    <div className="col-auto">
                        <div className="input-group input-group-sm">
                            <input type="date" className="form-control form-control-sm" id="worktimeEndDate" value={this.state.endDate} onChange={this.onEndDateChange} />
                            <button className="btn btn-outline-secondary" type="button" onClick={this.clearEndDate}><i className="fa-solid fa-times" /></button>
                        </div>
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-sm btn-outline-primary" onClick={this.fetchWorktimes}>Pobierz</button>
                    </div>
                </div>
                <SortableTable
                    idKey="Id"
                    dataRows={this.state.report?.Entries}
                    columns={this.columns()}
                    columnOptions={this.columnOptions()}
                    renderActions={this.renderActions}
                    renderSummaryRow={this.renderSummaryRow} />
                <div className="row mt-2">
                    <div className="dropdown d-inline-block">
                        <button className="btn btn-sm btn-outline-primary dropdown-toggle"
                            title="Dodaj wpis"
                            type="button"
                            data-bs-auto-close="outside"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={(event) => this.startAddEntry(event)}>Dodaj wpis <i className="fa-solid fa-plus"></i></button>
                        <div className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start p-2">
                            {this.state.editedNewWorktime &&
                                <WorktimeForm
                                worktime={this.state.editedNewWorktime!}
                                onSave={this.onWorktimeSaved}
                                workTypes={allWorkTypes}
                                allowSetDate={true}
                                allowSetProject={true}
                                allowSetEmployee={true}
                                allowSetDepartment={true}
                                employeesToSelect={[this.props.employee]}
                                departmentIds={this.allowedDepartmentIdsForEmployee()}
                                />
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
