import { UserManager } from "./UserManager";

export type SettingsData = {
    onlyMyProjects?: boolean
};

export class UserSettings {

    private settingsData?: SettingsData;
    private userManager: UserManager;

    constructor(userManager: UserManager) {
        this.userManager = userManager;
        this.loadSettings();
    }

    defaultSettings: SettingsData = {
        onlyMyProjects: false
    };

    getSettingsName(userId: number) {
        return `userSettings_${userId}`;
    }

    unloadSettings() {
        this.settingsData = Object.assign({}, this.defaultSettings);
    }

    loadSettings() {
        const userId = this.userManager.getUserProfileSync().employeeId;
        if (!userId) {
            return;
        }

        const settingsJson = localStorage.getItem(this.getSettingsName(userId));
        if (settingsJson) {
            this.settingsData = JSON.parse(settingsJson) as SettingsData;
        } else {
            this.settingsData = Object.assign({}, this.defaultSettings);
        }
    }

    saveSettings() {
        const userId = this.userManager.getUserProfileSync().employeeId;
        if (!userId || !this.settingsData) {
            return;
        }

        localStorage.setItem(
            this.getSettingsName(userId),
            JSON.stringify(this.settingsData));
    }

    get(): SettingsData {

        if (!this.settingsData) {
            this.settingsData = Object.assign({}, this.defaultSettings);
        }

        return this.settingsData;
    }

    set(settings: any, save: boolean) {
        this.settingsData = Object.assign({}, this.settingsData, settings);
        if (save) {
            this.saveSettings();
        }
    }

    saveAndUnload() {
        this.saveSettings();
        this.unloadSettings();
    }
};
