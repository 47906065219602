export function downloadBlob(blob: Blob, fileName: string) {
    var url = URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
}
