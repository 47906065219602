import React from "react";
import { Link } from "react-router-dom";

type ProjectLinkProps = {
    projectNumber: number
};

export function ProjectLink(props: ProjectLinkProps) {

    return <Link to={`/projects/${props.projectNumber}`}>{props.projectNumber}</Link>;
}
