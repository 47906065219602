import React, { Component, Fragment } from 'react';
import { getUnloadingFlagText, getUnloadingFlagValueText } from '../utilities/domain-utils';
import { UnloadingFlags, UnloadingFlagValue } from '../types/domain';
import { UnloadingLogEntryDTO } from '../types/dto';
import { DateToDateTimeString } from '../utilities/date-formatter';
import { StateFlagSelect, ValueData } from './common/StateFlagSelect';

type UnloadingFlagRowProps = {
    flag: UnloadingFlags,
    flagValue: UnloadingFlagValue,
    doSetValue: (flag: UnloadingFlags, value: UnloadingFlagValue) => void,
    canSet: boolean,
    logEntries: UnloadingLogEntryDTO[]
};

type UnloadingFlagRowState = {
};

export class UnloadingFlagRow extends Component<UnloadingFlagRowProps, UnloadingFlagRowState> {
    static displayName = UnloadingFlagRow.name;

    constructor(props: UnloadingFlagRowProps) {
        super(props);

        this.state = {
        };

        this.onConfirmed = this.onConfirmed.bind(this);
        this.getAvailableValues = this.getAvailableValues.bind(this);
        this.canSetValue = this.canSetValue.bind(this);
        this.renderLogEntry = this.renderLogEntry.bind(this);
        this.getValueDescriptions = this.getValueDescriptions.bind(this);
    }

    onConfirmed(value: UnloadingFlagValue) {
        this.props.doSetValue(this.props.flag, value);
    }

    getAvailableValues(): UnloadingFlagValue[] {
        // Just retrieve it from props to trigger when it changes
        const canSet = this.props.canSet;
        const isCabinetUnarmedFlag = this.props.flag == UnloadingFlags.IsCabinedUnarmed;

        const allValues: UnloadingFlagValue[] = isCabinetUnarmedFlag
            ? [
                UnloadingFlagValue.NotSet,
                UnloadingFlagValue.Yes,
            ]
            : [
                UnloadingFlagValue.NotSet,
                UnloadingFlagValue.Yes,
                UnloadingFlagValue.Missing,
                UnloadingFlagValue.Inapplicable
            ];

        return allValues.filter(this.canSetValue);
    }

    canSetValue(value: UnloadingFlagValue) {
        if (value == this.props.flagValue) {
            return false;
        }

        return this.props.canSet === true;
    }

    renderLogEntry(logEntry: UnloadingLogEntryDTO, index: number) {
        return <p key={index} className="mb-0"><small>{DateToDateTimeString(logEntry.Timestamp!)}, {logEntry.Doer?.FullName}: {getUnloadingFlagValueText(logEntry.Value, logEntry.UnloadingFlag)}</small></p>
    }

    getValueDescriptions(): ValueData[] {

        const isCabinedUnarmedFlag = this.props.flag == UnloadingFlags.IsCabinedUnarmed;

        if (isCabinedUnarmedFlag) {
            return [
                {
                    value: UnloadingFlagValue.NotSet,
                    buttonClass: "btn btn-outline-primary",
                    iconClass: "",
                    buttonText: getUnloadingFlagValueText(UnloadingFlagValue.NotSet, this.props.flag),
                    buttonTitle: getUnloadingFlagValueText(UnloadingFlagValue.NotSet, this.props.flag),
                    description: undefined
                },
                {
                    value: UnloadingFlagValue.Yes,
                    buttonClass: "btn btn-outline-primary",
                    iconClass: "",
                    buttonText: getUnloadingFlagValueText(UnloadingFlagValue.Yes, this.props.flag),
                    buttonTitle: getUnloadingFlagValueText(UnloadingFlagValue.Yes, this.props.flag),
                    description: undefined
                }
            ];
        }

        const isDamagesFlag = this.props.flag == UnloadingFlags.Damages;
        return [
            {
                value: UnloadingFlagValue.NotSet,
                buttonClass: "btn btn-outline-secondary",
                iconClass: "fa-regular fa-circle",
                buttonText: "",
                buttonTitle: getUnloadingFlagValueText(UnloadingFlagValue.NotSet, this.props.flag),
                description: getUnloadingFlagValueText(UnloadingFlagValue.NotSet, this.props.flag)
            },
            {
                value: UnloadingFlagValue.Yes,
                buttonClass: isDamagesFlag ? "btn btn-outline-danger" : "btn btn-outline-success",
                iconClass: isDamagesFlag ? "fa-solid fa-exclamation-circle" : "fa-solid fa-check-circle",
                buttonText: "",
                buttonTitle: getUnloadingFlagValueText(UnloadingFlagValue.Yes, this.props.flag),
                description: getUnloadingFlagValueText(UnloadingFlagValue.Yes, this.props.flag)
            },
            {
                value: UnloadingFlagValue.Missing,
                buttonClass: isDamagesFlag ? "btn btn-outline-success" : "btn btn-outline-danger",
                iconClass: isDamagesFlag ? "fa-solid fa-check-circle" : "fa-solid fa-exclamation-circle",
                buttonText: "",
                buttonTitle: getUnloadingFlagValueText(UnloadingFlagValue.Missing, this.props.flag),
                description: getUnloadingFlagValueText(UnloadingFlagValue.Missing, this.props.flag)
            },
            {
                value: UnloadingFlagValue.Inapplicable,
                buttonClass: "btn btn-outline-primary",
                iconClass: "fa-regular fa-times-circle",
                buttonText: "",
                buttonTitle: getUnloadingFlagValueText(UnloadingFlagValue.Inapplicable, this.props.flag),
                description: getUnloadingFlagValueText(UnloadingFlagValue.Inapplicable, this.props.flag)
            }
        ];
    };

    render() {
        const flagValue = this.props.flagValue;
        const availableValues = this.getAvailableValues();
        const canChangeValue = availableValues.length > 0;

        return (
            <tr>
                <td>
                    <p className="mb-0">{getUnloadingFlagText(this.props.flag)}</p>
                    {this.props.logEntries.map(this.renderLogEntry)}
                </td>
                <td>
                    <StateFlagSelect
                        value={flagValue}
                        disabled={!canChangeValue}
                        availableValues={availableValues}
                        valueDescriptions={this.getValueDescriptions()}
                        onSelected={this.onConfirmed} />
                </td>
            </tr>
        );
    }
}
