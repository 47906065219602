import React, { Component, Fragment } from 'react';
import { DateToWorkTimeString } from '../utilities/date-formatter';
import { ElapsedTimeText } from '../utilities/date-utils';
import TimeDuration from '../utilities/time-duration';

type WorktimeProps = {
    start: Date,
    end?: Date,
    showStartEnd?: boolean
};

type WorktimeState = {
    now: Date
};

export class Worktime extends Component<WorktimeProps, WorktimeState> {
    static displayName = Worktime.name;
    interval?: NodeJS.Timer;

    constructor(props: WorktimeProps) {
        super(props);

        this.state = {
            now: new Date()
        };
    }

    componentDidMount() {
        if (!this.props.end) {
            this.interval = setInterval(() => {
                const nowDate = new Date();

                this.setState({
                    now: new Date()
                })
            }, 1000);
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    render() {
        const endDate = this.props.end || this.state.now;
        const startTimeText = this.props.start.toLocaleTimeString('pl-PL', { hour: '2-digit', minute: '2-digit' });
        const endTimeText = endDate.toLocaleTimeString('pl-PL', { hour: '2-digit', minute: '2-digit' });
        const timeDuration = TimeDuration.FromDates(this.props.start, endDate);
        const diffText = (timeDuration.IsNegative())
            ? `Rozpocznie się o ${DateToWorkTimeString(this.props.start, true)}`
            : timeDuration.ElapsedTimeString(false, true);

        return (
            <Fragment>
                <div>{diffText}</div>
                <span></span>
                {this.props.showStartEnd && <div><small>{startTimeText} - {endTimeText}</small></div>}
            </Fragment>
        );
    }
}
