import React, { Component, Fragment } from 'react';
import { ExportTransportDTO } from '../types/dto';
import { TransportProjectRow } from './TransportProjectRow';

type TransportWithProjectRowsProps = {
    transport: ExportTransportDTO
};

type TransportWithProjectRowsState = {
};

export class TransportWithProjectRows extends Component<TransportWithProjectRowsProps, TransportWithProjectRowsState> {
    static displayName = TransportWithProjectRows.name;

    constructor(props: TransportWithProjectRowsProps) {
        super(props);

        this.state = {
        };
    }

    render() {
        const t = this.props.transport;
        const hasProjects = t.AssignedProjects && t.AssignedProjects.length > 0;
        const headerClass = t.IsPast
            ? "card-header text-secondary bg-light bg-gradient"
            : "card-header text-white bg-primary bg-gradient";

        return (
            <div className="card">
                <div className={headerClass}>
                    <strong>Transport {t.ExportDateText}</strong>
                </div>
                <div className="card-body">
                    {!hasProjects && <div className="text-center"><i>Brak przypisanych projektów</i></div>}
                    {hasProjects &&
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th>Klient</th>
                                    <th>Nr. projektu</th>
                                    <th>Status</th>
                                    <th>Model</th>
                                    <th>Uwagi</th>
                                    <th>50/50</th>
                                    <th>Otw. Pelikany</th>
                                </tr>
                            </thead>
                            <tbody>
                                {t.AssignedProjects.map(p => <TransportProjectRow key={p.Id} project={p} />)}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        );
    }
}
