import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkStatusDTO, WorkStatusWithDates } from '../types/dto';
import { apiFetchResponse } from '../utilities/auth-api';

type StateType = {
    value?: WorkStatusDTO,
    fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed'
};

const initialState: StateType = {
    fetchStatus: 'idle'
};

export const fetchWorkStatus = createAsyncThunk(
    'work-status/fetch',
    async () => {
        const response = await apiFetchResponse('employee/work-status');
        if (response.status == 200) {
            const result = await response.json() as WorkStatusDTO;
            if (result) {
                return result;
            } else {
                // console.log('Returning ', result);
                return undefined;
            }
        }
    }
);

export const workStatusSlice = createSlice({
    name: 'work-status',
    initialState,
    reducers: {
        set: (state, action: PayloadAction<WorkStatusDTO>) => {
            state.value = action.payload;
        },
        clear: (state) => {
            state.value = undefined;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchWorkStatus.fulfilled, (state, action) => {
                state.value = action.payload;
                state.fetchStatus = 'succeeded';
            })
            .addCase(fetchWorkStatus.pending, (state, action) => {
                state.fetchStatus = 'loading';
            })
            .addCase(fetchWorkStatus.rejected, (state, action) => {
                state.fetchStatus = 'failed';
            });
        }
});

export const { set, clear } = workStatusSlice.actions;

export default workStatusSlice.reducer;
