import React, { Component, Fragment } from 'react';
import { PelikanLogEntryDTO } from '../types/dto';
import { PelikanLogEntry } from './PelikanLogEntry';

type PelikanLogProps = {
    pelikanId: number,
    logEntries: PelikanLogEntryDTO[]
    showCollapsableTitle: boolean
};

type PelikanLogState = {
    showList: boolean
};

export class PelikanLog extends Component<PelikanLogProps, PelikanLogState> {
    static displayName = PelikanLog.name;

    constructor(props: PelikanLogProps) {
        super(props);

        this.state = {
            showList: !props.showCollapsableTitle
        };

        this.toggleList = this.toggleList.bind(this);
    }

    toggleList() {
        this.setState({
            showList: !this.state.showList
        });
    }

    render() {

        const entries = this.props.logEntries;

        return (
            <Fragment>
                {this.props.showCollapsableTitle && <div>
                    <strong>Historia Pelikana</strong>&nbsp;
                    <button className="btn btn-sm btn-outline-primary" type="button" title="Widok historii" onClick={this.toggleList}><i className={this.state.showList ? "fa-solid fa-caret-up" : "fa-solid fa-caret-down"} /></button>
                </div>}
                {this.state.showList && <ul className="list-group">
                    {entries.map(e =>
                        <PelikanLogEntry key={e.Id} entry={e} />
                    )}
                </ul>}
            </Fragment>
        );
    }
}
