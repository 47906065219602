import React, { Component } from 'react';
import { Navbar, NavbarText, NavItem } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService';
import { UserProfile } from './api-authorization/UserManager';
import { AllowsFeature, Features, AllowsMeasureDepartmentTime } from '../utilities/features';
import { PelikanPrompt } from './PelikanPrompt';
import { allDepartmentIds } from '../types/domain';
import { DepartmentName } from '../utilities/domain-utils';

type MainNavMenuProps = {
};

type MainNavMenuState = {
    userProfile?: UserProfile
};

export class MainNavMenu extends Component<MainNavMenuProps, MainNavMenuState> {
    static displayName = MainNavMenu.name;

    constructor(props: MainNavMenuProps) {
        super(props);

        this.state = {
        };

        authService.getUser().then(userProfile => {
            this.setState({
                userProfile
            });
        });

        this.departmentIdsToRender = this.departmentIdsToRender.bind(this);
    }

    _subscription: number = 0;

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async authenticationChanged() {
        const userProfile = await authService.getUser();
        this.setState({
            userProfile
        });
    }

    departmentIdsToRender() {

        const userProfile = this.state.userProfile;

        return allDepartmentIds
            .filter(mdId =>
                AllowsFeature(Features.ManageDepartments, userProfile)
                || AllowsMeasureDepartmentTime(mdId, userProfile));
    }

    render() {

        var departmentIds = this.departmentIdsToRender();

        return (
            <Navbar className="bg-light">
                <ul className="navbar-nav">
                    <NavItem active>
                        <RRNavLink to="/projects" className="text-dark nav-link">Projekty</RRNavLink>
                    </NavItem>
                    {AllowsFeature(Features.Archive, this.state.userProfile) && <NavItem>
                        <RRNavLink to="/archive" className="text-dark nav-link">Archiwum</RRNavLink>
                    </NavItem>}
                    <NavItem>
                        <RRNavLink to="/transports" className="text-dark nav-link">Transporty</RRNavLink>
                    </NavItem>
                    <NavItem>
                        <RRNavLink to="/pelikans" className="text-dark nav-link d-inline-block">Pelikany</RRNavLink><span> </span><PelikanPrompt />
                    </NavItem>
                    {AllowsFeature(Features.WorktimeReport, this.state.userProfile) && <NavItem>
                        <RRNavLink to="/reports/worktimes" className="text-dark nav-link">Raport czasu pracy</RRNavLink>
                    </NavItem>}
                    {AllowsFeature(Features.MyWorktimeReport, this.state.userProfile) && <NavItem>
                        <RRNavLink to="/reports/my-worktimes" className="text-dark nav-link">Mój czas pracy</RRNavLink>
                    </NavItem>}
                    {AllowsFeature(Features.QDEManagement, this.state.userProfile) && <NavItem>
                        <RRNavLink to="/qde-tickets" className="text-dark nav-link">Zgłoszenia QDE</RRNavLink>
                    </NavItem>}
                    {AllowsFeature(Features.ManageEmployees, this.state.userProfile) && <NavItem>
                        <RRNavLink to="/employees" className="text-dark nav-link">Pracownicy</RRNavLink>
                    </NavItem>}
                    {departmentIds.length > 0 && <>
                        <NavbarText className="text-dark"><strong>Działy</strong></NavbarText>
                    <NavItem>
                        {departmentIds.map(departmentId => <RRNavLink key={departmentId} to={`/department/${departmentId}`} className="ms-2 text-dark nav-link">{DepartmentName(departmentId)}</RRNavLink>)}
                    </NavItem></>}
                </ul>
            </Navbar>
        );
    }
}
