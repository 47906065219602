import React from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { OperationResultWithDataDTO, PelikanRemarkDTO } from '../types/dto';
import { apiFetchResponse } from '../utilities/auth-api';
import { doerName } from '../utilities/domain-utils';

type PelikanDiscussionButtonProps = {
    pelikanId: number,
    employeeId: number
};

export function PelikanDiscussionButton(prop: PelikanDiscussionButtonProps) {

    const [isOpen, setOpen] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');
    const [discussionItems, setDiscussionItems] = React.useState<PelikanRemarkDTO[] | undefined>(undefined);

    const togglePopover = async () => {

        if (!isOpen && !discussionItems) {
            setLoading(true);
            const response = await apiFetchResponse(`pelikan/${prop.pelikanId}/remark`);
            setLoading(false);
            if (response.status == 200) {
                const result = await response.json() as OperationResultWithDataDTO<PelikanRemarkDTO[]>;
                if (result.Success) {
                    setDiscussionItems(result.Data!);
                } else {
                    setErrorText(`Błąd operacji. ${result.Errors![0].Message}`);
                }
            } else {
                setErrorText(`Błąd odpowiedzi. Status ${response.statusText}`);
            }
        }

        setOpen(!isOpen);
    };

    const buttonId = `show-discussion-${prop.pelikanId}`;
    const remarks = discussionItems || [];

    const getRemarkTextClass = (r: PelikanRemarkDTO) => {
        return r.Employee && r.Employee.Id === prop.employeeId
            ? "ps-4 table-primary"
            : "pe-4 table-light";
    }

    return <>
        <button type="button" className="btn btn-sm" id={buttonId} title="Dyskusja" onClick={togglePopover}>
            {isLoading
                ? <i className="spinner-border spinner-border-sm" />
                : <i className="fa-solid fa-comment-dots" />}
        </button>
        <Popover flip target={buttonId} isOpen={isOpen}>
            {errorText
                ? <PopoverBody className="alert alert-danger mb-0">{errorText}</PopoverBody>
                : <PopoverBody>
                    <table className="table table-sm table-borderless">
                        <tbody>
                            {remarks.map(r => <tr key={r.Id}>
                                <td className={`${getRemarkTextClass(r)}`}>
                                    <div>
                                        <p className="mb-0 text-secondary"><small>{doerName(r.Employee)}</small></p>
                                        <p className="mb-0">{r.Text}</p>
                                    </div>
                                </td>
                            </tr>)}
                        </tbody>
                    </table>
                </PopoverBody>
            }
        </Popover>
    </>;
}