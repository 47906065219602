import { PelikanCategoryEnum } from "../../types/domain";
import { PelikanCategoryName } from "../../utilities/domain-utils";
import { ClearableDropdown } from "./ClearableDropdown";

type PelikanCategoryFilterProps = {
    value?: PelikanCategoryEnum,
    onChange: (status?: PelikanCategoryEnum) => void
}

export function PelikanCategoryFilter(props: PelikanCategoryFilterProps) {

    const values = [
        PelikanCategoryEnum.Problem,
        PelikanCategoryEnum.MaterialLackings,
        PelikanCategoryEnum.StickerNotesLackings,
        PelikanCategoryEnum.Remarks
    ];

    return <ClearableDropdown
        value={props.value}
        onChange={props.onChange}
        valueText={PelikanCategoryName}
        values={values}
    />;
}
