import authService from '../components/api-authorization/AuthorizeService';
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants';
import getHubInstance from './refresh-hub-client';

export async function apiFetchData(input: RequestInfo, init?: RequestInit): Promise<unknown> {

    const response = await apiFetchResponse(input, init);
    if (response.status == 401) {
        return null;
    }

    return await response.json();
}

export async function apiFetchResponse(input: RequestInfo, init?: RequestInit): Promise<Response> {
    let apiInit: RequestInit = init || {};
    let headers = new Headers(apiInit.headers);
    var authToken = await authService.getAccessToken();
    if (authToken !== null) {
        headers.append('Authorization', `Bearer ${authToken}`);
    }

    if (apiInit.body != null && typeof apiInit.body == "string") {
        if (!headers.get('Content-Type')) {
            headers.append('Content-Type', 'application/json');
        }
    }

    const connectionId = getHubInstance().getConnectionId();
    if (connectionId) {
        headers.append('hub-conn-id', connectionId);
    }

    apiInit.headers = headers;

    const response = await fetch(input, apiInit);
    if (response.status == 401) {
        await authService.signOut({
            returnUrl: ApplicationPaths.Login
        });
        return response;
    }
    return response;
}