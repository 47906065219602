import React from "react";
import { ToggleCollapseButton } from "./common/ToggleCollapseButton";
import { FMListEntryRow } from "./FMListEntryRow";
import { ProjectNumberContext } from './contexts';
import { apiFetchData } from "../utilities/auth-api";
import { FMListEntryDTO, OperationResultDTO, OperationResultWithDataDTO } from "../types/dto";
import { FileDropableButton } from "./common/FileDropableButton";
import { importFMListExcelFile } from "../utilities/excel-fm-list-importer";
import { renderStringsAsList } from "../utilities/render-utils";
import { OperationResultWithData } from "../utilities/operation-result";
import { CalculateDates } from "../utilities/data-enhancer";
import { FetchStatusEnum } from "../utilities/data-fetch";
import { AlertComponent, AlertMessage } from "./common/AlertComponent";

type FMListProps = {
    initiallyCollapsed: boolean,
    onFMListStatusChanged: () => void,
    canManage: boolean
};

export function FMList(props: FMListProps) {

    const projectNumber = React.useContext(ProjectNumberContext);

    const [isCollapsed, setIsCollapsed] = React.useState(props.initiallyCollapsed);
    const [canImportList, setCanImportList] = React.useState(false);
    const [fMListEntries, setFMListEntries] = React.useState<FMListEntryDTO[] | undefined>(undefined);
    const fetchStatus = React.useRef(FetchStatusEnum.Unitialised);
    const [importResult, setImportResult] = React.useState<OperationResultWithData<FMListEntryDTO[]> | undefined>();
    const [removeResult, setRemoveResult] = React.useState<OperationResultDTO>();

    const fetchFMList = async function (reFetch?: boolean) {

        if (reFetch) {
            if (fetchStatus.current == FetchStatusEnum.Fetching) {
                return;
            }
        }
        else {
            if (fetchStatus.current > FetchStatusEnum.Unitialised) {
                return;
            }
        }

        fetchStatus.current = FetchStatusEnum.Fetching;
        const responseData = await apiFetchData(`fm-list/project/${projectNumber}`) as OperationResultWithDataDTO<FMListEntryDTO[] | undefined>;

        if (responseData && responseData.Success) {
            if (responseData.Data) {
                const data = responseData.Data;
                CalculateDates(data);

                setFMListEntries(data);
            } else {
                setFMListEntries([]);
                setCanImportList(true);
            }
        }
        fetchStatus.current = FetchStatusEnum.Fetched;
    };

    const toggleCollapse = function () {
        if (isCollapsed && !fMListEntries) {
            fetchFMList();
        }
        setIsCollapsed(!isCollapsed);
    }

    const onEntryChanged = function (entry: FMListEntryDTO) {

        const newEntries = fMListEntries!.map(e => e.Id == entry.Id ? entry : e);

        setFMListEntries(newEntries);

        props.onFMListStatusChanged();
    }

    const importFMListFile = async function (file: File) {
        const result = await importFMListExcelFile(file, projectNumber);

        setImportResult(result);
        if (result.IsSuccess()) {
            const data = result.Data;
            CalculateDates(data);
            setFMListEntries(data);
            setCanImportList(false);

            props.onFMListStatusChanged();
        }
    }

    const removeFMList = async function () {
        const response = await apiFetchData(`fm-list/project/${projectNumber}`, {
            method: 'DELETE'
        }) as OperationResultDTO;

        if (response) {
            setRemoveResult(response);

            if (response.Success) {
                await fetchFMList(true);
            }
        }
    };

    const renderImportResult = function () {

        if (!importResult) {
            return null;
        }

        const warnings = importResult.GetWarnings();
        const errors = importResult.GetErrors();
        const isSuccess = importResult.IsSuccess();
        const data = importResult.Data;

        const headerClass = isSuccess
            ? "alert-success"
            : "alert-danger";

        return <div className="card text-start position-absolute end-0" style={{ zIndex: 100 }}>
            <div className={"card-header alert alert-dismissible mb-0 " + headerClass}>
                <strong>{isSuccess ? "Sukces" : "Błąd importu !"}</strong>
                <button type="button" className="btn-close" onClick={() => setImportResult(undefined)}></button>
            </div>
            {isSuccess && data &&
                <ul className="list-group list-group-flush">
                    <li className="list-group-item"><strong>Zaimportowano wpisów: </strong>{data.length}</li>
                    {renderStringsAsList("text-warning", warnings)}
                </ul>
            }
            {!isSuccess &&
                <ul className="list-group list-group-flush">
                    {renderStringsAsList("text-danger", errors)}
                    {renderStringsAsList("text-warning", warnings)}
                </ul>
            }
        </div>;
    }

    const onAlertHidden = function () {
        setRemoveResult(undefined);
    }

    const renderRemoveResult = function () {
        if (!removeResult) {
            return null;
        }

        let alertMessage: AlertMessage;

        if (removeResult.Success) {
            alertMessage = {
                text: "Usunięto listę",
                className: "alert-success",
                displayMs: 3000
            };
        }
        else {
            alertMessage = {
                text: removeResult.Errors![0].Message,
                className: "alert-danger",
                displayMs: 3000
            };
        }

        return <AlertComponent alertMessage={alertMessage} messageHidden={onAlertHidden} />
    }

    if (!isCollapsed) {
        fetchFMList();
    }

    return <>
        <div>
            {importResult && <div className="position-relative">
                {renderImportResult()}
            </div>}
            {removeResult && <div className="position-relative">
                {renderRemoveResult()}
            </div>}
            <ToggleCollapseButton isCollapsed={isCollapsed} toggle={toggleCollapse} />&nbsp;<strong>Lista FM</strong>
        </div>
        {!isCollapsed && canImportList && props.canManage &&
            <div className="position-relative">
                <FileDropableButton processFile={importFMListFile} inputId="import-fm-list" acceptFiles=".xlsx">Importuj liste</FileDropableButton>
            </div>}
        {!isCollapsed && fMListEntries && fMListEntries.length > 0 &&
            <>
            <div>
                {props.canManage &&
                    <button className="btn btn-outline-danger" onClick={removeFMList}>Usuń listę <i className="fa-solid fa-times"></i></button>
                }
                </div>
                <table className="table table-sm width-100">
                    <tbody>
                        {fMListEntries.map(e =>
                            <FMListEntryRow key={e.Id} entry={e} onEntryChanged={onEntryChanged} canChangeValue={true} />
                        )}
                    </tbody>
                </table>
            </>
        }
    </>;
}