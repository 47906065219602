import React, { Component, Fragment } from 'react';
import { PelikanLogEntryDTO } from '../types/dto';
import { BuildPelikanLogEntryProps, doerName, PelikanStatusName } from '../utilities/domain-utils';

type PelikanLogEntryProps = {
    entry: PelikanLogEntryDTO
};

type PelikanLogEntryState = {
};

// TODO - This is simply functional component
export class PelikanLogEntry extends Component<PelikanLogEntryProps, PelikanLogEntryState> {
    static displayName = PelikanLogEntry.name;

    constructor(props: PelikanLogEntryProps) {
        super(props);

        this.state = {
        };
    }

    render() {
        const entry = this.props.entry;
        const logEntryProps = BuildPelikanLogEntryProps(entry);

        return (
            <li>
                <small>{entry.DateOfChangeText} {doerName(entry.Doer)}</small>
                <table className="table table-sm">
                    <tbody>
                        {logEntryProps.map((prop, idx) => <tr key={idx}><th style={{ width: "30%" }}>{prop.title}</th><td>{prop.value}</td></tr>)}
                    </tbody>
                </table>
            </li>
        );
    }
}
