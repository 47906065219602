import React, { Component, Fragment } from 'react';
import { OperationResultWithDataDTO, PelikanRemarkDTO, ValidationErrorsResponse } from '../types/dto';
import { ValidationErrors } from '../types/forms';
import { apiFetchResponse } from '../utilities/auth-api';
import { FormValidationUtility } from './common/FormValidationUtility';

type PelikanRemarkFormProps = {
    pelikanId: number,
    remarkId?: number,
    initialRemarkText?: string,
    onSaved?: (pelikanRemark: PelikanRemarkDTO) => void
    onCancel?: () => void,
    renderAsTableRow?: boolean
};

type PelikanRemarkFormState = {
    fvu: FormValidationUtility,
    remarkText: string
};

export class PelikanRemarkForm extends Component<PelikanRemarkFormProps, PelikanRemarkFormState> {
    static displayName = PelikanRemarkForm.name;
    inputReference: React.RefObject<HTMLTextAreaElement>;

    constructor(props: PelikanRemarkFormProps) {
        super(props);

        this.inputReference = React.createRef();
        this.state = {
            fvu: new FormValidationUtility(),
            remarkText: props.initialRemarkText || ''
        };

        this.onCancel = this.onCancel.bind(this);
        this.onRemarkChanged = this.onRemarkChanged.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    componentDidMount() {
        this.inputReference.current?.focus();
    }

    onRemarkChanged(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            remarkText: event.target.value
        });
    }

    async onFormSubmit(event: React.FormEvent<HTMLFormElement>) {

        event.preventDefault();

        const data = {
            text: this.state.remarkText
        };

        let url: string;
        let method: string;

        if (this.props.remarkId && this.props.remarkId > 0) {
            url = `pelikan/remark/${this.props.remarkId}`;
            method = 'PATCH'
        } else {
            url = `pelikan/${this.props.pelikanId}/remark`;
            method = 'POST';
        }

        const response = await apiFetchResponse(url, {
            body: JSON.stringify(data),
            method,
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<PelikanRemarkDTO>;
            this.setState({
                fvu: new FormValidationUtility()
            });

            if (this.props.onSaved) {
                this.props.onSaved(result.Data!);
            }
        } else if (response.status == 400) {
            const result = await response.json() as ValidationErrorsResponse;
            const validator = new ValidationErrors();
            validator.SetFromValidationErrorsResponse(result);
            this.setState({
                fvu: new FormValidationUtility(validator)
            });
        }
    }

    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    render() {
        const fvu = this.state.fvu;

        if (this.props.renderAsTableRow) {
            return <tr>
                <td>
                    <form id="editRemarkForm" onSubmit={this.onFormSubmit}>
                        <textarea id="editedText" ref={this.inputReference} className={`form-control form-control ${fvu.invalidClass('Text')}`} onChange={this.onRemarkChanged} value={this.state.remarkText} />
                        {fvu.fieldFeedback('Text')}
                    </form>
                </td>
                <td>
                    <button form="editRemarkForm" className="btn btn-sm" type="submit"><i className="fa-solid fa-save" /></button>
                    <button type="button" className="btn btn-sm" onClick={this.onCancel}><i className="fa-solid fa-times" /></button>
                </td>
            </tr>;
        }

        return (
            <Fragment>
                <form onSubmit={this.onFormSubmit}>
                    <div className="mb-2">
                        <label htmlFor="new-remark" className="form-label">Tekst uwagi</label>
                        <textarea id="new-remark" className={`form-control form-control ${fvu.invalidClass('Text')}`} onChange={this.onRemarkChanged} value={this.state.remarkText} />
                        { fvu.fieldFeedback('Text') }
                    </div>
                    <div>
                        <button className="btn btn-sm btn-primary" type="submit">Zapisz</button>
                        <button className="btn btn-sm ms-2 btn-outline-secondary" type="button" onClick={this.onCancel}>Anuluj</button>
                    </div>
                </form>
            </Fragment>
        );
    }
}
