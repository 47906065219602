import React, { Component } from 'react';
import { KnownDepartmentsEnum, PelikanCategoryEnum, PelikanStatusEnum } from '../types/domain';
import { PelikanDTO } from '../types/dto';
import { StringDictionary } from '../types/global';
import { DateToDateString } from '../utilities/date-formatter';
import { DepartmentName, doerName, pelikanAssignmentText, PelikanCategoryName } from '../utilities/domain-utils';
import authService from './api-authorization/AuthorizeService';
import { AdvancedPelikanLink } from './common/AdvancedPelikanLink';
import { PelikanCategoryFilter } from './common/PelikanCategoryFilter';
import { PelikanStatusFilter } from './common/PelikanStatusFilter';
import { ProjectLink } from './common/ProjectLink';
import SortableTable, { ColumnOptionsCollection } from './common/SortableTable';
import { PelikanDiscussionButton } from './PelikanDiscussionButton';
import { PelikanStatusIndicator } from './PelikanStatusIndicator';

type PelikansTableProps = {
    Pelikans: PelikanDTO[],
    showProjectColumn?: boolean,
    showDepartmentColumn?: boolean,
    onPelikanSelected?: (pelikan: PelikanDTO) => void
};

type PelikansTableState = {
    employeeId: number
};

export class PelikansTable extends Component<PelikansTableProps, PelikansTableState> {
    static displayName = PelikansTable.name;
    tableRef: React.RefObject<SortableTable> | undefined = React.createRef<SortableTable>();

    constructor(props: PelikansTableProps) {
        super(props);

        // TODO - Maybe get currently logged employee from context
        this.state = {
            employeeId: authService.getEmployeeIdSync()
        };

        this.setStatusFilter = this.setStatusFilter.bind(this);
        this.setCategoryFilter = this.setCategoryFilter.bind(this);
    }

    private columns() {
        const columns = {} as StringDictionary;

        columns['Id'] = 'Id';
        columns['Status'] = 'Status';
        columns['Category'] = 'Kategoria';
        columns['Description'] = 'Problem';
        columns['NeedQDE'] = 'QDE';
        columns['HasDiscussion'] = 'Dyskusja';
        columns['CreatedDate'] = 'Utworzony';
        columns['CreatedEmployee.FullName'] = 'Utworzył';
        columns['Solution'] = 'Rozwiązanie';
        columns['Assigned'] = 'Przypisany';
        columns['LastUpdated'] = 'Zmodyfikowano';
        columns['LastUpdatedEmployee.FullName'] = 'Zmodyfikował';
        if (this.props.showProjectColumn) {
            columns['Project.ProjectNumber'] = 'Projekt';
        }
        if (this.props.showDepartmentColumn) {
            columns['DepartmentId'] = 'Dział';
        }

        return columns;
    }

    private setStatusFilter(status?: PelikanStatusEnum) {
        this.tableRef?.current?.setFilterValue('Status', status);
    }

    private setCategoryFilter(category?: PelikanCategoryEnum) {
        this.tableRef?.current?.setFilterValue('Category', category);
    }

    private columnOptions(): ColumnOptionsCollection {
        const options = {} as ColumnOptionsCollection;

        options['Id'] = {
            enableFilter: true,
            columnRenderer: (row) => {
                const pelikanData = row as PelikanDTO;
                const showUnreadIcon = pelikanData.IsAssignedToMe && !pelikanData.ReadConfirmation;
                const showAssignedIcon = pelikanData.IsAssignedToMe && !showUnreadIcon;
                return <div style={{ width: "2rem" }}><AdvancedPelikanLink id={pelikanData.Id} onOpen={() => this.props.onPelikanSelected!(pelikanData)} />{showAssignedIcon && <i className="fa-solid fa-book-reader text-info" />}{showUnreadIcon && <i className="fa-solid fa-book-open text-danger" />}</div>
            }
        };
        options['Status'] = {
            valueRenderer: (value) => (<PelikanStatusIndicator status={value as PelikanStatusEnum} />),
            filterRenderer: (filterValue) => <PelikanStatusFilter value={filterValue} onChange={this.setStatusFilter} />
        };
        options['Category'] = {
            valueRenderer: (value) => <p style={{ fontSize: "0.8rem" }}>{PelikanCategoryName(value as PelikanCategoryEnum)}</p>,
            filterRenderer: (filterValue) => <PelikanCategoryFilter value={filterValue} onChange={this.setCategoryFilter} />
        };
        options['HasDiscussion'] = {
            columnRenderer: (row) => {
                const pelikanRow = row as PelikanDTO;
                return pelikanRow.HasDiscussion
                    ? <PelikanDiscussionButton
                        pelikanId={pelikanRow.Id}
                        employeeId={this.state.employeeId} />
                    : '';
            }
        };
        options['CreatedDate'] = {
            valueRenderer: (value) => value ? DateToDateString(value as Date) : ''
        };
        options['CreatedEmployee.FullName'] = {
            columnRenderer: (row) => {
                const p = row as PelikanDTO;
                return doerName(p.CreatedEmployee);
            }
        };
        options['LastUpdated'] = {
            columnRenderer: (row) => {
                const pelikan = row as PelikanDTO;
                return pelikan.LastUpdatedText;
            }
        };
        options['Assigned'] = {
            columnRenderer: (row) => {
                const pelikan = row as PelikanDTO;

                return pelikanAssignmentText(pelikan);
            }
        };
        options['Description'] = {
            enableFilter: true,
            valueRenderer: (value) => {
                if (!value) return '';
                const stringValue = value as string;
                return <p className="text-start" style={{ width: "10rem", fontSize: "0.8rem" }}>{stringValue}</p>;
            }
        };
        options['Solution'] = {
            enableFilter: true,
            valueRenderer: (value) => {
                if (!value) return '';
                const stringValue = value as string;
                return <p className="text-start" style={{ width: "10rem", fontSize: "0.8rem" }}>{stringValue}</p>;
            }
        };
        if (this.props.showProjectColumn) {
            options['Project.ProjectNumber'] = {
                enableFilter: true,
                valueRenderer: (value) => <ProjectLink projectNumber={value as number} />
            };
        }
        if (this.props.showDepartmentColumn) {
            options['DepartmentId'] = {
                enableFilter: true,
                valueRenderer: (value) => value ? DepartmentName(value as KnownDepartmentsEnum) : ''
            };
        }

        return options;
    }

    getPelikanDataRowClasses(data: any) {
        var pelikanData = data as PelikanDTO;

        if (pelikanData && pelikanData.IsAssignedToMe) {
            return pelikanData.ReadConfirmation
                ? "table-info" : "table-danger";
        }

        return '';
    }

    render() {
        return (
            <>
                <div className={(this.props.Pelikans && this.props.Pelikans.length > 0) ? 'd-none' : 'alert alert-info mt-2'}>Brak pelikanów do wyświetlenia</div>
                <SortableTable ref={this.tableRef} idKey='Id'
                    dataRows={this.props.Pelikans}
                    columns={this.columns()}
                    columnOptions={this.columnOptions()}
                    rowClassesFunc={this.getPelikanDataRowClasses}
                />
            </>
        );
    }
}
