import React, { Component, Fragment } from 'react';
import { TransportAssignmentTypeEnum } from '../types/domain';
import { ExportTransportDTO, BAMProjectDTO, GetTransportsResult } from '../types/dto';
import { AssignTransportForm } from '../types/forms';
import { apiFetchData } from '../utilities/auth-api';
import { EnhanceBriefTransports } from '../utilities/data-enhancer';

type AssignTransportProps = {
    transports?: ExportTransportDTO[],
    project: BAMProjectDTO,
    canManage?: boolean
    onChange: () => void
};

type AssignTransportState = {
    selectedTransportId: number,
    halfToHalf: boolean,
    transports?: ExportTransportDTO[],
    selectedExistingTransport: boolean,
    newTransportDateString: string
};

export class AssignTransport extends Component<AssignTransportProps, AssignTransportState> {
    static displayName = AssignTransport.name;

    constructor(props: AssignTransportProps) {
        super(props);

        this.state = {
            selectedTransportId: 0,
            halfToHalf: false,
            selectedExistingTransport: true,
            newTransportDateString: ''
        };

        this.onAssignTransport = this.onAssignTransport.bind(this);
        this.onUnassignTransport = this.onUnassignTransport.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
        this.onTransportSelected = this.onTransportSelected.bind(this);
        this.getTransportText = this.getTransportText.bind(this);
        this.isTransportAssigned = this.isTransportAssigned.bind(this);
        this.fetchTransports = this.fetchTransports.bind(this);
        this.onSelectExistingTransport = this.onSelectExistingTransport.bind(this);
        this.onSelectNewTransport = this.onSelectNewTransport.bind(this);
        this.onNewTransportDateChange = this.onNewTransportDateChange.bind(this);

        if (!props.transports) {
            this.fetchTransports();
        }
    }

    async fetchTransports() {
        const transportsResult = await apiFetchData('transport') as GetTransportsResult;
        if (transportsResult.ExportTransports) {
            EnhanceBriefTransports(transportsResult.ExportTransports);
        }

        this.setState({
            transports: transportsResult.ExportTransports,
        });
    }

    onTransportSelected(event: React.ChangeEvent<HTMLSelectElement>) {
        const id = parseInt(event.target.value);

        this.setState({
            selectedTransportId: id
        });
    }

    getTransportText(): string {
        const p = this.props.project;

        if (p.ExportTransport) {
            let text = p.ExportTransport.ExportDateText!;
            if (p.TransportAssignmentType == TransportAssignmentTypeEnum.HalfToHalf) {
                text += " (50/50)";
            }
            return text;
        } else {
            return 'nieprzypisany';
        }
    }

    isTransportAssigned(): boolean {
        return !!this.props.project.ExportTransport;
    }

    async onAssignTransport(formEvent: React.FormEvent<HTMLFormElement>) {
        formEvent.preventDefault();

        const projectNumber = this.props.project.ProjectNumber;

        const data: AssignTransportForm = {
            HalfToHalf: this.state.halfToHalf
        };

        if (this.state.selectedExistingTransport) {
            data.TransportId = this.state.selectedTransportId;
        } else {
            data.NewTransportDate = this.state.newTransportDateString;
        }

        var result = await apiFetchData(`project/${projectNumber}/transport`, {
            method: 'POST',
            body: JSON.stringify(data)
        });

        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    async onUnassignTransport() {
        const projectNumber = this.props.project.ProjectNumber;

        var result = await apiFetchData(`project/${projectNumber}/transport`, {
            method: 'DELETE'
        });

        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    onCheckChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            halfToHalf: event.target.checked
        });
    }

    onSelectExistingTransport(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            selectedExistingTransport: event.target.checked
        });
    }

    onSelectNewTransport(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            selectedExistingTransport: !event.target.checked
        });
    }

    onNewTransportDateChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            newTransportDateString: event.target.value
        });
    }

    render() {
        const transports = this.props.transports
            || this.state.transports
            || [];

        return (
            <Fragment>
                <div className="row gy-2 gx-3 mb-2 align-items-center">
                    <div className="col-auto">{this.getTransportText()}</div>
                    {this.props.canManage == true &&
                        <>
                            <div className="col-auto">
                                <div className="dropdown">
                                    <button type="button" className="btn btn-outline-primary btn-sm dropdown-toggle" title="Przypisz transport" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside"><i className="fa-solid fa-truck-loading" /></button>
                                <form style={{width: "230px"}} className="dropdown-menu dropdown-menu-end p-2" onSubmit={this.onAssignTransport}>
                                    <div className="input-group input-group-sm mb-1">
                                        <div className="form-floating">
                                            <select className="form-select" id="transport-id-select" onChange={this.onTransportSelected} value={this.state.selectedTransportId} title="Wybierz zdefiniowany transport" disabled={!this.state.selectedExistingTransport}>
                                                <option value="0" disabled={true}>wybierz transport</option>
                                                {transports.map(w => <option key={w.Id} value={w.Id}>{w.ExportDateText}</option>)}
                                            </select>
                                            <label htmlFor="transport-id-select">Wybierz transport</label>
                                        </div>
                                            <div className="input-group-text">
                                            <input className="form-check-input mt-0" type="radio" checked={this.state.selectedExistingTransport} onChange={this.onSelectExistingTransport} title="Wybierz zdefiniowany transport" />
                                            </div>
                                        </div>
                                        <div className="input-group input-group-sm mb-1">
                                            <div className="form-floating">
                                            <input className="form-control" id="new-transport-date" type="date" value={this.state.newTransportDateString} title="Data nowego transportu" disabled={this.state.selectedExistingTransport} onChange={this.onNewTransportDateChange} />
                                            <label htmlFor="new-transport-date">Utwórz nowy transport</label>
                                            </div>
                                            <div className="input-group-text">
                                            <input className="form-check-input mt-0" type="radio" checked={!this.state.selectedExistingTransport} onChange={this.onSelectNewTransport} title="Utwórz nowy transport" />
                                            </div>
                                        </div>

                                        <div className="mb-1">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="halfToHalf" checked={this.state.halfToHalf} onChange={this.onCheckChange}></input>
                                                <label className="form-check-label" htmlFor="halfToHalf">50/50</label>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                        <button type="submit" className="btn btn-outline-primary btn-sm" title="Przypisz transport">Przypisz <i className="fa-solid fa-truck-loading" /></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {this.isTransportAssigned() &&
                            <div className="col-auto">
                                <button type="button" className="btn btn-outline-danger btn-sm" title="Usuń przypisanie" onClick={this.onUnassignTransport}><i className="fa-solid fa-times" /></button>
                            </div>}
                    </>
                    }
                </div>
            </Fragment>
        );
    }
}
