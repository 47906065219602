import React, { PropsWithChildren, Component } from 'react';

export type AlertMessage = {
    text?: string,
    className: string,
    displayMs?: number
};

type AlertComponentProps = {
    alertMessage?: AlertMessage,
    messageHidden?: () => void
};

type AlertComponentState = {
    isShowing: boolean
};

export class AlertComponent extends Component<PropsWithChildren<AlertComponentProps>, AlertComponentState> {
    static displayName = AlertComponent.name;
    timeout?: NodeJS.Timeout;

    constructor(props: AlertComponentProps) {
        super(props);

        const alertMessage = props.alertMessage;

        this.state = {
            isShowing: !!alertMessage
        };

        this.hideMessage = this.hideMessage.bind(this);

        if (alertMessage && alertMessage.displayMs && alertMessage.displayMs > 0) {
            this.timeout = setTimeout(this.hideMessage, alertMessage.displayMs);
        }
    }

    componentDidUpdate(prevProps: AlertComponentProps) {

        if (prevProps == this.props) {
            return;
        }

        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = undefined;
        }

        const alertMessage = this.props.alertMessage;
        if (alertMessage) {
            this.setState({
                isShowing: true
            });

            if (alertMessage.displayMs && alertMessage.displayMs > 0) {
                this.timeout = setTimeout(this.hideMessage, alertMessage.displayMs);
            }
        } else {
            this.setState({
                isShowing: false
            });
        }
    }

    hideMessage() {
        this.timeout = undefined;

        this.setState({
            isShowing: false
        });

        if (this.props.messageHidden) {
            this.props.messageHidden();
        }
    }

    className(): string {
        return "alert " + this.props.alertMessage!.className;
    }

    render() {
        if (!this.props.alertMessage || !this.state.isShowing) {
            return null;
        }
        const alertMessage = this.props.alertMessage;

        return (
            <div className={this.className()}>{alertMessage.text || this.props.children}</div>
        );
    }
}
