import React, { Component, Fragment } from 'react';
import { WorkFlags, MajorProjectStateEnum, WorkFlagValue, KnownRolesEnum } from '../types/domain';
import { OperationResultDTO } from '../types/dto';
import { ProjectWorkFlagRow } from './ProjectWorkFlagRow';
import { apiFetchData } from '../utilities/auth-api';
import authService from './api-authorization/AuthorizeService';
import { ToggleCollapseButton } from './common/ToggleCollapseButton';

type ProjectWorkFlagsProps = {
    projectNumber: number,
    majorProjectState: MajorProjectStateEnum
    workFinishedFlags: WorkFlags,
    workInapplicableFlags: WorkFlags,
    fmPelikanValueFlag: WorkFlagValue,
    onFlagsChanged?: () => void,
    initiallyCollapsed: boolean
};

type ProjectWorkFlagsState = {
    isCollapsed: boolean,
    isMonter: boolean,
    isTester: boolean,
    isOffice: boolean,
    isWarehouseKeeper: boolean,
    isManager: boolean
};

export class ProjectWorkFlags extends Component<ProjectWorkFlagsProps, ProjectWorkFlagsState> {
    static displayName = ProjectWorkFlags.name;


    constructor(props: ProjectWorkFlagsProps) {
        super(props);

        this.state = {
            isCollapsed: props.initiallyCollapsed,
            isMonter: authService.hasUserRoleSync(KnownRolesEnum.Monter),
            isTester: authService.hasUserRoleSync(KnownRolesEnum.Tester),
            isOffice: authService.hasUserRoleSync(KnownRolesEnum.Office),
            isWarehouseKeeper: authService.hasUserRoleSync(KnownRolesEnum.WarehouseKeeper),
            isManager: authService.hasUserRoleSync(KnownRolesEnum.Manager)
        };

        this.flagValue = this.flagValue.bind(this);
        this.doSetValue = this.doSetValue.bind(this);
        this.isBetweenStates = this.isBetweenStates.bind(this);
        this.isAtState = this.isAtState.bind(this);
        this.canSetFlagAtCurrentState = this.canSetFlagAtCurrentState.bind(this);
        this.areFlagsSet = this.areFlagsSet.bind(this);
        this.toggleCollapsed = this.toggleCollapsed.bind(this);
    }

    componentDidUpdate(oldProps: ProjectWorkFlagsProps) {
        if (oldProps.initiallyCollapsed != this.props.initiallyCollapsed
         || oldProps.projectNumber != this.props.projectNumber) {

            this.setState({
                isCollapsed: this.props.initiallyCollapsed
            });
        }
    }

    toggleCollapsed() {
        this.setState({
            isCollapsed: !this.state.isCollapsed
        });
    }

    isBetweenStates(s1: MajorProjectStateEnum, s2: MajorProjectStateEnum) {
        return this.props.majorProjectState >= s1
            && this.props.majorProjectState <= s2;
    }

    isAtState(state: MajorProjectStateEnum) {
        return this.props.majorProjectState == state;
    }

    getProjectFlags(): WorkFlags[] {
        const baseMontageFlagsPart1 = [
            WorkFlags.BeipackFinished,
            WorkFlags.AssemblyFinished,
            WorkFlags.ControlBoardFinished,
            WorkFlags.FMLista,
            WorkFlags.CableFinished,
            WorkFlags.TorquesBelow6qmm,
            WorkFlags.TorquesAbove6qmm
        ];

        const baseMontageFlagsPart2 = [
            WorkFlags.VDLSigned,
            WorkFlags.Lashe5ForMonter,
            WorkFlags.Lashe12
        ];

        const baseMontageFlagsForNotMonter = [
            WorkFlags.PelicansOffice,
            WorkFlags.Lashe9,
            WorkFlags.Pictures,
            WorkFlags.FMPelikan,
            WorkFlags.VDLScan
        ];

        const baseTestingFlags = [
            WorkFlags.P1Visual,
            WorkFlags.P1Approved,
            WorkFlags.Lashe5ForTester,
            // WorkFlags.PelicansTester,
            WorkFlags.FixesFinished
        ];

        let resultFlags: WorkFlags[] = [];

        if (this.isBetweenStates(MajorProjectStateEnum.Production,
            MajorProjectStateEnum.PrepareForSend)) {

            resultFlags = resultFlags.concat(baseMontageFlagsPart1);

            if (this.areFlagsSet(baseMontageFlagsPart1)) {
                resultFlags = resultFlags.concat(baseMontageFlagsPart2);
            }

            resultFlags = resultFlags.concat(baseMontageFlagsForNotMonter);
        }

        if (this.isBetweenStates(MajorProjectStateEnum.TestProduction,
            MajorProjectStateEnum.PrepareForSend)) {
            resultFlags = resultFlags.concat(baseTestingFlags);
        }

        return resultFlags;
    }

    flagValueFromFlagBits(flag: WorkFlags): WorkFlagValue {
        if (flag & this.props.workFinishedFlags) {
            return WorkFlagValue.True;
        } else if (flag & this.props.workInapplicableFlags) {
            return WorkFlagValue.Inapplicable;
        } else {
            return WorkFlagValue.False;
        }
    }

    flagValue(flag: WorkFlags): WorkFlagValue {

        // NOTE - procedure will handle case when FMPelikan
        // was set in old way
        if (flag == WorkFlags.FMPelikan
            && this.props.fmPelikanValueFlag != WorkFlagValue.Empty) {

                // If new field value is set it has precedence
                return this.props.fmPelikanValueFlag;
        }

        return this.flagValueFromFlagBits(flag);
    }

    async doSetValue(workFlag: WorkFlags, value: WorkFlagValue) {

        var data = {
            WorkFlag: workFlag,
            Value: value
        };
        var result = await apiFetchData(`project/${this.props.projectNumber}/workflag`, {
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'PATCH'
        }) as OperationResultDTO;

        if (result.Success) {
            if (this.props.onFlagsChanged) {
                this.props.onFlagsChanged();
            }
        }
    }

    canSetFlagAtCurrentState(flag: WorkFlags): boolean {
        switch (flag) {
            case WorkFlags.ControlBoardFinished:
            case WorkFlags.AssemblyFinished:
            case WorkFlags.CableFinished:
            case WorkFlags.VDLSigned:
            case WorkFlags.Lashe5ForMonter:
            case WorkFlags.BeipackFinished:
            case WorkFlags.FMLista:
                return this.isAtState(MajorProjectStateEnum.Production)
                    || this.isAtState(MajorProjectStateEnum.Fixes);
            case WorkFlags.P1Approved:
            case WorkFlags.P1Visual:
            case WorkFlags.Lashe5ForTester:
                return this.isAtState(MajorProjectStateEnum.TestProduction);
            case WorkFlags.FixesFinished:
                return this.isBetweenStates(MajorProjectStateEnum.TestProduction,
                    MajorProjectStateEnum.Fixes);
            case WorkFlags.Lashe9:
            case WorkFlags.Lashe12:
            case WorkFlags.TorquesBelow6qmm:
            case WorkFlags.TorquesAbove6qmm:
                return this.isBetweenStates(MajorProjectStateEnum.Production,
                        MajorProjectStateEnum.Fixes);
            case WorkFlags.PelicansOffice:
            case WorkFlags.VDLScan:
            case WorkFlags.FMPelikan:
                return this.isBetweenStates(MajorProjectStateEnum.Production,
                    MajorProjectStateEnum.PrepareForSend);
            // OBSOLETE - this flag is no longer used
            case WorkFlags.PelicansTester:
                return false;
            //    return this.isBetweenStates(MajorProjectStateEnum.TestProduction,
            //            MajorProjectStateEnum.PrepareForSend);
            case WorkFlags.Pictures:
                return this.isBetweenStates(MajorProjectStateEnum.Production,
                    MajorProjectStateEnum.PrepareForSend);
        }
    }

    canSetFlag(flag: WorkFlags): boolean {

        if (!this.canSetFlagAtCurrentState(flag)) {
            return false;
        }

        if (this.state.isManager) {
            return true;
        }

        switch (flag) {
            case WorkFlags.ControlBoardFinished:
            case WorkFlags.AssemblyFinished:
            case WorkFlags.CableFinished:
            case WorkFlags.VDLSigned:
            case WorkFlags.Lashe5ForMonter:
            case WorkFlags.BeipackFinished:
            case WorkFlags.FMLista:
                return this.state.isMonter;
            case WorkFlags.FixesFinished:
            case WorkFlags.Lashe12:
            case WorkFlags.TorquesBelow6qmm:
            case WorkFlags.TorquesAbove6qmm:
                return this.state.isMonter || this.state.isTester;
            case WorkFlags.P1Approved:
            case WorkFlags.P1Visual:
            case WorkFlags.Lashe5ForTester:
            case WorkFlags.Lashe9:
                return this.state.isTester;
            // OBSOLETE - this flag is no longer used
            case WorkFlags.PelicansTester:
                return false;
            case WorkFlags.PelicansOffice:
                return (this.state.isTester || this.state.isOffice);
            case WorkFlags.VDLScan:
                return this.state.isOffice;
            case WorkFlags.Pictures:
                return (this.state.isOffice || this.state.isWarehouseKeeper);
            case WorkFlags.FMPelikan:
                return (this.state.isOffice || this.state.isTester);
        }
    }

    canResetFlag(flag: WorkFlags): boolean {

        if (this.state.isManager) {
            return true;
        }

        switch (flag) {
            case WorkFlags.VDLScan:
                return this.state.isOffice;
            case WorkFlags.Pictures:
                return (this.state.isOffice || this.state.isWarehouseKeeper);
            default:    // Almost all flags can be reset by tester, office, manager
                return (this.state.isTester || this.state.isOffice);
        }
    }

    areFlagsSet(flags: WorkFlags[]): boolean {
        return flags.every(flag => this.flagValue(flag) !== WorkFlagValue.False);
    }

    render() {

        return (
            <Fragment>
                <div><ToggleCollapseButton isCollapsed={this.state.isCollapsed} toggle={this.toggleCollapsed} />&nbsp;<strong>Stan produkcji</strong></div>
                {!this.state.isCollapsed && <table className="table table-sm">
                    <tbody>
                        {this.getProjectFlags().map(flag =>
                            <ProjectWorkFlagRow
                                key={flag}
                                workFlag={flag}
                                flagValue={this.flagValue(flag)}
                                doSetValue={this.doSetValue}
                                canSet={this.canSetFlag(flag)}
                                canReset={this.canResetFlag(flag)} />
                        )}
                    </tbody>
                </table>}
            </Fragment>
        );
    }
}
