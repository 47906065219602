import React, { Component, Fragment } from 'react';
import { KnownDepartmentsEnum } from '../types/domain';
import { BriefBAMProjectDTO, OperationResultWithDataDTO, PelikanDTO } from '../types/dto';
import { apiFetchResponse } from '../utilities/auth-api';
import { CalculateDates, EnhancePelikans } from '../utilities/data-enhancer';
import { PelikanPageComponent } from './PelikanPage';
import { PelikansTable } from './PelikansTable';

type ProjectOrDepartmentPelikansProps = {
    projectNumber?: number,
    project?: BriefBAMProjectDTO,
    departmentId?: KnownDepartmentsEnum,
    isVisible?: boolean
};

type ProjectOrDepartmentPelikansState = {
    showForm: boolean
    pelikans: PelikanDTO[],
    editedPelikan?: PelikanDTO,
    selectedPelikanId?: number,
    wasSelectedModified: boolean
};

export class ProjectOrDepartmentPelikans extends Component<ProjectOrDepartmentPelikansProps, ProjectOrDepartmentPelikansState> {
    static displayName = ProjectOrDepartmentPelikans.name;

    constructor(props: ProjectOrDepartmentPelikansProps) {
        super(props);

        this.state = {
            showForm: false,
            pelikans: [],
            wasSelectedModified: false
        };

        this.onNewPelikanClicked = this.onNewPelikanClicked.bind(this);
        this.onPelikanSaved = this.onPelikanSaved.bind(this);
        this.onCancelPelikan = this.onCancelPelikan.bind(this);
        this.fetchPelikans = this.fetchPelikans.bind(this);
        this.onPelikanSelected = this.onPelikanSelected.bind(this);
        this.onClosePelikan = this.onClosePelikan.bind(this);
        this.onSelectedSaved = this.onSelectedSaved.bind(this);
    }

    componentDidMount() {
        if (this.props.isVisible !== false) {
            this.fetchPelikans();
        }
    }

    componentDidUpdate(prevProps: ProjectOrDepartmentPelikansProps) {
        if (this.props.isVisible !== false
            && (prevProps.isVisible === false
                || prevProps.departmentId != this.props.departmentId)) {
            // Always re-fetch pelikans even if they were loaded
            this.fetchPelikans();
        }
    }

    getProjectNumber = () => this.props.projectNumber || this.props.project?.ProjectNumber;

    async fetchPelikans() {

        const projectNumber = this.getProjectNumber();
        let url = '';
        if (projectNumber) {
            url = `project/${this.props.projectNumber}/pelikan`;
        }
        if (this.props.departmentId) {
            url = `department/${this.props.departmentId}/pelikan`;
        }

        if (!url) {
            console.warn('No url given for fetching pelikans');
            return;
        }

        const response = await apiFetchResponse(url);
        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<PelikanDTO[]>;
            if (result.Success) {
                const pelikans = result.Data || [];
                CalculateDates(pelikans);
                EnhancePelikans(pelikans);
                this.setState({
                    pelikans
                });
            }
        }
    }

    onNewPelikanClicked() {
        this.setState({
            editedPelikan: undefined,
            showForm: true
        });
    }

    onPelikanSaved(pelikan: PelikanDTO) {
        this.setState({
            showForm: false
        });

        this.fetchPelikans();
    }

    onCancelPelikan() {
        this.setState({
            showForm: false
        });
    }

    onPelikanSelected(pelikan: PelikanDTO) {
        this.setState({
            selectedPelikanId: pelikan.Id
        });
    }

    onClosePelikan() {
        const wasSelectedModified = this.state.wasSelectedModified;

        this.setState({
            selectedPelikanId: undefined,
            wasSelectedModified: false,
            showForm: false
        });

        this.fetchPelikans();
    }

    onSelectedSaved() {
        this.setState({
            wasSelectedModified: true
        });
    }


    render() {
        return (
            <Fragment>
                <div>
                    {!this.state.showForm && !this.state.selectedPelikanId && <button className="btn btn-sm btn-outline-primary" onClick={this.onNewPelikanClicked}>Nowy pelikan</button>}
                </div>
                {!this.state.showForm && !this.state.selectedPelikanId &&
                    <PelikansTable
                        Pelikans={this.state.pelikans}
                        showProjectColumn={false}
                        showDepartmentColumn={false}
                        onPelikanSelected={this.onPelikanSelected} />
                }
                {this.state.selectedPelikanId &&
                    <PelikanPageComponent
                        pelikanId={this.state.selectedPelikanId}
                        onClosePage={this.onClosePelikan}
                        onSaved={this.onSelectedSaved} />
                }
                {this.state.showForm &&
                    <PelikanPageComponent
                        pelikanId={0}
                        onClosePage={this.onClosePelikan}
                        onSaved={this.onSelectedSaved}
                        projectNumber={this.props.projectNumber}
                        project={this.props.project}
                        departmentId={this.props.departmentId} />
                }
            </Fragment>
        );
    }
}
