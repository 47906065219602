import { useState } from "react";
import { FormValidationUtility } from "./FormValidationUtility";

type SingleNoteFormProps = {
    initText: string,
    onFormSubmit: (text: string) => void,
    onCancel?: () => void,
    id: string,
    name: string,
    label: string,
    fvu?: FormValidationUtility,
    useInputField?: boolean
};

export function SingleNoteForm(props: SingleNoteFormProps) {

    const [text, setText] = useState<string>(props.initText);

    const onSubmit = function (event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        props.onFormSubmit(text);
    }

    const onCancel = function () {
        if (props.onCancel) {
            props.onCancel();
        }
    }

    const fieldClass = function (): string {
        if (!props.fvu) {
            return '';
        }

        return props.fvu.invalidClass(props.name);
    }

    const handleTextChange = function (event: React.ChangeEvent<HTMLTextAreaElement>) {
        setText(event.target.value);
    }

    const handleInputTextChange = function (event: React.ChangeEvent<HTMLInputElement>) {
        setText(event.target.value);
    }

    return <form onSubmit={onSubmit}>
        <div className="mb-2">
            <label htmlFor={props.id} className="form-label">{props.label}</label>
            {!props.useInputField && <textarea id="tester-note-text" className={`form-control form-control ${fieldClass()}`} onChange={handleTextChange} value={text} />}
            {props.useInputField && <input id="tester-note-text" className={`form-control form-control ${fieldClass()}`} onChange={handleInputTextChange} value={text} />}
            {props.fvu && props.fvu.fieldFeedback('NoteText')}
        </div>
        <div>
            <button className="btn btn-sm btn-primary" type="submit">Zapisz</button>
            <button className="btn btn-sm btn-outline-secondary ms-2" type="button" onClick={onCancel}>Anuluj</button>
        </div>
    </form>
}
