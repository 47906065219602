import React, { Component } from 'react';
import { TransportAssignmentTypeEnum } from '../types/domain';
import { BriefBAMProjectDTO } from '../types/dto';
import { AllowsFeature, Features } from '../utilities/features';
import authService from './api-authorization/AuthorizeService';
import { ProjectLink } from './common/ProjectLink';
import { StatusIndicator } from './StatusIndicator';

type TransportProjectRowProps = {
    project: BriefBAMProjectDTO
};

type TransportProjectRowState = {
    showOrderNumber: boolean
};

export class TransportProjectRow extends Component<TransportProjectRowProps, TransportProjectRowState> {
    static displayName = TransportProjectRow.name;

    constructor(props: TransportProjectRowProps) {
        super(props);

        const userProfile = authService.getUserProfileSync();

        this.state = {
            showOrderNumber: AllowsFeature(Features.ShowOrderNumber, userProfile)
        };
    }

    render() {
        const p = this.props.project;
        const isHalfToHalf = p.TransportAssignmentType == TransportAssignmentTypeEnum.HalfToHalf;
        const remarkText = p.Remarks
            ? p.Remarks.map(r => r.Text).join("; ")
            : '';

        return (
            <tr className={isHalfToHalf ? "text-black-50" : ""}>
                <td><small>{p.ClientName}</small></td>
                <td>
                    <ProjectLink projectNumber={p.ProjectNumber} />
                    {p.OrderNumber && this.state.showOrderNumber ? <p title='Numer zamówienia'><small>Zam. {p.OrderNumber}</small></p> : ''}
                </td>
                <td><StatusIndicator status={p.MajorProjectState} /></td>
                <td><small>{p.ModelDescription}</small></td>
                <td>{remarkText}</td>
                <td>{isHalfToHalf &&
                    <i className="fa-solid fa-check" />}</td>
                <td>{p.OpenPelikansCount}</td>
            </tr>
        );
    }
}
