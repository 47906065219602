import React from "react";
import { EmployeeBriefDTO, OperationResultWithDataDTO, QDETicketDTO, WorktimeEntryDTO } from "../types/dto";
import { apiFetchResponse } from "../utilities/auth-api";
import { CalculateDates } from "../utilities/data-enhancer";
import { DateToDateTimeString } from "../utilities/date-formatter";
import { getHoursDurationText } from "../utilities/date-utils";
import { allWorkTypes, doerName, QDETicketTypeName } from "../utilities/domain-utils";
import { ProjectLink } from "./common/ProjectLink";
import { PelikanPageComponent } from "./PelikanPage";
import { QDETicketStatusIndicator } from "./QDETicketStatusIndicator";
import { WorktimeForm } from "./WorktimeForm";
import { AccordionBody, AccordionHeader } from 'reactstrap';

type QDETicketRowProps = {
    includeProjectColumn: boolean,
    qdeTicket: QDETicketDTO,
    onTicketChanged?: (ticket: QDETicketDTO) => void,
    canEditStatus: boolean,
    employeesToSelect: EmployeeBriefDTO[],
    accordionId: string,
    isExpanded: boolean
}

export function QDETicketRow(props: QDETicketRowProps) {

    const ticket = props.qdeTicket;

    const fetchTicketData = async (ticketId: number): Promise<QDETicketDTO|null> => {
        const response = await apiFetchResponse(`qde-ticket/${ticketId}`);
        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<QDETicketDTO>;
            if (result.Success) {
                const ticketData = result.Data!;

                CalculateDates(ticketData);

                return ticketData;
            }
        }
        return null;
    }

    const onRelatedTicketDataSaved = async (ticketId: number) => {
        var ticketData = await fetchTicketData(ticketId);
        if (ticketData && props.onTicketChanged) {
            props.onTicketChanged(ticketData);
        }
    }

    return <>
        <tr style={{
            borderBottomColor: "transparent"
        }}>
            <td>{ticket.Id}</td>
            <td><QDETicketStatusIndicator
                ticketId={ticket.Id}
                value={ticket.TicketStatus}
                canChange={props.canEditStatus} onChange={props.onTicketChanged} /></td>
            {props.includeProjectColumn && <>
                <td><ProjectLink projectNumber={ticket.Project.ProjectNumber} /></td>
                <td>{ticket.Project.ClientName}</td>
            </>}
            <td>
                <AccordionHeader tag="div" className="p-1" style={{
                    "--bs-accordion-btn-padding-y": 0,
                    "--bs-accordion-btn-padding-x": 0
                } as React.CSSProperties} targetId={props.accordionId}>{QDETicketTypeName(ticket.QDETicketType)}</AccordionHeader>
            </td>
            <td>{doerName(ticket.CreatorEmployee)}</td>
            <td>{ticket.Description}</td>
            <td>{DateToDateTimeString(ticket.Date!)}</td>
            <td>{getHoursDurationText(ticket.QDE)}</td>
        </tr>
        <tr className="border border-top-0">
            <td colSpan={props.includeProjectColumn ? 8 : 7} className="py-0">
                <AccordionBody accordionId={props.accordionId}>
                    {ticket.RelatedPelikan &&
                        <PelikanPageComponent
                        pelikanId={ticket.RelatedPelikan.Id}
                        onSaved={() => onRelatedTicketDataSaved(ticket.Id)}
                        isVisible={props.isExpanded}
                        employeesToSelect={props.employeesToSelect}
                        />}
                    {ticket.RelatedWorktimeEntry && props.isExpanded &&
                        <div className="col col-4">
                        <WorktimeForm
                                worktime={ticket.RelatedWorktimeEntry}
                                workTypes={allWorkTypes}
                                onSave={(we) => onRelatedTicketDataSaved(ticket.Id)}
                            />
                        </div>
                        }
                </AccordionBody>
            </td>
        </tr>
    </>;
}
