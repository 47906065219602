import React, { Component } from 'react';
import { WorkTypeEnum, WorkSubtypeEnum } from '../types/domain';
import { CabinetModelSubtypeBriefDTO, TesterNoteDTO } from '../types/dto';
import { workTypeText, workSubtypeText, getWorkSubtypes, workTypeCanHaveDetails, briefDetailsText } from '../utilities/domain-utils';
import { FormInputSize, formSelectClass, detailsAreaClass } from '../utilities/form-utils';

type ProjectWorkTypeFormControlsProps = {
    workType: WorkTypeEnum,
    workSubtype?: WorkSubtypeEnum,
    details?: string,
    testerNoteId?: number,
    cabinetModelSubTypeId?: number,
    onWorkTypeChange: (wt: WorkTypeEnum) => void,
    onWorkSubtypeChange: (ws: WorkSubtypeEnum) => void,
    onDetailsChange: (details: string) => void,
    onTesterNoteIdChange: (testerNoteId?: number) => void,
    onCabinetModelSubTypeChange: (cabinetModelSubTypeId: number) => void,
    workTypes: WorkTypeEnum[],
    testerNotes: TesterNoteDTO[],
    size?: FormInputSize,
    cabinetModelSubTypes?: CabinetModelSubtypeBriefDTO[],


};

type ProjectWorkTypeFormControlsState = {
};

export class ProjectWorkTypeFormControls extends Component<ProjectWorkTypeFormControlsProps, ProjectWorkTypeFormControlsState> {
    static displayName = ProjectWorkTypeFormControls.name;

    constructor(props: ProjectWorkTypeFormControlsProps) {
        super(props);

        this.state = {
        };

        this.onWorkTypeSelected = this.onWorkTypeSelected.bind(this);
        this.onWorkSubtypeSelected = this.onWorkSubtypeSelected.bind(this);
        this.onWorkTypeDetailsChanged = this.onWorkTypeDetailsChanged.bind(this);
        this.onTesterNoteSelected = this.onTesterNoteSelected.bind(this);
        this.onCabinetModelSubTypeSelected = this.onCabinetModelSubTypeSelected.bind(this);
    }

    private onWorkTypeSelected(event: React.ChangeEvent<HTMLSelectElement>) {
        const workType = parseInt(event.target.value);

        this.props.onWorkTypeChange(workType);
    }

    private onWorkSubtypeSelected(event: React.ChangeEvent<HTMLSelectElement>) {
        const workSubtype = parseInt(event.target.value);

        this.props.onWorkSubtypeChange(workSubtype);
    }

    private onTesterNoteSelected(event: React.ChangeEvent<HTMLSelectElement>) {
        const testerNoteId = parseInt(event.target.value);

        this.props.onTesterNoteIdChange(testerNoteId || undefined);
    }

    private onWorkTypeDetailsChanged(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.props.onDetailsChange(event.target.value);
    }

    private onCabinetModelSubTypeSelected(event: React.ChangeEvent<HTMLSelectElement>) {
        const id = parseInt(event.target.value);

        this.props.onCabinetModelSubTypeChange(id);
    }

    render() {
        const workSubtypes = getWorkSubtypes(this.props.workType);
        const workSubtypeValue = this.props.workSubtype || WorkSubtypeEnum.None;
        const canSelectCabinetModelSubType = this.props.cabinetModelSubTypes
            && this.props.cabinetModelSubTypes.length > 0;

        return (
            <>
                {canSelectCabinetModelSubType &&
                    <select className={formSelectClass(this.props.size)}
                        onChange={this.onCabinetModelSubTypeSelected}
                        value={this.props.cabinetModelSubTypeId || '0'}>
                        <option value="0" disabled={true}>wybierz szafę</option>
                        {this.props.cabinetModelSubTypes!.map((cmst, idx) => <option key={idx} value={cmst.Id}>{cmst.SubTypeName}</option>)}
                    </select>
                }
                <select className={formSelectClass(this.props.size)} onChange={this.onWorkTypeSelected} value={this.props.workType}>
                    <option value="0" disabled={true}>wybierz typ pracy</option>
                    {this.props.workTypes.map(value =>
                        <option key={value} value={value}>{workTypeText(value as WorkTypeEnum)}</option>
                    )}
                </select>
                {workSubtypes.length > 0 &&
                    <select className={formSelectClass(this.props.size)} onChange={this.onWorkSubtypeSelected} value={workSubtypeValue}>
                        {workSubtypes.map(ws =>
                            <option key={ws} value={ws} disabled={ws == WorkSubtypeEnum.None}>{workSubtypeText(this.props.workType, ws)}</option>
                        )}
                    </select>
                }
                {this.props.workType == WorkTypeEnum.Fixes && this.props.testerNotes.length > 0 &&
                    <select className={formSelectClass(this.props.size)} onChange={this.onTesterNoteSelected} value={this.props.testerNoteId || '0'}>
                        {this.props.testerNotes.map(ws =>
                            <option key={ws.Id} value={ws.Id}>{briefDetailsText(ws.NoteText)}</option>
                        )}
                    </select>
                }
                {workTypeCanHaveDetails(this.props.workType) &&
                    <textarea className={detailsAreaClass(this.props.size)} onChange={this.onWorkTypeDetailsChanged} value={this.props.details} placeholder="szczegóły" />
                }
            </>
        );
    }
}
