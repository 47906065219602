import * as React from 'react';

const defaultPropsValues = {
    isMandatory: false as boolean,
    errors: {} as {[key: string]:string[]},
    label: "" as string
};

type FormTextFieldProps = {
} & Partial<DefaultProps> & React.InputHTMLAttributes<HTMLInputElement>;

type DefaultProps = Readonly<typeof defaultPropsValues>;

// TODO - add possibility to add other button attributes
// input: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
// because of this https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands
export default class FormTextField extends React.PureComponent<FormTextFieldProps>{
    static defaultProps = defaultPropsValues;

    // TODO - utilise isMandatory prop
    public render() {
        let {label, isMandatory, errors, ...inputProps} = this.props;

        inputProps.className = "form-control" + (this.haveErrors() ? " is-invalid" : "");
        inputProps.id = this.props.id ? this.props.id : this.props.name;

        let groupClass = "mb-2";
        if (isMandatory) groupClass += " required";

        return (
            <div className={groupClass}>
                {this.props.label && <label htmlFor={this.props.name} className="form-label">{this.props.label}</label>}
                <input {...inputProps}/>
                <div className="invalid-feedback">{this.errorText()}</div>
            </div>
        );
    }

    private errorText() : string {
        if (!this.props.name)
        {
            return '';
        }
        if (this.props.errors![this.props.name] && this.props.errors![this.props.name][0]) {
            return this.props.errors![this.props.name][0];
        } else {
            return '';
        }
    }

    private haveErrors() : boolean {
        return !!this.props.name && this.props.errors![this.props.name!] != null;
    }
}