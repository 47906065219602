import { ValidationErrors } from '../../types/forms';

export class FormValidationUtility {

    validationErrors?: ValidationErrors;

    constructor(validationErrors?: ValidationErrors) {
        this.validationErrors = validationErrors;
    }

    fieldFeedback(name: string) {
        return this.fieldHasError(name) && <div className="invalid-feedback multi-line-text">{this.fieldErrorHtml(name)}</div>;
    }

    fieldHasError(name: string): boolean {
        if (this.validationErrors) {
            return this.validationErrors.HasErrors(name);
        }
        return false;
    }

    fieldErrorHtml(name: string) {
        if (this.validationErrors
            && this.validationErrors.ValidationErrors[name]) {
            return this.validationErrors.ValidationErrors[name].map(e => e.Message)
                .join('\n');
        }

        return null;
    }

    invalidClass(fieldName: string) {
        return this.fieldHasError(fieldName)
            ? " is-invalid" : "";
    }
}