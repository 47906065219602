import { Component } from "react";
import { MajorProjectStateEnum } from '../types/domain';
import { MainStatusName } from '../utilities/domain-utils';

type StatusIndicatorProps = {
    status: MajorProjectStateEnum
};

type StatusIndicatorState = {
};

export class StatusIndicator extends Component<StatusIndicatorProps, StatusIndicatorState>
{
    static displayName = StatusIndicator.name;

    constructor(props: StatusIndicatorProps) {
        super(props);
    }

    render() {
        return <div className="badge text-bg-primary">{MainStatusName(this.props.status)}</div>
    }
}
