import React, { Component } from 'react';
import { TesterNoteStatusEnum } from '../types/domain';
import { OperationResultWithDataDTO, TesterNoteDTO, ValidationErrorsResponse } from '../types/dto';
import { ValidationErrors } from '../types/forms';
import { apiFetchResponse } from '../utilities/auth-api';
import { FormValidationUtility } from './common/FormValidationUtility';
import { ProjectNumberContext } from './contexts';
import { StateFlagSelect, ValueData } from './common/StateFlagSelect';
import { ToggleCollapseButton } from './common/ToggleCollapseButton';
import { fetchTesterNotes } from '../utilities/data-fetch';

type TesterNotesProps = {
    canEdit: boolean,
    canCheck: boolean,
    onChecked?: (tn: TesterNoteDTO) => void,
    initiallyCollapsed?: boolean
};

type TesterNotesState = {
    fvu: FormValidationUtility,
    text: string,
    testerNotes: TesterNoteDTO[],
    showForm: boolean,
    editedNoteId?: number,
    isCollapsed: boolean
};

export class TesterNotes extends Component<TesterNotesProps, TesterNotesState> {
    static displayName = TesterNotes.name;

    static contextType = ProjectNumberContext;

    constructor(props: TesterNotesProps) {
        super(props);

        this.state = {
            fvu: new FormValidationUtility(),
            text: '',
            testerNotes: [],
            showForm: false,
            isCollapsed: props.initiallyCollapsed !== false
        };

        this.onTesterNoteTextChanged = this.onTesterNoteTextChanged.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.fetchTesterNotes = this.fetchTesterNotes.bind(this);
        this.testerNoteRow = this.testerNoteRow.bind(this);
        this.startCreateNew = this.startCreateNew.bind(this);
        this.editTesterNote = this.editTesterNote.bind(this);
        this.setNoteStatus = this.setNoteStatus.bind(this);
        this.toggleCollapsed = this.toggleCollapsed.bind(this);
        this.cancelEdit = this.cancelEdit.bind(this);
    }

    componentDidMount() {
        this.fetchTesterNotes();
    }

    async fetchTesterNotes() {
        const projectNumber = this.context;

        const testerNotes = await fetchTesterNotes(projectNumber as number);
        this.setState({
            testerNotes
        });
    }

    async onFormSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const projectNumber = this.context;

        const formData = {
            NoteText: this.state.text
        };
        const url = this.state.editedNoteId
            ? `project/${projectNumber}/tester-note/${this.state.editedNoteId}`
            : `project/${projectNumber}/tester-note`;
        const method = this.state.editedNoteId
            ? 'PUT' : 'POST';

        const response = await apiFetchResponse(url,
            {
                body: JSON.stringify(formData),
                method,
                headers: {
                    'Content-Type': 'application/json'
                }
            });

        if (response.status == 200) {

            this.setState({
                text: '',
                fvu: new FormValidationUtility(),
                showForm: false,
                editedNoteId: undefined,
                isCollapsed: false      // Expand after saving note
            });

            const result = await response.json() as OperationResultWithDataDTO<TesterNoteDTO>;

            await this.fetchTesterNotes();
        } else if (response.status == 400) {
            // handle validation errors
            const validationErrors = await response.json() as ValidationErrorsResponse;
            const validator = new ValidationErrors();

            validator.SetFromValidationErrorsResponse(validationErrors);

            this.setState({
                fvu: new FormValidationUtility(validator)
            });
        }
    }

    onTesterNoteTextChanged(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({
            text: event.target.value
        });
    }

    async deleteTesterNote(tn: TesterNoteDTO) {
        const projectNumber = this.context;
        const response = await apiFetchResponse(`project/${projectNumber}/tester-note/${tn.Id}`,
            {
                method: 'DELETE'
            });
        if (response.status == 200) {
            await this.fetchTesterNotes();
        }
    }

    editTesterNote(tn: TesterNoteDTO) {
        if (!this.props.canEdit) {
            return;
        }

        this.setState({
            text: tn.NoteText,
            editedNoteId: tn.Id,
            showForm: true
        });
    }

    async setNoteStatus(tn: TesterNoteDTO, status: TesterNoteStatusEnum) {
        const projectNumber = this.context;
        const data = {
            status
        };

        const response = await apiFetchResponse(`project/${projectNumber}/tester-note/${tn.Id}/status`,
            {
                method: 'PATCH',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<TesterNoteDTO>;
            if (result.Success) {
                await this.fetchTesterNotes();
                if (this.props.onChecked) {
                    this.props.onChecked(result.Data!);
                }
            } else {
                // TODO - React on errors
            }
        }
    }

    testerNoteRow(tn: TesterNoteDTO, index: number) {
        return <tr key={tn.Id}>
            <td>{index + 1}</td>
            <td>{tn.NoteText}</td>
            <td>
                <StateFlagSelect
                    value={tn.Status}
                    valueDescriptions={this.testerNoteFlagsData()}
                    availableValues={this.testerNoteFlagValues(tn.Status)}
                    disabled={!this.props.canCheck || tn.Status != TesterNoteStatusEnum.New}
                    onSelected={(value) => this.setNoteStatus(tn, value)}
                />
                {tn.Status == TesterNoteStatusEnum.New && this.props.canEdit &&
                    <>
                        <button className="btn btn-sm btn-outline-primary" title="Edytuj" type="button" onClick={(event) => this.editTesterNote(tn)}><i className="fa-solid fa-edit"></i></button>
                        <button className="btn btn-sm btn-outline-primary" title="Usuń" type="button" onClick={(event) => this.deleteTesterNote(tn)}><i className="fa-solid fa-times"></i></button>
                    </>
                }
            </td>
        </tr>;
    }

    startCreateNew() {
        if (!this.props.canEdit) {
            return;
        }

        this.setState({
            showForm: true,
            editedNoteId: undefined
        });
    }

    testerNoteFlagsData(): ValueData[] {
        return [
            {
                value: TesterNoteStatusEnum.New,
                buttonClass: "btn btn-sm btn-outline-secondary",
                iconClass: "fa-regular fa-circle",
                buttonText: "",
                buttonTitle: "Nowy",
                description: "Nowy"
            },
            {
                value: TesterNoteStatusEnum.Done,
                buttonClass: "btn btn-sm btn-outline-primary",
                iconClass: "fa-solid fa-check-circle",
                buttonText: "",
                buttonTitle: "Ukończone",
                description: "Ukończone"
            }
        ];
    }

    testerNoteFlagValues(value: TesterNoteStatusEnum): TesterNoteStatusEnum[] {
        return value == TesterNoteStatusEnum.New
            ? [TesterNoteStatusEnum.Done] : [];
    }

    toggleCollapsed() {
        this.setState({
            isCollapsed: !this.state.isCollapsed
        });
    }

    cancelEdit() {
        this.setState({
            showForm: false,
            editedNoteId: undefined
        });
    }

    render() {
        const fvu = this.state.fvu;
        const isExpanded = !this.state.isCollapsed;

        return (
            <>
                <div>
                    <ToggleCollapseButton isCollapsed={this.state.isCollapsed} toggle={this.toggleCollapsed} />&nbsp;<strong>Poprawki</strong>
                    {!this.state.showForm && this.props.canEdit &&
                        <button className="btn btn-sm btn-outline-primary ms-2" onClick={this.startCreateNew}>Dodaj poprawkę</button>
                    }

                </div>
                {this.state.showForm &&
                    <form onSubmit={this.onFormSubmit}>
                        <div className="mb-2">
                            <label htmlFor="tester-note-text" className="form-label">Opis poprawki</label>
                            <textarea id="tester-note-text" className={`form-control form-control ${fvu.invalidClass('NoteText')}`} onChange={this.onTesterNoteTextChanged} value={this.state.text} />
                            {fvu.fieldFeedback('NoteText')}
                        </div>
                        <div>
                            <button className="btn btn-sm btn-primary" type="submit">Zapisz</button>
                            <button className="btn btn-sm btn-outline-secondary ms-2" type="button" onClick={this.cancelEdit}>Anuluj</button>
                        </div>
                    </form>
                }
                {!this.state.showForm && this.state.testerNotes.length > 0 && isExpanded &&
                    <table className="table table-sm">
                        <tbody>
                            {this.state.testerNotes.map(this.testerNoteRow)}
                        </tbody>
                    </table>
                }
                {!this.state.showForm && this.state.testerNotes.length == 0 && isExpanded &&
                    <i>Brak usterek</i>
                }
            </>
        );
    }
}
