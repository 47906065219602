import React, { Component } from 'react';
import { QDETicketStatus, QDETicketTypeEnum } from '../types/domain';
import { EmployeeBriefDTO, QDETicketDTO } from '../types/dto';
import { StringDictionary } from '../types/global';
import { DateToDateTimeString } from '../utilities/date-formatter';
import { getHoursDurationText } from '../utilities/date-utils';
import { doerName, QDETicketTypeName } from '../utilities/domain-utils';
import { ProjectLink } from './common/ProjectLink';
import { QDEStatusFilter } from './common/QDEStatusFilter';
import { QDETicketTypeFilter } from './common/QDETicketTypeFilter';
import SortableTable, { ColumnOptionsCollection } from './common/SortableTable';
import { QDETicketStatusIndicator } from './QDETicketStatusIndicator';

type QDETicketsListProps = {
    tickets: QDETicketDTO[],
    canEditStatus: boolean,
    onTicketChanged?: (ticket: QDETicketDTO) => void,
    projectNumber?: number,
    showSum?: boolean
};

type QDETicketsListState = {
};

export class QDETicketsList extends Component<QDETicketsListProps, QDETicketsListState> {
    static displayName = QDETicketsList.name;
    tableRef: React.RefObject<SortableTable> = React.createRef<SortableTable>();

    constructor(props: QDETicketsListProps) {
        super(props);

        this.state = {
        };

        this.columns = this.columns.bind(this);
        this.columnOptions = this.columnOptions.bind(this);
        this.onQDEStatusFilterChange = this.onQDEStatusFilterChange.bind(this);
        this.onQDETicketTypeChange = this.onQDETicketTypeChange.bind(this);
        this.renderSummaryRow = this.renderSummaryRow.bind(this);
    }

    columns() {
        const columns = {} as StringDictionary;

        columns['Id'] = "Id";
        columns['TicketStatus'] = "Status";
        if (!this.props.projectNumber) {
            columns['Project.ProjectNumber'] = "Projekt";
        }
        columns['QDETicketType'] = "Typ";
        columns['CreatorEmployee'] = "Zgłaszający";
        columns['Description'] = "Opis";
        columns['Date'] = "Data";
        columns['QDE'] = "Wartość QDE";

        return columns;
    }

    onQDEStatusFilterChange(status?: QDETicketStatus) {
        this.tableRef.current?.setFilterValue('TicketStatus', status);
    }

    onQDETicketTypeChange(value?: QDETicketTypeEnum) {
        this.tableRef.current?.setFilterValue('QDETicketType', value);
    }

    columnOptions(): ColumnOptionsCollection {
        const options = {} as ColumnOptionsCollection;

        options['TicketStatus'] = {
            columnRenderer: (row) => {
                const ticket = row as QDETicketDTO;
                return <QDETicketStatusIndicator
                    ticketId={ticket.Id}
                    value={ticket.TicketStatus}
                    canChange={this.props.canEditStatus} onChange={this.props.onTicketChanged} />;
            },
            filterRenderer: (filterValue) => <QDEStatusFilter value={filterValue} onChange={this.onQDEStatusFilterChange} />
        };

        if (!this.props.projectNumber) {
            options['Project.ProjectNumber'] = {
                valueRenderer: (value) => <ProjectLink projectNumber={value as number} />,
                enableFilter: true
            };
        }

        options['Description'] = {
            enableFilter: true
        };

        options['CreatorEmployee'] = {
            valueRenderer: (value) => doerName(value as EmployeeBriefDTO),
            filterValueGetter: (value) => doerName(value as EmployeeBriefDTO),
            enableFilter: true
        };

        options['QDETicketType'] = {
            valueRenderer: (value) => QDETicketTypeName(value as QDETicketTypeEnum),
            filterRenderer: (filterValue) => <QDETicketTypeFilter value={filterValue} onChange={this.onQDETicketTypeChange} />
        };

        options['Date'] = {
            valueRenderer: (dateValue) => DateToDateTimeString(dateValue as Date)
        };

        options['QDE'] = {
            valueRenderer: (qdeValue) => qdeValue !== undefined
                ? getHoursDurationText(qdeValue as number)
                : ''
        };

        return options;
    }

    renderSummaryRow(rows?: any[]) {
        let sum = 0;
        if (rows && rows.length > 0) {
            sum = rows.reduce((acc, row) => {
                const qdeTicket = row as QDETicketDTO;
                const status = qdeTicket.TicketStatus;
                const addValue = ((status <= QDETicketStatus.Accepted) && !!qdeTicket.QDE)
                    ? qdeTicket.QDE
                    : 0;
                return acc + addValue;
            }, sum);
        }

        const columnsCount = Object.keys(this.columns()).length;

        // Assuming last column has QDE value

        return <tr>
            <th colSpan={columnsCount - 1}>Suma</th>
            <th>{getHoursDurationText(sum)}</th>
        </tr>;
    }

    render() {
        if (!this.props.tickets.length) {
            return <p><i>Brak aktywnych zgłoszeń QDE</i></p>
        }

        return (
            <>
                <SortableTable ref={this.tableRef}
                    idKey="Id"
                    dataRows={this.props.tickets}
                    columns={this.columns()} columnOptions={this.columnOptions()}
                    renderSummaryRow={this.props.showSum === true ? this.renderSummaryRow : undefined}
                />
            </>
        );
    }
}
