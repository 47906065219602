import React, { forwardRef, useImperativeHandle, useState } from "react"
import { WorkSubtypeEnum, WorkTypeDescriptor, WorkTypeEnum } from "../../types/domain";
import { WorktimeReportEntryDTO, WorktimeReportSummaryDTO } from "../../types/dto";
import { DateToDateString } from "../../utilities/date-formatter";
import { ElapsedTimeInMilisecsText } from "../../utilities/date-utils";
import { workSubtypeAbbr, workSubtypeText, workTypeAbbr, workTypeMatches, workTypeText } from "../../utilities/domain-utils";
import { exportWortimeReportToExcel } from "../../utilities/excel/excel-worktime-report";
import { getMaxEnumValue } from "../../utilities/ts-utils";
import { CheckboxFilter } from "../common/CheckboxFilter";
import { ProjectLink } from "../common/ProjectLink";
import { StringFilter } from "../common/StringFilter"

type ProjectWorktimeReportTableProps = {
    showProjectColumn: boolean,
    projectWorkTypeColumns: WorkTypeDescriptor[],
    entries: WorktimeReportEntryDTO[],
    showSubtypes: boolean
}

export type ProjectWorktimeReportTableHandle = {
    exportToExcel: () => void
};

export const ProjectWorktimeReportTable = forwardRef<ProjectWorktimeReportTableHandle, ProjectWorktimeReportTableProps>((props: ProjectWorktimeReportTableProps, ref) => {

    const [projectFilterValue, setProjectFilterValue] = useState<string>();
    const [clientOrModelFilterValue, setClientOrModelFilterValue] = useState<string>();
    const [workTypesFilterOutBits, setWorkTypesFilterOutBits] = useState<number>(0);
    const [workSubtypesFilterOutBits, setWorkSubtypesFilterOutBits] = useState<number>(0);

    useImperativeHandle(ref, () => ({
        exportToExcel: () => {
             // TODO - Must get proper rows and columns for Excel report
            exportWortimeReportToExcel(
                props.showProjectColumn,
                props.projectWorkTypeColumns,
                filteredEntries()
            );
        }
    }));

    const workTypeTitle = function (wtc: WorkTypeDescriptor, description?: string) {

        let resultString = workTypeText(wtc.Type);

        if (wtc.SubType) {
            resultString += `
${workSubtypeText(wtc.Type, wtc.SubType)}`;
        }
        if (description) {
            resultString += `
${description}`;
        }

        return resultString;
    }

    const onTypeFilterValueChange = function (wtc: WorkTypeDescriptor, value: boolean) {
        // Mind that logic of bits is inverted
        if (wtc.SubType !== undefined) {
            const bit = 1 << wtc.SubType;
            let newValue = workSubtypesFilterOutBits;
            if (value) {
                newValue &= ~bit;
            } else {
                newValue |= bit;
            }
            setWorkSubtypesFilterOutBits(newValue);
        } else {
            const bit = 1 << (wtc.Type - 1);
            let newValue = workTypesFilterOutBits;
            if (value) {
                newValue &= ~bit;
            } else {
                newValue |= bit;
            }
            setWorkTypesFilterOutBits(newValue);
        }
    }

    const onProjectFilterValueChange = function (projectFilterValue?: string) {
        setProjectFilterValue(projectFilterValue);
    }

    const onClientOrModelFilterValueChange = function (clientOrModelFilterValue?: string) {
        setClientOrModelFilterValue(clientOrModelFilterValue);
    }
    const renderWorkTypeAbbr = function (wtc: WorkTypeDescriptor) {
        if (wtc.SubType !== undefined) {
            return <>{workTypeAbbr(wtc.Type)}<br />{workSubtypeAbbr(wtc.Type, wtc.SubType)}</>
        }

        return workTypeAbbr(wtc.Type);
    }

    const typeFilterValue = function (wtc: WorkTypeDescriptor): boolean {
        // Mind that logic of bits is inverted
        if (wtc.SubType !== undefined) {
            const bit = 1 << wtc.SubType;
            return (workSubtypesFilterOutBits & bit) === 0;
        }
        const bit = 1 << (wtc.Type - 1);
        return (workTypesFilterOutBits & bit) === 0;
    }

    const renderProjectWorkTypesHeaderCells = function (withFilter: boolean, projectWorkTypeColumns: WorkTypeDescriptor[]) {
        return projectWorkTypeColumns.map((wtc, index) =>
            <th key={index} title={workTypeTitle(wtc)} className="align-top">
                {renderWorkTypeAbbr(wtc)}
                {withFilter && <CheckboxFilter
                    value={typeFilterValue(wtc)}
                    onChange={(value) => onTypeFilterValueChange(wtc, value)} />}
            </th>);
    }

    const renderValueCell = function (e: WorktimeReportEntryDTO, wtc: WorkTypeDescriptor, index: number) {
        return workTypeMatches(e, wtc)
            ? <td key={index} title={workTypeTitle(wtc, e.Details)}>{ElapsedTimeInMilisecsText(e.TotalMilliseconds)}</td>
            : <td key={index}></td>;
    }


    const renderProjectEntryRow = function (e: WorktimeReportEntryDTO, index: number, workTypeColumns: WorkTypeDescriptor[]) {

        return <tr key={index} className={e.ByAppr ? "table-warning" : ""}>
            <td>{DateToDateString(e.Date!)}</td>
            <td>{e.Employee.FullName}</td>
            {props.showProjectColumn && <td><ProjectLink projectNumber={e.ProjectNumber} /></td>}
            {props.showProjectColumn && <td><div>{e.ClientName}</div><div><small>{e.Model}</small></div></td>}
            {workTypeColumns.map((wtc, index) => renderValueCell(e, wtc, index))}
            <td></td>
        </tr>;
    }

    const renderSummaryHeader = function () {

        let firstColumnsCount = props.showProjectColumn ? 4 : 2;

        return <tr>
            <td colSpan={firstColumnsCount}></td>
            {renderProjectWorkTypesHeaderCells(false, props.projectWorkTypeColumns)}
            <td></td>
        </tr>;
    }

    const getSummaryValue = function (s: WorktimeReportSummaryDTO, wtc: WorkTypeDescriptor) {
        if (wtc.SubType !== undefined) {
            return s.MilisecondsPerSubtype[wtc.SubType];
        }
        return s.MillisecondsPerWorkType[wtc.Type - 1];
    }

    const summaryRow = function (s: WorktimeReportSummaryDTO) {
        return <tr>
            <th>Suma</th>
            <th>{ElapsedTimeInMilisecsText(s.TotalMilliseconds)}</th>
            {props.showProjectColumn && <td colSpan={2}></td>}
            {props.projectWorkTypeColumns.map((wtc, index) => <th key={index}>{ElapsedTimeInMilisecsText(getSummaryValue(s, wtc))}</th>)}
            <td></td>
        </tr>
    }

    const filteredEntries = function (): WorktimeReportEntryDTO[] {

        const entries = props.entries;
        const showSubtypes = props.showSubtypes;
        let wtfob = workTypesFilterOutBits;
        if (showSubtypes) {
            const exceptCableTypeBit = ~(1 << (WorkTypeEnum.Cable - 1));
            wtfob &= exceptCableTypeBit;
        }

        if (!projectFilterValue
            && !clientOrModelFilterValue
            && !wtfob
            && !workSubtypesFilterOutBits
        ) {
            return entries;
        }

        return entries.filter(e => {
            if (projectFilterValue
                && !e.ProjectNumber.toString().includes(projectFilterValue)) {
                return false;
            }
            if (clientOrModelFilterValue
                && (!e.ClientName || e.ClientName.includes(clientOrModelFilterValue))
                && (!e.Model || !e.Model.includes(clientOrModelFilterValue))
            ) {
                return false;
            }
            const typeBit = 1 << (e.WorkType - 1);
            if ((wtfob & typeBit) !== 0) {
                return false;
            }

            if (showSubtypes && e.WorkType == WorkTypeEnum.Cable) {
                const subTypeBit = 1 << (e.WorkSubType || 0);
                if ((workSubtypesFilterOutBits & subTypeBit) !== 0) {
                    return false;
                }
            }

            return true;
        });
    }

    const buildSummary = function (): WorktimeReportSummaryDTO {

        const entries = filteredEntries();

        const summary: WorktimeReportSummaryDTO = {
            TotalMilliseconds: 0,
            MillisecondsPerWorkType: Array<number>(getMaxEnumValue(WorkTypeEnum)).fill(0),
            MilisecondsPerSubtype: Array<number>(getMaxEnumValue(WorkSubtypeEnum) + 1).fill(0)
        };

        entries.forEach(we => {
            // ApplyReportEntry to Summary
            summary.TotalMilliseconds += we.TotalMilliseconds;
            summary.MillisecondsPerWorkType[we.WorkType - 1] += we.TotalMilliseconds;
            if (we.WorkType == WorkTypeEnum.Cable) {
                var index = we.WorkSubType! > 0
                    ? we.WorkSubType!
                    : 0;
                summary.MilisecondsPerSubtype[index] += we.TotalMilliseconds;
            }
        });

        return summary;
    }

    const selectAllColumnsClicked = () => {
        // Mind that bits logic is inverted
        setWorkTypesFilterOutBits(0);
        setWorkSubtypesFilterOutBits(0);
    };

    const deselectAllColumnsClicked = () => {

        const maxWorkType = getMaxEnumValue(WorkTypeEnum);
        const maxWorkSubType = getMaxEnumValue(WorkSubtypeEnum);

        let workTypeBits = (1 << maxWorkType) - 1;
        let workSubtypeBits = (1 << (maxWorkSubType + 1)) - 1;

        if (workTypeBits > 0) {
            setWorkTypesFilterOutBits(workTypeBits);
        }
        if (workSubtypeBits > 0) {
            setWorkSubtypesFilterOutBits(workSubtypeBits);
        }
    };

    // TODO - Summary must be built according to filtered entries
    var summary = buildSummary();

    return <table className='table table-striped'>
        <thead>
            <tr>
                <th className="align-top">Data</th>
                <th className="align-top">Pracownik</th>
                {props.showProjectColumn && <th className="align-top">
                    Projekt
                    <StringFilter
                        value={projectFilterValue}
                        onChange={onProjectFilterValueChange} />
                </th>}
                {props.showProjectColumn && <th className="align-top">
                    Klient i Model
                    <StringFilter
                        value={clientOrModelFilterValue}
                        onChange={onClientOrModelFilterValueChange} />
                </th>}
                {renderProjectWorkTypesHeaderCells(true, props.projectWorkTypeColumns)}
                <th className="align-top">
                    &nbsp;
                    <div className="mt-1">
                        <div className="btn-group btn-group-sm" role="group" aria-label="Check uncheck">
                            <button type="button" className="btn btn-outline-secondary" onClick={selectAllColumnsClicked} title="Zaznacz wszystkie"><i className="fa-regular fa-check-square"></i></button>
                            <button type="button" className="btn btn-outline-secondary" onClick={deselectAllColumnsClicked} title="Odznacz wszystkie"><i className="fa-regular fa-square"></i></button>
                        </div>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody className="table-group-divider">
            {filteredEntries().map((e, idx) => renderProjectEntryRow(e, idx, props.projectWorkTypeColumns))}
        </tbody>
        <tfoot className="table-group-divider">
            {renderSummaryHeader()}
            {summaryRow(summary)}
        </tfoot>
    </table>
});
