import { useEffect, useState } from "react";

type QDEInputProps = {
    qdeMinutes?: number
    disable?: boolean,
    onQDEChange: (qde?: number) => void
};

export function QDEInput(props: QDEInputProps) {

    const getQDEHours = function (QDEMinutes?: number): number {
        const result = QDEMinutes === undefined ? 0 : Math.floor(QDEMinutes / 60);
        return result;
    };
    const getQDEMinutesFraction = function (QDEMinutes?: number): number {
        const result = QDEMinutes === undefined ? 0 : QDEMinutes % 60;
        return result;
    };

    const extractQDEHoursString = function(qdeMinutes ?: number): string {
        if (qdeMinutes === undefined) {
            return '';
        }

        return getQDEHours(qdeMinutes).toString();
    };

    const extractQDEMinutesString = function(qdeMinutes ?: number): string {
        if (qdeMinutes === undefined) {
            return '';
        }

        return getQDEMinutesFraction(qdeMinutes).toString();
    }

    const calcQDEFromStrings = function(hoursString ?: string, minutesString ?: string): number | undefined {
        if (hoursString == undefined && minutesString === undefined) {
            return undefined;
        }

        let hours = hoursString ? parseInt(hoursString) : 0;
        if (Number.isNaN(hours)) {
            hours = 0;
        }
        let minutes = minutesString ? parseInt(minutesString) : 0;
        if (Number.isNaN(minutes)) {
            minutes = 0;
        }

        const result = hours * 60 + minutes;

        return result;
    }

    const [hoursString, setHoursString] = useState(extractQDEHoursString(props.qdeMinutes));
    const [minutesString, setMinutesString] = useState(extractQDEMinutesString(props.qdeMinutes));

    useEffect(() => {
        setHoursString(extractQDEHoursString(props.qdeMinutes));
        setMinutesString(extractQDEMinutesString(props.qdeMinutes));
    }, [props.qdeMinutes]);

    const onQDEHoursChange = function(event: React.ChangeEvent<HTMLInputElement>) {
        const qdeHoursString = event.target.value;
        // const QDE = calcQDEFromStrings(qdeHoursString, minutesString);

        setHoursString(qdeHoursString);

        // props.onQDEChange(QDE);
    }

    const onQDEMinutesChange = function(event: React.ChangeEvent<HTMLInputElement>) {
        const qdeMinutesString = event.target.value;
        // const QDE = calcQDEFromStrings(hoursString, qdeMinutesString);

        setMinutesString(qdeMinutesString);

        // props.onQDEChange(QDE);
    }

    const onInputBlur = function (event: React.FocusEvent<HTMLInputElement>) {
        const QDE = calcQDEFromStrings(hoursString, minutesString);
        props.onQDEChange(QDE);
    }

    return <div className="input-group input-group-sm">
        <span className="input-group-text">QDE</span>
        <input
            style={{ maxWidth: "6em" }}
            className="form-control"
            id="QDE-hours"
            type="number"
            min={0}
            placeholder="godz."
            title="godz."
            value={hoursString}
            onChange={onQDEHoursChange}
            disabled={props.disable}
            onBlur={onInputBlur} />
        <input
            style={{ maxWidth: "6em" }}
            className="form-control"
            id="QDE-minutes"
            type="number"
            min={0}
            max={59}
            placeholder="min."
            title="min."
            value={minutesString}
            onChange={onQDEMinutesChange}
            disabled={props.disable}
            onBlur={onInputBlur} />
    </div>
}
