import * as signalR from "@microsoft/signalr";
import { ProjectCommandsEnum } from "../types/domain";

class Connector {
    private connection: signalR.HubConnection;

    static instance: Connector;

    public getConnectionId(): string | null {
        return this.connection.connectionId;
    }

    constructor() {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl("/refresh-notify-hub")
            .withAutomaticReconnect()
            .build();
        this.connection.start().catch((err) => document.write(err));

        this.onProjectChanged = this.onProjectChanged.bind(this);
        this.connection.on("projectChanged", this.onProjectChanged);
    }

    private onProjectChanged(projectNumber: string, commandId: ProjectCommandsEnum) {
        const event = new CustomEvent("bamProjectChanged", {
            detail: {
                projectNumber,
                commandId
            }
        });
        document.dispatchEvent(event);
    }

    public subscribe(listener: EventListenerOrEventListenerObject) {
        document.addEventListener("bamProjectChanged", listener);
    }

    public unsubscribe(listener: EventListenerOrEventListenerObject) {
        document.removeEventListener("bamProjectChanged", listener);
    }

    public static getInstance(): Connector {
        if (!Connector.instance)
            Connector.instance = new Connector();
        return Connector.instance;
    }
}

export default Connector.getInstance;
