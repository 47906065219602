import React, { Component, Fragment } from 'react';
import { apiFetchData } from '../utilities/auth-api';
import { GetTransportsResult } from '../types/dto';
import { ExportTransportDTO } from '../types/dto';
import { EnhanceTransports } from '../utilities/data-enhancer';
import authService from './api-authorization/AuthorizeService';
import { TransportWithProjectRows } from './TransportWithProjectRows';
import { AllowsFeature, Features } from '../utilities/features';

type TransportsViewProps = {
};

type TransportsViewState = {
    IsLoading: boolean,
    Transports: ExportTransportDTO[],
    showCreate: boolean
    DateFrom: string,
    DateTo: string,
};

export class TransportsView extends Component<TransportsViewProps, TransportsViewState> {
    static displayName = TransportsView.name;
    input: React.RefObject<HTMLInputElement> | undefined;

    constructor(props: TransportsViewProps) {
        super(props);

        this.state = {
            IsLoading: false,
            Transports: [],
            showCreate: false,
            DateFrom: "",
            DateTo: ""
        };

        this.input = React.createRef();
        this.createTransport = this.createTransport.bind(this);
        this.dateFromChanged = this.dateFromChanged.bind(this);
        this.clearDateFrom = this.clearDateFrom.bind(this);
        this.dateToChanged = this.dateToChanged.bind(this);
        this.clearDateTo = this.clearDateTo.bind(this);
        this.fetchTransports = this.fetchTransports.bind(this);

        authService.getUser().then(userProfile => {
            this.setState({
                showCreate: AllowsFeature(Features.CreateTransport, userProfile)
            });
        });
    }

    componentDidMount() {
        this.fetchTransports();
    }

    async fetchTransports() {
        this.setState({ IsLoading: true });
        const data: Record<string, string> = {};

        const dateFrom = this.state.DateFrom
            ? new Date(this.state.DateFrom)
            : undefined;
        const dateTo = this.state.DateTo
            ? new Date(this.state.DateTo)
            : undefined;

        if (dateFrom) {
            data['dateFrom'] = dateFrom.getTime().toString();
        }
        if (dateTo) {
            data['dateTo'] = dateTo.getTime().toString();
        }

        const params = new URLSearchParams(data);
        const paramsString = params.toString();
        const queryString = paramsString
            ? '?' + params.toString() : '';

        const transportsData = await apiFetchData('transport' + queryString) as GetTransportsResult;
        const transports = ((transportsData && transportsData.ExportTransports) || []) as ExportTransportDTO[];

        EnhanceTransports(transports);

        this.setState({
            Transports: transports,
            IsLoading: false,
        });
    }

    async createTransport(event: React.MouseEvent) {
        var inputObject = this.input!.current as HTMLInputElement;
        event.preventDefault();

        if (!inputObject.value) {
            return;
        }

        const data = {
            Date: inputObject.value
        };

        await apiFetchData('transport', {
            method: 'PUT',
            body: JSON.stringify(data), // TODO - change so there's no need to pass Content-Type
            headers: {
                'Content-Type': 'application/json'
            }
        });

        await this.fetchTransports();
    }

    dateFromChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            DateFrom: event.target.value
        });
    }

    clearDateFrom() {
        this.setState({
            DateFrom: ''
        });
    }

    dateToChanged(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            DateTo: event.target.value
        });
    }

    clearDateTo() {
        this.setState({
            DateTo: ''
        });
    }

    render() {
        const transports = this.state.Transports;
        const hasTransports = transports && transports.length > 0;

        return (
            <>
                <div className="row row-cols-lg-auto g-3 mt-1 mb-2 align-items-center">
                    <div className='col-auto'>
                        <button className='btn btn-outline-secondary' type='button' title='Przeładuj' disabled={this.state.IsLoading} onClick={this.fetchTransports}>
                            <span className={this.state.IsLoading ? 'spinner-border' : 'fa-solid fa-redo'}></span>
                        </button>
                    </div>
                    <div className='col-auto'>
                        <div className='input-group'>
                            <div className='form-floating'>
                                <input type="date" className='form-control form-control-sm' id='dateFrom' value={this.state.DateFrom} onChange={this.dateFromChanged} />
                                <label htmlFor="dateFrom">Transporty od</label>
                            </div>
                            <button className="btn btn-outline-secondary" type="button" onClick={this.clearDateFrom}><i className='fa-solid fa-times' /></button>
                        </div>
                    </div>
                    <div className='col-auto'>
                        <div className='input-group'>
                            <div className='form-floating'>
                                <input type="date" className='form-control form-control-sm' id='dateTo' value={this.state.DateTo} onChange={this.dateToChanged} />
                                <label htmlFor="dateTo">Transporty do</label>
                            </div>
                            <button className="btn btn-outline-secondary" type="button" onClick={this.clearDateTo}><i className='fa-solid fa-times' /></button>
                        </div>
                    </div>
                    {this.state.showCreate &&
                        <div className="col text-end">
                            <div className="input-group">
                                <input type="date" className="form-control" ref={this.input} />
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={this.createTransport}>Utwórz transport</button>
                            </div>
                        </div>
                    }
                </div>
                {!hasTransports && <div className='alert alert-info mt-2'>Brak zdefiniowanych transportów</div>}
                {hasTransports && transports.map(transport => <TransportWithProjectRows key={transport.Id} transport={transport} />)}
            </>
        );
    }
}
