import ExcelJS from 'exceljs';
import { WorkTypeDescriptor } from '../../types/domain';
import { DepartmentWorkTypeExDTO, WorktimeReportEntryDTO } from '../../types/dto';
import { MilisecsToExcelTime } from '../date-utils';
import { DepartmentName, depWorkTypeMatches, workSubtypeAbbr, workTypeAbbr, workTypeMatches } from '../domain-utils';
import { downloadBlob } from '../file-utils';

function workTypeAbbrString(wtc: WorkTypeDescriptor): string {
    if (wtc.SubType !== undefined) {
        return `${workTypeAbbr(wtc.Type)} (${workSubtypeAbbr(wtc.Type, wtc.SubType)})`;
    }

    return workTypeAbbr(wtc.Type);
}

function departmentWorkTypeAbbrString(dpt: DepartmentWorkTypeExDTO): string {
    let resultString: string;
    if (!dpt.Id) {
        resultString = DepartmentName(dpt.DepartmentId);
    } else {
        resultString = `${dpt.Name} ${DepartmentName(dpt.DepartmentId)}`;
    }

    return resultString;
} 

function buildExcelRowValues(
    showProjectAndClientColumns: boolean,
    workTypeColumns: WorkTypeDescriptor[],
    tableEntry: WorktimeReportEntryDTO) {
    const values: any[] = [];

    values.push(tableEntry.Date);
    values.push(tableEntry.Employee.FullName);

    if (showProjectAndClientColumns) {
        values.push(tableEntry.ProjectNumber);
        values.push(tableEntry.ClientName);
    }

    workTypeColumns.forEach((wtc) => {
        if (workTypeMatches(tableEntry, wtc)) {
            values.push(MilisecsToExcelTime(tableEntry.TotalMilliseconds));
        } else {
            values.push('');
        }
    });

    return values;
}

function buildDepartmentExcelRowValues(
    departmentWorkTypes: DepartmentWorkTypeExDTO[],
    tableEntry: WorktimeReportEntryDTO) {
    const values: any[] = [];

    values.push(tableEntry.Date);
    values.push(tableEntry.Employee.FullName);

    departmentWorkTypes.forEach((wtc) => {
        if (depWorkTypeMatches(tableEntry, wtc)) {
            values.push(MilisecsToExcelTime(tableEntry.TotalMilliseconds));
        } else {
            values.push('');
        }
    });

    return values;
}

function ColumnIndexToLetter(index: number): string {
    const charCode = 65 + index;
    return String.fromCharCode(charCode);
}

export function exportWortimeReportToExcel(
    showProjectAndClientColumns: boolean,
    workTypeColumns: WorkTypeDescriptor[],
    data: WorktimeReportEntryDTO[]
) {
    var workbook = new ExcelJS.Workbook();
    var sheet = workbook.addWorksheet("Raport czasu pracy", {
        headerFooter: {
            firstHeader: "Raport"
        }
    });

    const columns: Partial<ExcelJS.Column>[] = [
        { header: "Data", key: 'date', width: 15 },
        { header: "Pracownik", key: 'employee', width: 30 }
    ];

    // if (!this.state.isProjectNumberGiven) {
    if (showProjectAndClientColumns) {
        columns.push({
            header: "Projekt",
            key: 'project',
            width: 15
        });
        columns.push({
            header: "Klient",
            key: 'client',
            width: 30
        });
    };

    // const workTypeColumns = this.getWorkTypeColumns();
    const timeCellFormat = '[h]:mm;@';

    workTypeColumns.forEach((wtc) => {
        const column = {
            header: workTypeAbbrString(wtc),
            width: 10,
            style: { numFmt: timeCellFormat }
        };
        columns.push(column);
    });

    sheet.columns = columns;

    let dataRowCount = 0;
    data.forEach(tableEntry => {
        const values = buildExcelRowValues(
            showProjectAndClientColumns,
            workTypeColumns,
            tableEntry);
        sheet.addRow(values);
        dataRowCount++;
    });

    // Adding summary row with formulas
    const dataStartRowNumber = 2;
    const summaryRowNumber = dataRowCount + dataStartRowNumber;
    const lastDataRowNumber = summaryRowNumber - 1;
    const startDataColumnIndex = showProjectAndClientColumns
        ? 4 : 2;

    const sumTextCell = sheet.getCell(`A${summaryRowNumber}`);
    sumTextCell.value = "Suma";

    workTypeColumns.forEach((wtc, index) => {
        const columnLetter = ColumnIndexToLetter(startDataColumnIndex + index);
        const theCell = sheet.getCell(`${columnLetter}${summaryRowNumber}`);
        theCell.value = {
            formula: `SUM(${columnLetter}${dataStartRowNumber}:${columnLetter}${lastDataRowNumber})`,
            date1904: false
        };
    });

    const startTotalColumnLetter = ColumnIndexToLetter(startDataColumnIndex);
    const lastDataColumnIndex = startDataColumnIndex + workTypeColumns.length - 1;
    const endTotalColumnLetter = ColumnIndexToLetter(lastDataColumnIndex);
    // const totalSumColumnLetter = String.fromCharCode(startDataColumnCharCode + workTypeColumns.length);
    const totalSumCell = sheet.getCell(`B${summaryRowNumber}`);

    totalSumCell.value = {
        formula: `SUM(${startTotalColumnLetter}${summaryRowNumber}:${endTotalColumnLetter}${summaryRowNumber})`,
        date1904: false
    };
    totalSumCell.numFmt = timeCellFormat;

    const summaryCellBorder: Partial<ExcelJS.Borders> = {
        top: { style: 'thick' }
    };
    for (let columnIndex = 0; columnIndex <= lastDataColumnIndex; columnIndex++) {
        const columnLetter = ColumnIndexToLetter(columnIndex);
        sheet.getCell(`${columnLetter}${summaryRowNumber}`).border = summaryCellBorder;
    }

    sheet.views = [
        {
            state: 'frozen',
            ySplit: 1
        }
    ];

    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        downloadBlob(blob, "Excel report");
    });
}

export function exportDepartmentWortimeReportToExcel(
    departmentWorkTypes: DepartmentWorkTypeExDTO[],
    data: WorktimeReportEntryDTO[]
) {
    var workbook = new ExcelJS.Workbook();
    var sheet = workbook.addWorksheet("Raport czasu pracy", {
        headerFooter: {
            firstHeader: "Raport"
        }
    });

    const columns: Partial<ExcelJS.Column>[] = [
        { header: "Data", key: 'date', width: 15 },
        { header: "Pracownik", key: 'employee', width: 30 }
    ];

    // const workTypeColumns = this.getWorkTypeColumns();
    const timeCellFormat = '[h]:mm;@';

    departmentWorkTypes.forEach((wtc) => {
        const column = {
            header: departmentWorkTypeAbbrString(wtc),
            width: 20,
            style: { numFmt: timeCellFormat }
        };
        columns.push(column);
    });

    sheet.columns = columns;

    let dataRowCount = 0;
    data.forEach(tableEntry => {
        const values = buildDepartmentExcelRowValues(
            departmentWorkTypes,
            tableEntry);
        sheet.addRow(values);
        dataRowCount++;
    });

    // Adding summary row with formulas
    const dataStartRowNumber = 2;
    const summaryRowNumber = dataRowCount + dataStartRowNumber;
    const lastDataRowNumber = summaryRowNumber - 1;
    const startDataColumnIndex = 2;

    const sumTextCell = sheet.getCell(`A${summaryRowNumber}`);
    sumTextCell.value = "Suma";

    departmentWorkTypes.forEach((wtc, index) => {
        const columnLetter = ColumnIndexToLetter(startDataColumnIndex + index);
        const theCell = sheet.getCell(`${columnLetter}${summaryRowNumber}`);
        theCell.value = {
            formula: `SUM(${columnLetter}${dataStartRowNumber}:${columnLetter}${lastDataRowNumber})`,
            date1904: false
        };
    });

    const startTotalColumnLetter = ColumnIndexToLetter(startDataColumnIndex);
    const lastDataColumnIndex = startDataColumnIndex + departmentWorkTypes.length - 1;
    const endTotalColumnLetter = ColumnIndexToLetter(lastDataColumnIndex);
    // const totalSumColumnLetter = String.fromCharCode(startDataColumnCharCode + workTypeColumns.length);
    const totalSumCell = sheet.getCell(`B${summaryRowNumber}`);

    totalSumCell.value = {
        formula: `SUM(${startTotalColumnLetter}${summaryRowNumber}:${endTotalColumnLetter}${summaryRowNumber})`,
        date1904: false
    };
    totalSumCell.numFmt = timeCellFormat;

    const summaryCellBorder: Partial<ExcelJS.Borders> = {
        top: { style: 'thick' }
    };
    for (let columnIndex = 0; columnIndex <= lastDataColumnIndex; columnIndex++) {
        const columnLetter = ColumnIndexToLetter(columnIndex);
        sheet.getCell(`${columnLetter}${summaryRowNumber}`).border = summaryCellBorder;
    }

    sheet.views = [
        {
            state: 'frozen',
            ySplit: 1
        }
    ];

    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        downloadBlob(blob, "Excel report");
    });
}