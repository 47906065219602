
export class OperationResult {
    private errors: string[] = [];
    private warnings: string[] = [];

    public AddError(error: string): void {
        this.errors.push(error);
    }

    public AddWarning(warning: string): void {
        this.warnings.push(warning);
    }

    public IsSuccess(): boolean {
        return this.errors.length == 0;
    }

    public GetErrors(): string[] {
        return this.errors;
    }

    public GetWarnings(): string[] {
        return this.warnings;
    }

    public ClearErrors() {
        this.errors = [];
    }
}

export class OperationResultWithData<T> extends OperationResult {
    public Data?: T;
}
