import React, { Component, Fragment } from 'react';
import authService from './AuthorizeService';
import { UserProfile, nullUserProfile } from './UserManager';
import { OperationResultDTO } from '../../types/dto';
import { apiFetchData } from '../../utilities/auth-api';
import { roleName } from '../../utilities/domain-utils';

type ProfilePageProps = {
};

type ProfilePageState = {
    userProfile: UserProfile,
    showMessage: boolean,
    message: string
};

export class ProfilePage extends Component<ProfilePageProps, ProfilePageState> {

    constructor(props: ProfilePageProps) {
        super(props);

        authService.getUser()
            .then(userProfile => {
                if (userProfile) {
                    this.setState({
                        userProfile
                    });
                }
            });

        this.state = {
            userProfile: nullUserProfile,
            showMessage: false,
            message: ''
        };

        this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
        this.hideMessage = this.hideMessage.bind(this);
    }

    handlePasswordSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        var formData = new FormData(event.target as HTMLFormElement);

        apiFetchData('user/password', {
            body: formData,
            method: 'PUT'
        }).then(result => {
            const operationResult = result as OperationResultDTO;
            if (operationResult.Success) {
                this.setState({
                    showMessage: true,
                    message: 'Zmieniono hasło'
                });

                setTimeout(this.hideMessage, 3000);
            }
        });
    }

    hideMessage() {
        this.setState({
            showMessage: false,
            message: ''
        });
    }

    render() {
        const userProfile = this.state.userProfile;
        return <Fragment>
            <h2>Profil użytkownika {userProfile.userName}</h2>
            {this.state.showMessage &&
                <div className="alert alert-success" role="alert">
                    {this.state.message}
                </div>}
            <strong>Role</strong>
            <ul>
                {userProfile.roleIds.map(roleId => {
                    return <li key={roleId}>{roleName(roleId)}</li>
                })}
            </ul>
            <form onSubmit={this.handlePasswordSubmit}>
                <div className="row g-3 align-items-center">
                    <div className="col-auto">
                        <label htmlFor="inputPassword6" className="col-form-label">Hasło</label>
                    </div>
                    <div className="col-auto">
                        <input type="password" name="Password" id="inputPassword6" className="form-control" aria-describedby="passwordHelpInline" />
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-outline-danger">Ustaw hasło</button>
                    </div>
                </div>
            </form>
        </Fragment>;
    }
}
