import { EmployeeActivityStatusEnum } from "../types/domain";

type EmployeeActivityStatusPromptProps = {
    status: EmployeeActivityStatusEnum
};

export function EmployeeActivityStatusPrompt(props: EmployeeActivityStatusPromptProps) {

    const status = props.status;
    const titleTexts = [];
    let color = 'grey';
    const isLoggedOut = (status & EmployeeActivityStatusEnum.LoggedOut) > 0;
    const isLoggedIn = (status & EmployeeActivityStatusEnum.LoggedIn) > 0;
    const isLoginExpired = (status & EmployeeActivityStatusEnum.LoginExpired) > 0;
    const isWorkInProgress = (status & EmployeeActivityStatusEnum.WorkInProgress) > 0;
    const isWorkStopped = (status & EmployeeActivityStatusEnum.WorkStopped) > 0;
    const isWorkNotStarted = (status & EmployeeActivityStatusEnum.NoWorkEntries) > 0;
    if (isLoggedIn && isWorkInProgress) {
        color = 'green';
    }
    else if (!isLoggedIn && isWorkInProgress) {
        color = 'orange';
    }
    else if (isLoggedIn && isWorkNotStarted) {
        color = 'yellow';
    }

    if (isLoginExpired) {
        titleTexts.push("login wygasł");
    } else if (isLoggedOut) {
        titleTexts.push("wylogowany");
    } else if (isLoggedIn) {
        titleTexts.push("zalogowany");
    }

    if (isLoggedIn)
    {
        if (isWorkNotStarted) {
            titleTexts.push("nie rozpoczęto pracy");
        } else if (isWorkStopped) {
            titleTexts.push("zatrzymano pracę");
        } else if (isWorkInProgress) {
            titleTexts.push("praca w toku");
        }
    }

    const title = titleTexts.join(', ');

    return <span style={{ color }} title={title}>
        {isLoggedOut && <i className="fa-solid fa-sign-out-alt" />}
        {isLoginExpired && <i className="ms-1 fa-regular fa-clock" />}
        {isLoggedIn && <i className="fa-solid fa-sign-in-alt" />}
        {isWorkInProgress && <i className="ms-1 fa-solid fa-play"> </i>}
        {isWorkStopped && <i className="ms-1 fa-solid fa-stop"> </i>}
    </span>;
}
