import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavbarText, NavbarToggler, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';

import './NavMenu.css';

type MainHeaderState = {
    isLoginMenuOpen: boolean
};

export class MainHeader extends Component<{}, MainHeaderState> {
    static displayName = MainHeader.name;

    constructor(props: {}) {
        super(props);

        this.toggleLoginMenu = this.toggleLoginMenu.bind(this);

        this.state = {
            isLoginMenuOpen: false
        };
    }

    toggleLoginMenu() {
        this.setState({
            isLoginMenuOpen: !this.state.isLoginMenuOpen
        });
    }

    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm ng-white border-bottom box-shadow" light>
                    <NavbarBrand tag={Link} to="/">BAM Project Manager</NavbarBrand>
                    <NavbarToggler onClick={this.toggleLoginMenu} className="mr-2">
                        <i className="fa-solid fa-user"></i>
                    </NavbarToggler>
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isLoginMenuOpen} navbar>
                        <ul className="navbar-nav flex-grow">
                            <LoginMenu/>
                        </ul>
                    </Collapse>
                </Navbar>
            </header>
        );
    }
}
