import { DepartmentWorkTypeDTO } from "../types/dto";
import { detailsAreaClass, FormInputSize, formSelectClass } from "../utilities/form-utils";

type DepartmentWorkTypeFormControlsProps = {
    workTypeId: number,     // 0 means not selected
    details?: string,
    size?: FormInputSize
    onWorkTypeChange: (workTypeId: number) => void,
    onDetailsChange: (details: string) => void,
    workTypes: DepartmentWorkTypeDTO[]
};

export default function DepartmentWorkTypeFormControls(props: DepartmentWorkTypeFormControlsProps) {

    const onWorkTypeSelected = function(event: React.ChangeEvent<HTMLSelectElement>) {
        const workType = parseInt(event.target.value);
        props.onWorkTypeChange(workType);
    }
    const onWorkTypeDetailsChanged = function(event: React.ChangeEvent<HTMLTextAreaElement>) {
        props.onDetailsChange(event.target.value);
    }

    return <>
        <select className={formSelectClass(props.size)} onChange={onWorkTypeSelected} value={props.workTypeId}>
            <option value="0" disabled={true}>wybierz typ pracy</option>
            {props.workTypes.map(wt =>
                <option key={wt.Id} value={wt.Id}>{wt.Name}</option>
            )}
        </select>
        <textarea className={detailsAreaClass(props.size)} onChange={onWorkTypeDetailsChanged} value={props.details} placeholder="szczegóły" />
    </>;
}
