import React, { Component } from 'react';
import { EmployeeBriefDTO, QDETicketDTO } from '../types/dto';
import { fetchEmployeesToSelect } from '../utilities/data-fetch';
import { QDETicketRow } from './QDETicketRow';
import { Accordion } from 'reactstrap';
import { QDEStatusFilter } from './common/QDEStatusFilter';
import { QDETicketTypeFilter } from './common/QDETicketTypeFilter';
import { QDETicketStatus, QDETicketTypeEnum } from '../types/domain';
import { StringFilter } from './common/StringFilter';
import { DateRangeFilter } from './common/DateRangeFilter';

type QDETicketsListEditableProps = {
    tickets: QDETicketDTO[],
    canEditStatus: boolean,
    onTicketChanged?: (ticket: QDETicketDTO) => void,
    projectNumber?: number
};

type QDETicketsListEditableState = {
    employeesToSelect: EmployeeBriefDTO[],
    openedAccordion: string,
    idFilterValue?: string,
    statusFilterValue?: QDETicketStatus,
    typeFilterValue?: QDETicketTypeEnum,
    projectFilterValue?: string,
    clientFilterValue?: string,
    creatorEmployeeFilterValue?: string,
    descriptionFilterValue?: string,
    fromDateString: string,
    toDateString: string,
    fromDateFilterValue?: Date
    toDateFilterValue?: Date
};

export class QDETicketsListEditable extends Component<QDETicketsListEditableProps, QDETicketsListEditableState> {
    static displayName = QDETicketsListEditable.name;

    constructor(props: QDETicketsListEditableProps) {
        super(props);

        this.state = {
            employeesToSelect: [],
            openedAccordion: '',
            fromDateString: '',
            toDateString: ''
        };

        this.fetchEmployeesToSelect();
        this.onAccordionToggle = this.onAccordionToggle.bind(this);
        this.filteredItems = this.filteredItems.bind(this);
        this.onIdFilterChange = this.onIdFilterChange.bind(this);
        this.onQDEStatusFilterChange = this.onQDEStatusFilterChange.bind(this);
        this.onQDETicketTypeChange = this.onQDETicketTypeChange.bind(this);
        this.onProjectFilterChange = this.onProjectFilterChange.bind(this);
        this.onClientFilterChange = this.onClientFilterChange.bind(this);
        this.onCreatorEmployeeFilterChange = this.onCreatorEmployeeFilterChange.bind(this);
        this.onDescriptionFilterChange = this.onDescriptionFilterChange.bind(this);
        this.onFromDateChanged = this.onFromDateChanged.bind(this);
        this.onToDateChanged = this.onToDateChanged.bind(this);
    }


    async fetchEmployeesToSelect() {

        const employeesToSelect = await fetchEmployeesToSelect(false);

        this.setState({
            employeesToSelect
        });
    }

    onAccordionToggle(id: string) {
        const openedAccordion = id == this.state.openedAccordion
            ? ''
            : id;

        this.setState({
            openedAccordion
        });
    }

    onIdFilterChange(idFilterValue?: string) {
        this.setState({
            idFilterValue
        });
    }

    onQDEStatusFilterChange(statusFilterValue?: QDETicketStatus) {
        this.setState({
            statusFilterValue
        });
    }

    onQDETicketTypeChange(typeFilterValue?: QDETicketTypeEnum) {
        this.setState({
            typeFilterValue
        });
    }

    onProjectFilterChange(projectFilterValue?: string) {
        this.setState({
            projectFilterValue
        });
    }

    onClientFilterChange(clientFilterValue?: string) {
        this.setState({
            clientFilterValue
        });
    }

    onCreatorEmployeeFilterChange(creatorEmployeeFilterValue?: string) {
        this.setState({
            creatorEmployeeFilterValue
        });
    }

    onDescriptionFilterChange(descriptionFilterValue?: string) {
        this.setState({
            descriptionFilterValue
        });
    }

    filteredItems(): QDETicketDTO[] {

        const creatorFilterValue = this.state.creatorEmployeeFilterValue
            ? this.state.creatorEmployeeFilterValue.toLowerCase()
            : null;
        const descriptionFilterValue = this.state.descriptionFilterValue
            ? this.state.descriptionFilterValue.toLowerCase()
            : null;
        const clientFilterValue = this.state.clientFilterValue
            ? this.state.clientFilterValue.toLowerCase()
            : null;

        return this.props.tickets.filter(ticket => {
            if (this.state.idFilterValue !== undefined
                && !ticket.Id.toString().includes(this.state.idFilterValue)) {
                return false;
            }
            if (this.state.statusFilterValue !== undefined
                && ticket.TicketStatus !== this.state.statusFilterValue) {
                return false;
            }
            if (this.state.typeFilterValue !== undefined
                && ticket.QDETicketType !== this.state.typeFilterValue) {
                return false;
            }
            if (this.state.projectFilterValue
                && ticket.Project
                && !ticket.Project.ProjectNumber.toString().includes(this.state.projectFilterValue)) {
                return false;
            }
            if (creatorFilterValue
                && !(ticket.CreatorEmployee && ticket.CreatorEmployee.FullName.toLowerCase().includes(creatorFilterValue))) {
                return false;
            }
            if (descriptionFilterValue
                && !(ticket.Description && ticket.Description.toLowerCase().includes(descriptionFilterValue))) {
                return false;
            }
            if (this.state.fromDateFilterValue
                && (!ticket.Date || ticket.Date < this.state.fromDateFilterValue)) {
                return false;
            }
            if (this.state.toDateFilterValue
                && (!ticket.Date || ticket.Date > this.state.toDateFilterValue)) {
                return false;
            }
            if (clientFilterValue
                && ticket.Project
                && !ticket.Project.ClientName.toLowerCase().includes(clientFilterValue)) {
                return false;
            }

            return true;
        });
    }

    onFromDateChanged(fromDateString: string, fromDateFilterValue?: Date) {
        this.setState({
            fromDateString,
            fromDateFilterValue
        });
    }

    onToDateChanged(toDateString: string, toDateFilterValue?: Date) {

        // Set date's end of day
        if (toDateFilterValue) {
            toDateFilterValue.setHours(23, 59, 59);
        }

        this.setState({
            toDateString,
            toDateFilterValue
        });
    }

    render() {
        const includeProjectColumn = !this.props.projectNumber;

        return (
            <table className="table">
                <thead>
                    <tr>
                        <th className="align-top">Id<StringFilter value={this.state.idFilterValue} onChange={this.onIdFilterChange} /></th>
                        <th className="align-top">Status<QDEStatusFilter value={this.state.statusFilterValue} onChange={this.onQDEStatusFilterChange} /></th>
                        {includeProjectColumn && <>
                            <th className="align-top">Projekt<StringFilter value={this.state.projectFilterValue} onChange={this.onProjectFilterChange} /></th>
                            <th className="align-top">Klient<StringFilter value={this.state.clientFilterValue} onChange={this.onClientFilterChange} /></th>
                        </>}
                        <th className="align-top">Typ<QDETicketTypeFilter value={this.state.typeFilterValue} onChange={this.onQDETicketTypeChange} /></th>
                        <th className="align-top">Zgłaszający<StringFilter value={this.state.creatorEmployeeFilterValue} onChange={this.onCreatorEmployeeFilterChange} /></th>
                        <th className="align-top">Opis<StringFilter value={this.state.descriptionFilterValue} onChange={this.onDescriptionFilterChange} /></th>
                        <th className="align-top">Data<DateRangeFilter
                            fromDate={this.state.fromDateFilterValue}
                            onFromDateChanged={this.onFromDateChanged}
                            toDate={this.state.toDateFilterValue}
                            onToDateChanged={this.onToDateChanged}
                            />
                        </th>
                        <th className="align-top">Wartość QDE</th>
                    </tr>
                </thead>
                <Accordion open={this.state.openedAccordion} toggle={this.onAccordionToggle} tag="tbody" id="qde-tickets-tbody">
                    {this.filteredItems().map(t => {
                        const accordionId = `qde-ticket-details-${t.Id}`;

                        return <QDETicketRow key={t.Id}
                            isExpanded={this.state.openedAccordion==accordionId}
                            accordionId={accordionId}
                            includeProjectColumn={includeProjectColumn}
                            qdeTicket={t}
                            onTicketChanged={this.props.onTicketChanged}
                            canEditStatus={this.props.canEditStatus}
                            employeesToSelect={this.state.employeesToSelect}
                        />
                    })}
                </Accordion>
            </table>
        );
    }
}
