import {
    BAMProjectDTO,
    BriefExportTransportDTO,
    BriefBAMProjectDTO,
    ExportTransportDTO,
    PelikanDTO,
    DetailedPelikanDTO,
    PelikanLogEntryDTO,
    QDETicketDTO,
    BriefPelikanDTO
} from '../types/dto';
import { DateToDateString, DateToDateTimeString } from './date-formatter';

export function CalculateDates(instance: any) {
    if (!instance) {
        return;
    }

    Object.entries(instance).forEach(([key, value]) => {
        const suffixIndex = key.indexOf('JsMilisecs');
        if (suffixIndex > 0 && value && typeof value == 'number') {
            const baseName = key.substring(0, suffixIndex);
            instance[baseName] = new Date(value);
        } else if (instance instanceof Object) {
            CalculateDates(value);
        }
    });
}

export function EnhanceBriefTransport(transport: BriefExportTransportDTO) {
    CalculateDates(transport);
    transport.ExportDateText = DateToDateString(transport.ExportDate!);
}

export function EnhanceProject(project: BAMProjectDTO) {
    CalculateDates(project);
    project.StartDateText = DateToDateString(project.StartDate!);
    project.EndDateText = DateToDateString(project.EndDate!);
    if (project.ExportTransport) {
        EnhanceBriefTransport(project.ExportTransport);
    }
}

export function EnhanceProjects(projects: BAMProjectDTO[]) {
    projects.forEach(EnhanceProject);
}

export function EnhanceBriefBAMProjectDTO(project: BriefBAMProjectDTO) {
    CalculateDates(project);
    project.StartDateText = DateToDateString(project.StartDate!);
    project.EndDateText = DateToDateString(project.EndDate!);
}

export function EnhanceBriefTransports(transports: BriefExportTransportDTO[]) {
    transports.forEach(EnhanceBriefTransport);
};

export function EnhanceTransport(transport: ExportTransportDTO) {
    CalculateDates(transport);
    transport.ExportDateText = DateToDateString(transport.ExportDate!);

    transport.AssignedProjects.forEach(EnhanceBriefBAMProjectDTO);
}

export function EnhanceTransports(transports: ExportTransportDTO[]) {
    transports.forEach(EnhanceTransport);
}

export function EnhancePelikan(pelikan: PelikanDTO) {
    pelikan.CreatedDateText = DateToDateTimeString(pelikan.CreatedDate!);
    pelikan.LastUpdatedText = DateToDateTimeString(pelikan.LastUpdated!);
}

export function EnhanceBriefPelikan(pelikan: BriefPelikanDTO) {
    pelikan.CreatedDateText = DateToDateTimeString(pelikan.CreatedDate!);
}

export function EnchancePelikanLogEntry(entry: PelikanLogEntryDTO) {
    entry.DateOfChangeText = DateToDateTimeString(entry.DateOfChange!);
}

export function EnhanceDetailedPelikan(pelikan: DetailedPelikanDTO) {
    EnhancePelikan(pelikan);

    if (pelikan.LogEntries) {
        pelikan.LogEntries.forEach(EnchancePelikanLogEntry);
    }
}

export function EnhancePelikans(pelikans: PelikanDTO[]) {
    pelikans.forEach(EnhancePelikan);
}

export function EnhanceQDETicket(ticket: QDETicketDTO) {
    if (ticket.RelatedPelikan) {
        EnhanceBriefPelikan(ticket.RelatedPelikan);
    }
}

export function EnhanceQDETickets(tickets: QDETicketDTO[]) {
    tickets.forEach(EnhanceQDETicket);
}
