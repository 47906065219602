import React, { Component } from 'react';
import { CabinetModelSubtypeBriefDTO, OperationResultDTO, OperationResultWithDataDTO } from '../types/dto';
import { apiFetchResponse } from '../utilities/auth-api';
import { AlertComponent, AlertMessage } from './common/AlertComponent';

type CabinetSubTypesProps = {
    subtypes?: CabinetModelSubtypeBriefDTO[],
    canManage: boolean,
    onChange: () => void,
    projectNumber: number,
    cabinetModelId?: number
};

type CabinetSubTypesState = {
    newSubTypeName: string,
    alertMessage?: AlertMessage,
    subtypeToRemove?: CabinetModelSubtypeBriefDTO
};

export class CabinetSubTypes extends Component<CabinetSubTypesProps, CabinetSubTypesState> {
    static displayName = CabinetSubTypes.name;

    constructor(props: CabinetSubTypesProps) {
        super(props);

        this.state = {
            newSubTypeName: ''
        };

        this.onNewSubtypeNameChange = this.onNewSubtypeNameChange.bind(this);
        this.onAddSubtype = this.onAddSubtype.bind(this);
        this.onSearchModelSubtypes = this.onSearchModelSubtypes.bind(this);
        this.removeMessage = this.removeMessage.bind(this);
        this.onSubTypeRemove = this.onSubTypeRemove.bind(this);
        this.confirmSubtypeRemoval = this.confirmSubtypeRemoval.bind(this);
        this.cancelSubtypeRemoval = this.cancelSubtypeRemoval.bind(this);
        this.showMessage = this.showMessage.bind(this);
    }

    onNewSubtypeNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            newSubTypeName: event.target.value
        });
    }

    async onAddSubtype() {
        const data = {
            name: this.state.newSubTypeName,
            projectNumber: this.props.projectNumber
        };

        const response = await apiFetchResponse('model-sub-type', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status == 200) {

            const result = await response.json() as OperationResultWithDataDTO<CabinetModelSubtypeBriefDTO>;
            if (result.Success) {
                this.setState({
                    newSubTypeName: ''
                });
                this.props.onChange();
            } else {
                this.showMessage(result.Errors![0].Message, "alert-danger", 5000);
            }
        }
    }

    showMessage(text: string, className: string, displayMs: number) {
        this.setState({
            alertMessage: {
                text,
                className: className + " p-1",
                displayMs
            }
        });
    }

    async onSearchModelSubtypes() {
        const response = await apiFetchResponse(`project/${this.props.projectNumber}/model-subtypes`,
            {
                method: 'PATCH'
            });

        if (response.status == 200) {
            const result = await response.json() as OperationResultWithDataDTO<CabinetModelSubtypeBriefDTO[]>;
            if (result.Success) {
                this.props.onChange();
                if (result.Data?.length == 0) {
                    this.showMessage(
                        "Przypisano model, ale bez szaf",
                        "alert-success",
                        3000
                    );
                }
            } else {
                const message = result.Errors
                    && result.Errors.length > 0
                    ? result.Errors[0].Message
                    : "Błąd przypisania szaf";

                this.showMessage(message, "alert-danger", 5000);
            }
        }
    }

    onSubTypeRemove(subtypeToRemove: CabinetModelSubtypeBriefDTO) {
        this.setState({
            subtypeToRemove
        });
    }

    async confirmSubtypeRemoval() {
        const subtypeId = this.state.subtypeToRemove!.Id;
        const subtypeName = this.state.subtypeToRemove!.SubTypeName;

        this.setState({
            subtypeToRemove: undefined
        });

        const response = await apiFetchResponse(`model-sub-type/${subtypeId}`, {
            method: 'DELETE'
        });

        if (response.status == 200) {
            const result = await response.json() as OperationResultDTO;
            if (result.Success) {
                this.showMessage(`Usunięto szafę ${subtypeName}`, "alert-success", 3000);
                this.props.onChange();
            } else {
                this.showMessage(result.Errors![0].Message, "alert-danger", 5000);
            }
        } else {
            this.showMessage("Błąd usunięcia szafy", "alert-danger", 5000);
        }
    }

    cancelSubtypeRemoval() {
        this.setState({
            subtypeToRemove: undefined
        });
    }

    renderModelSubTypes(modelSubtypes: CabinetModelSubtypeBriefDTO[]) {
        return modelSubtypes.map((mst, idx) => <span className="badge bg-secondary" key={idx}>{mst.SubTypeName}<button className="border border-0 fa-solid fa-times bg-transparent" onClick={() => this.onSubTypeRemove(mst)} /></span>);
    }

    removeMessage() {
        this.setState({
            alertMessage: undefined
        });
    }

    render() {
        const modelSubtypes: CabinetModelSubtypeBriefDTO[] = this.props.subtypes || [];
        // NOTE - Searching for model subtypes is disabled for now
        // TODO - remove it if turns out to be unnecessary
        const enableSearchModelSubtypes = false;

        if (this.props.canManage) {
            const hasSubtypes = modelSubtypes.length > 0;
            const hasModelSet = !!this.props.cabinetModelId;
            return (
                <>
                    <div className="input-group input-group-sm">
                        {hasSubtypes && <span className="input-group-text">{this.renderModelSubTypes(modelSubtypes)}</span>}
                        <input className="form-control"
                            type="text"
                            value={this.state.newSubTypeName}
                            onChange={this.onNewSubtypeNameChange}
                            placeholder="nowa szafa" />
                        <button className="btn btn-outline-secondary" title="Dodaj" disabled={!this.state.newSubTypeName} onClick={this.onAddSubtype}><i className="fa-solid fa-plus"></i></button>
                        {!hasModelSet && enableSearchModelSubtypes &&
                            <button className="btn btn-outline-secondary" title="Wyszukaj szafy dla modelu" onClick={this.onSearchModelSubtypes}>Utwórz model</button>
                        }
                    </div>
                    {this.state.subtypeToRemove &&
                        <AlertComponent alertMessage={{ className: "alert-danger p-1" }}>
                            Usunąć szafę '{this.state.subtypeToRemove.SubTypeName}'?
                            <button className="ms-2 btn btn-sm btn-outline-danger" onClick={this.confirmSubtypeRemoval}>Usuń</button>
                            <button className="ms-2 btn btn-sm btn-outline-secondary" onClick={this.cancelSubtypeRemoval}>Anuluj</button>
                        </AlertComponent>}
                    <AlertComponent alertMessage={this.state.alertMessage} messageHidden={this.removeMessage} />
                </>
            );
        } else {
            return this.renderModelSubTypes(modelSubtypes);
        }
    }
}
