import { KnownRolesEnum, PelikanAssignmentData } from "../../types/domain";
import { EmployeeBriefDTO } from "../../types/dto";
import { roleName } from "../../utilities/domain-utils";
import { FormValidationUtility } from "./FormValidationUtility"

type PelikanAssignmentSelectProps = {
    fvu?: FormValidationUtility,
    assignedRole?: KnownRolesEnum,
    assignedEmployeeId?: number,
    assignedToWorkers?: boolean,
    rolesToSelect: KnownRolesEnum[],
    employeesToSelect: EmployeeBriefDTO[],
    showProjectWorkersOption: boolean
    onChange: (roleOrEmployee?: PelikanAssignmentData) => void
}
export function PelikanAssignmentSelect(props: PelikanAssignmentSelectProps) {

    const fvu = props.fvu || new FormValidationUtility();

    const getRoleTextValue = function (role: KnownRolesEnum) {
        return "role_" + role.toString();
    }

    const getEmployeeIdTextValue = function (employeeId: number) {
        return "employee_" + employeeId.toString();
    }

    const getAssignedSelectValue = function (
        assignedRole?: KnownRolesEnum,
        assignedEmployeeId?: number,
        assignedToWorkers?: boolean
    ) {
        let textValue = "0";
        if (assignedRole) {
            textValue = getRoleTextValue(assignedRole);
        } else if (assignedEmployeeId) {
            textValue = getEmployeeIdTextValue(assignedEmployeeId);
        } else if (assignedToWorkers) {
            textValue = "project_workers";
        }

        return textValue;
    }

    const getRoleOption = function(role: KnownRolesEnum) {
        const textValue = getRoleTextValue(role);
        return <option key={textValue} value={textValue}>{roleName(role)}</option>;
    }

    const getEmployeeOption = function(employee: EmployeeBriefDTO) {
        const textValue = getEmployeeIdTextValue(employee.Id);
        return <option key={textValue} value={textValue}>{employee.FullName}</option>;
    }

    const getPelikanAssignmentFromOptionValue = function (textValue: string): PelikanAssignmentData | undefined {
        if (textValue.startsWith("role_")) {
            const roleIdString = textValue.substr("role_".length);
            const roleId: KnownRolesEnum = parseInt(roleIdString);

            return {
                assignedRole: roleId,
                assignedEmployeeId: undefined,
                assignedToWorkers: undefined
            };
        } else if (textValue.startsWith("employee_")) {
            const employeeIdString = textValue.substr("employee_".length);
            const employeeId = parseInt(employeeIdString);

            return {
                assignedRole: undefined,
                assignedEmployeeId: employeeId,
                assignedToWorkers: undefined
            };
        } else {
            return {
                assignedRole: undefined,
                assignedEmployeeId: undefined,
                assignedToWorkers: true
            }
        }

        return undefined;
    }

    const onSelected = function (event: React.ChangeEvent<HTMLSelectElement>) {
        const textValue = event.currentTarget.value;

        const selection = getPelikanAssignmentFromOptionValue(textValue);

        if (props.onChange) {
            props.onChange(selection);
        }
    }

    return <>
        <select id="assigned" className={`form-select form-select-sm ${fvu.invalidClass('assignedRole')} ${fvu.invalidClass('assignedEmployeeId')}`} onChange={onSelected} value={getAssignedSelectValue(
            props.assignedRole,
            props.assignedEmployeeId,
            props.assignedToWorkers)}>
            <option disabled={true} value="0">Nie wybrano</option>
            <optgroup label="Rola">
                {props.rolesToSelect.map(getRoleOption)}
            </optgroup>
            <optgroup label="Pracownik">
                {props.employeesToSelect.map(getEmployeeOption)}
            </optgroup>
            {props.showProjectWorkersOption && <optgroup label="Inne">
                <option value="project_workers">Pracownicy projektu</option>
            </optgroup>}
        </select>
        {fvu.fieldFeedback('assignedRole')}
        {fvu.fieldFeedback('assignedEmployeeId')}
    </>
}